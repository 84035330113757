import { memo } from 'react';

const ReportInfoSkeleton = memo(function ReportInfoSkeleton() {
  return (
    <div className="flex flex-col gap-6">
      <div className="h-32 w-full animate-pulse rounded-md bg-gray-100" />
      <div className="flex flex-col gap-2">
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
        <div className="h-8 animate-pulse rounded-md bg-gray-50" />
      </div>
    </div>
  );
});

export default ReportInfoSkeleton;
