import { ReportSendScheduleSchema } from 'lib/model';
import { memo } from 'react';
import CreateScheduleForm from './CreateScheduleForm';
import UpdateScheduleForm from './UpdateScheduleForm';
import { ScheduleReportFormValues } from './interfaces';

interface ScheduleReportFormProps {
  schedule?: ReportSendScheduleSchema;
  createSchedule: (data: ScheduleReportFormValues) => void;
  updateSchedule: (data: ScheduleReportFormValues) => void;
  deleteSchedule: () => void;
}

function ScheduleReportForm({ schedule, createSchedule, updateSchedule, deleteSchedule }: ScheduleReportFormProps) {
  const createScheduleAction = schedule ? updateSchedule : createSchedule;

  return (
    <>
      {!schedule ? (
        <CreateScheduleForm scheduleAction={createScheduleAction} />
      ) : (
        <UpdateScheduleForm deleteSchedule={deleteSchedule} schedule={schedule!} updateSchedule={updateSchedule} />
      )}
    </>
  );
}

export default memo(ScheduleReportForm);
