import { dayOfMonthOptions, dayOfWeekOptions } from 'constants/formOptions';
import { timeOptions } from 'constants/generated';
import { ReportSendScheduleSchema } from 'lib/model';
import Tooltip from 'modules/common/Tooltip';
import ReportIcon from 'modules/reports/reports/components/ReportIcon';
import { memo } from 'react';
import { EventCardProps } from '../EventCard';

function ReportScheduledEvent({ event }: EventCardProps) {
  const schedule = event.json_content!.schedule as ReportSendScheduleSchema;
  let scheduleText = '';
  if (schedule.frequency === 'daily') {
    scheduleText = 'Every day at ' + timeOptions.find((option) => option.id + ':00' === schedule.time_of_day)?.label;
  } else if (schedule.frequency === 'weekly') {
    scheduleText =
      'Every week on ' +
      dayOfWeekOptions.find((option) => option.id === schedule.day_of_week)?.label +
      ' at ' +
      timeOptions.find((option) => option.id + ':00' === schedule.time_of_day)?.label;
  } else if (schedule.frequency === 'monthly') {
    scheduleText =
      'Every month on ' +
      dayOfMonthOptions.find((option) => option.id === schedule.day_of_month)?.label +
      ' at ' +
      timeOptions.find((option) => option.id + ':00' === schedule.time_of_day)?.label;
  } else if (schedule.frequency === 'after_iteration') {
    scheduleText = 'Every time a new update is available';
  }

  return (
    <div className="flex flex-col gap-1 rounded-md bg-orange-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex flex-col gap-1">
        <span className="text-tiny font-medium">{scheduleText}</span>
        <div className="flex justify-between gap-4">
          <div className="flex min-w-0 items-center gap-2">
            <ReportIcon reportType={event.json_content!.report_type} size="sm" />
            <span className="truncate text-xs font-semibold" title={event.json_content!.report_name}>
              {event.json_content!.report_name}
            </span>
          </div>
          <Tooltip message={schedule.user_emails!.join('\n')}>
            <span className="shrink-0 rounded-badge border border-gray-300 px-2 py-1 text-tiny font-medium">
              to {schedule.user_emails!.length} Emails
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default memo(ReportScheduledEvent);
