import { useParentSize } from '@visx/responsive';
import PortalTooltip from 'modules/common/PortalTooltip';
import { getDefaultColor } from 'modules/reports/helpers';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import Jersey from '../../../Jersey';
import { LineupPlayer, LineupTeam } from '../interfaces';

interface LineupPitchGridProps {
  grid: LineupPlayer[][] | null;
  team: LineupTeam;
  reversed?: boolean;
  widthBorder: number;
}

function LineupPitchGrid({ grid, team, reversed, widthBorder }: LineupPitchGridProps) {
  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  if (!grid) {
    return null;
  }

  const players = reversed ? grid.toReversed() : grid;
  return players.map((row, i) => (
    <div key={`row-${i}`} className="flex justify-around" ref={parentRef}>
      {row.map((player) => (
        <div
          key={player.player_id}
          className={twJoin('flex basis-full flex-col items-center gap-1', reversed && 'justify-end')}
        >
          <PortalTooltip message={width < widthBorder ? player.player_name : ''}>
            <Jersey color={team.team_color ?? getDefaultColor(0)} shirt_number={player.shirt_number} size="md" shadow />
          </PortalTooltip>
          <span
            className={twJoin(
              'rounded-badge bg-white/80 px-2 py-1 text-center text-micro font-bold shadow-card',
              width < widthBorder && 'hidden'
            )}
          >
            {player.player_name}
          </span>
        </div>
      ))}
    </div>
  ));
}

export default memo(LineupPitchGrid);
