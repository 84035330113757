import EyeOut from 'assets/eye-out.svg?react';
import Eye from 'assets/eye.svg?react';
import Info from 'assets/info.svg?react';
import { memo, useCallback, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

interface PasswordInputProps {
  registerReturn: UseFormRegisterReturn;
  label: string;
  disabled?: boolean;
  error?: FieldError;
  info?: string;
}

function PasswordInput({ registerReturn, label, disabled, error, info }: PasswordInputProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleToggleShowPassword = useCallback(function handleToggleShowPassword() {
    setShowPassword((password) => !password);
  }, []);

  return (
    <div className="relative flex flex-col items-start gap-2">
      <div className="relative flex w-full flex-col">
        <input
          {...registerReturn}
          placeholder=""
          id={registerReturn.name}
          disabled={disabled}
          type={showPassword ? 'text' : 'password'}
          className={twMerge(
            'border-px focus: peer block min-h-14 w-full appearance-none rounded-lg border-gray-300 bg-white pb-1.5 pl-3 pr-10 pt-6 text-md focus:outline-none focus:ring-0',
            error && 'border-red-600 text-red-600'
          )}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-3 z-10 flex items-center justify-center"
          onClick={handleToggleShowPassword}
        >
          {showPassword ? (
            <Eye width={20} height={20} className={twJoin(error ? 'fill-red-600' : 'fill-gray-700')} />
          ) : (
            <EyeOut width={20} height={20} className={twJoin(error ? 'fill-red-600' : 'fill-gray-700')} />
          )}
        </button>
        <label
          htmlFor={registerReturn.name}
          className={twMerge(
            'absolute start-3 top-1.5 z-10 origin-[0] transform text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs',
            error && 'text-red-600'
          )}
        >
          {label}
        </label>
      </div>
      {info && (
        <div className="flex items-center gap-2">
          <Info className="size-5 fill-gray-700" />
          <span className="text-sm">{info}</span>
        </div>
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(PasswordInput);
