import { ActivityLogDefaultSchema } from 'lib/model';
import { memo } from 'react';
import AddedToReportEvent from './Events/AddedToReportEvent';
import DataSourcesAddedEvent from './Events/DataSourcesAddedEvent';
import DataSourcesRemovedEvent from './Events/DataSourcesRemovedEvent';
import ReportScheduledEvent from './Events/ReportScheduledEvent';
import ReportSentEvent from './Events/ReportSentEvent';
import ReportSharedEvent from './Events/ReportSharedEvent';
import UsersAddedEvent from './Events/UsersAddedEvent';

export interface EventCardProps {
  event: ActivityLogDefaultSchema;
}

function EventCard({ event }: EventCardProps) {
  if (event.activity_type === 'report_shared') {
    return <ReportSharedEvent event={event} />;
  } else if (event.activity_type === 'report_sent') {
    return <ReportSentEvent event={event} />;
  } else if (event.activity_type === 'report_scheduled') {
    return <ReportScheduledEvent event={event} />;
  } else if (event.activity_type === 'added_to_report') {
    return <AddedToReportEvent event={event} />;
  } else if (event.activity_type === 'datasources_added') {
    return <DataSourcesAddedEvent event={event} />;
  } else if (event.activity_type === 'datasources_removed') {
    return <DataSourcesRemovedEvent event={event} />;
  } else if (event.activity_type === 'users_added') {
    return <UsersAddedEvent event={event} />;
  }
  return null;
}

export default memo(EventCard);
