/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator,
  default as getProjectProjectIdConfigurationProjectConfigurationIdMutator,
  default as postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator,
  default as postProjectProjectIdConfigurationMutator,
  default as postProjectProjectIdConfigurationProjectConfigurationIdMutator,
  default as putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
} from '../../api/axios';
import type {
  DatasourceConfigurationCreateSchema,
  DatasourceConfigurationSchema,
  GetProjectProjectIdConfigurationProjectConfigurationId200,
  GetProjectProjectIdConfigurationProjectConfigurationIdParams,
  ProjectConfigurationCreateSchema,
  ProjectConfigurationSchema,
  ProjectDeploymentSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Adds a datasource to the specified project configuration
 */
export const postProjectConfigurationProjectConfigurationIdDatasourceConfiguration = (
  projectConfigurationId: string,
  datasourceConfigurationCreateSchema: DatasourceConfigurationCreateSchema,
  options?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>
) => {
  return postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator<DatasourceConfigurationSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceConfigurationCreateSchema
    },
    options
  );
};

export const getPostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    TError,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError,
  { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema }
  > = (props) => {
    const { projectConfigurationId, data } = props ?? {};

    return postProjectConfigurationProjectConfigurationIdDatasourceConfiguration(
      projectConfigurationId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>
>;
export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationBody =
  DatasourceConfigurationCreateSchema;
export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationError = unknown;

/**
 * @summary Adds a datasource to the specified project configuration
 */
export const usePostProjectConfigurationProjectConfigurationIdDatasourceConfiguration = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    TError,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError,
  { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const mutationOptions =
    getPostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Removes the specified datasource from the configuration
 */
export const deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  options?: SecondParameter<
    typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >
) => {
  return deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator<ProjectConfigurationCreateSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
        >
      >,
      TError,
      { projectConfigurationId: string; datasourceConfigurationId: string },
      TContext
    >;
    request?: SecondParameter<
      typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
        >
      >,
      { projectConfigurationId: string; datasourceConfigurationId: string }
    > = (props) => {
      const { projectConfigurationId, datasourceConfigurationId } = props ?? {};

      return deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId(
        projectConfigurationId,
        datasourceConfigurationId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >
  >;

export type DeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationError =
  unknown;

/**
 * @summary Removes the specified datasource from the configuration
 */
export const useDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<
    typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
  >,
  TError,
  { projectConfigurationId: string; datasourceConfigurationId: string },
  TContext
> => {
  const mutationOptions =
    getDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * @summary Updates the specified datasource in the configuration
 */
export const putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  datasourceConfigurationCreateSchema: DatasourceConfigurationCreateSchema,
  options?: SecondParameter<
    typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >
) => {
  return putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator<ProjectConfigurationCreateSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceConfigurationCreateSchema
    },
    options
  );
};

export const getPutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >,
      TError,
      { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
      TContext
    >;
    request?: SecondParameter<
      typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >,
      { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema }
    > = (props) => {
      const { projectConfigurationId, datasourceConfigurationId, data } = props ?? {};

      return putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId(
        projectConfigurationId,
        datasourceConfigurationId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >
  >;
export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationBody =
  DatasourceConfigurationCreateSchema;
export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationError =
  unknown;

/**
 * @summary Updates the specified datasource in the configuration
 */
export const usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<
    typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
  >,
  TError,
  { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const mutationOptions =
    getPutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * @summary Creates a new configuration for the specified project and deploys it (editing an existing configuration is not supported, a new configuration is created instead)
 */
export const postProjectProjectIdConfiguration = (
  projectId: string,
  projectConfigurationCreateSchema: ProjectConfigurationCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdConfigurationMutator>
) => {
  return postProjectProjectIdConfigurationMutator<ProjectConfigurationSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/configuration`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectConfigurationCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdConfigurationMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>,
    TError,
    { projectId: string; data: ProjectConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>,
  TError,
  { projectId: string; data: ProjectConfigurationCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>,
    { projectId: string; data: ProjectConfigurationCreateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdConfiguration(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>
>;
export type PostProjectProjectIdConfigurationMutationBody = ProjectConfigurationCreateSchema;
export type PostProjectProjectIdConfigurationMutationError = unknown;

/**
 * @summary Creates a new configuration for the specified project and deploys it (editing an existing configuration is not supported, a new configuration is created instead)
 */
export const usePostProjectProjectIdConfiguration = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>,
    TError,
    { projectId: string; data: ProjectConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdConfiguration>>,
  TError,
  { projectId: string; data: ProjectConfigurationCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the specified project_configuration
 */
export const getProjectProjectIdConfigurationProjectConfigurationId = (
  projectId: string,
  projectConfigurationId: string,
  params?: GetProjectProjectIdConfigurationProjectConfigurationIdParams,
  options?: SecondParameter<typeof getProjectProjectIdConfigurationProjectConfigurationIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdConfigurationProjectConfigurationIdMutator<GetProjectProjectIdConfigurationProjectConfigurationId200>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/configuration/${encodeURIComponent(String(projectConfigurationId))}`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdConfigurationProjectConfigurationIdQueryKey = (
  projectId: string,
  projectConfigurationId: string,
  params?: GetProjectProjectIdConfigurationProjectConfigurationIdParams
) => {
  return [`/project/${projectId}/configuration/${projectConfigurationId}`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>,
  TError = unknown
>(
  projectId: string,
  projectConfigurationId: string,
  params?: GetProjectProjectIdConfigurationProjectConfigurationIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdConfigurationProjectConfigurationIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdConfigurationProjectConfigurationIdQueryKey(projectId, projectConfigurationId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>> = ({
    signal
  }) =>
    getProjectProjectIdConfigurationProjectConfigurationId(
      projectId,
      projectConfigurationId,
      params,
      requestOptions,
      signal
    );

  return { queryKey, queryFn, enabled: !!(projectId && projectConfigurationId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdConfigurationProjectConfigurationIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>
>;
export type GetProjectProjectIdConfigurationProjectConfigurationIdQueryError = unknown;

/**
 * @summary Returns the specified project_configuration
 */
export const useGetProjectProjectIdConfigurationProjectConfigurationId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>,
  TError = unknown
>(
  projectId: string,
  projectConfigurationId: string,
  params?: GetProjectProjectIdConfigurationProjectConfigurationIdParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdConfigurationProjectConfigurationId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdConfigurationProjectConfigurationIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
    projectId,
    projectConfigurationId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Redeploys the specified project configuration creating a new project_deployment
 */
export const postProjectProjectIdConfigurationProjectConfigurationId = (
  projectId: string,
  projectConfigurationId: string,
  options?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>
) => {
  return postProjectProjectIdConfigurationProjectConfigurationIdMutator<ProjectDeploymentSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/configuration/${encodeURIComponent(String(projectConfigurationId))}`,
      method: 'POST'
    },
    options
  );
};

export const getPostProjectProjectIdConfigurationProjectConfigurationIdMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    TError,
    { projectId: string; projectConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
  TError,
  { projectId: string; projectConfigurationId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    { projectId: string; projectConfigurationId: string }
  > = (props) => {
    const { projectId, projectConfigurationId } = props ?? {};

    return postProjectProjectIdConfigurationProjectConfigurationId(projectId, projectConfigurationId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdConfigurationProjectConfigurationIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>
>;

export type PostProjectProjectIdConfigurationProjectConfigurationIdMutationError = unknown;

/**
 * @summary Redeploys the specified project configuration creating a new project_deployment
 */
export const usePostProjectProjectIdConfigurationProjectConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
    TError,
    { projectId: string; projectConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdConfigurationProjectConfigurationIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdConfigurationProjectConfigurationId>>,
  TError,
  { projectId: string; projectConfigurationId: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdConfigurationProjectConfigurationIdMutationOptions(options);

  return useMutation(mutationOptions);
};
