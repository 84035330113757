import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { capitalizeWord } from 'utils/helpers';
import { AttributeValues } from 'utils/interfaces';
import {
  ComparisonElementFormValues,
  RadarChartElementFormValues,
  ScatterChartElementFormValues,
  TableElementFormValues
} from '../dialogs/elementForms/interfaces';

interface AggregationDataProps {
  report: ReportNestedSchema;
  element: ReportElementSchema;
}

const AggregationData = memo(function AggregationData({ element }: AggregationDataProps) {
  const element_type = element.report_element_template_name;

  let aggregationText: JSX.Element | string = '';
  const seasons = (element.attribute_values!.seasons ?? []) as string[];
  const aggregationMethod = (element.attribute_values!.aggregation_metric[0] ?? '') as string;
  let grouping: string = '';

  switch (element_type) {
    case 'comparison':
    case 'radar-chart':
    case 'scatter-chart': {
      const attributes = element.attribute_values! as AttributeValues<
        RadarChartElementFormValues | ScatterChartElementFormValues | ComparisonElementFormValues
      >;
      const aggregatedBy = attributes.aggregate_by[0] as string;
      grouping = capitalizeWord(aggregatedBy);
      break;
    }
    case 'table': {
      const attributes = element.attribute_values as AttributeValues<TableElementFormValues>;
      const groupedBy = attributes.group_by as string[];

      const isMatch = groupedBy.includes('match');
      const isCompetition = groupedBy.includes('competition');
      const isSeason = groupedBy.includes('season');

      switch (true) {
        case isMatch:
          grouping = 'Match';
          break;
        case isCompetition && isSeason:
          grouping = 'Season and Competiton';
          break;
        case isCompetition:
          grouping = 'Competiton';
          break;
        case isSeason:
          grouping = 'Season';
          break;
      }
      break;
    }
  }
  aggregationText = (
    <>
      <span className="font-semibold">{capitalizeWord(aggregationMethod)}</span> value of selected metrics per
      <span className="font-semibold">{grouping}</span> in seasons
      <span className="font-semibold">{!!seasons && <>{seasons.sort().join(', ')}</>}</span>
    </>
  );

  return (
    <div className="flex flex-col gap-2 text-xs">
      <div className="flex gap-1">{aggregationText}</div>
    </div>
  );
});

export default AggregationData;
