import { useQueryClient } from '@tanstack/react-query';
import { ProjectSchema } from 'lib/model';
import {
  getGetReportGetCountriesQueryOptions,
  getGetReportGetPlayerPositionsQueryOptions,
  getGetReportGetPlayerSubpositionsQueryOptions
} from 'lib/report/report';

function usePrefetchScoutReportDropdowns() {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  queryClient.prefetchQuery({
    ...getGetReportGetPlayerPositionsQueryOptions({ project_id: project.id! }),
    queryKey: ['playerPositions', project.id],
    staleTime: Infinity
  });

  queryClient.prefetchQuery({
    ...getGetReportGetPlayerSubpositionsQueryOptions({ project_id: project.id! }),
    queryKey: ['playerSubPositions', project.id],
    staleTime: Infinity
  });

  queryClient.prefetchQuery({
    ...getGetReportGetCountriesQueryOptions({ project_id: project.id! }),
    queryKey: ['countries', project.id],
    staleTime: Infinity
  });
}

export default usePrefetchScoutReportDropdowns;
