import { memo, PropsWithChildren } from 'react';
import Divider from '../Divider';

const DialogFooter = memo(function DialogFooter({ children }: PropsWithChildren) {
  return (
    <footer className="flex w-full grow-0 flex-col gap-8 px-10 pb-10">
      <Divider />
      <div className="flex w-full items-center justify-between gap-6">{children}</div>
    </footer>
  );
});

export default DialogFooter;
