import CheckCircle from 'assets/check-circle.svg?react';
import CloseCircle from 'assets/close-circle-full.svg?react';
import { accessLevelPermissionList, accessLevels } from 'constants/accessLevelOptions';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo } from 'react';
import { DialogProps } from 'utils/interfaces';

function AccessLevelDialog({ open, setOpen }: DialogProps) {
  function handleClose() {
    setOpen(false);
  }

  return (
    <DialogBase title="Access level" open={open} onCancel={handleClose} extraWide>
      <DialogContent>
        <div className="rounded-xl bg-gray-50 p-6">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                <th scope="col" className="sticky -top-10 z-10 bg-gray-50 py-3 text-left text-sm font-medium sm:pl-0">
                  Action
                </th>
                <th
                  scope="col"
                  className="sticky -top-10 z-10 w-[100px] bg-gray-50 py-4 text-center text-sm font-medium"
                >
                  Owner
                </th>
                {accessLevels.map((level) => (
                  <th
                    key={level.id}
                    scope="col"
                    className="sticky -top-10 z-10 w-[100px] bg-gray-50 py-4 text-center text-sm font-medium"
                  >
                    {level.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {accessLevelPermissionList.map((permission) => (
                <tr key={permission.permission} className="divide-x divide-gray-200">
                  <td className="whitespace-nowrap py-2 text-sm sm:pl-0">{permission.permission}</td>
                  <td key={'owner_' + permission.permission}>
                    <CheckCircle className="m-auto size-4 fill-green-600" />
                  </td>
                  {accessLevels.map((level) => (
                    <td key={level.id + '_' + permission.permission}>
                      {permission.level.includes(level.id as string) ? (
                        <CheckCircle className="m-auto size-4 fill-green-600" />
                      ) : (
                        <CloseCircle className="m-auto size-4 fill-red-600" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(AccessLevelDialog);
