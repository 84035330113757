import { keepPreviousData, useQueryClient } from '@tanstack/react-query';
import Remove from 'assets/close-circle.svg?react';
import Info from 'assets/info.svg?react';
import { GetReportSearchPlayersParams, PlayerQuerySchema, ProjectSchema } from 'lib/model';
import { useGetReportSearchPlayers } from 'lib/report/report';
import Jersey from 'modules/reports/reportPage/components/Jersey';
import { memo, useCallback } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { getDefaultColor } from '../../../../../helpers';
import { ScoutReportFormValues } from '../interfaces';

interface ScoutReportFilteredPlayerList {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
}

function ScoutReportFilteredPlayerList({ scoutReportForm }: ScoutReportFilteredPlayerList) {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const formData = useWatch(scoutReportForm) as ScoutReportFormValues;

  const searchPlayersData = scoutFormToSearchPlayers(formData, project.id!);
  const { data: filteredPlayers, isFetching: isFilteredPlayersFetching } = useGetReportSearchPlayers(
    searchPlayersData,
    {
      query: {
        queryKey: ['filtered-players', searchPlayersData],
        staleTime: 1000 * 60,
        placeholderData: keepPreviousData,
        enabled: !!formData.position && !!formData.metric
      }
    }
  );

  const handleRemoveFilteredPlayer = useCallback(
    function handleRemoveFilteredPlayer(player: PlayerQuerySchema) {
      return () => {
        const removedPlayersOld = scoutReportForm.getValues('removedPlayers');
        scoutReportForm.setValue('removedPlayers', [...new Set([...removedPlayersOld, player.player_id!])]);
      };
    },
    [scoutReportForm]
  );

  const handleResetFilteredPlayers = useCallback(
    function handleResetFilteredPlayers() {
      scoutReportForm.setValue('removedPlayers', []);
    },
    [scoutReportForm]
  );

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex flex-wrap items-center gap-3">
          <span className="text-md font-medium">{formData.position?.label}</span>
          <div className="flex items-center gap-2">
            <span className="shrink-0 text-md">Metric criteria:</span>
            <span className="truncate text-md font-medium" title={formData.metric?.label}>
              {formData.metric?.label}
            </span>
          </div>
        </div>
        <button className="text-sm font-medium text-brand-800" onClick={handleResetFilteredPlayers}>
          Set back to default
        </button>
      </div>
      <div className="flex gap-2 rounded-md bg-brand-50 px-6 py-4">
        <Info className="size-5 shrink-0 fill-brand-800" />
        <p className="text-sm text-brand-800">
          Players are selected based on the aggregated value of the chosen metric in the selected season(s), so the top
          performers displayed in the report may change with each data update.
        </p>
      </div>
      {filteredPlayers?.players && filteredPlayers.players.length > 0 ? (
        <div className="flex flex-col gap-3">
          {filteredPlayers.players.map((player, i) => (
            <div key={player.player_id} className="flex items-center justify-between gap-6">
              <div className="flex w-full flex-row items-center gap-2 rounded-md border border-gray-300 p-3">
                <Jersey size="md" shirt_number={player.shirt_number} color={getDefaultColor(i)} />
                <span className="text-md font-medium">{player.player_name}</span>
              </div>
              <button
                className="group flex items-center gap-2"
                disabled={isFilteredPlayersFetching}
                onClick={handleRemoveFilteredPlayer(player)}
              >
                <span className="text-xs font-medium text-brand-800 group-disabled:text-brand-500">Remove</span>
                <Remove className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center bg-gray-50 px-6 py-4">
          <span className="text-sm text-gray-900">
            {isFilteredPlayersFetching
              ? 'Loading...'
              : 'Unfortunately no players were found that match your preferences'}
          </span>
        </div>
      )}
    </div>
  );
}

function scoutFormToSearchPlayers(data: ScoutReportFormValues, projectId: string): GetReportSearchPlayersParams {
  const searchPlayersParams: GetReportSearchPlayersParams = {
    project_id: projectId,
    num_filtered_players: data.numberOfFilteredPlayers,
    'removed_players[]': data.removedPlayers,
    player_position: data.position?.id as string,
    'player_sub_position[]': data.subPositions?.map((option) => option.id as string),
    metric_criteria: data.metric?.id as string,
    aggregation_metric: data.aggregation_metric?.id as string,
    'seasons[]': data.seasons?.map((option) => option.id as string) ?? undefined
  };
  if (data.isCompetitionsAndTeamsEnabled) {
    searchPlayersParams['competitions[]'] = data.competitions?.map((option) => option.id as number) ?? undefined;
    searchPlayersParams['teams[]'] = data.teams?.map((option) => option.id as number) ?? undefined;
  }
  if (data.isPlaytimeExperienceEnabled) {
    searchPlayersParams.min_games_played = data.minPlayedGames ?? undefined;
    searchPlayersParams.avg_min_played_per_match_min = data.avgMinutesPlayed?.at(0) ?? undefined;
    searchPlayersParams.avg_min_played_per_match_max = data.avgMinutesPlayed?.at(1) ?? undefined;
  }
  if (data.isMarketValueEnabled) {
    searchPlayersParams.market_value_min = data.avgMarketValue?.at(0) ?? undefined;
    searchPlayersParams.market_value_max = data.avgMarketValue?.at(1) ?? undefined;
  }
  if (data.isPhysicalAttributesEnabled) {
    searchPlayersParams.age_min = data.age?.at(0) ?? undefined;
    searchPlayersParams.age_max = data.age?.at(1) ?? undefined;
    searchPlayersParams.weight_min = data.weight?.at(0) ?? undefined;
    searchPlayersParams.weight_max = data.weight?.at(1) ?? undefined;
    searchPlayersParams.height_min = data.height?.at(0) ?? undefined;
    searchPlayersParams.height_max = data.height?.at(1) ?? undefined;
  }
  if (data.isNationalityEnabled) {
    searchPlayersParams['birth_country[]'] = data.countryOfBirth?.map((option) => option.id as string);
    searchPlayersParams['nationality[]'] = data.nationality?.map((option) => option.id as string);
  }

  return searchPlayersParams;
}

export default memo(ScoutReportFilteredPlayerList);
