import MatchReport from 'assets/event-ball.svg?react';
import TeamReport from 'assets/football-shield-outline.svg?react';
import TeamComparisonReport from 'assets/football-shield2-outline.svg?react';
import PlayerReport from 'assets/jersey.svg?react';
import PlayerComparisonReport from 'assets/jersey2.svg?react';
import ScoutReport from 'assets/spy.svg?react';
import { ReportSchemaReportType } from 'lib/model';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

interface ReportIconProps {
  reportType: ReportSchemaReportType;
  size?: 'sm' | 'md';
}

function ReportIcon({ reportType, size = 'md' }: ReportIconProps) {
  if (reportType === 'match') {
    return <MatchReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  } else if (reportType === 'player') {
    return <PlayerReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  } else if (reportType === 'player_comparison') {
    return <PlayerComparisonReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  } else if (reportType === 'team') {
    return <TeamReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  } else if (reportType === 'team_comparison') {
    return <TeamComparisonReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  } else if (reportType === 'scout') {
    return <ScoutReport className={twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')} />;
  }

  return null;
}

export default memo(ReportIcon);
