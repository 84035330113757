import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import MenuContext from 'assets/menu-context.svg?react';
import { ReportNestedSchema } from 'lib/model';
import { Fragment, memo, useCallback, useState } from 'react';
import DeleteReportDialog from './dialogs/DeleteReportDialog';
import RenameReportDialog from './dialogs/RenameReportDialog';

interface ReportPageDropdownProps {
  report?: ReportNestedSchema;
}

const ReportPageDropdown = memo(function ConnectedDataSourceDropdown({ report }: ReportPageDropdownProps) {
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const [showRename, setShowRename] = useState<boolean>(false);

  const handleRemove = useCallback(function handleRemove() {
    setShowRemove(true);
  }, []);

  const handleRename = useCallback(function handleRename() {
    setShowRename(true);
  }, []);

  return (
    <Menu as={'div'} className="relative inline-block h-6 text-left">
      <MenuButton>
        <MenuContext width={24} height={24} className="fill-gray-700" />
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom end"
          className="absolute z-50 mt-2 h-fit w-40 whitespace-nowrap rounded-md bg-white py-3 shadow-card focus:outline-none"
        >
          <MenuItem>
            <button className="flex w-full px-4 py-1.5 text-sm ui-active:bg-gray-50" onClick={handleRemove}>
              Delete report
            </button>
          </MenuItem>
          <MenuItem>
            <button className="flex w-full px-4 py-1.5 text-sm ui-active:bg-gray-50" onClick={handleRename}>
              Change report name
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
      {report && (
        <DeleteReportDialog reportId={report.id!} reportName={report.name!} open={showRemove} setOpen={setShowRemove} />
      )}
      {report && <RenameReportDialog report={report} open={showRename} setOpen={setShowRename} />}
    </Menu>
  );
});

export default ReportPageDropdown;
