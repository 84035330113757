/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import getProjectOrganizationTypeMutator from '../../api/axios';
import type { OrganizationTypesSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of possible organization types
 */
export const getProjectOrganizationType = (
  options?: SecondParameter<typeof getProjectOrganizationTypeMutator>,
  signal?: AbortSignal
) => {
  return getProjectOrganizationTypeMutator<OrganizationTypesSchema>(
    { url: `/project/organization-type`, method: 'GET', signal },
    options
  );
};

export const getGetProjectOrganizationTypeQueryKey = () => {
  return [`/project/organization-type`] as const;
};

export const getGetProjectOrganizationTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectOrganizationType>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectOrganizationType>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectOrganizationTypeMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectOrganizationTypeQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectOrganizationType>>> = ({ signal }) =>
    getProjectOrganizationType(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectOrganizationType>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectOrganizationTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectOrganizationType>>>;
export type GetProjectOrganizationTypeQueryError = unknown;

/**
 * @summary Returns a list of possible organization types
 */
export const useGetProjectOrganizationType = <
  TData = Awaited<ReturnType<typeof getProjectOrganizationType>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectOrganizationType>>, TError, TData>>;
  request?: SecondParameter<typeof getProjectOrganizationTypeMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectOrganizationTypeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
