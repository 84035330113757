import MatchReport from 'assets/event-ball.svg?react';
import TeamReport from 'assets/football-shield-outline.svg?react';
import TeamComparisonReport from 'assets/football-shield2-outline.svg?react';
import PlayerReport from 'assets/jersey.svg?react';
import PlayerComparisonReport from 'assets/jersey2.svg?react';
import ScoutReport from 'assets/spy.svg?react';
import { SearchParamRoute } from 'utils/interfaces';

export const reportTypeRoutes: SearchParamRoute[] = [
  {
    name: 'ALL',
    key: 'reportType',
    value: null
  },
  {
    name: 'MATCH REPORT',
    key: 'reportType',
    value: 'match',
    Icon: MatchReport
  },
  {
    name: 'PLAYER REPORT',
    key: 'reportType',
    value: 'player',
    Icon: PlayerReport
  },
  {
    name: 'PLAYER COMPARISON REPORT',
    key: 'reportType',
    value: 'player_comparison',
    Icon: PlayerComparisonReport
  },
  {
    name: 'TEAM REPORT',
    key: 'reportType',
    value: 'team',
    Icon: TeamReport
  },
  {
    name: 'TEAM COMPARISON REPORT',
    key: 'reportType',
    value: 'team_comparison',
    Icon: TeamComparisonReport
  },
  {
    name: 'SCOUT REPORT',
    key: 'reportType',
    value: 'scout',
    Icon: ScoutReport
  }
];
