/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteProjectProjectIdMutator,
  default as getProjectMutator,
  default as getProjectProjectIdAcceptMutator,
  default as getProjectProjectIdMutator,
  default as postProjectMutator,
  default as postProjectProjectIdDatasourceDatasourceIdResyncMutator,
  default as postProjectProjectIdInviteMutator,
  default as postProjectProjectIdResendEmailMutator,
  default as postProjectProjectIdResyncMutator,
  default as postProjectProjectIdRevokeEmailMutator,
  default as postProjectProjectIdTransferOwnershipMutator,
  default as putProjectProjectIdMutator
} from '../../api/axios';
import type {
  GetProjectParams,
  GetProjectProjectIdAcceptParams,
  ProjectCreateSchema,
  ProjectGetSchema,
  ProjectSchema,
  ProjectTransferOwnershipSchema,
  ProjectUpdateSchema,
  ProjectUserInviteSchema,
  ProjectsGetSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all project objects
 */
export const getProject = (
  params?: GetProjectParams,
  options?: SecondParameter<typeof getProjectMutator>,
  signal?: AbortSignal
) => {
  return getProjectMutator<ProjectsGetSchema>({ url: `/project`, method: 'GET', params, signal }, options);
};

export const getGetProjectQueryKey = (params?: GetProjectParams) => {
  return [`/project`, ...(params ? [params] : [])] as const;
};

export const getGetProjectQueryOptions = <TData = Awaited<ReturnType<typeof getProject>>, TError = unknown>(
  params?: GetProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProject>>> = ({ signal }) =>
    getProject(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProject>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectQueryResult = NonNullable<Awaited<ReturnType<typeof getProject>>>;
export type GetProjectQueryError = unknown;

/**
 * @summary Returns a list of all project objects
 */
export const useGetProject = <TData = Awaited<ReturnType<typeof getProject>>, TError = unknown>(
  params?: GetProjectParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Create a new project
 */
export const postProject = (
  projectCreateSchema: ProjectCreateSchema,
  options?: SecondParameter<typeof postProjectMutator>
) => {
  return postProjectMutator<ProjectSchema>(
    { url: `/project`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: projectCreateSchema },
    options
  );
};

export const getPostProjectMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProject>>,
    TError,
    { data: ProjectCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof postProject>>, TError, { data: ProjectCreateSchema }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProject>>, { data: ProjectCreateSchema }> = (
    props
  ) => {
    const { data } = props ?? {};

    return postProject(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectMutationResult = NonNullable<Awaited<ReturnType<typeof postProject>>>;
export type PostProjectMutationBody = ProjectCreateSchema;
export type PostProjectMutationError = unknown;

/**
 * @summary Create a new project
 */
export const usePostProject = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProject>>,
    TError,
    { data: ProjectCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof postProject>>, TError, { data: ProjectCreateSchema }, TContext> => {
  const mutationOptions = getPostProjectMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete an existing project
 */
export const deleteProjectProjectId = (
  projectId: string,
  options?: SecondParameter<typeof deleteProjectProjectIdMutator>
) => {
  return deleteProjectProjectIdMutator<void>(
    { url: `/project/${encodeURIComponent(String(projectId))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteProjectProjectIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectId>>,
    TError,
    { projectId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectProjectId>>, TError, { projectId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectProjectId>>, { projectId: string }> = (
    props
  ) => {
    const { projectId } = props ?? {};

    return deleteProjectProjectId(projectId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectProjectId>>>;

export type DeleteProjectProjectIdMutationError = unknown;

/**
 * @summary Delete an existing project
 */
export const useDeleteProjectProjectId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectId>>,
    TError,
    { projectId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteProjectProjectId>>, TError, { projectId: string }, TContext> => {
  const mutationOptions = getDeleteProjectProjectIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the specified project object
 */
export const getProjectProjectId = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdMutator<ProjectGetSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdQueryKey = (projectId: string) => {
  return [`/project/${projectId}`] as const;
};

export const getGetProjectProjectIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectId>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectId>>> = ({ signal }) =>
    getProjectProjectId(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectProjectId>>>;
export type GetProjectProjectIdQueryError = unknown;

/**
 * @summary Returns the specified project object
 */
export const useGetProjectProjectId = <TData = Awaited<ReturnType<typeof getProjectProjectId>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update an existing project object
 */
export const putProjectProjectId = (
  projectId: string,
  projectUpdateSchema: ProjectUpdateSchema,
  options?: SecondParameter<typeof putProjectProjectIdMutator>
) => {
  return putProjectProjectIdMutator<ProjectSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: projectUpdateSchema
    },
    options
  );
};

export const getPutProjectProjectIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectId>>,
    TError,
    { projectId: string; data: ProjectUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectProjectId>>,
  TError,
  { projectId: string; data: ProjectUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectProjectId>>,
    { projectId: string; data: ProjectUpdateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return putProjectProjectId(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectProjectIdMutationResult = NonNullable<Awaited<ReturnType<typeof putProjectProjectId>>>;
export type PutProjectProjectIdMutationBody = ProjectUpdateSchema;
export type PutProjectProjectIdMutationError = unknown;

/**
 * @summary Update an existing project object
 */
export const usePutProjectProjectId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectId>>,
    TError,
    { projectId: string; data: ProjectUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectProjectId>>,
  TError,
  { projectId: string; data: ProjectUpdateSchema },
  TContext
> => {
  const mutationOptions = getPutProjectProjectIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Invites a user to the project via an email link
 */
export const getProjectProjectIdAccept = (
  projectId: string,
  params?: GetProjectProjectIdAcceptParams,
  options?: SecondParameter<typeof getProjectProjectIdAcceptMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdAcceptMutator<void>(
    { url: `/project/${encodeURIComponent(String(projectId))}/accept`, method: 'GET', params, signal },
    options
  );
};

export const getGetProjectProjectIdAcceptQueryKey = (projectId: string, params?: GetProjectProjectIdAcceptParams) => {
  return [`/project/${projectId}/accept`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdAcceptQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdAccept>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdAcceptParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdAccept>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdAcceptMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdAcceptQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdAccept>>> = ({ signal }) =>
    getProjectProjectIdAccept(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdAccept>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdAcceptQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectProjectIdAccept>>>;
export type GetProjectProjectIdAcceptQueryError = unknown;

/**
 * @summary Invites a user to the project via an email link
 */
export const useGetProjectProjectIdAccept = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdAccept>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdAcceptParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdAccept>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdAcceptMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdAcceptQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Manually triggers a resnychronization of a specific datasource in a project
 */
export const postProjectProjectIdDatasourceDatasourceIdResync = (
  projectId: string,
  datasourceId: string,
  options?: SecondParameter<typeof postProjectProjectIdDatasourceDatasourceIdResyncMutator>
) => {
  return postProjectProjectIdDatasourceDatasourceIdResyncMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/datasource/${encodeURIComponent(String(datasourceId))}/resync`,
      method: 'POST'
    },
    options
  );
};

export const getPostProjectProjectIdDatasourceDatasourceIdResyncMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>,
    TError,
    { projectId: string; datasourceId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdDatasourceDatasourceIdResyncMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>,
  TError,
  { projectId: string; datasourceId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>,
    { projectId: string; datasourceId: string }
  > = (props) => {
    const { projectId, datasourceId } = props ?? {};

    return postProjectProjectIdDatasourceDatasourceIdResync(projectId, datasourceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdDatasourceDatasourceIdResyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>
>;

export type PostProjectProjectIdDatasourceDatasourceIdResyncMutationError = unknown;

/**
 * @summary Manually triggers a resnychronization of a specific datasource in a project
 */
export const usePostProjectProjectIdDatasourceDatasourceIdResync = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>,
    TError,
    { projectId: string; datasourceId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdDatasourceDatasourceIdResyncMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdDatasourceDatasourceIdResync>>,
  TError,
  { projectId: string; datasourceId: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdDatasourceDatasourceIdResyncMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Invites a user to the project via an email link
 */
export const postProjectProjectIdInvite = (
  projectId: string,
  projectUserInviteSchema: ProjectUserInviteSchema,
  options?: SecondParameter<typeof postProjectProjectIdInviteMutator>
) => {
  return postProjectProjectIdInviteMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/invite`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectUserInviteSchema
    },
    options
  );
};

export const getPostProjectProjectIdInviteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdInvite>>,
    TError,
    { projectId: string; data: ProjectUserInviteSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdInviteMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdInvite>>,
  TError,
  { projectId: string; data: ProjectUserInviteSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdInvite>>,
    { projectId: string; data: ProjectUserInviteSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdInvite(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdInvite>>
>;
export type PostProjectProjectIdInviteMutationBody = ProjectUserInviteSchema;
export type PostProjectProjectIdInviteMutationError = unknown;

/**
 * @summary Invites a user to the project via an email link
 */
export const usePostProjectProjectIdInvite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdInvite>>,
    TError,
    { projectId: string; data: ProjectUserInviteSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdInviteMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdInvite>>,
  TError,
  { projectId: string; data: ProjectUserInviteSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resend an invite to a user to join a project
 */
export const postProjectProjectIdResendEmail = (
  projectId: string,
  email: string,
  options?: SecondParameter<typeof postProjectProjectIdResendEmailMutator>
) => {
  return postProjectProjectIdResendEmailMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/resend/${encodeURIComponent(String(email))}`,
      method: 'POST'
    },
    options
  );
};

export const getPostProjectProjectIdResendEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>,
    TError,
    { projectId: string; email: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdResendEmailMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>,
  TError,
  { projectId: string; email: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>,
    { projectId: string; email: string }
  > = (props) => {
    const { projectId, email } = props ?? {};

    return postProjectProjectIdResendEmail(projectId, email, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdResendEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>
>;

export type PostProjectProjectIdResendEmailMutationError = unknown;

/**
 * @summary Resend an invite to a user to join a project
 */
export const usePostProjectProjectIdResendEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>,
    TError,
    { projectId: string; email: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdResendEmailMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdResendEmail>>,
  TError,
  { projectId: string; email: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdResendEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Manually triggers a resnychronization of all datasources in a project
 */
export const postProjectProjectIdResync = (
  projectId: string,
  options?: SecondParameter<typeof postProjectProjectIdResyncMutator>
) => {
  return postProjectProjectIdResyncMutator<void>(
    { url: `/project/${encodeURIComponent(String(projectId))}/resync`, method: 'POST' },
    options
  );
};

export const getPostProjectProjectIdResyncMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdResync>>,
    TError,
    { projectId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdResyncMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdResync>>,
  TError,
  { projectId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postProjectProjectIdResync>>, { projectId: string }> = (
    props
  ) => {
    const { projectId } = props ?? {};

    return postProjectProjectIdResync(projectId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdResyncMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdResync>>
>;

export type PostProjectProjectIdResyncMutationError = unknown;

/**
 * @summary Manually triggers a resnychronization of all datasources in a project
 */
export const usePostProjectProjectIdResync = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdResync>>,
    TError,
    { projectId: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdResyncMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdResync>>,
  TError,
  { projectId: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdResyncMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revoke an invite to a user to join a project
 */
export const postProjectProjectIdRevokeEmail = (
  projectId: string,
  email: string,
  options?: SecondParameter<typeof postProjectProjectIdRevokeEmailMutator>
) => {
  return postProjectProjectIdRevokeEmailMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/revoke/${encodeURIComponent(String(email))}`,
      method: 'POST'
    },
    options
  );
};

export const getPostProjectProjectIdRevokeEmailMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>,
    TError,
    { projectId: string; email: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdRevokeEmailMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>,
  TError,
  { projectId: string; email: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>,
    { projectId: string; email: string }
  > = (props) => {
    const { projectId, email } = props ?? {};

    return postProjectProjectIdRevokeEmail(projectId, email, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdRevokeEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>
>;

export type PostProjectProjectIdRevokeEmailMutationError = unknown;

/**
 * @summary Revoke an invite to a user to join a project
 */
export const usePostProjectProjectIdRevokeEmail = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>,
    TError,
    { projectId: string; email: string },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdRevokeEmailMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdRevokeEmail>>,
  TError,
  { projectId: string; email: string },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdRevokeEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Transfer project ownership to another user
 */
export const postProjectProjectIdTransferOwnership = (
  projectId: string,
  projectTransferOwnershipSchema: ProjectTransferOwnershipSchema,
  options?: SecondParameter<typeof postProjectProjectIdTransferOwnershipMutator>
) => {
  return postProjectProjectIdTransferOwnershipMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/transfer-ownership`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectTransferOwnershipSchema
    },
    options
  );
};

export const getPostProjectProjectIdTransferOwnershipMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>,
    TError,
    { projectId: string; data: ProjectTransferOwnershipSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdTransferOwnershipMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>,
  TError,
  { projectId: string; data: ProjectTransferOwnershipSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>,
    { projectId: string; data: ProjectTransferOwnershipSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdTransferOwnership(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdTransferOwnershipMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>
>;
export type PostProjectProjectIdTransferOwnershipMutationBody = ProjectTransferOwnershipSchema;
export type PostProjectProjectIdTransferOwnershipMutationError = unknown;

/**
 * @summary Transfer project ownership to another user
 */
export const usePostProjectProjectIdTransferOwnership = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>,
    TError,
    { projectId: string; data: ProjectTransferOwnershipSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdTransferOwnershipMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdTransferOwnership>>,
  TError,
  { projectId: string; data: ProjectTransferOwnershipSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdTransferOwnershipMutationOptions(options);

  return useMutation(mutationOptions);
};
