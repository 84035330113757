import { usePutUserResetPassword } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface PasswordResetValues {
  password: string;
  repeatPassword: string;
}

const PasswordResetForm = memo(function PasswordResetForm() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    trigger
  } = useForm<PasswordResetValues>();
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('userToken');

  const { mutate, isPending } = usePutUserResetPassword({
    mutation: {
      // TODO: Remove any type when BE Swagger updated
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: (data: any) => {
        const { message } = data;

        toast.success(message);
        navigate('/login');
      }
    }
  });

  function onSubmit(formData: PasswordResetValues) {
    mutate({ data: { password: formData.password }, params: { userToken: userToken ?? '' } });
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full max-w-xl flex-col items-start gap-10 rounded-3xl bg-white p-16 text-center"
    >
      <h6 className="text-h6 font-semibold">Reset password</h6>
      <div className="flex w-full flex-col gap-6">
        <p className="text-left text-md">Enter your new password</p>
        <PasswordInput
          registerReturn={register('password', { required: 'Password is required' })}
          label="New password"
          error={errors.password}
        />
        <div className="flex w-full flex-col gap-2">
          <PasswordInput
            registerReturn={register('repeatPassword', {
              required: 'Password is required',
              validate: (val: string) => {
                if (watch('password') !== val) return 'Passwords do not match.';
              },
              onChange: () => trigger('repeatPassword')
            })}
            label="Repeat password"
            error={errors.repeatPassword}
          />
        </div>
      </div>
      <Button isSubmitButton={true} loading={isPending} size="xl">
        Update Password
      </Button>
    </form>
  );
});

export default PasswordResetForm;
