import Premium from 'assets/award.svg?react';
import Medium from 'assets/euro.svg?react';
import Affordable from 'assets/free.svg?react';
import { DatasourceSchemaPricing } from 'lib/model';
import { memo } from 'react';

interface DataSourcePricingBadgeProps {
  pricing?: DatasourceSchemaPricing;
}

const DataSourcePricingBadge = memo(function DataSourceTypeBadge({ pricing }: DataSourcePricingBadgeProps) {
  if (!pricing) return null;

  return (
    <>
      {pricing === DatasourceSchemaPricing.affordable && (
        <div className="flex items-center gap-1 rounded-badge border border-gray-950 bg-white px-3 py-2">
          <Affordable width={14} height={14} className="fill-gray-950" />
          <span className="text-tiny font-semibold">AFFORDABLE</span>
        </div>
      )}
      {pricing === DatasourceSchemaPricing.enhanced && (
        <div className="flex items-center gap-1 rounded-badge bg-gray-950 px-3 py-2">
          <Medium width={14} height={14} className="fill-white" />
          <span className="text-tiny font-semibold text-white">ENHANCED</span>
        </div>
      )}
      {pricing === DatasourceSchemaPricing.premium && (
        <div className="flex items-center gap-1 rounded-badge bg-gray-950 px-3 py-2">
          <Premium width={14} height={14} className="fill-white" />
          <span className="text-tiny font-semibold text-white">PREMIUM</span>
        </div>
      )}
    </>
  );
});

export default DataSourcePricingBadge;
