import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import {
  Combobox as ComboboxBase,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition
} from '@headlessui/react';
import { useDebounce } from '@uidotdev/usehooks';
import Check from 'assets/check.svg?react';
import Loader from 'assets/loading.svg?react';
import { getProjectProjectIdDataTableDataTableIdAutocomplete } from 'lib/client-data/client-data';
import ValueBadge from 'modules/common/Form/ValueBadge';
import { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Portal } from 'utils/helpers';

interface DataTableAutocompleteProps {
  placeholder?: string;
  projectId: string;
  dataTableId: string;
  columnName: string;
  filters: Record<string, string[]>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
}

function DataTableAutocomplete({
  placeholder = 'Enter value to filter options',
  projectId,
  dataTableId,
  columnName,
  filters,
  setFilters
}: DataTableAutocompleteProps) {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<string[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(filters[columnName] ?? []);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedQuery = useDebounce(query, 300);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleInputChange = useCallback(
    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      setQuery(event.target.value);
    },
    [setQuery]
  );

  useEffect(() => {
    const autocomplete = async () => {
      let res: string[] = [];
      setIsLoading(true);

      if (debouncedQuery) {
        const data = await getProjectProjectIdDataTableDataTableIdAutocomplete(projectId, dataTableId, {
          column_name: columnName,
          search: debouncedQuery ?? ''
        });
        res = data || [];
      }

      setIsLoading(false);
      setResults(res);
    };

    autocomplete();
  }, [debouncedQuery, columnName, dataTableId, projectId]);

  const handleComboboxChange = useCallback(
    function handleComboboxChange(option: string[]) {
      setSelectedOptions(option);
      setFilters((prev) => {
        const next = prev;
        next[columnName] = option;
        return next;
      });
    },
    [columnName, setFilters]
  );

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      const newSelectedOptions = selectedOptions.filter((x) => x !== removedValue);
      setSelectedOptions(newSelectedOptions);
      setFilters((prev) => {
        const next = prev;
        if (newSelectedOptions.length > 0) next[columnName] = newSelectedOptions;
        else delete next[columnName];
        return next;
      });
    },
    [columnName, setFilters, selectedOptions]
  );

  return (
    <div className="flex w-[352px] flex-col gap-2">
      <ComboboxBase
        as="div"
        className="relative w-full grow"
        // Type system excepts T[] where T is the type of value
        virtual={{ options: results as unknown as string[][] }}
        value={selectedOptions}
        onChange={handleComboboxChange}
        multiple
        immediate
      >
        {({ open }) => (
          <>
            <ComboboxInput
              ref={refs.setReference}
              placeholder={placeholder}
              className="peer flex min-h-14 w-full appearance-none flex-wrap rounded-lg border border-gray-300 bg-white px-3 pb-1.5 text-md focus:outline-none focus:ring-0"
              onChange={handleInputChange}
            />
            {isLoading && (
              <Loader
                aria-hidden="true"
                className="absolute inset-y-4 right-3 z-10 size-7 animate-spin fill-brand-800"
              />
            )}
            {open && results && results.length > 0 && (
              <Portal>
                <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <ComboboxOptions className="absolute z-50 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
                      {({ option: item }: { option: string }) => (
                        <ComboboxOption
                          key={item}
                          value={item}
                          className="w-full cursor-default select-none px-2 py-1 ui-active:bg-gray-50"
                        >
                          <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                            <div className="flex flex-col">
                              <span className="block truncate text-sm font-medium ui-selected:font-semibold ui-selected:text-brand-800">
                                {item}
                              </span>
                            </div>
                            <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
                          </div>
                        </ComboboxOption>
                      )}
                    </ComboboxOptions>
                  </Transition>
                </div>
              </Portal>
            )}
          </>
        )}
      </ComboboxBase>
      {selectedOptions && selectedOptions.length > 0 && (
        <div className="flex w-full flex-wrap gap-1">
          {selectedOptions.map((val: string) => (
            <ValueBadge value={val} key={val} onRemove={handleRemove} />
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(DataTableAutocomplete);
