import Award from 'assets/award.svg?react';
import EventBall from 'assets/event-ball.svg?react';
import Trophy from 'assets/trophy.svg?react';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

interface TierPlanTitleProps {
  name: string;
  bigger?: boolean;
}

function TierPlanTitle({ name, bigger = false }: TierPlanTitleProps) {
  return (
    <div className={twMerge('flex items-center gap-2', bigger && 'gap-3')}>
      {name.toLowerCase().includes('essential') ? (
        <EventBall className={bigger ? 'size-6 fill-gray-900' : 'size-5 fill-gray-700'} />
      ) : name.toLowerCase().includes('advanced plus') ? (
        <Award className={bigger ? 'size-6 fill-gray-900' : 'size-5 fill-gray-700'} />
      ) : name.toLowerCase().includes('advanced') ? (
        <Award className={bigger ? 'size-6 fill-gray-900' : 'size-5 fill-gray-700'} />
      ) : (
        <Trophy className={bigger ? 'size-6 fill-gray-900' : 'size-5 fill-gray-700'} />
      )}
      <span className={twMerge('text-md font-semibold text-black', bigger && 'text-lg font-bold')}>{name}</span>
    </div>
  );
}

export default memo(TierPlanTitle);
