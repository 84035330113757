import { useQueryClient } from '@tanstack/react-query';
import { Table } from '@tanstack/react-table';
import useAuth from 'contexts/auth/authContext';
import {
  ProjectRolesSchema,
  ProjectUserSchema,
  ProjectUsersSchema,
  UserAccountRemoveSchema,
  UserAccountRemoveSchemaReportsAction
} from 'lib/model';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SettingsUserSelectInput from 'modules/common/Form/SettingsUserSelectInput';
import SelectButton from 'modules/common/SelectButton';
import { memo, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { capitalizeWord } from 'utils/helpers';
import { DialogProps, UserRow } from 'utils/interfaces';

interface DeleteUsersDialogProps extends DialogProps {
  table: Table<UserRow>;
  deleteUsers: (users: UserAccountRemoveSchema[]) => void;
}

interface DeleteUserData {
  user_id: string;
  reports_action: UserAccountRemoveSchemaReportsAction;
  new_report_owner_id: ProjectUserSchema;
}

interface DeleteUserFormValues {
  users: DeleteUserData[];
}

function DeleteUsersDialog({ table, deleteUsers, open, setOpen }: DeleteUsersDialogProps) {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const projectUsers = queryClient.getQueryData<ProjectUsersSchema>(['projectUsers'])!.objects! ?? [];
  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['projectRoles'])!.objects! ?? [];

  const deletedUsers: DeleteUserData[] = useMemo(() => {
    return table.getSelectedRowModel().flatRows.map((row) => {
      return {
        user_id: row.original.id!,
        reports_action: UserAccountRemoveSchemaReportsAction.transfer!,
        new_report_owner_id: projectUsers.find((pUser) => user.id === pUser.user_account_id)!
      };
    });
  }, [table, open, user]);

  const rolesMapping = useMemo(() => {
    return projectRoles.reduce(
      (acc, role) => {
        acc[role.id!] = role.name!;
        return acc;
      },
      {} as Record<string, string>
    );
  }, [projectRoles]);

  const userOptions = useMemo(() => {
    return projectUsers
      .filter((user) => !deletedUsers.some((deleted) => deleted.user_id === user.user_account_id))
      .filter((user) => rolesMapping[user.project_role_id!] !== 'viewer');
    // add check for user.user_account_id exists?
  }, [projectUsers, deletedUsers]);

  const { control, setValue, handleSubmit } = useForm<DeleteUserFormValues>({
    defaultValues: {
      users: deletedUsers
    },
    shouldUnregister: true
  });

  const { fields, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'users' // unique name for your Field Array
  });

  useEffect(() => {
    setValue('users', deletedUsers);
  }, [deletedUsers, setValue]);

  function handleCancel() {
    setOpen(false);
  }

  function handleDelete(data: DeleteUserFormValues) {
    const formattedData = data.users.map((user) => {
      return {
        reports_action: user.reports_action,
        user_id: user.user_id,
        new_reports_owner_id: user.new_report_owner_id.user_account_id!
      };
    });
    deleteUsers(formattedData);
  }

  return (
    <DialogBase title="Delete accounts" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex flex-col gap-6">
          <div>
            <span className="text-md font-medium">You are about to delete the following accounts.</span>
            <br />
            <span className="text-md">Please select how to handle user reports.</span>
          </div>
          <div className="flex flex-col gap-3">
            <form id="transferReportsForm" onSubmit={handleSubmit(handleDelete)} className={'flex flex-col gap-4'}>
              {fields.map((field, index) => {
                const userData = table
                  .getSelectedRowModel()
                  .flatRows.find((row) => row.original.id === field.user_id)?.original;

                if (!userData) return null;
                return (
                  <div key={field.id} className="flex flex-col gap-3 rounded-md bg-gray-50 px-3 py-2">
                    <div className="flex w-full items-center justify-between gap-3">
                      <div className="flex items-center justify-between gap-3">
                        <CircleImage image={userData.image} text={userData.initials!} size="size-9" />
                        <div className="flex flex-col">
                          <span className="text-sm font-semibold">{userData.name!}</span>
                          <span className="text-tiny">{userData.email!}</span>
                        </div>
                      </div>

                      <SelectButton
                        options={[
                          {
                            id: UserAccountRemoveSchemaReportsAction.transfer,
                            label: capitalizeWord(UserAccountRemoveSchemaReportsAction.transfer)
                          },
                          {
                            id: UserAccountRemoveSchemaReportsAction.delete,
                            label: capitalizeWord(UserAccountRemoveSchemaReportsAction.delete)
                          }
                        ]}
                        initial={{
                          id: field.reports_action,
                          label: capitalizeWord(field.reports_action)
                        }}
                        onChange={(option) => {
                          update(index, {
                            ...field,
                            reports_action: option.id as UserAccountRemoveSchemaReportsAction
                          });
                        }}
                      />
                    </div>
                    {field.reports_action === UserAccountRemoveSchemaReportsAction.transfer && (
                      <div>
                        <div className="text-xs font-semibold">Transfer reports to:</div>
                        <SettingsUserSelectInput
                          label="Choose new owner of your reports"
                          name={`users.${index}.new_report_owner_id`}
                          control={control}
                          options={userOptions}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="red" size="lg" isSubmitButton form="transferReportsForm">
          Delete Accounts
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(DeleteUsersDialog);
