import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Next from 'assets/arrow-right.svg?react';
import Premium from 'assets/award.svg?react';
import Enhanced from 'assets/euro.svg?react';
import Event from 'assets/event-ball.svg?react';
import Affordable from 'assets/free.svg?react';
import Biographic from 'assets/id2.svg?react';
import Tracking from 'assets/live-recording.svg?react';
import Odds from 'assets/odds.svg?react';
import Stats from 'assets/statistics.svg?react';
import Assistant from 'assets/text-bubble-smile.svg?react';
import Ranking from 'assets/trophy.svg?react';
import DataSourceDataTypeEnum from 'constants/enums/dataSourceDataType';
import DataSourcePricingEnum from 'constants/enums/dataSourcePricing';
import { seasonOptions } from 'constants/generated';
import useDataSourcesFilters, {
  DataSourceFilters,
  defaultDataSourceFilters
} from 'contexts/dataSourcesFilters/dataSourcesFiltersContext';
import useFilteredDataSources from 'contexts/filteredDataSources/filteredDataSourcesContext';
import { useObjectsOptions } from 'hooks/useOptions';
import { DataTableSchema, DataTablesSchema, ProjectSchema } from 'lib/model';
import { useGetReportGetCompetitions } from 'lib/report/report';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import MultipleSelectList from 'modules/common/Form/MultipleSelectList';
import MultipleSelectTileCheckbox from 'modules/common/Form/MultipleSelectTiles';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DialogProps, TileOption } from 'utils/interfaces';
import Button from '../../../common/Button';
import DialogContent from '../../../common/Dialog/DialogContent';
import { competitionToAutocompleteOption } from 'utils/mappings';
import SelectInput from 'modules/common/Form/Select/SelectInput';

enum AssistantStepsEnum {
  WELCOME,
  BUDGET,
  DATA_TYPES,
  DATA_TABLES,
  LEAGUES_AND_SEASONS,
  EXCLUDED
}

function nextStep(step: AssistantStepsEnum) {
  return step < AssistantStepsEnum.EXCLUDED ? step + 1 : step;
}

function previousStep(step: AssistantStepsEnum) {
  return step > AssistantStepsEnum.WELCOME ? step - 1 : step;
}

const DataSourcesAssistantDialog = memo(function DataSourcesAssistantDialog({ open, setOpen }: DialogProps) {
  const queryClient = useQueryClient();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['dataTables']);
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { filteredDataSources } = useFilteredDataSources();
  const { dataSourcesFilters, setDataSourcesFilters } = useDataSourcesFilters();
  const [step, setStep] = useState<AssistantStepsEnum>(AssistantStepsEnum.WELCOME);

  const { handleSubmit, control } = useForm<DataSourceFilters>({
    defaultValues: defaultDataSourceFilters,
    values: dataSourcesFilters
  });

  const excludedDataSourcesOptions = useObjectsOptions({ objects: filteredDataSources });
  const dataTableOptions = useObjectsOptions(dataTables, {
    getName: (obj: DataTableSchema) => obj.display_name
  });

  const useAssistant = useCallback(
    function useAssistant(data: DataSourceFilters) {
      if (step === AssistantStepsEnum.EXCLUDED) {
        // TODO: do something with the data
        setDataSourcesFilters({ ...data, useAssistant: true, source: 'assistant' });
        setOpen(false);
      }
    },
    [step, setDataSourcesFilters, setOpen]
  );

  const handleNext = useCallback(
    function handleNext() {
      setStep(nextStep(step));
    },
    [step]
  );

  const handleBack = useCallback(
    function handleBack() {
      if (step === AssistantStepsEnum.WELCOME) {
        setOpen(false);
      } else {
        setStep(previousStep(step));
      }
    },
    [setOpen, step]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  useEffect(() => {
    // makes it so that there is no step change during the exit transition
    if (open) setStep(AssistantStepsEnum.WELCOME);
  }, [open]);

  const competitions = useGetReportGetCompetitions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['competitions', project.id],
        staleTime: Infinity
      }
    }
  );
  const competitionOptions = useMemo(() => {
    return competitions.data?.map(competitionToAutocompleteOption) ?? [];
  }, [competitions.data]);

  return (
    <DialogBase title="Data Sources Assistant" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="assistant-form" onSubmit={handleSubmit(useAssistant)}>
          {step === AssistantStepsEnum.WELCOME && (
            <div className="flex flex-col items-center gap-6">
              <div className="flex h-36 flex-col items-center justify-center">
                <Assistant width={64} height={64} className="fill-brand-800" />
              </div>
              <span className="text-center text-md font-semibold">Welcome to your Data Source Assistant!</span>
              <span className="text-center text-md font-medium">
                We're here to help you with every step of the way in finding the perfect combination of Data Sources to
                cover all your needs.
              </span>
            </div>
          )}
          {step === AssistantStepsEnum.BUDGET && (
            <div className="flex w-full flex-col gap-6">
              <span className="text-md font-semibold">What's your budget?</span>
              <MultipleSelectTileCheckbox options={pricingOptions} control={control} name="pricing" />
            </div>
          )}
          {step === AssistantStepsEnum.DATA_TYPES && (
            <div className="flex w-full flex-col gap-6">
              <div className="flex flex-col gap-1">
                <span className="text-md font-semibold">What data types are you looking for?</span>
                <span className="text-sm">You can choose multiple options</span>
              </div>
              <MultipleSelectTileCheckbox options={dataTypeOptions} control={control} name="dataTypes" />
            </div>
          )}
          {step === AssistantStepsEnum.DATA_TABLES && (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <span className="text-md font-semibold">What data tables are you looking for?</span>
                <span className="text-sm">You can choose multiple options</span>
              </div>
              <MultipleSelectList options={dataTableOptions} name="dataTables" control={control} />
            </div>
          )}
          {step === AssistantStepsEnum.LEAGUES_AND_SEASONS && (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <span className="text-md font-semibold">Select Leagues and Seasons</span>
                <span className="text-sm">
                  To refine your search and find exactly what you're looking for, choose the specific leagues and
                  seasons
                </span>
              </div>
              <SelectInput
                multiple
                searchable
                formProps={{
                  control: control,
                  name: 'competitions'
                }}
                label="Leagues"
                placeholder="All Leagues"
                options={competitionOptions}
                loading={competitions.isPending}
              />
              <SelectInput
                formProps={{
                  control: control,
                  name: 'seasons'
                }}
                multiple
                label="Seasons"
                placeholder="All Seasons"
                options={seasonOptions}
              />
            </div>
          )}
          {step === AssistantStepsEnum.EXCLUDED && (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-1">
                <span className="text-md font-semibold">
                  Do you want to exclude some Data Sources from our suggestions? (Optional)
                </span>
                <span className="text-sm">You can choose multiple options</span>
              </div>
              <SelectInput
                formProps={{
                  control: control,
                  name: 'excludedDataSources'
                }}
                multiple
                label="Excluded Data Sources"
                placeholder="None"
                options={excludedDataSourcesOptions}
              />
            </div>
          )}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" isFullWidth={true} onClick={handleBack}>
          {step > AssistantStepsEnum.WELCOME && <Back width={20} height={20} />}
          <span>{step === AssistantStepsEnum.WELCOME ? 'Cancel' : 'Back'}</span>
        </Button>
        {step !== AssistantStepsEnum.EXCLUDED && (
          <Button size="xl" onClick={handleNext}>
            <span>
              {step === AssistantStepsEnum.WELCOME
                ? "Let's get started"
                : step < AssistantStepsEnum.EXCLUDED
                  ? 'Next'
                  : 'Find my perfect combo'}
            </span>
            {step > AssistantStepsEnum.WELCOME && <Next width={20} height={20} />}
          </Button>
        )}
        {step === AssistantStepsEnum.EXCLUDED && (
          <Button size="xl" isSubmitButton form="assistant-form">
            <span>Find my perfect combo</span>
            <Next width={20} height={20} />
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
});

const pricingOptions: TileOption[] = [
  {
    id: DataSourcePricingEnum.AFFORDABLE,
    Icon: Affordable,
    label: 'Affordable',
    description: 'Accessible Data'
  },
  {
    id: DataSourcePricingEnum.ENHANCED,
    Icon: Enhanced,
    label: 'Enhanced',
    description: 'Enhanced Insights'
  },
  {
    id: DataSourcePricingEnum.PREMIUM,
    Icon: Premium,
    label: 'Premium',
    description: 'Comprehensive Analytics'
  }
];

const dataTypeOptions: TileOption[] = [
  {
    id: DataSourceDataTypeEnum.TRACKING,
    Icon: Tracking,
    label: 'Tracking',
    description: 'Tracks player movement for strategic insights during matches.'
  },
  {
    id: DataSourceDataTypeEnum.EVENT,
    Icon: Event,
    label: 'Event',
    description: 'Captures key match events for clear gameplay analysis'
  },
  {
    id: DataSourceDataTypeEnum.BIOGRAPHIC,
    Icon: Biographic,
    label: 'Biographic',
    description: 'Offers detailed profiles of football entities.'
  },
  {
    id: DataSourceDataTypeEnum.RANKING,
    Icon: Ranking,
    label: 'Ranking',
    description: 'Ranks teams and players based on performance.'
  },
  {
    id: DataSourceDataTypeEnum.STATS,
    Icon: Stats,
    label: 'Stats',
    description: 'Provides key performance metrics for players and teams.'
  },
  {
    id: DataSourceDataTypeEnum.ODDS,
    Icon: Odds,
    label: 'Odds',
    description: 'Presents match probabilities, aiding in outcome assessment.'
  }
];

export default DataSourcesAssistantDialog;
