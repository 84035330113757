import Warning from 'assets/warning.svg?react';
import Button from 'modules/common/Button';
import { memo } from 'react';

interface ElementCardWarningProps {
  onRemove?: () => void;
  error: string;
}

function ElementCardWarning({ onRemove, error }: ElementCardWarningProps) {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-6">
        <span className="text-md font-medium">{error}</span>
        <div className="flex h-32 items-center justify-center rounded-xl bg-gray-50">
          <Warning className="size-16 fill-orange-500" />
        </div>
      </div>
      {onRemove && (
        <Button variant="secondary" size="lg" onClick={onRemove}>
          Remove from report
        </Button>
      )}
    </div>
  );
}

export default memo(ElementCardWarning);
