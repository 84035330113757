import { keepPreviousData } from '@tanstack/react-query';
import { useGetReport } from 'lib/report/report';
import Pagination from 'modules/common/Pagination';
import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReportsGrid from './ReportGrid';
import ReportsTableController from './reportTable/ReportsTableController';

interface AllReportsInterface {
  display: 'list' | 'grid';
}

const AllReports = memo(function AllReports({ display }: AllReportsInterface) {
  const [searchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize')!;
  const pageIndex = searchParams.get('pageIndex')!;
  const reportState = searchParams.get('reportState') ?? undefined;
  const reportType = searchParams.get('reportType') ?? undefined;
  const ownership = searchParams.get('ownership') ?? undefined;

  const { data: reports, isFetching: isReportsFetching } = useGetReport(
    {
      state: reportState,
      page: Number(pageIndex) + 1,
      per_page: Number(pageSize),
      report_type: reportType,
      sharing_type: ownership
    },
    {
      query: {
        queryKey: ['reports', 'filtered', reportState, pageIndex, pageSize, reportType, ownership],
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData
      }
    }
  );

  const showTitle = ownership === undefined;

  return (
    <div className="flex flex-col gap-10">
      {showTitle && <span className="text-sm font-semibold">All Reports</span>}
      {display === 'list' && <ReportsTableController data={reports} isDataFetching={isReportsFetching} />}
      {display === 'grid' && <ReportsGrid data={reports} isDataFetching={isReportsFetching} />}
      <Pagination rowCount={reports?.row_count} />
    </div>
  );
});

export default AllReports;
