import { memo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { EventCardProps } from '../EventCard';

function UsersAddedEvent({ event }: EventCardProps) {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex flex-col gap-1">
        {event.json_content!.users.map(([email, role_name]: [string, string]) => (
          <div key={email} className="flex min-w-0 items-center justify-between gap-4">
            <span className="truncate text-xs font-semibold">{email}</span>
            <span className="text-end text-tiny font-medium text-gray-500">{snakeCaseToWords(role_name)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(UsersAddedEvent);
