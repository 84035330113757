import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { max, mean, min } from '@visx/vendor/d3-array';
import { ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useMemo, useRef } from 'react';
import { isLightColor, snakeCaseToWords } from 'utils/helpers';
import { getReportGeneralColor } from '../../../../helpers';
import ChartAxes from '../ChartAxes';
import ChartTooltip from '../ChartTooltip';
import { ScatterChartPoint } from './interfaces';
import SvgJersey from './SvgJersey';

interface ScatterChartProps {
  xField: string;
  yField: string;
  points: ScatterChartPoint[];
  report: ReportNestedSchema;
}

const ScatterChart = memo(function ScatterChart({ xField, yField, points, report }: ScatterChartProps) {
  const xName = snakeCaseToWords(xField);
  const yName = snakeCaseToWords(yField);

  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  const height = 500;
  const margin = {
    top: 0,
    right: 0,
    bottom: 50,
    left: 50
  };
  const chartOffset = 20;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const getX = useCallback((data: ScatterChartPoint) => data[xField] as number, [xField]);
  const getY = useCallback((data: ScatterChartPoint) => data[yField] as number, [yField]);

  const xScale = scaleLinear<number>({
    domain: [Math.min(0, min(points, getX)!), Math.max(max(points, getX)!, 1)],
    round: true
  });
  const yScale = scaleLinear<number>({
    domain: [Math.min(0, min(points, getY)!), Math.max(max(points, getY)!, 1)],
    round: true
  });

  const meanX = useMemo(() => mean(points, getX)!, [points, getX]);
  const meanY = useMemo(() => mean(points, getY)!, [points, getY]);

  // domain -> range === value -> "pixels" mapping
  xScale.range([chartOffset, chartWidth - chartOffset]);
  yScale.range([chartHeight - chartOffset, chartOffset]);

  const jerseyTooltipTimeout = useRef(0);
  const jerseyTooltip = useTooltip<ScatterChartPoint>({
    tooltipOpen: false
  });
  const { containerRef: jerseyContainerRef, TooltipInPortal: JerseyTooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleJerseyMouseLeave = useCallback(() => {
    jerseyTooltipTimeout.current = window.setTimeout(() => {
      jerseyTooltip.hideTooltip();
    }, 300);
  }, [jerseyTooltip]);

  const handleJerseyMouseEnter = useCallback(
    function handleMouseEnter(data: ScatterChartPoint) {
      return () => {
        clearTimeout(jerseyTooltipTimeout.current);
        jerseyTooltip.showTooltip({
          tooltipData: data,
          tooltipLeft: xScale(getX(data)) + 40, // 40 and 20 are magic numbers, don't know why
          tooltipTop: yScale(getY(data)) - 20 - 24
        });
      };
    },
    [getX, getY, jerseyTooltip, xScale, yScale]
  );

  const lineTooltipTimeout = useRef(0);
  const lineTooltip = useTooltip<number>({
    tooltipOpen: false
  });
  const { containerRef: lineContainerRef, TooltipInPortal: LineTooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleLineMouseLeave = useCallback(() => {
    lineTooltipTimeout.current = window.setTimeout(() => {
      lineTooltip.hideTooltip();
    }, 300);
  }, [lineTooltip]);

  const handleLineMouseEnter = useCallback(
    function handleMouseEnter(value: number, left: number, top: number) {
      return () => {
        clearTimeout(lineTooltipTimeout.current);
        lineTooltip.showTooltip({
          tooltipData: value,
          tooltipLeft: left,
          tooltipTop: top
        });
      };
    },
    [lineTooltip]
  );

  const handleSvgRef = useCallback(
    function handleSvgRef(ref: SVGSVGElement | null) {
      if (ref) {
        jerseyContainerRef(ref);
        lineContainerRef(ref);
      }
    },
    [jerseyContainerRef, lineContainerRef]
  );

  return (
    <div ref={parentRef} className="relative">
      <svg className="w-full" height={height} ref={handleSvgRef}>
        <rect width={chartWidth} height={chartHeight} x={margin.left} y={margin.top} fill="#F9FAFB" rx={12} ry={12} />
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={chartWidth}
            height={chartHeight}
            strokeDasharray="2,2"
            stroke="#D1D5DB"
            strokeWidth={1}
            numTicksRows={8}
            numTicksColumns={8}
          />
          <ChartAxes
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            xScale={xScale}
            yScale={yScale}
            xUnit={xName}
            yUnit={yName}
          />
          {/* average on Y axis */}
          <Group
            onMouseEnter={handleLineMouseEnter(meanY, chartWidth + 40 - 14, yScale(meanY) - 20 + 4 - 16)}
            onMouseLeave={handleLineMouseLeave}
          >
            <Line
              from={{ x: 0, y: yScale(meanY) }}
              to={{ x: chartWidth - 28, y: yScale(meanY) }}
              stroke="#6B7280"
              strokeWidth={1}
              strokeDasharray="2,2"
            />
            <Line
              from={{ x: 0, y: yScale(meanY) }}
              to={{ x: chartWidth - 28, y: yScale(meanY) }}
              stroke="transparent"
              strokeWidth={16}
            />
            <text x={chartWidth - 8} y={yScale(meanY) + 4} {...chartTextStyle} textAnchor="end">
              AVG
            </text>
          </Group>
          {/* average on X axis */}
          <Group
            onMouseEnter={handleLineMouseEnter(meanX, xScale(meanX) + 40, chartHeight - 20 - 8 - 16)}
            onMouseLeave={handleLineMouseLeave}
          >
            <Line
              from={{ x: xScale(meanX), y: 0 }}
              to={{ x: xScale(meanX), y: chartHeight - 22 }}
              stroke="#6B7280"
              strokeWidth={1}
              strokeDasharray="2,2"
            />
            <Line
              from={{ x: xScale(meanX), y: 0 }}
              to={{ x: xScale(meanX), y: chartHeight - 22 }}
              stroke="transparent"
              strokeWidth={16}
            />
            <text x={xScale(meanX)} y={chartHeight - 8} {...chartTextStyle} textAnchor="middle">
              AVG
            </text>
          </Group>
          {/* players scatter plot */}
          {points.map((point) => (
            <SvgJersey
              key={point.player_id}
              left={xScale(getX(point))}
              top={yScale(getY(point))}
              color={getReportGeneralColor(report, point.team_id, undefined, point.player_id)}
              blackNumber={isLightColor(getReportGeneralColor(report, point.team_id, undefined, point.player_id))}
              number={point.shirt_number}
              onMouseLeave={handleJerseyMouseLeave}
              onMouseEnter={handleJerseyMouseEnter(point)}
            />
          ))}
        </Group>
      </svg>
      <ChartTooltip
        open={jerseyTooltip.tooltipOpen}
        left={jerseyTooltip.tooltipLeft}
        top={jerseyTooltip.tooltipTop}
        Tooltip={JerseyTooltipInPortal}
      >
        {jerseyTooltip.tooltipData && (
          <>
            <span>{jerseyTooltip.tooltipData.player_name}</span>
            <span>
              {`${snakeCaseToWords(xField)}: `}
              {typeof jerseyTooltip.tooltipData[xField] === 'number'
                ? Number((jerseyTooltip.tooltipData[xField] as number).toFixed(2))
                : jerseyTooltip.tooltipData[xField]}
            </span>
            <span>
              {`${snakeCaseToWords(yField)}: `}
              {typeof jerseyTooltip.tooltipData[yField] === 'number'
                ? Number((jerseyTooltip.tooltipData[yField] as number).toFixed(2))
                : jerseyTooltip.tooltipData[yField]}
            </span>
          </>
        )}
      </ChartTooltip>
      <ChartTooltip
        open={lineTooltip.tooltipOpen}
        left={lineTooltip.tooltipLeft}
        top={lineTooltip.tooltipTop}
        Tooltip={LineTooltipInPortal}
      >
        {lineTooltip.tooltipData && <span>AVG {Number(lineTooltip.tooltipData.toFixed(2))}</span>}
      </ChartTooltip>
    </div>
  );
});

const chartTextStyle = {
  fill: '#6B7280',
  fontSize: 8,
  fontWeight: 500,
  paintOrder: 'stroke',
  fontFamily: 'Inter'
} as const;

export default ScatterChart;
