import { memo } from 'react';
import ElementRow from '../ElementRow';
import Player from '../Player';
import { HeatMapPlayer } from './interfaces';

interface HeatMapPlayerListProps {
  players?: HeatMapPlayer[];
  color: string;
}

function HeatMapPlayerList({ players, color }: HeatMapPlayerListProps) {
  if (!players) return null;
  return (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold">Included players: {players.length === 0 ? 'All' : players.length}</span>
      {players.length > 0 && (
        <div className="grid grid-cols-2 gap-2">
          {players.map((player) => (
            <ElementRow key={player.player_name}>
              <Player size="md" player_name={player.player_name} shirt_number={player.shirt_number} color={color} />
            </ElementRow>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(HeatMapPlayerList);
