import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';

interface PerformanceBarProps {
  value: number;
  maxValue: number;
  color: string;
  reverse?: boolean;
}

const PerformanceBar = memo(function PerformanceBar({ value, maxValue, color, reverse = false }: PerformanceBarProps) {
  if (value === 0 || !value) {
    color = '#D1D5DB';
  }
  const isLight = isLightColor(color);
  return (
    <div className="w-full px-3">
      <div className="flex h-4 rounded-badge bg-gray-200">
        <div
          className={twJoin(
            'flex h-4 min-w-9 items-center rounded-badge',
            reverse && 'ml-auto justify-end',
            isLight && 'border border-gray-300'
          )}
          style={{ backgroundColor: color, width: `${(value / maxValue) * 100}%` }}
        >
          <span className={twJoin('px-2 text-tiny font-bold', isLight ? '' : 'text-white')}>
            {value !== undefined && value !== null ? Number(value.toFixed(2)) : '-'}
          </span>
        </div>
      </div>
    </div>
  );
});

export default PerformanceBar;
