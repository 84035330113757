/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdDashboardActivityLogMutator,
  default as getProjectProjectIdDashboardDatasourcesCountMutator,
  default as getProjectProjectIdDashboardIterationStatsMutator,
  default as getProjectProjectIdDashboardUsersInRolesCountMutator
} from '../../api/axios';
import type {
  ActivityLogsDefaultSchema,
  DatasourceCountSchema,
  GetProjectProjectIdDashboardIterationStatsParams,
  IterationStatsIngestSchema,
  ProjectUserRolesCountSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary get relevant activity logs for a project
 */
export const getProjectProjectIdDashboardActivityLog = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDashboardActivityLogMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDashboardActivityLogMutator<ActivityLogsDefaultSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/dashboard/activity-log`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDashboardActivityLogQueryKey = (projectId: string) => {
  return [`/project/${projectId}/dashboard/activity-log`] as const;
};

export const getGetProjectProjectIdDashboardActivityLogQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardActivityLogMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDashboardActivityLogQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>> = ({ signal }) =>
    getProjectProjectIdDashboardActivityLog(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDashboardActivityLogQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>
>;
export type GetProjectProjectIdDashboardActivityLogQueryError = unknown;

/**
 * @summary get relevant activity logs for a project
 */
export const useGetProjectProjectIdDashboardActivityLog = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardActivityLog>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardActivityLogMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDashboardActivityLogQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get the datasources for a project
 */
export const getProjectProjectIdDashboardDatasourcesCount = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDashboardDatasourcesCountMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDashboardDatasourcesCountMutator<DatasourceCountSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/dashboard/datasources-count`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDashboardDatasourcesCountQueryKey = (projectId: string) => {
  return [`/project/${projectId}/dashboard/datasources-count`] as const;
};

export const getGetProjectProjectIdDashboardDatasourcesCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardDatasourcesCountMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDashboardDatasourcesCountQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>> = ({
    signal
  }) => getProjectProjectIdDashboardDatasourcesCount(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDashboardDatasourcesCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>
>;
export type GetProjectProjectIdDashboardDatasourcesCountQueryError = unknown;

/**
 * @summary get the datasources for a project
 */
export const useGetProjectProjectIdDashboardDatasourcesCount = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardDatasourcesCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardDatasourcesCountMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDashboardDatasourcesCountQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary get the iterations stats for a project
 */
export const getProjectProjectIdDashboardIterationStats = (
  projectId: string,
  params?: GetProjectProjectIdDashboardIterationStatsParams,
  options?: SecondParameter<typeof getProjectProjectIdDashboardIterationStatsMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDashboardIterationStatsMutator<IterationStatsIngestSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/dashboard/iteration-stats`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDashboardIterationStatsQueryKey = (
  projectId: string,
  params?: GetProjectProjectIdDashboardIterationStatsParams
) => {
  return [`/project/${projectId}/dashboard/iteration-stats`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdDashboardIterationStatsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdDashboardIterationStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardIterationStatsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDashboardIterationStatsQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>> = ({ signal }) =>
    getProjectProjectIdDashboardIterationStats(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDashboardIterationStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>
>;
export type GetProjectProjectIdDashboardIterationStatsQueryError = unknown;

/**
 * @summary get the iterations stats for a project
 */
export const useGetProjectProjectIdDashboardIterationStats = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdDashboardIterationStatsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardIterationStats>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardIterationStatsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDashboardIterationStatsQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary count the number of users in each role for a project
 */
export const getProjectProjectIdDashboardUsersInRolesCount = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDashboardUsersInRolesCountMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDashboardUsersInRolesCountMutator<ProjectUserRolesCountSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/dashboard/users-in-roles-count`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDashboardUsersInRolesCountQueryKey = (projectId: string) => {
  return [`/project/${projectId}/dashboard/users-in-roles-count`] as const;
};

export const getGetProjectProjectIdDashboardUsersInRolesCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardUsersInRolesCountMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDashboardUsersInRolesCountQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>> = ({
    signal
  }) => getProjectProjectIdDashboardUsersInRolesCount(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDashboardUsersInRolesCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>
>;
export type GetProjectProjectIdDashboardUsersInRolesCountQueryError = unknown;

/**
 * @summary count the number of users in each role for a project
 */
export const useGetProjectProjectIdDashboardUsersInRolesCount = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDashboardUsersInRolesCount>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDashboardUsersInRolesCountMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDashboardUsersInRolesCountQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
