import LightFootballShield from 'assets/football-shield-outline-light.svg?react';
import FootballShield from 'assets/football-shield.svg?react';
import { memo } from 'react';
import { isWhite } from 'utils/helpers';

interface TeamProps {
  color: string;
  team_name: string;
  size?: 'sm' | 'md' | 'lg';
}

const Team = memo(function Team({ team_name, color, size = 'sm' }: TeamProps) {
  let svgSize = 20;
  if (size === 'md') svgSize = 32;
  else if (size === 'lg') svgSize = 44;

  return (
    <div className="flex items-center gap-2">
      {isWhite(color) ? (
        <LightFootballShield width={svgSize} height={svgSize} style={{ fill: color }} />
      ) : (
        <FootballShield width={svgSize} height={svgSize} style={{ fill: color }} />
      )}
      <span className="text-xs font-medium">{team_name}</span>
    </div>
  );
});

export default Team;
