import { memo } from 'react';

function DashboardSectionSkeleton() {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <div className="size-5 animate-pulse rounded-badge bg-white" />
          <div className="h-4 w-40 animate-pulse rounded-md bg-white" />
        </div>
      </div>
      <div className="grid grid-cols-cards-smaller gap-6">
        <div className="h-28 animate-pulse rounded-xl bg-white" />
        <div className="h-28 animate-pulse rounded-xl bg-white" />
        <div className="h-28 animate-pulse rounded-xl bg-white" />
        <div className="h-28 animate-pulse rounded-xl bg-white" />
      </div>
    </div>
  );
}

export default memo(DashboardSectionSkeleton);
