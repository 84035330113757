import { memo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PasswordRequestResetForm from './PasswordRequestResetForm';
import PasswordResetForm from './PasswordResetForm';
import PasswordResetRequestSent from './PasswordResetRequestSent';

const PasswordResetPage = memo(function PasswordResetPage() {
  const [searchParams] = useSearchParams();
  const userToken = searchParams.get('userToken');
  const [email, setEmail] = useState('');

  if (userToken) {
    return <PasswordResetForm />;
  }

  if (email !== '') {
    return <PasswordResetRequestSent email={email} />;
  }

  return <PasswordRequestResetForm emailEntered={setEmail} />;
});

export default PasswordResetPage;
