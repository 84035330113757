import Copy from 'assets/copy.svg?react';
import { memo } from 'react';
import { toast } from 'react-toastify';

interface InputProps {
  label: string;
  value: string;
}

const Input = memo(function Input({ label, value }: InputProps) {
  async function copyValue() {
    await navigator.clipboard.writeText(value).then(() => toast.success('Successfully copied.'));
  }

  return (
    <div className="relative flex flex-col items-start gap-2 bg-gray-50">
      <input
        id={'readonly-' + label}
        readOnly
        type="text"
        className="peer block min-h-14 w-full cursor-default appearance-none rounded-lg border border-gray-300 bg-gray-50 px-3 pb-1.5 pt-6 text-md focus:border-gray-300 focus:outline-none focus:ring-0"
        value={value}
      />
      <label
        htmlFor={'readonly-' + label}
        className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
      >
        {label}
      </label>
      <Copy className="absolute inset-y-5 end-3 z-10 size-5 cursor-pointer fill-gray-700" onClick={copyValue} />
    </div>
  );
});

export default Input;
