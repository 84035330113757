import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

interface DashboardProgressBarProps {
  progress: number;
}

function DashboardProgressBar({ progress }: DashboardProgressBarProps) {
  return (
    <div className="h-1 w-full rounded-badge bg-gray-300">
      <div
        style={{ width: `${progress * 100}%` }}
        className={twJoin(
          'h-1 rounded-badge',
          progress <= 0.75 && 'bg-green-500',
          progress > 0.74 && progress <= 0.99 && 'bg-orange-400',
          progress > 0.99 && 'bg-red-500'
        )}
      />
    </div>
  );
}

export default memo(DashboardProgressBar);
