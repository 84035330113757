import { ReportAccess } from 'lib/model';
import CircleImage from 'modules/common/CircleImage';
import { memo } from 'react';

interface SharedUsersIconsProps {
  users: ReportAccess[];
}

function SharedUsersIcons({ users }: SharedUsersIconsProps) {
  return (
    <div
      className="flex cursor-default items-center gap-1 pl-1"
      title={users
        .map((user) => (user.first_name ? `${user.first_name} ${user.last_name}` : user.user_email))
        .join('\n')}
    >
      <div className="flex">
        {users.slice(0, 4).map((user) => (
          <CircleImage
            key={user.user_email}
            image={user.user_image_path}
            text={user.first_name && user.last_name ? `${user.first_name[0]}${user.last_name[0]}` : user.user_email![0]}
            size="size-5 -ml-1"
          />
        ))}
      </div>
      {users.length > 4 && <span className="text-tiny font-medium">+{users.length - 4}</span>}
    </div>
  );
}

export default memo(SharedUsersIcons);
