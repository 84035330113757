import Tooltip from 'modules/common/Tooltip';
import ReportIcon from 'modules/reports/reports/components/ReportIcon';
import { memo } from 'react';
import { EventCardProps } from '../EventCard';

function ReportSentEvent({ event }: EventCardProps) {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex justify-between gap-4">
        <div className="flex min-w-0 items-center gap-2">
          <ReportIcon reportType={event.json_content!.report_type} size="sm" />
          <span className="truncate text-xs font-semibold" title={event.json_content!.report_name}>
            {event.json_content!.report_name}
          </span>
        </div>
        <Tooltip message={event.json_content!.sent_emails.join('\n')}>
          <span className="shrink-0 cursor-default rounded-badge border border-gray-300 px-2 py-1 text-tiny font-medium">
            to {event.json_content!.sent_emails.length} Emails
          </span>
        </Tooltip>
      </div>
    </div>
  );
}

export default memo(ReportSentEvent);
