enum DataSourceDataTypeEnum {
  TRACKING = 'tracking',
  BIOGRAPHIC = 'biographic',
  RANKING = 'ranking',
  EVENT = 'event',
  ODDS = 'odds',
  STATS = 'stats',
  SPATIAL = 'spatial'
}

export default DataSourceDataTypeEnum;
