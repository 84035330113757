import Minimize from 'assets/double-arrow-left.svg?react';
import Expand from 'assets/double-arrow-right.svg?react';
import LogoQ from 'assets/logo-q.svg?react';
import Logo from 'assets/logo.svg?react';
import useAppNavigation from 'hooks/useAppNavigation';
import CircleImage from 'modules/common/CircleImage';
import Divider from 'modules/common/Divider';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import NavLinks from './NavLinks';
import UserDropdown from './UserDropdown';

interface SideNavigationProps {
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideNavigation = memo(function SideNavigation({ expanded, setExpanded }: SideNavigationProps) {
  const { project, user, primaryRoutes, secondaryRoutes } = useAppNavigation();

  function toggleSidebar() {
    setExpanded((state) => !state);
  }

  return (
    <aside
      className={twJoin(
        'group sticky inset-y-0 left-0 flex h-screen shrink-0 flex-col rounded-2xl py-6 pl-6 transition-all duration-500 ease-out',
        expanded ? 'w-[280px]' : 'w-[108px]'
      )}
    >
      <header className="relative flex w-full items-center justify-center rounded-t-2xl bg-gray-950 py-10">
        <Link to="/">
          {expanded ? <Logo className="fill-white" width={86} height={28} /> : <LogoQ width={28} height={28} />}
        </Link>
        <button
          onClick={toggleSidebar}
          className={twJoin(
            'absolute flex h-8 w-8 items-center justify-center bg-gray-950 opacity-0 transition-opacity duration-500 group-hover:opacity-100',
            expanded && 'right-6'
          )}
        >
          {expanded ? (
            <Minimize width={28} height={28} className="fill-white" />
          ) : (
            <Expand width={28} height={28} className="fill-white" />
          )}
        </button>
      </header>
      <div className="flex grow flex-col gap-6 rounded-b-2xl bg-white px-4 py-6">
        <div className="flex w-full items-center justify-center gap-3 p-2">
          <CircleImage
            image={project?.organization_image_path}
            text={project!.organization_name!.charAt(0)}
            size="size-8"
          />
          {expanded && (
            <span className="pointer-events-none grow truncate text-md font-medium">{project?.organization_name}</span>
          )}
        </div>
        <div className="px-2">
          <Divider />
        </div>
        <nav className="flex grow flex-col items-center justify-between">
          <div className="flex w-full flex-col items-center">
            <NavLinks items={primaryRoutes} expanded={expanded} />
          </div>
          <div className="flex w-full flex-col items-center">
            <NavLinks items={secondaryRoutes} expanded={expanded} />
          </div>
        </nav>
        <Divider />
        <div className={twJoin('flex items-center gap-2', expanded ? 'justify-between' : 'justify-center')}>
          <UserDropdown expanded={expanded} user={user!} />
        </div>
      </div>
    </aside>
  );
});

export default SideNavigation;
