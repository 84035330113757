import CheckCircle from 'assets/check-circle.svg?react';
import Progress from 'assets/progress.svg?react';
import Unknown from 'assets/unknown.svg?react';
import Unlink from 'assets/unlink.svg?react';
import Warning from 'assets/warning.svg?react';
import SyncStatusEnum from 'constants/enums/syncStatus';
import { memo } from 'react';

interface SyncStatusBadgeProps {
  status: string;
  inactive: boolean;
}

const DataSourceSyncStatusBadge = memo(function SyncStatusBadge({ status, inactive }: SyncStatusBadgeProps) {
  if (inactive) {
    return (
      <div className="flex w-fit items-center gap-1 rounded-badge border border-gray-300 bg-gray-50 px-3 py-2">
        <Unlink width={14} height={14} className="fill-gray-500" />
        <span className="text-tiny font-semibold text-gray-500">INACTIVE</span>
      </div>
    );
  }
  switch (status) {
    case SyncStatusEnum.FAILED:
      return (
        <div className="flex w-fit items-center gap-1 rounded-badge border border-red-200 bg-red-50 px-3 py-2">
          <Warning width={14} height={14} className="fill-red-600" />
          <span className="text-tiny font-semibold text-red-600">SYNC FAILED</span>
        </div>
      );
    case SyncStatusEnum.SUCCESSFUL:
      return (
        <div className="flex w-fit items-center gap-1 rounded-badge border border-green-400 bg-green-50 px-3 py-2">
          <CheckCircle width={14} height={14} className="fill-green-600" />
          <span className="text-tiny font-semibold text-green-600">SYNCED</span>
        </div>
      );
    case SyncStatusEnum.IN_PROGRESS:
      return (
        <div className="flex w-fit items-center gap-1 rounded-badge border border-orange-400 bg-orange-50 px-3 py-2">
          <Progress width={14} height={14} className="fill-orange-600" />
          <span className="text-tiny font-semibold text-orange-600">SYNC IN PROGRESS</span>
        </div>
      );
    case SyncStatusEnum.DEPLOYING:
      return (
        <div className="flex w-fit items-center gap-1 rounded-badge border border-blue-400 bg-blue-50 px-3 py-2">
          <Progress width={14} height={14} className="fill-blue-600" />
          <span className="text-tiny font-semibold text-blue-600">DEPLOYING</span>
        </div>
      );
    case SyncStatusEnum.UNKNOWN:
      return (
        <div className="flex w-fit items-center gap-1 rounded-badge border border-gray-300 bg-gray-50 px-3 py-2">
          <Unknown width={14} height={14} className="fill-gray-500" />
          <span className="text-tiny font-semibold text-gray-500">UNKNOWN</span>
        </div>
      );
    default:
      return null;
  }
});

export default DataSourceSyncStatusBadge;
