/* eslint-disable @typescript-eslint/no-explicit-any */
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { ReportNestedSchema } from 'lib/model';
import { useCallback, useMemo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { getReportGeneralColor } from '../../../../helpers';
import { Standing } from './interfaces';

interface StandingsTableProps {
  standings: Standing[];
  highlightedTeams: Record<string, Standing>;
  report: ReportNestedSchema;
}

const columnHelper = createColumnHelper<Standing>();

export default function StandingsTable({ standings, highlightedTeams, report }: StandingsTableProps) {
  const columns = [
    columnHelper.accessor('rank', {
      cell: (info) => {
        const team_id = info.row.original.team_id;
        let color;
        if (highlightedTeams[team_id] !== undefined) {
          color = highlightedTeams[team_id].team_color
            ? highlightedTeams[team_id].team_color
            : getReportGeneralColor(report, team_id);
        }
        return (
          <div
            className="flex size-5 items-center justify-center rounded-full bg-gray-200 text-tiny font-semibold"
            style={color ? { backgroundColor: color, color: isLightColor(color) ? 'black' : 'white' } : undefined}
          >
            {info.getValue() as number}
          </div>
        );
      },
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">RANK</span>
    }),
    columnHelper.accessor('team_name', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">TEAM</span>
    }),
    columnHelper.accessor('played', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">MP</span>
    }),
    columnHelper.accessor('win', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">W</span>
    }),
    columnHelper.accessor('draw', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">D</span>
    }),
    columnHelper.accessor('lose', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">L</span>
    }),
    columnHelper.accessor('goal_difference', {
      cell: (info) => (
        <span className="px-3 text-xs">
          {new Intl.NumberFormat('en-US', { signDisplay: 'exceptZero' }).format(info.getValue())}
        </span>
      ),
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">GD</span>
    }),
    columnHelper.accessor('last_5_results', {
      cell: (info) => (
        <div className="flex w-fit items-center gap-0.5 px-3">
          {info.getValue().map((outcome, index) => (
            <span
              key={index}
              className={twJoin(
                'flex h-6 w-6 items-center justify-center rounded-md text-tiny font-bold',
                outcome === 'W' && 'bg-green-500 text-white',
                outcome === 'L' && 'bg-red-500 text-white',
                outcome === 'D' && 'bg-gray-200'
              )}
            >
              {outcome}
            </span>
          ))}
        </div>
      ),
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">LAST 5</span>,
      meta: {
        align: 'center'
      }
    }),
    columnHelper.accessor('points', {
      cell: (info) => <span className="px-3 text-xs">{info.getValue()}</span>,
      header: () => <span className="px-3 text-tiny font-medium uppercase text-gray-500">PTS</span>
    })
  ];

  const [showAll, setShowAll] = useState<boolean>(false);

  const handleToggleShowAll = useCallback(() => setShowAll((state) => !state), []);

  const minRank = useMemo(
    () => Math.max(Math.min(...Object.values(highlightedTeams).map((standing) => standing.rank)) - 2 - 1, 0),
    [highlightedTeams]
  );
  const maxRank = useMemo(
    () => Math.min(Math.max(...Object.values(highlightedTeams).map((standing) => standing.rank)) + 2, standings.length),
    [highlightedTeams, standings.length]
  );
  const shownStandings = useMemo(() => {
    if (showAll) {
      return standings;
    } else {
      const sortedHiglightedTeams = Object.values(highlightedTeams).sort((a, b) => a.rank - b.rank);
      const minTeam = sortedHiglightedTeams[0];
      const maxTeam = sortedHiglightedTeams[sortedHiglightedTeams.length - 1];
      if (sortedHiglightedTeams.length === 2 && maxTeam.rank - minTeam.rank > 5) {
        const lowerSlice = standings.slice(minRank, minTeam.rank + 2);
        const upperSlice = standings.slice(maxTeam.rank - 2, maxRank);
        return [...lowerSlice, { rank: -1 } as Standing, ...upperSlice];
      } else {
        return standings.slice(minRank, maxRank);
      }
    }
  }, [highlightedTeams, maxRank, minRank, showAll, standings]);

  const table = useReactTable({
    data: shownStandings,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className="flex flex-col gap-6">
      <div className="grid grid-cols-cards overflow-x-auto">
        <table className="w-full border-separate border-spacing-y-2">
          <thead>
            <tr className="h-12">
              {table.getHeaderGroups().flatMap((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className="bg-white"
                      align={(header.column.columnDef.meta as any)?.align ?? 'left'}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody className="gap-2 bg-transparent">
            {!showAll && minRank > 1 && (
              <tr className="h-7">
                <td colSpan={table.getAllColumns().length} align="center">
                  <span className="align-top text-tiny font-semibold text-gray-500">...</span>
                </td>
              </tr>
            )}
            {table.getRowModel().rows.map((row) => {
              if (row.original.rank === -1) {
                return (
                  <tr className="h-7" key="middle-separator">
                    <td colSpan={table.getAllColumns().length} align="center">
                      <span className="align-top text-tiny font-semibold text-gray-500">...</span>
                    </td>
                  </tr>
                );
              }

              return (
                <tr key={row.id} className="h-12 bg-gray-50 font-medium">
                  {row.getVisibleCells().map((cell) => {
                    const team_id = row.original.team_id;
                    let color;
                    if (highlightedTeams[team_id] !== undefined) {
                      color = highlightedTeams[team_id].team_color
                        ? highlightedTeams[team_id].team_color + '0f'
                        : getReportGeneralColor(report, team_id) + '0f';
                    }
                    return (
                      <td
                        style={color ? { backgroundColor: color } : undefined}
                        className={twJoin(
                          'whitespace-nowrap first:rounded-l-md first:pl-3 last:rounded-r-md last:pr-3',
                          color && 'font-bold'
                        )}
                        key={cell.id}
                        align={(cell.column.columnDef.meta as any)?.align ?? 'left'}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {!showAll && maxRank < standings.length && (
              <tr className="h-7">
                <td colSpan={table.getAllColumns().length} align="center">
                  <span className="align-top text-tiny font-semibold text-gray-500">...</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <button className="w-full text-center text-sm font-medium text-brand-800" onClick={handleToggleShowAll}>
        {showAll ? 'Hide full standings' : 'Show full standings'}
      </button>
    </div>
  );
}
