import { useQueryClient } from '@tanstack/react-query';
import { reportPermissionOptions } from 'constants/formOptions';
import { ReportAccessCreatePermission, ReportAccessSchema, ReportNestedSchema } from 'lib/model';
import { useDeleteReportReportIdUsers, usePutReportReportIdUsers } from 'lib/report/report';
import SelectButton from 'modules/common/SelectButton';
import UserCard from 'modules/common/UserCard';
import { memo } from 'react';
import { toast } from 'react-toastify';
import { getUserInitials } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';

interface SharedWithItemProps {
  user: ReportAccessSchema;
  report: ReportNestedSchema;
}

function SharedWithItem({ user, report }: SharedWithItemProps) {
  const queryClient = useQueryClient();
  const { mutate: editPermission, isPending: isEditing } = usePutReportReportIdUsers({
    mutation: {
      onSuccess: () => {
        toast.success('Successfully changed permissions');
        queryClient.invalidateQueries({ queryKey: ['reportUsers'] });
        queryClient.invalidateQueries({ queryKey: ['reports'] });
      },
      onError: () => {
        toast.error('Permission change failed');
      }
    }
  });
  const { mutate: revokePermission, isPending: isRevoking } = useDeleteReportReportIdUsers({
    mutation: {
      onSuccess: () => {
        toast.success('Successfully revoked permissions');
        queryClient.invalidateQueries({ queryKey: ['reportUsers'] });
        queryClient.invalidateQueries({ queryKey: ['reports'] });
      },
      onError: () => {
        toast.error('Permission revocation failed');
      }
    }
  });

  function handlePermissionChange(option: ValueOption) {
    if (option.id === 'none') {
      revokePermission({
        reportId: report.id!,
        data: {
          user_email: user.user_email,
          user_id: user.user_id
        }
      });
    } else {
      editPermission({
        reportId: report.id!,
        data: {
          permission: option.id as ReportAccessCreatePermission,
          user_email: user.user_email,
          user_id: user.user_id
        }
      });
    }
  }

  return (
    <div className="flex w-full items-center justify-between rounded-md bg-gray-50 px-3 py-2">
      <UserCard
        email={user.user_email!}
        name={`${user.first_name} ${user.last_name}`}
        initials={getUserInitials(user)}
      />
      <SelectButton
        loading={isEditing || isRevoking}
        initial={reportPermissionOptions.find((perm) => perm.id === user.permission)!}
        options={extendedReportPermissionOptions}
        onChange={handlePermissionChange}
      />
    </div>
  );
}

const extendedReportPermissionOptions: ValueOption[] = [
  ...reportPermissionOptions,
  {
    label: 'Revoke permissions',
    id: 'none'
  }
];

export default memo(SharedWithItem);
