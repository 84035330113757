/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdDataTableDataTableIdUserCorrectionMutator,
  default as getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator,
  default as postProjectProjectIdDataTableDataTableIdUserCorrectionMutator,
  default as putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator
} from '../../api/axios';
import type {
  GetProjectProjectIdDataTableDataTableIdUserCorrectionParams,
  UserCorrectionSchema,
  UserCorrectionsCreateSchema,
  UserCorrectionsSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all user corrections on the given project.
 */
export const getProjectProjectIdDataTableDataTableIdUserCorrection = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdUserCorrectionParams,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdUserCorrectionMutator<UserCorrectionsSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/user-correction`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdUserCorrectionQueryKey = (
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdUserCorrectionParams
) => {
  return [`/project/${projectId}/data-table/${dataTableId}/user-correction`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdUserCorrectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdUserCorrectionParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdDataTableDataTableIdUserCorrectionQueryKey(projectId, dataTableId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>> = ({
    signal
  }) => getProjectProjectIdDataTableDataTableIdUserCorrection(projectId, dataTableId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && dataTableId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdUserCorrectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>
>;
export type GetProjectProjectIdDataTableDataTableIdUserCorrectionQueryError = unknown;

/**
 * @summary Returns a list of all user corrections on the given project.
 */
export const useGetProjectProjectIdDataTableDataTableIdUserCorrection = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  params?: GetProjectProjectIdDataTableDataTableIdUserCorrectionParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrection>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdUserCorrectionQueryOptions(
    projectId,
    dataTableId,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a user_correction(s) for the given data table, project and optionally specific column.
 */
export const postProjectProjectIdDataTableDataTableIdUserCorrection = (
  projectId: string,
  dataTableId: string,
  userCorrectionsCreateSchema: UserCorrectionsCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdDataTableDataTableIdUserCorrectionMutator>
) => {
  return postProjectProjectIdDataTableDataTableIdUserCorrectionMutator<UserCorrectionSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/user-correction`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userCorrectionsCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdDataTableDataTableIdUserCorrectionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>,
    TError,
    { projectId: string; dataTableId: string; data: UserCorrectionsCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdDataTableDataTableIdUserCorrectionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>,
  TError,
  { projectId: string; dataTableId: string; data: UserCorrectionsCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>,
    { projectId: string; dataTableId: string; data: UserCorrectionsCreateSchema }
  > = (props) => {
    const { projectId, dataTableId, data } = props ?? {};

    return postProjectProjectIdDataTableDataTableIdUserCorrection(projectId, dataTableId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdDataTableDataTableIdUserCorrectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>
>;
export type PostProjectProjectIdDataTableDataTableIdUserCorrectionMutationBody = UserCorrectionsCreateSchema;
export type PostProjectProjectIdDataTableDataTableIdUserCorrectionMutationError = unknown;

/**
 * @summary Creates a user_correction(s) for the given data table, project and optionally specific column.
 */
export const usePostProjectProjectIdDataTableDataTableIdUserCorrection = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>,
    TError,
    { projectId: string; dataTableId: string; data: UserCorrectionsCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdDataTableDataTableIdUserCorrectionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdDataTableDataTableIdUserCorrection>>,
  TError,
  { projectId: string; dataTableId: string; data: UserCorrectionsCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdDataTableDataTableIdUserCorrectionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns a user correction.
 */
export const getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId = (
  projectId: string,
  dataTableId: string,
  userCorrectionId: string,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator<UserCorrectionSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/user-correction/${encodeURIComponent(String(userCorrectionId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryKey = (
  projectId: string,
  dataTableId: string,
  userCorrectionId: string
) => {
  return [`/project/${projectId}/data-table/${dataTableId}/user-correction/${userCorrectionId}`] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  userCorrectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryKey(
      projectId,
      dataTableId,
      userCorrectionId
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>
  > = ({ signal }) =>
    getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId(
      projectId,
      dataTableId,
      userCorrectionId,
      requestOptions,
      signal
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(projectId && dataTableId && userCorrectionId),
    ...queryOptions
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>
>;
export type GetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryError = unknown;

/**
 * @summary Returns a user correction.
 */
export const useGetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  userCorrectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdQueryOptions(
    projectId,
    dataTableId,
    userCorrectionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Marks a user correction as resolved.
 */
export const putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId = (
  projectId: string,
  dataTableId: string,
  userCorrectionId: string,
  options?: SecondParameter<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>
) => {
  return putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}/user-correction/${encodeURIComponent(String(userCorrectionId))}`,
      method: 'PUT'
    },
    options
  );
};

export const getPutProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
    TError,
    { projectId: string; dataTableId: string; userCorrectionId: string },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
  TError,
  { projectId: string; dataTableId: string; userCorrectionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
    { projectId: string; dataTableId: string; userCorrectionId: string }
  > = (props) => {
    const { projectId, dataTableId, userCorrectionId } = props ?? {};

    return putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId(
      projectId,
      dataTableId,
      userCorrectionId,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>
>;

export type PutProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutationError = unknown;

/**
 * @summary Marks a user correction as resolved.
 */
export const usePutProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
    TError,
    { projectId: string; dataTableId: string; userCorrectionId: string },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionId>>,
  TError,
  { projectId: string; dataTableId: string; userCorrectionId: string },
  TContext
> => {
  const mutationOptions =
    getPutProjectProjectIdDataTableDataTableIdUserCorrectionUserCorrectionIdMutationOptions(options);

  return useMutation(mutationOptions);
};
