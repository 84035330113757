import { defaultReportsTablePageSize } from 'constants/tableDefaults';
import { memo } from 'react';

interface ReportTableSkeletonProps {
  rowNum?: number;
}

const ReportTableSkeleton = memo(function ReportTableSkeleton({
  rowNum = defaultReportsTablePageSize
}: ReportTableSkeletonProps) {
  const rows = Array.from(new Array(rowNum).keys());

  return (
    <div className="flex flex-col gap-3">
      {/* <div className="flex h-14 items-center gap-5 rounded-xl bg-white px-3">
        <div className="h-3 basis-6/12 animate-pulse rounded-md bg-gray-50" />
        <div className="h-3 basis-3/12 animate-pulse rounded-md bg-gray-50" />
        <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
        <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
        <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
      </div> */}
      {rows.map((index) => (
        <div key={index} className="flex h-[72px] items-center gap-5 rounded-xl bg-white px-3">
          <div className="h-10 basis-6/12 animate-pulse rounded-md bg-gray-50" />
          <div className="h-3 basis-3/12 animate-pulse rounded-md bg-gray-50" />
          <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
          <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
          <div className="h-3 basis-1/12 animate-pulse rounded-md bg-gray-50" />
        </div>
      ))}
    </div>
  );
});

export default ReportTableSkeleton;
