import { ReportAccessCreatePermission } from 'lib/model';
import { ValueOption } from 'utils/interfaces';

export const dataSourceRefreshFrequencyOptions = [
  // { id: '* * * *', label: 'Daily' },
  { id: '? * MON *', label: 'Weekly (Every Monday)' },
  { id: '? * TUE *', label: 'Weekly (Every Tuesday)' },
  { id: '? * WED *', label: 'Weekly (Every Wednesday)' },
  { id: '? * THU *', label: 'Weekly (Every Thursday)' },
  { id: '? * FRI *', label: 'Weekly (Every Friday)' },
  { id: '? * SAT *', label: 'Weekly (Every Saturday)' },
  { id: '? * SUN *', label: 'Weekly (Every Sunday)' },
  { id: new Date().getDate().toString() + ' * ? *', label: 'Monthly' }
] as ValueOption[];

export const organizationTypeOptions = [{ id: 'footballClub', label: 'Football Club' }] as ValueOption[];

export const dayOfWeekOptions: ValueOption[] = [
  {
    label: 'Monday',
    id: 0
  },
  {
    label: 'Tuesday',
    id: 1
  },
  {
    label: 'Wednesday',
    id: 2
  },
  {
    label: 'Thursday',
    id: 3
  },
  {
    label: 'Friday',
    id: 4
  },
  {
    label: 'Saturday',
    id: 5
  },
  {
    label: 'Sunday',
    id: 6
  }
];

export const reportPermissionOptions: ValueOption[] = [
  {
    label: 'Can Read',
    id: ReportAccessCreatePermission.read
  },
  {
    label: 'Can Edit',
    id: ReportAccessCreatePermission.edit
  }
];

export const sendReportScheduleFrequencyOptions: ValueOption[] = [
  {
    label: 'Every day',
    id: 'daily'
  },
  {
    label: 'Every week',
    id: 'weekly'
  },
  {
    label: 'Every month',
    id: 'monthly'
  },
  {
    label: 'Every time a new update is available',
    id: 'after_iteration'
  }
  /*   {
    label: 'Send only once',
    value: 'once'
  } */
];

export const dayOfMonthOptions: ValueOption[] = [
  {
    label: 'First work day of the month',
    id: 'first'
  },
  {
    label: 'Last work day of the month',
    id: 'last'
  },
  {
    label: 'Current day of the month',
    id: 'current'
  }
];

export const timePeriodOptions = [
  {
    label: '1st Half',
    id: '1st half'
  },
  {
    label: '2nd Half',
    id: '2nd half'
  },
  {
    label: 'Extra Time',
    id: 'Extra time'
  },
  {
    label: 'Full Match',
    id: 'Full Match'
  }
] as const;

export const positionOptions = [
  {
    label: 'Attack & Defence',
    id: 'all'
  },
  {
    label: 'Attack',
    id: 'attack'
  },
  {
    label: 'Defence',
    id: 'defence'
  }
] as const;

export const pressureOptions = [
  {
    label: 'All',
    id: 'all'
  },
  {
    label: 'Pressure',
    id: 'pressure'
  },
  {
    label: 'No Pressure',
    id: 'no-pressure'
  }
] as const;
