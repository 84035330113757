/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */

export type ProjectRoleSchemaName = (typeof ProjectRoleSchemaName)[keyof typeof ProjectRoleSchemaName];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectRoleSchemaName = {
  admin: 'admin',
  owner: 'owner',
  viewer: 'viewer',
  editor: 'editor',
  scout_viewer: 'scout_viewer',
  scout: 'scout',
  head_scout: 'head_scout'
} as const;
