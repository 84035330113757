import useIsMobile from 'hooks/useIsMobile';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import LoginForm from './components/LoginForm';

const LoginPage = memo(function LoginPage() {
  const isMobile = useIsMobile();
  // const handleGoogle = useCallback(function handleGoogle() {
  //   return undefined;
  // }, []);

  // const handleMicrosoft = useCallback(function handleMicrosoft() {
  //   return undefined;
  // }, []);

  return (
    <div
      className={twMerge(
        'flex w-full max-w-xl flex-col gap-10 rounded-3xl bg-white p-16',
        isMobile && 'rounded-none p-8'
      )}
    >
      <h2 className="text-h6 font-semibold text-gray-900">Welcome back</h2>
      <LoginForm />
      {/* <TextDivider text="OR CONTINUE WITH" />
      <div className="flex w-full gap-6">
        <Button variant="secondary" size="xl" onClick={handleGoogle}>
          <Google width={20} height={20} className="fill-gray-700" />
          <span>Google</span>
        </Button>
        <Button variant="secondary" size="xl" onClick={handleMicrosoft}>
          <Microsoft width={20} height={20} className="fill-gray-700" />
          <span>Microsoft</span>
        </Button>
      </div> */}
    </div>
  );
});

export default LoginPage;
