import Add from 'assets/plus.svg?react';
import Button from 'modules/common/Button';
import Fieldset from 'modules/common/Form/Fieldset';
import MultipleValueInput from 'modules/common/Form/MultipleValueInput';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScheduleReportAddEmailsFormValues } from './interfaces';

interface ScheduleFormAddEmailsProps {
  onAddEmails: (data: ScheduleReportAddEmailsFormValues) => void;
}

function ScheduleFormAddEmails({ onAddEmails }: ScheduleFormAddEmailsProps) {
  const [editEmails, setEditEmails] = useState<boolean>(false);
  const emailsSubform = useForm<ScheduleReportAddEmailsFormValues>();

  const toggleEditEmails = useCallback(
    function toggleEditEmails() {
      if (editEmails) {
        emailsSubform.reset();
      }
      setEditEmails((state) => !state);
    },
    [editEmails, emailsSubform]
  );

  const addEmails = useCallback(
    function addEmails(data: ScheduleReportAddEmailsFormValues) {
      onAddEmails(data);
      toggleEditEmails();
    },
    [onAddEmails, toggleEditEmails]
  );

  return (
    <>
      {!editEmails ? (
        <Button size="lg" variant="secondary" onClick={toggleEditEmails}>
          <Add width={20} height={20} />
          Add more emails
        </Button>
      ) : (
        <form className="flex flex-col gap-10" onSubmit={emailsSubform.handleSubmit(addEmails)} id="add-emails-form">
          <Fieldset legend="Add more emails">
            <MultipleValueInput
              name="emails"
              initialPlaceholder="Ex: user.name@gmail.com, user.name@yahoo.com"
              label="Enter emails"
              control={emailsSubform.control}
              rules={{
                validate: {
                  minLength: (values: string[]) => (values && values.length > 0) || 'At least one email is required'
                }
              }}
              error={emailsSubform.formState.errors.emails}
            />
            <div className="ml-auto flex w-1/2 items-center gap-2.5">
              <Button size="lg" isSubmitButton form="add-emails-form">
                Add emails
              </Button>
              <Button variant="secondary" size="lg" onClick={toggleEditEmails}>
                Cancel
              </Button>
            </div>
          </Fieldset>
        </form>
      )}
    </>
  );
}

export default memo(ScheduleFormAddEmails);
