import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import MatchLineupPitch from './variations/MatchLineupPitch';
import TeamComparisonLineupPitch from './variations/TeamComparisonLineupPitch';
import TeamLineupPitch from './variations/TeamLineupPitch';

interface LineupPitchElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const LineupPitchElement = memo(function LineupPitchElement({ report, element }: LineupPitchElementProps) {
  if (report.report_type === 'match') {
    return <MatchLineupPitch element={element} />;
  } else if (report.report_type === 'team') {
    return <TeamLineupPitch element={element} />;
  } else if (report.report_type === 'team_comparison') {
    return <TeamComparisonLineupPitch element={element} />;
  }

  return null;
});

export default LineupPitchElement;
