/* eslint-disable react/jsx-no-bind */
import { useQueryClient } from '@tanstack/react-query';
import { defaultDataTablePageSize, defaultPageIndex } from 'constants/tableDefaults';
import { ProjectDataColumn, ProjectSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SideDialogBase from 'modules/common/Dialog/SideDialogBase';
import { memo, useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { DialogProps } from 'utils/interfaces';
import DataTableAutocomplete from './components/DataTableAutocomplete';
import DataTableDatePicker from './components/DataTableDatePicker';
import DataTableSlider from './components/DataTableSlider';

interface FiltersDialogProps extends DialogProps {
  dataTableColumns: ProjectDataColumn[];
}

function DataTableFiltersDialog({ open, setOpen, dataTableColumns }: FiltersDialogProps) {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { dataTableId } = useParams();
  const refDataTableId = useRef(dataTableId);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Record<string, string[] | number[]>>(
    searchParams.get('filters') ? JSON.parse(searchParams.get('filters')!) : {}
  );

  function onCancel() {
    setOpen(false);
  }

  function filterDataTable(clear = false) {
    searchParams.set('filters', JSON.stringify(clear ? {} : filters));
    searchParams.set('pageSize', String(defaultDataTablePageSize));
    searchParams.set('pageIndex', String(defaultPageIndex));
    setSearchParams(searchParams);

    setOpen(false);
  }

  function clearFilters() {
    setFilters({});
    filterDataTable(true);
  }

  useEffect(() => {
    if (dataTableId !== refDataTableId.current) {
      refDataTableId.current = dataTableId;
      setFilters({});
    }
  }, [dataTableId]);

  return (
    <SideDialogBase title="Filter" open={open} onCancel={onCancel}>
      <DialogContent>
        <div className="flex w-full max-w-[352px] flex-col gap-6" id="filter-data-table-form">
          {dataTableColumns
            .filter((x) => x.filterable)
            .map((col) =>
              col.data_type === 'text' ? (
                <DataTableAutocomplete
                  key={col.column_name}
                  placeholder={col.display_name!}
                  projectId={project.id!}
                  dataTableId={dataTableId!}
                  columnName={col.column_name!}
                  filters={filters as Record<string, string[]>}
                  setFilters={setFilters}
                />
              ) : col.data_type === 'date' ? (
                <div key={col.column_name} className="flex flex-col">
                  <span className="pb-3 text-md font-medium">{col.display_name}</span>
                  <DataTableDatePicker
                    key={col.column_name}
                    min={col.min_value!}
                    max={col.max_value!}
                    columnName={col.column_name!}
                    filters={filters as Record<string, string[]>}
                    setFilters={setFilters}
                  />
                </div>
              ) : (
                <div key={col.column_name} className="flex flex-col">
                  <span className="pb-3 text-md font-medium">{col.display_name}</span>
                  <DataTableSlider
                    key={col.column_name}
                    min={parseInt(col.min_value!)}
                    max={parseInt(col.max_value!)}
                    columnName={col.column_name!}
                    filters={filters as Record<string, number[]>}
                    setFilters={setFilters}
                  />
                </div>
              )
            )}
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="flex w-full items-center gap-8">
          {Object.keys(filters).length > 0 && (
            <span
              className="cursor-pointer whitespace-nowrap text-md font-semibold text-brand-800"
              onClick={clearFilters}
            >
              Clear all
            </span>
          )}
          <Button size="xl" form="filter-data-table-form" onClick={() => filterDataTable()}>
            Filter
          </Button>
        </div>
      </DialogFooter>
    </SideDialogBase>
  );
}

export default memo(DataTableFiltersDialog);
