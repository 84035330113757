import useAuth from 'contexts/auth/authContext';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { useGetProjectProjectIdRole } from 'lib/project-role/project-role';
import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { useGetProjectProjectId } from 'lib/project/project';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';

export default function useInitialDataLoad() {
  const { user } = useAuth();

  const userId = user?.id;
  const projectId = user?.user_projects![0].project;

  // base endpoints
  const { isPending: isProjectPending, data: project } = useGetProjectProjectId(projectId!, {
    query: { queryKey: ['project'], enabled: !!userId, staleTime: Infinity }
  });

  const { isPending: isDataTablesPending } = useGetProjectProjectIdDataTable(projectId!, {
    query: {
      queryKey: ['dataTables'],
      staleTime: Infinity
    }
  });

  const { data: activeTier } = useGetProjectProjectIdSubscription(
    projectId!,
    {},
    { query: { queryKey: ['activeTier'], staleTime: Infinity, enabled: !!projectId } }
  );

  const {
    isPending: isProjectUsersPending,
    data: projectUsers,
    isError: isProjectUsersError
  } = useGetProjectProjectIdUser(projectId!, {
    query: { queryKey: ['projectUsers'], retry: false, refetchOnWindowFocus: false }
  });

  // Load for other elements?
  useGetProjectProjectIdRole(projectId!, {
    query: { queryKey: ['projectRoles'], retry: false, staleTime: Infinity, enabled: !!projectId }
  });

  const isLoading = isProjectPending || isProjectUsersPending || isDataTablesPending;
  const shouldInviteUsers = !isProjectUsersError && projectUsers?.objects?.length === 1;

  return { project, isLoading, shouldInviteUsers, activeTier };
}
