import { ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { getDefaultColor, getDefaultTeamColor } from '../../../../helpers';
import { PlayerGeneralData, ScoutPlayerData, TeamGeneralData } from '../../reportCards/interfaces';

interface ScatterChartLegendProps {
  report: ReportNestedSchema;
}

function ScatterChartLegend({ report }: ScatterChartLegendProps) {
  let generalData;
  if (report.report_type === 'team_comparison') {
    generalData = report.general_data as { team1: TeamGeneralData; team2: TeamGeneralData };
  } else if (report.report_type === 'team') {
    generalData = report.general_data as TeamGeneralData;
  } else if (report.report_type === 'player_comparison') {
    generalData = report.general_data as { player1: PlayerGeneralData; player2: PlayerGeneralData };
  } else if (report.report_type === 'scout') {
    generalData = report.general_data as { players_filtered: ScoutPlayerData[]; players_manual: ScoutPlayerData[] };
  }
  if (!generalData) return null;

  return (
    <div className="flex flex-wrap items-center gap-6">
      {/* Team Comparison */}
      {'team1' in generalData && (
        <>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor: generalData.team1.color ?? getDefaultTeamColor(report, generalData.team1.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.team1.team_name}</span>
          </span>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor: generalData.team2.color ?? getDefaultTeamColor(report, generalData.team2.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.team2.team_name}</span>
          </span>
        </>
      )}
      {/* Team */}
      {'team_id' in generalData && (
        <span className="flex items-center gap-3">
          <span
            className="size-3 rounded-full"
            style={{ backgroundColor: generalData.color ?? getDefaultTeamColor(report, generalData.team_id) }}
          />
          <span className="text-xs font-semibold">{generalData.team_name}</span>
        </span>
      )}
      {/* Player Comparison */}
      {'player1' in generalData && (
        <>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor:
                  generalData.player1.team_color ?? getDefaultTeamColor(report, generalData.player1.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.player1.player_name}</span>
          </span>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor:
                  generalData.player2.team_color ?? getDefaultTeamColor(report, generalData.player2.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.player2.player_name}</span>
          </span>
        </>
      )}
      {/* Scout */}
      {'players_filtered' in generalData && (
        <>
          {[...generalData.players_manual, ...generalData.players_filtered].map((player: ScoutPlayerData, index) => (
            <span key={player.player_id} className="flex items-center gap-3">
              <span
                className="size-3 rounded-full"
                style={{ backgroundColor: player.team_color ?? getDefaultColor(index) }}
              />
              <span className="text-xs font-semibold">{player.player_name}</span>
            </span>
          ))}
        </>
      )}
    </div>
  );
}

export default memo(ScatterChartLegend);
