import LightFootballShield from 'assets/football-shield-outline-light.svg?react';
import DarkFootballShield from 'assets/football-shield.svg?react';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';

interface TeamShieldProps {
  color: string;
  size?: 'sm' | 'md';
  shadow?: boolean;
}

function TeamShield({ color, shadow = false, size = 'md' }: TeamShieldProps) {
  const isLight = isLightColor(color);
  const svgSize = size === 'md' ? 32 : 24;

  if (isLight) {
    return <LightFootballShield width={svgSize} height={svgSize} className={twJoin(shadow && 'drop-shadow-card')} />;
  } else {
    return (
      <DarkFootballShield
        width={svgSize}
        height={svgSize}
        className={twJoin('fill-white', shadow && 'drop-shadow-card')}
      />
    );
  }
}

export default memo(TeamShield);
