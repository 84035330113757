import { autoUpdate, size } from '@floating-ui/dom';
import { SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Listbox, ListboxButton } from '@headlessui/react';
import { Table } from '@tanstack/react-table';
import ChevronDown from 'assets/chevron-down.svg?react';
import { ProjectRoleSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ValueOptionsDropdown from 'modules/common/Form/ValueOptionsDropdown';
import UserCard from 'modules/common/UserCard';
import { memo, useEffect, useState } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { DialogProps, UserRow, ValueOption } from 'utils/interfaces';
import { projectRoleToValueOption } from 'utils/mappings';

interface LabelSelectInputProps {
  options: ValueOption[];
  selectedOption: ValueOption;
  user: UserRow;
  changeSelection: (user: UserRow, selectedOption: ValueOption) => void;
}

function LabelSelectInput({ options, selectedOption, user, changeSelection }: LabelSelectInputProps) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ elements }) {
          Object.assign(elements.floating.style, {
            width: `150px`
          });
        }
      } as SizeOptions)
    ]
  });

  function handleChange(value: ValueOption) {
    changeSelection(user, value);
  }

  return (
    <Listbox value={selectedOption} onChange={handleChange} by="id">
      {({ open }) => (
        <>
          <div className="flex items-center">
            <ListboxButton ref={refs.setReference} className="flex cursor-pointer items-center">
              <span className="text-sm font-medium text-brand-800">{selectedOption.label}</span>
              <ChevronDown className="size-5 fill-brand-800" />
            </ListboxButton>
            {open && <ValueOptionsDropdown ref={refs.setFloating} floatingStyles={floatingStyles} options={options} />}
          </div>
        </>
      )}
    </Listbox>
  );
}

interface EditAccessLevelDialogProps extends DialogProps {
  table: Table<UserRow>;
  projectRoles: ProjectRoleSchema[];
  setNewAccessLevels: (users: UserRow[]) => void;
}

function EditAccessLevelDialog({ table, projectRoles, setNewAccessLevels, open, setOpen }: EditAccessLevelDialogProps) {
  const [selectedUsers, setSelectedUsers] = useState<UserRow[]>([]);
  const projectRoleOptions = projectRoles.map(projectRoleToValueOption);

  function handleClose() {
    setOpen(false);
  }

  function changeAccessLevel(user: UserRow, value: ValueOption) {
    setSelectedUsers((prev) => {
      return prev.map((u) => {
        if (u.id === user.id) {
          return {
            ...u,
            access_level: value.label,
            access_level_id: value.id as string
          };
        }
        return u;
      });
    });
  }

  function handleSetNewAccessLevels() {
    setNewAccessLevels(selectedUsers);
  }

  useEffect(() => {
    if (open) {
      setSelectedUsers(table.getSelectedRowModel().flatRows.map((row) => row.original));
    }
  }, [table, open]);

  return (
    <DialogBase title="Edit access level" open={open} onCancel={handleClose}>
      <DialogContent>
        <div className="flex flex-col gap-2">
          {selectedUsers.map((user) => (
            <div key={user.name} className="flex justify-between rounded-md bg-gray-50 px-3 py-2">
              <UserCard name={user.name!} email={user.email!} initials={user.initials!} image={user.image} />
              <LabelSelectInput
                user={user}
                changeSelection={changeAccessLevel}
                selectedOption={projectRoleOptions.find((x) => x.id === user.access_level_id)!}
                options={projectRoleOptions}
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button size="xl" variant="primary" onClick={handleSetNewAccessLevels}>
          Save
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(EditAccessLevelDialog);
