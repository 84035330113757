import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconButton } from 'utils/interfaces';

interface RadioIconButtonsProps {
  options: IconButton[];
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

const RadioIconButtons = memo(function RadioIconButtons({ options, selected, setSelected }: RadioIconButtonsProps) {
  const handleClick = useCallback(
    function handleClick(newDisplay: IconButton) {
      return () => {
        setSelected(newDisplay.id);
      };
    },
    [setSelected]
  );

  return (
    <div className="flex items-center gap-3">
      {options.map((option) => (
        <button
          key={option.id}
          onClick={handleClick(option)}
          className={twMerge(
            'rounded-md border border-gray-400 bg-white p-2',
            selected === option.id && 'border-brand-800 bg-brand-50'
          )}
        >
          <option.Icon className={twMerge('size-5 fill-gray-500', selected === option.id && 'fill-brand-800')} />
        </button>
      ))}
    </div>
  );
});

export default RadioIconButtons;
