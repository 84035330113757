import Close from 'assets/close.svg?react';
import { memo, useCallback } from 'react';

import { twMerge } from 'tailwind-merge';

interface ValueBadgeProps {
  value: string;
  onRemove: (removedValue: string) => void;
  fullWidth?: boolean;
}

const ValueBadge = memo(function ValueBadge({ value, onRemove, fullWidth = false }: ValueBadgeProps) {
  const handleClick = useCallback(
    function handleClick(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
      e.stopPropagation();
      onRemove(value);
    },
    [onRemove, value]
  );

  return (
    <div
      className={twMerge(
        'z-10 flex h-fit w-fit items-center justify-center gap-2 rounded-md bg-gray-900 px-1.5 py-0.5',
        fullWidth && 'w-full justify-between'
      )}
    >
      <span className="text-xs text-white">{value}</span>
      <Close width={14} height={14} className="cursor-pointer fill-white" onClick={handleClick} />
    </div>
  );
});

export default ValueBadge;
