import { useQueryClient } from '@tanstack/react-query';
import { DefaultConfigurationFormValues } from 'constants/defaultValues';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions, seasonOptions } from 'constants/generated';
import {
  SelectedDataSource,
  SelectedDataSourceState,
  useSelectedDataSources
} from 'contexts/selectedDataSourcesContext';
import useIsMobile from 'hooks/useIsMobile';
import { useObjectsOptions } from 'hooks/useOptions';
import { DatasourceConfigurationsNestedSchema, DatasourceSchema } from 'lib/model';
import Button from 'modules/common/Button';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { ConfigurationFormValues, ValueOption } from 'utils/interfaces';

interface ConfigurationFormProps {
  dataSource: DatasourceSchema;
  state: SelectedDataSourceState;
}

const ConfigurationForm = memo(function ConfigurationForm({ dataSource, state }: ConfigurationFormProps) {
  const isMobile = useIsMobile();
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();
  const leagueOptions = useObjectsOptions({ objects: dataSource.datasource_competitions });
  const areaOptions = useObjectsOptions({ objects: dataSource.datasource_areas });
  const showSeasons =
    (dataSource.datasource_areas && dataSource.datasource_areas.length > 0) ||
    (dataSource.datasource_competitions && dataSource.datasource_competitions.length > 0);

  const queryClient = useQueryClient();
  const dataSourceConfigs = queryClient.getQueryData<DatasourceConfigurationsNestedSchema>([
    'dataSourceConfigurations'
  ]);

  const { refreshFrequency, time } = useMemo(() => {
    let refreshFrequency: ValueOption = DefaultConfigurationFormValues.refreshFrequency;
    let time: ValueOption = DefaultConfigurationFormValues.time;

    // Check if any data source is already configured and copy their frequency
    if (selectedDataSources.length > 1 && selectedDataSources.some((option) => !!option.state.configuration)) {
      const selectedDataSourcesWithConfig = selectedDataSources.find((option) => option.state.configuration)!;
      refreshFrequency = selectedDataSourcesWithConfig.state.configuration!.refreshFrequency;
      time = selectedDataSourcesWithConfig.state.configuration!.time;
    } else if (dataSourceConfigs && dataSourceConfigs.objects && dataSourceConfigs.objects.length > 0) {
      const frequency = dataSourceConfigs.objects[0].frequency!;
      refreshFrequency =
        dataSourceRefreshFrequencyOptions.find((x) => x.id === frequency.substring(4).trimStart()) ?? refreshFrequency;
      time = CRONTimeOptions.find((x) => x.id === frequency.substring(0, 4).trimStart()) ?? time;
    }

    return { refreshFrequency: refreshFrequency, time: time };
  }, [selectedDataSources[0].state.configuration?.refreshFrequency, selectedDataSources[0].state.configuration?.time]);

  const { control, handleSubmit, setValue, formState } = useForm<ConfigurationFormValues>({
    defaultValues: {
      areas: state.configuration?.areas ?? DefaultConfigurationFormValues.areas,
      leagues: state.configuration?.leagues ?? DefaultConfigurationFormValues.leagues,
      min_season: state.configuration?.min_season ?? DefaultConfigurationFormValues.min_season,
      // Assuming all data sources have identical refresh frequency
      refreshFrequency: state.configuration?.refreshFrequency ?? refreshFrequency,
      time: state.configuration?.time ?? time
    }
  });

  useEffect(() => {
    if (!state.configuration?.refreshFrequency) {
      setValue('refreshFrequency', refreshFrequency);
    }
    if (!state.configuration?.time) {
      setValue('time', time);
    }
  }, [refreshFrequency, time]);

  function onSubmit(formData: ConfigurationFormValues) {
    const newRefreshFrequency = formData.refreshFrequency;
    const newTime = formData.time;
    setSelectedDataSources((prev) => {
      const next = prev.map((x) => {
        if (x.dataSource.id === dataSource.id) {
          return {
            ...x,
            state: {
              ...x.state,
              configured: true,
              configuration: {
                areas: formData.areas.length === 0 ? areaOptions : formData.areas,
                leagues: formData.leagues.length === 0 ? leagueOptions : formData.leagues,
                min_season: formData.min_season,
                refreshFrequency: formData.refreshFrequency,
                time: formData.time
              }
            }
          };
        }
        return {
          ...x,
          state: {
            ...x.state,
            configuration: {
              ...x.state.configuration,
              refreshFrequency: newRefreshFrequency,
              time: newTime
            }
          }
        } as SelectedDataSource;
      });
      const configuredIndex = next.findIndex((x) => x.dataSource.id === dataSource.id);
      if (configuredIndex < next.length - 1) {
        next[configuredIndex + 1] = {
          ...next[configuredIndex + 1],
          state: { ...next[configuredIndex + 1].state, expanded: true }
        };
      }
      return next;
    });
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <span className="text-base font-semibold">Configure Data Source</span>
        <p className="flex flex-col text-sm">
          <span>Customize your data source to get more specific data.</span>
          <span>You can always change it later.</span>
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          {dataSource.datasource_areas && dataSource.datasource_areas.length > 0 && (
            <div>
              <SelectInput
                formProps={{
                  control: control,
                  name: 'areas',
                  rules: {
                    validate: {
                      maxLength: (values: ValueOption[]) =>
                        values.length <= 5 || `Currently it's possible to select at most 5 areas.`
                    }
                  }
                }}
                multiple
                label="Areas"
                placeholder="Select areas"
                searchable={true}
                options={areaOptions}
                error={formState.errors.areas}
              />
              <div className="mt-3 text-sm italic text-gray-400">
                Note: Your account may not have access to all of the listed areas.
              </div>
            </div>
          )}
          {dataSource.datasource_competitions && dataSource.datasource_competitions.length > 0 && (
            <>
              <div>
                <SelectInput
                  formProps={{
                    control: control,
                    name: 'leagues',
                    rules: {
                      validate: {
                        maxLength: (values: ValueOption[]) =>
                          values.length <= 5 || `Currently it's possible to select at most 5 competitions.`
                      }
                    }
                  }}
                  multiple
                  label="Competitions"
                  placeholder="Select competitions"
                  searchable={true}
                  options={leagueOptions}
                  error={formState.errors.leagues}
                />

                <div className="mt-3 text-sm italic text-gray-400">
                  Note: Your account may not have access to all of the listed competitions.
                </div>
              </div>
            </>
          )}
          {showSeasons && (
            <SelectInput
              formProps={{
                control: control,
                name: 'min_season'
              }}
              label="Start Season"
              placeholder="Select start season"
              options={seasonOptions}
            />
          )}

          <div>
            <div className={twJoin('flex gap-6', isMobile && 'flex-col')}>
              <SelectInput
                formProps={{
                  control: control,
                  name: 'refreshFrequency'
                }}
                searchable={false}
                placeholder="Default"
                label="Refresh Frequency"
                options={dataSourceRefreshFrequencyOptions}
              />
              <SelectInput
                formProps={{
                  control: control,
                  name: 'time'
                }}
                searchable={false}
                placeholder="Default"
                label="Time"
                options={CRONTimeOptions}
              />
            </div>

            <div className="mt-1 text-sm font-bold italic text-gray-600">
              Note: Changing the refresh frequency will change it for all data sources.
            </div>
            <div className="mt-3 text-sm italic text-gray-400">
              Note: Currently it's not possible to load data more frequently than weekly. All your datasources should
              have the iteration start setup at the same time.
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Button isSubmitButton={true} size="lg">
            Save configuration preferences
          </Button>
        </div>
      </form>
    </div>
  );
});

export default ConfigurationForm;
