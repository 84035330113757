import Next from 'assets/arrow-right.svg?react';
import Custom from 'assets/jersey-add.svg?react';
import Filter from 'assets/jersey-filter.svg?react';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SingleSelectTiles from 'modules/common/SingleSelectTiles';
import { memo } from 'react';
import { TileOption } from 'utils/interfaces';

interface NewScoutReportDialogProps {
  scoutReportType: string | null;
  setScoutReportType: React.Dispatch<React.SetStateAction<string | null>>;
  onBack: () => void;
  onNext: () => void;
}

const ScoutReportType = memo(function ScoutReportType({
  scoutReportType,
  setScoutReportType,
  onBack,
  onNext
}: NewScoutReportDialogProps) {
  return (
    <>
      <DialogContent>
        <SingleSelectTiles options={reportTypeOptions} selected={scoutReportType} setSelected={setScoutReportType} />
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" disabled={!scoutReportType} onClick={onNext}>
          <span>Next</span>
          <Next width={20} height={20} />
        </Button>
      </DialogFooter>
    </>
  );
});

const reportTypeOptions: TileOption[] = [
  {
    label: 'Filter players',
    description: 'Enter preferences to find best players',
    Icon: Filter,
    id: 'filter'
  },
  {
    label: 'Add custom players',
    description: 'Add players manually',
    Icon: Custom,
    id: 'manual'
  }
];

export default ScoutReportType;
