import useFilteredDataSources from 'contexts/filteredDataSources/filteredDataSourcesContext';
import { useGetDatasource } from 'lib/datasource/datasource';
import { memo } from 'react';
import DataSourceCardSkeleton from '../components/DataSourceCardSkeleton';
import DataSourcesCombination from './components/DataSourcesCombination';
import DataSourcesGrid from './components/DataSourcesGrid';
import FeaturedDataSources from './components/FeaturedDataSources';

const DataSourcesPage = memo(function DataSourcesPage() {
  const { data: dataSources, isPending: isPendingDataSources } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });
  const { filteredDataSources, isPendingFilteredDataSources, isCombination, isFiltered } = useFilteredDataSources();

  if (isPendingDataSources || isPendingFilteredDataSources) {
    return (
      <div className="grid w-full grid-cols-2 gap-6 px-10 max-lg:grid-cols-1">
        <DataSourceCardSkeleton />
        <DataSourceCardSkeleton />
        <DataSourceCardSkeleton />
        <DataSourceCardSkeleton />
        <DataSourceCardSkeleton />
      </div>
    );
  }

  if (!isFiltered) {
    return (
      <div className="flex flex-col gap-8">
        <FeaturedDataSources dataSources={dataSources?.objects!.filter((x) => x.featured) ?? []} />
        <DataSourcesGrid dataSources={dataSources?.objects!.filter((x) => !x.featured) ?? []} />
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col gap-8">
      {isCombination ? (
        <DataSourcesCombination dataSources={filteredDataSources} />
      ) : (
        <DataSourcesGrid dataSources={filteredDataSources} />
      )}
    </div>
  );
});

export default DataSourcesPage;
