/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import {
  Combobox as ComboboxBase,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Label,
  Portal,
  Transition
} from '@headlessui/react';
import Check from 'assets/check.svg?react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import ValueBadge from 'modules/common/Form/ValueBadge';
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps,
  UseFormClearErrors,
  UseFormSetError
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { AutocompleteOption } from 'utils/interfaces';

interface ShareReportUserInputProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  placeholder?: string;
  options: AutocompleteOption[];
  error?: Merge<FieldError, FieldErrorsImpl<AutocompleteOption[]>>;
  loading?: boolean;
  setInputError: UseFormSetError<{ users: AutocompleteOption[] | null }>;
  clearInputError: UseFormClearErrors<{ users: AutocompleteOption[] | null }>;
  allowViewers: boolean;
}

function ShareReportUserInput<T extends FieldValues>({
  options,
  label,
  loading,
  placeholder,
  error,
  setInputError,
  clearInputError,
  allowViewers,
  defaultValue = [] as PathValue<T, Path<T>>,
  ...formProps
}: ShareReportUserInputProps<T>) {
  const [input, setInput] = useState('');
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController({
    ...formProps,
    defaultValue,
    rules: {
      validate: {
        minLength: (values: AutocompleteOption[]) => values?.length > 0 || 'At least one email is required',
        inputEmpty: () => input.length === 0 || 'Please clear the email input before inviting users'
      }
    }
  });

  const convertInputToEmail = useCallback(
    function convertInputToEmail() {
      if (!input) return;
      if (!isValidEmailAddress(input)) {
        setInputError('users', {
          message: 'Please enter a valid email'
        });
        return;
      }
      clearInputError('users');
      formOnChange([...formValues, { label: input, id: input }] as AutocompleteOption[]);
      setInput('');
    },
    [clearInputError, formOnChange, formValues, input, setInputError]
  );

  const handleKeyDown = useCallback(
    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter') {
        convertInputToEmail();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [convertInputToEmail]
  );

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    strategy: 'fixed',
    whileElementsMounted: autoUpdate,
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  const handleInputChange = useCallback(function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInput(event.target.value);
  }, []);

  const filteredOptions = useMemo(() => {
    const query = input.toLowerCase();
    return options.filter((option) => option.secondaryLabel!.toLowerCase().includes(query));
  }, [input, options]);

  const handleRemove = useCallback(
    function handleRemove(email: string) {
      formOnChange(
        (formValues as AutocompleteOption[]).filter((option) => {
          if (option.secondaryLabel) {
            return option.secondaryLabel !== email;
          } else {
            return option.label !== email;
          }
        })
      );
    },
    [formOnChange, formValues]
  );

  const handleChange = useCallback(
    function handleChange(val: AutocompleteOption[]) {
      formOnChange(val);
    },
    [formOnChange]
  );

  useEffect(() => {
    setInput('');
  }, [formValues]);

  return (
    <div className="flex w-full flex-col gap-2">
      <ComboboxBase
        onChange={handleChange}
        as="div"
        className="relative flex w-full flex-wrap gap-2 rounded-lg border border-gray-300 bg-white pb-1.5 pl-3 pr-8 pt-6"
        value={(formValues ?? []) as AutocompleteOption[]}
        by="id"
        multiple
        ref={refs.setReference}
      >
        {({ open }) => (
          <>
            <Label
              className={twMerge(
                'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                (Boolean(placeholder) || formValues) && '-translate-y-0 text-xs'
              )}
            >
              {label}
            </Label>
            {formValues &&
              formValues.length > 0 &&
              formValues.map((option: AutocompleteOption) => (
                <ValueBadge
                  value={option.secondaryLabel ? option.secondaryLabel : option.label}
                  key={option.id}
                  onRemove={handleRemove}
                />
              ))}
            <ComboboxInput
              className="peer h-fit grow appearance-none rounded-lg border-0 p-0 text-md placeholder:text-ellipsis placeholder:text-gray-400 focus:outline-none focus:ring-0"
              onChange={handleInputChange}
              placeholder={formValues.length === 0 ? placeholder : undefined}
              value={input}
              onKeyDownCapture={handleKeyDown}
              // onBlur={convertInputToEmail}
            />
            {loading ? (
              <Loader
                aria-hidden="true"
                className="pointer-events-none absolute right-3 top-5 z-10 size-5 animate-spin fill-gray-700"
              />
            ) : (
              <ComboboxButton className="absolute right-3 top-5 z-10">
                <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
              </ComboboxButton>
            )}

            {open && filteredOptions.length > 0 && (
              <Portal>
                <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <ComboboxOptions className="absolute z-50 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
                      {filteredOptions.map((option) => (
                        <ComboboxOption
                          key={option.id}
                          value={option}
                          className="cursor-default select-none px-2 py-1 ui-active:bg-gray-50 ui-disabled:cursor-not-allowed"
                          disabled={!allowViewers && (option.value as string).toLowerCase().includes('viewer')}
                        >
                          <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                            <div className="flex flex-col">
                              <span className="block truncate text-sm font-medium text-gray-700 ui-selected:font-semibold ui-selected:text-brand-800 ui-disabled:text-gray-400">
                                {option.label}
                              </span>
                              <span className="block truncate text-xs text-gray-500 ui-selected:font-semibold ui-selected:text-brand-800 ui-disabled:text-gray-300">
                                {option.secondaryLabel}
                              </span>
                            </div>
                            <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
                          </div>
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </Transition>
                </div>
              </Portal>
            )}
          </>
        )}
      </ComboboxBase>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

function isValidEmailAddress(email: string) {
  return email.match(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
}

export default memo(ShareReportUserInput) as typeof ShareReportUserInput;
