import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { getDefaultColor } from '../../../../helpers';
import Jersey from '../../Jersey';
import { PlayerGeneralData, ScoutPlayerData } from '../interfaces';

interface PlayerHeaderProps {
  player: PlayerGeneralData | ScoutPlayerData;
  textSize?: 'tiny' | 'sm' | 'lg';
  iconSize?: 'sm' | 'md' | 'lg';
  narrower?: boolean;
  index?: number;
}

function PlayerHeader({ player, textSize = 'sm', iconSize = 'md', narrower = false, index = 0 }: PlayerHeaderProps) {
  const color = player.team_color ?? getDefaultColor(index);
  const isLight = isLightColor(color);
  return (
    <div
      className={twJoin('flex flex-col items-center gap-3 rounded-xl px-3', narrower ? 'py-3' : 'py-6')}
      style={{ backgroundColor: color }}
    >
      <Jersey color="white" shirt_number={player.shirt_number} size={iconSize} shadow outline={isLight} />
      <span
        className={twJoin(
          'text-center font-semibold',
          textSize === 'tiny' && 'text-tiny',
          textSize === 'sm' && 'text-sm',
          textSize === 'lg' && 'text-lg',
          isLight ? 'text-black' : 'text-white'
        )}
      >
        {player.player_name}
      </span>
    </div>
  );
}

export default memo(PlayerHeader);
