/* eslint-disable react-refresh/only-export-components */
import { memo, useCallback } from 'react';
import { FieldError, FieldValues, UseControllerProps, useController } from 'react-hook-form';
import { TileOption, ValueOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';

interface MultipleSelectTiles<T extends FieldValues> extends UseControllerProps<T> {
  options: TileOption[];
  error?: FieldError;
}

function MultipleSelectTiles<T extends FieldValues>({ options, error, ...formProps }: MultipleSelectTiles<T>) {
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController(formProps);

  const handleChange = useCallback(
    (option: TileOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let copy = [...formValues];
      if (event.target.checked) {
        copy.push({ label: option.label, id: option.id } as ValueOption);
      } else {
        copy = copy.filter((x: ValueOption) => x.id !== option.id);
      }
      formOnChange(copy);
    },
    [formOnChange, formValues]
  );

  const isChecked = useCallback(
    (option: TileOption) => {
      return (formValues as TileOption[]).some((x: TileOption) => x.id === option.id);
    },
    [formValues]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-3 gap-6">
        {options.map((option) => (
          <div key={option.id} className="w-full">
            <input
              id={option.id}
              type="checkbox"
              className={`peer hidden`}
              name={option.id}
              value={option.id}
              checked={isChecked(option)}
              onChange={handleChange(option)}
            />
            <label
              htmlFor={option.id}
              className={`group flex h-full flex-col items-center justify-center gap-2 rounded-md border border-gray-400 bg-white px-6 py-8 text-center peer-checked:border-brand-800 peer-checked:bg-brand-50 peer-disabled:bg-gray-50`}
            >
              <option.Icon width={40} height={40} className="fill-gray-300 peer-checked:group-[]:fill-brand-800" />
              <div className="flex flex-col items-center gap-1">
                <span className="text-md font-medium peer-checked:group-[]:text-brand-800">{option.label}</span>
                <span className="text-xs peer-checked:group-[]:text-brand-800">{option.description}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleSelectTiles) as typeof MultipleSelectTiles;
