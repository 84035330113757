import useDataSourcesFilters from 'contexts/dataSourcesFilters/dataSourcesFiltersContext';
import { useGetDatasource } from 'lib/datasource/datasource';
import { GetDatasourceParams } from 'lib/model';
import { PropsWithChildren, useMemo } from 'react';
import { FilteredDataSourcesContext } from './filteredDataSourcesContext';

export function FilteredDataSourcesProvider({ children }: PropsWithChildren) {
  const { dataSourcesFilters } = useDataSourcesFilters();
  const dataSourcesQueryParams = useMemo((): GetDatasourceParams => {
    return {
      budget:
        dataSourcesFilters.pricing.length > 0
          ? dataSourcesFilters.pricing.map((option) => option.id).join(',')
          : undefined,
      competitions:
        dataSourcesFilters.competitions.length > 0
          ? dataSourcesFilters.competitions.map((option) => option.id).join(',')
          : undefined,
      data_tables:
        dataSourcesFilters.dataTables.length > 0
          ? dataSourcesFilters.dataTables.map((option) => option.id).join(',')
          : undefined,
      data_types:
        dataSourcesFilters.dataTypes.length > 0
          ? dataSourcesFilters.dataTypes.map((option) => option.id).join(',')
          : undefined,
      datasources_to_exclude:
        dataSourcesFilters.excludedDataSources.length > 0
          ? dataSourcesFilters.excludedDataSources.map((option) => option.id).join(',')
          : undefined,
      seasons:
        dataSourcesFilters.seasons.length > 0
          ? dataSourcesFilters.seasons.map((option) => option.id).join(',')
          : undefined,
      verified: !(dataSourcesFilters.verified as unknown as string) ? undefined : 'true',
      use_assistant: !dataSourcesFilters.useAssistant ? undefined : true,
      name: dataSourcesFilters.selectedDataSource?.label
    };
  }, [dataSourcesFilters]);

  const { data: filteredDataSources, isPending: isPendingFilteredDataSources } = useGetDatasource(
    dataSourcesQueryParams,
    {
      query: { queryKey: ['dataSources', dataSourcesQueryParams], staleTime: Infinity }
    }
  );

  return (
    <FilteredDataSourcesContext.Provider
      value={{
        isCombination: dataSourcesFilters.useAssistant,
        isFiltered: !!dataSourcesFilters.source,
        filteredDataSources: filteredDataSources?.objects ?? [],
        isPendingFilteredDataSources: isPendingFilteredDataSources
      }}
    >
      {children}
    </FilteredDataSourcesContext.Provider>
  );
}
