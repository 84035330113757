import Graph from 'assets/graph.svg?react';
import { memo } from 'react';
import IntegrationChartCard from './IntegrationChartCard';

function DashboardIntegrationStatus() {
  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <Graph className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Integration Performance and Data Source Status</span>
        </div>
      </div>
      <IntegrationChartCard />
    </div>
  );
}

export default memo(DashboardIntegrationStatus);
