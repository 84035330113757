import { memo } from 'react';

interface CircleImageProps {
  image: string | undefined;
  text: string;
  size: string;
}

function CircleImage({ image, text, size }: CircleImageProps) {
  return (
    <div className={'brand-gradient flex items-center justify-center rounded-full border border-gray-200 ' + size}>
      {image ? (
        <img alt="" src={image} className={'rounded-full ' + size} />
      ) : (
        <span className="text-tiny font-medium uppercase text-white">{text}</span>
      )}
    </div>
  );
}

export default memo(CircleImage);
