import { memo, useCallback, useState } from 'react';
import { RegisterFormValues } from 'utils/interfaces';
import CreateOrganizationForm from './components/CreateOrganizationForm';
import RegisterForm from './components/RegisterForm';

const RegisterPage = memo(function RegisterPage() {
  const [userInfo, setUserInfo] = useState<RegisterFormValues>();
  const handleGoogle = useCallback(function handleGoogle() {
    return undefined;
  }, []);

  const handleMicrosoft = useCallback(function handleMicrosoft() {
    return undefined;
  }, []);

  return (
    <>
      {!userInfo ? (
        <RegisterForm setUserInfo={setUserInfo} handleGoogle={handleGoogle} handleMicrosoft={handleMicrosoft} />
      ) : (
        <CreateOrganizationForm userInfo={userInfo} />
      )}
    </>
  );
});

export default RegisterPage;
