import { memo } from 'react';
import CircleImage from './CircleImage';

interface UserCardProps {
  name: string;
  email: string;
  initials: string;
  image?: string | undefined;
}

function UserCard({ name, email, image = undefined, initials }: UserCardProps) {
  return (
    <div className="flex items-center gap-3">
      <CircleImage image={image} text={initials} size="size-9" />
      <div className="flex flex-col">
        <span className="text-sm font-semibold">{name}</span>
        <span className="text-tiny">{email}</span>
      </div>
    </div>
  );
}

export default memo(UserCard);
