import { memo } from 'react';

const ElementCardSkeleton = memo(function ElementCardSkeleton() {
  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <div className="flex h-80 items-center justify-center bg-white">
        <div className="size-full animate-pulse rounded-md bg-gray-50" />
      </div>
    </div>
  );
});

export default ElementCardSkeleton;
