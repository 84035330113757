import { useQueryClient } from '@tanstack/react-query';
import Replace from 'assets/replace.svg?react';
import { ProjectGetSchema } from 'lib/model';
import { usePostProjectProjectIdDatasourceDatasourceIdResync } from 'lib/project/project';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo } from 'react';
import { toast } from 'react-toastify';
import { DataSourceDialogProps } from 'utils/interfaces';

function ResyncDataSourceDialog({ open, setOpen, dataSource }: DataSourceDialogProps) {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project']);
  const { mutate: resyncDataSource, isPending } = usePostProjectProjectIdDatasourceDatasourceIdResync();

  function handleResync() {
    resyncDataSource(
      { projectId: project!.id!, datasourceId: dataSource.id! },
      {
        onSuccess: () => {
          toast.success('Data source re-sync successfully triggered.');
          setOpen(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <DialogBase title="Re-sync Data Source" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex h-36 items-center justify-center">
          <Replace className="size-16 fill-brand-800" />
        </div>
        <div className="flex items-center bg-gray-50 p-3">
          <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm">You are about to manually sync this data source. This might take a while.</span>
          <span className="text-sm">Are you sure you want to proceed with re-sync?</span>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="lg" onClick={handleResync} loading={isPending}>
          Re-Sync
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(ResyncDataSourceDialog);
