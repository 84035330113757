import ArrowLeft from 'assets/arrow-left.svg?react';
import { AxiosError } from 'axios';
import useIsMobile from 'hooks/useIsMobile';
import { usePutUserRequestResetPassword } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import Input from 'modules/common/Form/Input';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

interface PasswordRequestResetValues {
  email: string;
}

interface PasswordRequestResetFormProps {
  emailEntered: (email: string) => void;
}

const PasswordRequestResetForm = memo(function PasswordRequestResetForm({
  emailEntered
}: PasswordRequestResetFormProps) {
  const isMobile = useIsMobile();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<PasswordRequestResetValues>();

  const { mutate, isPending } = usePutUserRequestResetPassword();

  function onSubmit(formData: PasswordRequestResetValues) {
    mutate(
      { data: { email: formData.email } },
      {
        onSuccess() {
          emailEntered(formData.email);
        },
        onError: (error: AxiosError) => {
          const data = error.response?.data as { error: string };
          toast.error(data.error || 'An error occurred. Please try again later.');
        }
      }
    );
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={twMerge(
        'flex w-full max-w-xl flex-col items-start gap-10 rounded-3xl bg-white p-16 text-center',
        isMobile && 'm-16 p-8'
      )}
    >
      <div className="flex flex-col items-start gap-3">
        <Link className="flex items-center justify-start gap-2" to="/login">
          <ArrowLeft width={20} height={20} className="fill-black" />
          <span className="text-sm font-semibold">Sign in</span>
        </Link>
        <h6 className="text-h6 font-semibold">Forgot password</h6>
      </div>
      <div className="flex flex-col gap-6">
        <p className="text-left text-md">
          Enter the email address associated with your account and we'll email you a link to reset your password
        </p>
        <div className="flex w-full flex-col gap-2">
          <Input
            registerReturn={register('email', {
              required: 'Email is required.',
              pattern: {
                value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Invalid Email pattern.'
              }
            })}
            label="Email"
            error={errors.email}
          />
        </div>
      </div>
      <Button isSubmitButton={true} loading={isPending} size="xl">
        Send reset link
      </Button>
    </form>
  );
});

export default PasswordRequestResetForm;
