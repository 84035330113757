import { memo } from 'react';

interface DashboardDateTimeProps {
  date: Date;
}

const DashboardDateTime = memo(function DashboardDateTime({ date }: DashboardDateTimeProps) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-tiny font-semibold">{date.toLocaleDateString()}</span>
      <div className="h-2.5 w-px bg-gray-300" />
      <span className="text-tiny font-semibold">{date.toLocaleTimeString()}</span>
    </div>
  );
});

export default DashboardDateTime;
