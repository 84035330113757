/* eslint-disable react-refresh/only-export-components */
import useIsMobile from 'hooks/useIsMobile';
import { memo, useCallback } from 'react';
import { FieldError, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { ButtonCheckboxOption, ValueOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';

interface MultipleSelectButtonsProps<T extends FieldValues> extends UseControllerProps<T> {
  options: ButtonCheckboxOption[];
  error?: FieldError;
}

function MultipleSelectButtons<T extends FieldValues>({ options, error, ...formProps }: MultipleSelectButtonsProps<T>) {
  const isMobile = useIsMobile();

  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController(formProps);

  const handleChange = useCallback(
    (option: ButtonCheckboxOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let copy = [...formValues];
      if (event.target.checked) {
        copy.push({ label: option.label, id: option.id } as ValueOption);
      } else {
        copy = copy.filter((x) => x.value !== option.id);
      }
      formOnChange(copy);
    },
    [formOnChange, formValues]
  );

  const isChecked = useCallback(
    (option: ButtonCheckboxOption) => {
      return (formValues as ButtonCheckboxOption[]).some((x: ButtonCheckboxOption) => x.id === option.id);
    },
    [formValues]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="grid grid-cols-3 gap-6">
        {options.map((option) => (
          <div key={option.id}>
            <input
              id={option.id}
              name={option.id}
              value={option.id}
              checked={isChecked(option)}
              type="checkbox"
              className={`peer hidden`}
              onChange={handleChange(option)}
            />
            <label
              htmlFor={option.id}
              className={twMerge(
                'group flex items-center justify-center gap-2 rounded-md border border-gray-400 bg-white p-3 peer-checked:border-brand-800 peer-checked:bg-brand-50 peer-disabled:bg-gray-50',
                isMobile && 'text-sm'
              )}
            >
              {!isMobile && (
                <option.Icon width={20} height={20} className="fill-gray-700 peer-checked:group-[]:fill-brand-800" />
              )}
              <span className="text-md peer-checked:group-[]:font-semibold peer-checked:group-[]:text-brand-800">
                {option.label}
              </span>
            </label>
          </div>
        ))}
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleSelectButtons) as typeof MultipleSelectButtons;
