import Info from 'assets/info.svg?react';
import { memo } from 'react';

interface InfoMessageProps {
  info: string;
}

function InfoMessage({ info }: InfoMessageProps) {
  if (!info) return null;
  return (
    <div className="flex items-center gap-2">
      <Info className="size-5 fill-gray-700" />
      <span className="text-xs">{info}</span>
    </div>
  );
}

export default memo(InfoMessage);
