import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MetricOption, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, playerToValueOption, stringToValueOption } from 'utils/mappings';
import AggregateOptions from './AggregateOptions';
import { loadAttributeValues } from './helpers';
import { ComparisonElementFormValues, ElementFormProps } from './interfaces';

const ComparisonElementForm = memo(function ComparisonElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<ComparisonElementFormValues> = {
    attribute_type: null,
    aggregate_by: {
      label: 'Match',
      id: 'match'
    },
    aggregation_metric: undefined,
    players: [],
    metrics_players_match: [],
    metrics_teams_match: [],
    metrics_players_season: [],
    metrics_teams_season: [],
    seasons: [],
    matches: [],
    competitions: []
  };

  if (element?.attribute_values) {
    // Editing element
    loadAttributeValues(defaultValues, element, template);
  } else {
    // Creating new element
    switch (report.report_type) {
      case 'team_comparison':
        defaultValues.attribute_type = {
          label: 'Team',
          id: 'team'
        };
        break;
      default:
        defaultValues.attribute_type = {
          label: 'Player',
          id: 'player'
        };
        break;
    }
  }

  const comparisonForm = useForm<ComparisonElementFormValues>({
    defaultValues
  });
  const { handleSubmit, getValues, watch, setValue, control, formState } = comparisonForm;
  const attributeType = watch('attribute_type');
  const playerMetrics = watch('metrics_players_match');
  const teamMetrics = watch('metrics_teams_match');

  const addElement = useCallback(
    function addElement(data: ComparisonElementFormValues) {
      if (template.attribute_choices!.players) {
        const players = getValues('players');
        if (attributeType?.id === 'player' && players.length === 0) {
          data.players = template.attribute_choices!.players.map(playerToValueOption);
        }
      }
      onSubmitForm(data);
    },
    [attributeType?.id, getValues, onSubmitForm, template.attribute_choices]
  );

  const attributeTypeOptions = useMemo(
    () => template.attribute_choices!.attribute_type?.map(stringToValueOption) || [],
    [template.attribute_choices]
  );

  const playerOptions = useMemo(
    () => template.attribute_choices!.players?.map(playerToValueOption) || [],
    [template.attribute_choices]
  );

  // Resetting values on change
  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
      setValue('metrics_players_match', []);
    } else {
      setValue('metrics_teams_match', []);
    }
  }, [attributeType, setValue]);

  // Get metric options from metric seasons and active season
  const metricsTeamsMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_teams_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const metricsPlayersMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_players_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const active_metrics: MetricOption[] = useMemo(() => {
    if (attributeType?.id === 'team') {
      return teamMetrics;
    } else {
      return playerMetrics;
    }
  }, [attributeType, playerMetrics, teamMetrics]);

  const renderMetrics = useCallback(() => {
    let active_metric = '';
    let metric_options = [];
    switch (attributeType?.id) {
      case 'team':
        active_metric = 'metrics_teams_match';
        metric_options = metricsTeamsMatchOptions;
        break;
      case 'player':
        active_metric = 'metrics_players_match';
        metric_options = metricsPlayersMatchOptions;
        break;
      default:
        return (
          <SelectInput
            formProps={{
              control: control,
              name: 'placeholder'
            }}
            multiple
            key={'placeholder'}
            label={'Select aggregation data'}
            disabled={true}
            options={[]}
          />
        );
    }

    return (
      <SelectInput
        formProps={{
          control: control,
          name: active_metric,
          rules: {
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }
        }}
        multiple
        key={active_metric}
        label={'Metrics'}
        placeholder={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={metric_options}
        error={formState.errors[active_metric]}
      />
    );
  }, [attributeType, control, formState.errors, metricsPlayersMatchOptions, metricsTeamsMatchOptions]);

  return (
    <FormProvider {...comparisonForm}>
      <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
        <SelectInput
          formProps={{
            control: control,
            name: 'attribute_type',
            rules: { required: 'Main attribute is required!' }
          }}
          label={'Main attribute'}
          disabled={report.report_type !== 'match' && report.report_type !== 'team_comparison'}
          options={attributeTypeOptions}
          error={formState.errors.attribute_type}
        />
        {/* Player/Team metrics */}
        {renderMetrics()}
        {report.report_type !== 'match' && <AggregateOptions template={template} metrics={active_metrics} />}
        {/* Select players to include in comparison */}
        {attributeType?.id === 'player' && template.attribute_choices!.players && (
          <SelectInput
            formProps={{
              control: control,
              name: 'players'
            }}
            multiple
            label={'Players'}
            placeholder="All"
            options={playerOptions}
          />
        )}
      </form>
    </FormProvider>
  );
});

export default ComparisonElementForm;
