import LinkIcon from 'assets/link.svg?react';
import { memo } from 'react';

import { Link } from 'react-router-dom';

interface ExternalLinkProps {
  href: string;
}

const ExternalLink = memo(function ExternalLink({ href }: ExternalLinkProps) {
  function stopPropagation(e: React.MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
  }

  return (
    <Link className="flex items-center gap-2" to={href} target="_blank" onClick={stopPropagation}>
      <LinkIcon width={16} height={16} className="fill-brand-800" />
      <span className="text-xs font-medium text-brand-800">Visit website</span>
    </Link>
  );
});

export default ExternalLink;
