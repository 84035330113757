import { useQueryClient } from '@tanstack/react-query';
import Clear from 'assets/close-circle.svg?react';
import Filter from 'assets/filter.svg?react';
import Search from 'assets/search.svg?react';
import TextBubble from 'assets/text-bubble-smile.svg?react';
import useAuth from 'contexts/auth/authContext';
import useDataSourcesFilters, { defaultDataSourceFilters } from 'contexts/dataSourcesFilters/dataSourcesFiltersContext';
import { useObjectsOptions } from 'hooks/useOptions';
import { useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration } from 'lib/datasource-configuration/datasource-configuration';
import { useGetDatasource } from 'lib/datasource/datasource';
import { ProjectGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import SimpleCombobox from 'modules/common/SimpleCombobox';
import { memo, useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { NavLinkRenderProps, ValueOption } from '../../../../utils/interfaces';
import ConnectDataSourcesBanner from './ConnectDataSourcesBanner';
import DataSourcesAssistantDialog from './DataSourcesAssistantDialog';
import DataSourcesFilterDialog from './DataSourcesFilterDialog';
import DataSourcesHeaderSkeleton from './DataSourcesHeaderSkeleton';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';

const DataSourcesHeader = memo(function DataSourcesLayout() {
  const { isViewer } = useAuth();
  const { pathname } = useLocation();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState<boolean>(false);
  const [isAssistantDialogOpen, setIsAssistantDialogOpen] = useState<boolean>(false);
  const { dataSourcesFilters, setDataSourcesFilters } = useDataSourcesFilters();

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project'])!;

  const { data: dataSourcesCount, isPending: isDatasourceCountPending } =
    useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
      query: { queryKey: ['dataSourcesCount'], enabled: !!project.id, staleTime: Infinity }
    });

  const { data: dataSources, isPending: isDataSourcesPending } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });

  const dataSourceOptions = useObjectsOptions(dataSources);

  const openFilters = useCallback(function onOpenFilters() {
    setIsFilterDialogOpen(true);
  }, []);
  const openAssistant = useCallback(function onOpenAssistant() {
    setIsAssistantDialogOpen(true);
  }, []);

  const clearFilters = useCallback(
    function clearFilters(e: React.MouseEvent<SVGSVGElement, MouseEvent>) {
      setDataSourcesFilters(defaultDataSourceFilters);
      e.stopPropagation();
    },
    [setDataSourcesFilters]
  );

  const toggleSearch = useCallback(
    function toggleSearch() {
      setSearchOpen(!searchOpen);
      setDataSourcesFilters(defaultDataSourceFilters);
    },
    [searchOpen, setDataSourcesFilters]
  );

  const handleSearch = useCallback(
    function handleSearch(option: ValueOption | null) {
      if (option) {
        setDataSourcesFilters({
          ...defaultDataSourceFilters,
          source: 'search',
          selectedDataSource: option
        });
      } else {
        setDataSourcesFilters(defaultDataSourceFilters);
      }
    },
    [setDataSourcesFilters]
  );

  const navLinkClassName = useCallback(function navLinkClassName(props: NavLinkRenderProps) {
    return twMerge(
      'rounded-md px-4 text-center py-2.5 text-xs font-semibold text-gray-800 max-md:px-2 max-md:py-1.5 max-md:items-center max-md:flex',
      props.isActive ? 'bg-brand-50 text-brand-800' : 'bg-transparent'
    );
  }, []);

  const connectedDataSources = dataSourcesCount?.count ?? 0;
  const totalDataSources = dataSourcesCount?.max ?? 0;
  const hasActiveDataSources = connectedDataSources > 0;
  const showFilters = pathname === '/data-sources/all' && !isViewer;

  if (!(pathname === '/data-sources/all' || pathname === '/data-sources/connected')) {
    return null;
  }

  if (isDatasourceCountPending) {
    return <DataSourcesHeaderSkeleton />;
  }

  return (
    <>
      {isFilterDialogOpen && <DataSourcesFilterDialog open={isFilterDialogOpen} setOpen={setIsFilterDialogOpen} />}
      <DataSourcesAssistantDialog open={isAssistantDialogOpen} setOpen={setIsAssistantDialogOpen} />
      <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 pb-8 pt-10 max-md:top-14 max-md:px-0 max-md:py-5">
        <span className={twMerge('text-lg font-semibold text-gray-950')}>Data sources</span>
        <div
          className={twMerge(
            'relative flex items-center justify-between gap-6 rounded-xl max-md:flex-col max-md:gap-2',
            hasActiveDataSources && 'bg-white p-3',
            searchOpen && 'items-start'
          )}
        >
          {hasActiveDataSources && (
            <nav className={twMerge('flex w-full items-center gap-2')}>
              <NavLink to="/data-sources/connected" className={navLinkClassName}>
                {`CONNECTED DATA SOURCES ${connectedDataSources}/${totalDataSources}`}
              </NavLink>
              <NavLink to="/data-sources/all" className={navLinkClassName}>
                ALL DATA SOURCES
              </NavLink>
            </nav>
          )}
          {showFilters && (
            <>
              <div
                className={twMerge(
                  'flex w-full items-center justify-between gap-6',
                  searchOpen && 'flex-wrap',
                  hasActiveDataSources && 'justify-end'
                )}
              >
                <div className="flex w-fit items-center gap-4">
                  <Button
                    variant="secondary"
                    size="md"
                    isFullWidth={false}
                    onClick={openAssistant}
                    disabled={dataSourcesFilters.source && dataSourcesFilters.source !== 'assistant'}
                  >
                    <TextBubble width={18} height={18} className="fill-gray-700" />
                    <span className="max-lg:hidden">Data Source Assistant</span>
                    {dataSourcesFilters.source === 'assistant' && (
                      <Clear width={20} height={20} onClick={clearFilters} />
                    )}
                  </Button>
                  <Button
                    variant="secondary"
                    size="md"
                    isFullWidth={false}
                    onClick={openFilters}
                    disabled={dataSourcesFilters.source && dataSourcesFilters.source !== 'filters'}
                  >
                    <Filter width={18} height={18} className="fill-gray-700" />
                    <span className="max-lg:hidden">Filter</span>
                    {dataSourcesFilters.source === 'filters' && <Clear width={20} height={20} onClick={clearFilters} />}
                  </Button>
                </div>
                <button
                  className="group mr-4"
                  disabled={dataSourcesFilters.source && dataSourcesFilters.source !== 'search'}
                  onClick={toggleSearch}
                >
                  <Search width={24} height={24} className="fill-gray-700 group-disabled:fill-gray-400" />
                </button>
                {searchOpen && (
                  <SimpleCombobox onChange={handleSearch} options={dataSourceOptions} loading={isDataSourcesPending} />
                )}
              </div>
            </>
          )}
        </div>
        {!hasActiveDataSources && <ConnectDataSourcesBanner />}
      </header>
    </>
  );
});

export default DataSourcesHeader;
