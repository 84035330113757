import CheckCircle from 'assets/check-circle.svg?react';
import { useGetUserConfirmEmail } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import { memo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

const EmailConfirmationPage = memo(function EmailConfirmationPage() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { isPending } = useGetUserConfirmEmail({ token: token! }, { query: { queryKey: ['confirmEmail'] } });

  if (isPending) return <></>;

  return (
    <div className="flex w-full max-w-xl flex-col items-center gap-10 rounded-xl bg-white p-16 text-center">
      <h6 className="text-h6 font-semibold text-gray-900">Registration complete</h6>
      <div className="flex h-32 items-center justify-center">
        <CheckCircle width={64} height={64} className="fill-brand-800" />
      </div>
      <span className="text-md">You have successfully registered to Q-ant</span>
      <Link to="/login" className="w-full">
        <Button size="xl">Sign in</Button>
      </Link>
    </div>
  );
});

export default EmailConfirmationPage;
