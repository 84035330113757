import LinkIcon from 'assets/link.svg?react';
import { DatasourceSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import ExternalLink from 'modules/common/ExternalLink';
import DataSourcePricingBadge from 'modules/dataSources/components/DataSourcePricingBadge';
import DataSourceDataTypeBadge from 'modules/dataSources/components/DataSourceTypeBadge';
import DataSourcesCardBase from 'modules/dataSources/components/DataSourcesCardBase';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface DashboardFeaturedDataSourceCardProps {
  dataSource: DatasourceSchema;
}

function DashboardFeaturedDataSourceCard({ dataSource }: DashboardFeaturedDataSourceCardProps) {
  const navigate = useNavigate();

  const goToDataSources = useCallback(
    function goToDataSources() {
      navigate('/data-sources/all');
    },
    [navigate]
  );

  return (
    <DataSourcesCardBase>
      <div className="flex w-full items-center justify-between gap-6">
        <DataSourceTitle name={dataSource!.name!} size="sm" image={dataSource?.logo_image_path} />
        <DataSourcePricingBadge pricing={dataSource.pricing!} />
      </div>
      <Divider />
      <div className="flex flex-wrap justify-between gap-4">
        <ExternalLink href={dataSource.website!} />
        <div className="flex flex-wrap gap-2">
          {dataSource.data_types?.map((type) => <DataSourceDataTypeBadge type={type} key={type} />)}
        </div>
      </div>
      <Button variant="secondary" size="md" onClick={goToDataSources}>
        <LinkIcon width={18} height={18} className="fill-black" />
        <span>Connect Data Source</span>
      </Button>
    </DataSourcesCardBase>
  );
}

export default memo(DashboardFeaturedDataSourceCard);
