import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import {
  ProjectGetSchema,
  ProjectRoleSchemaName,
  ProjectRolesSchema,
  ProjectUserSchema,
  ProjectUsersSchema,
  UserAccountDeactivateSchemaReportsAction
} from 'lib/model';
import { usePostProjectProjectIdTransferOwnership } from 'lib/project/project';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import PasswordInput from 'modules/common/Form/PasswordInput';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import SettingsUserSelectInput from 'modules/common/Form/SettingsUserSelectInput';
import UserCard from 'modules/common/UserCard';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twJoin, twMerge } from 'tailwind-merge';
import { getUserInitials, snakeCaseToWords } from 'utils/helpers';
import { DialogProps, ValueOption } from 'utils/interfaces';
import { projectRoleToValueOption } from 'utils/mappings';

interface TransferOwnershipDialogProps extends DialogProps {
  hasReports: boolean;
}

interface TransferOwnershipFormValues {
  newAccessLevel: ValueOption;
  newOwner: ProjectUserSchema | null;
  password: string;
  reportsAction: UserAccountDeactivateSchemaReportsAction;
}

function TransferOwnershipDialog({ hasReports, open, setOpen }: TransferOwnershipDialogProps) {
  const { user, logout } = useAuth();
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project']);
  const projectUsers = queryClient.getQueryData<ProjectUsersSchema>(['projectUsers'])!.objects!;
  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['projectRoles'])?.objects;
  const projectRolesOptions = projectRoles
    ?.filter((x) => x.name !== ProjectRoleSchemaName.owner)
    .map(projectRoleToValueOption);
  const { control, register, handleSubmit, reset, watch } = useForm<TransferOwnershipFormValues>({
    defaultValues: {
      newAccessLevel: projectRolesOptions?.find((x) => x.value === ProjectRoleSchemaName.viewer),
      newOwner: null,
      reportsAction: UserAccountDeactivateSchemaReportsAction.transfer
    }
  });
  const newOwner = watch('newOwner');
  const [transferInitiated, setTransferInitiated] = useState(false);

  function handleCancel() {
    setOpen(false);
    setTransferInitiated(false);
    reset();
  }

  function initiateTransfer(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setTransferInitiated(true);
  }

  const { mutate: transferOwnership } = usePostProjectProjectIdTransferOwnership();

  function handleTransferOwnership(data: TransferOwnershipFormValues) {
    transferOwnership(
      {
        projectId: project!.id!,
        data: {
          new_owner_user_id: data.newOwner!.user_account_id!,
          new_user_role_id: data.newAccessLevel.id as string,
          user_password: data.password,
          reports_action: data.reportsAction
        }
      },
      {
        onSuccess: () => {
          toast.success('Ownership successfully transferred. You will be logged out shortly.');
          setTimeout(() => logout(), 2000);
        }
      }
    );
  }

  return (
    <DialogBase title="Transfer ownership" open={open} onCancel={handleCancel}>
      <DialogContent>
        <>
          <form
            id="trasnferOwnershipForm"
            className={twJoin('flex flex-col gap-10', transferInitiated && 'hidden')}
            onSubmit={handleSubmit(handleTransferOwnership)}
          >
            <span className="text-md font-medium">
              Transfer ownership of the <b>{project?.organization_name}</b> organization.
            </span>
            <div className="flex flex-col gap-6">
              <span className="text-md font-semibold">Current organization owner</span>
              <div className="flex gap-6">
                <div className="w-1/2 rounded-md bg-gray-50 px-3 py-2">
                  <UserCard
                    name={user.first_name + ' ' + user.last_name}
                    email={user.email!}
                    initials={getUserInitials(user)}
                    image={user.user_image_path}
                  />
                </div>
                <div className="w-1/2">
                  <SelectInput
                    formProps={{
                      control: control,
                      name: 'newAccessLevel'
                    }}
                    label="Choose new access level"
                    options={projectRolesOptions!}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <span className="text-md font-semibold">New Owner</span>
              <SettingsUserSelectInput
                label="Choose new organization owner"
                name="newOwner"
                control={control}
                options={projectUsers.filter((u) => u.user_account_id && u.user_account_id !== user!.id!)}
              />
              <PasswordInput
                label="Enter your password"
                registerReturn={register('password', { required: 'Password is required.' })}
              />
            </div>
            {hasReports && (
              <div className="flex flex-col gap-6">
                <span className="text-md font-semibold">My reports</span>
                <div className="radio flex items-center">
                  <input id="keep" type="radio" {...register('reportsAction')} value="keep" />
                  <label htmlFor="keep" className="radio-label">
                    Keep my reports
                  </label>
                </div>
                <div className="radio flex items-center">
                  <input id="transfer" type="radio" {...register('reportsAction')} value="transfer" />
                  <label htmlFor="transfer" className="radio-label">
                    Transfer my reports to new owner
                  </label>
                </div>
                <div className="radio flex items-center">
                  <input id="delete" type="radio" {...register('reportsAction')} value="delete" />
                  <label htmlFor="delete" className="radio-label">
                    Delete all my reports
                  </label>
                </div>
              </div>
            )}
          </form>
          <div className={twMerge('hidden flex-col gap-6', transferInitiated && 'flex')}>
            <span className="text-md font-medium">
              You are about to make <b>{newOwner?.email}</b> the new owner of the <b>{project?.organization_name} </b>
              organization.
            </span>
            <span className="text-md font-medium">
              Once you confirm this transfer, there is no turning back.
              <br />
              Are you sure you want to proceed?
            </span>
          </div>
        </>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        {transferInitiated ? (
          <Button variant="red" size="lg" isSubmitButton form="trasnferOwnershipForm">
            Confirm transfer
          </Button>
        ) : (
          <Button variant="red" size="lg" onClick={initiateTransfer}>
            Transfer ownership
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(TransferOwnershipDialog);
