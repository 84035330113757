import { defaultPageSize } from 'constants/tableDefaults';
import { memo } from 'react';

interface TableSkeletonProps {
  rowNum?: number;
}

const TableSkeleton = memo(function TableSkeleton({ rowNum = defaultPageSize }: TableSkeletonProps) {
  const columns = Array.from(new Array(5).keys());
  const rows = Array.from(new Array(rowNum).keys());

  return (
    <div className="grid grid-cols-cards items-center overflow-x-hidden bg-white">
      <table className="size-full">
        <thead>
          <tr className="h-12 bg-gray-50">
            {columns.map((i) => (
              <th key={i}>
                <div className="mx-3 h-4 animate-pulse bg-gray-100" style={{ width: Math.random() * 120 + 40 }} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-divider-gray bg-transparent">
          {rows.map((i) => (
            <tr key={i} className="h-12 bg-white hover:bg-gray-50">
              {columns.map((j) => (
                <td key={j}>
                  <div
                    className="mx-3 h-4 animate-pulse bg-gray-50 group-hover:bg-gray-100"
                    style={{ width: Math.random() * 120 + 40 }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default TableSkeleton;
