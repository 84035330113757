import { memo, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

interface TextAreaProps {
  registerReturn: UseFormRegisterReturn;
  label: string;
  disabled?: boolean;
  error?: FieldError;
  placeholder?: string;
}

function TextArea({ registerReturn, label, error, disabled, placeholder }: TextAreaProps) {
  const [textValue, setTextValue] = useState('');
  return (
    <div className="relative flex flex-col gap-2">
      <textarea
        {...registerReturn}
        disabled={disabled}
        placeholder={placeholder}
        id={registerReturn.name}
        className="peer block min-h-28 w-full appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
        value={textValue}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={(e) => setTextValue(e.target.value)}
      />
      <label
        htmlFor={registerReturn.name}
        className={twJoin(
          'absolute start-3 top-4 z-10 origin-[0] text-md text-gray-500 duration-300 peer-placeholder-shown:-translate-y-2.5 peer-placeholder-shown:text-xs peer-focus:-translate-y-2.5 peer-focus:text-xs',
          textValue !== '' && '-translate-y-2.5 text-xs'
        )}
      >
        {label}
      </label>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
export default memo(TextArea);
