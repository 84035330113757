import { DatasourceCredentialSchema } from 'lib/model';
import Button from 'modules/common/Button';
import Input from 'modules/common/Form/Input';
import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

interface ApiKeyAuthFormProps {
  dataSource: string;
  website: string;
  signIn: (accountData: DatasourceCredentialSchema) => void;
  authError: string;
  isPending: boolean;
}

interface ApiKeyAuthFormValues {
  apiKey: string;
}

const ApiKeyAuthForm = memo(function ApiKeyAuthForm({
  signIn,
  dataSource,
  website,
  authError,
  isPending
}: ApiKeyAuthFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors
  } = useForm<ApiKeyAuthFormValues>();

  useEffect(() => {
    if (authError.length > 0 && !isPending) {
      setError('apiKey', { message: authError });
    } else {
      clearErrors();
    }
  }, [authError, setError, clearErrors, isPending]);

  function onSubmit(data: ApiKeyAuthFormValues) {
    signIn({ token: data.apiKey });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-8 flex flex-col gap-8">
      <div>
        <Input
          registerReturn={register('apiKey', {
            required: 'API key is required'
          })}
          error={errors.apiKey}
          type="password"
          label="API key"
        />
      </div>
      <p className="text-sm font-medium">
        If you dont have an account.&nbsp;
        <Link to={website} target="_blank" className="text-brand-800 underline" rel="noreferrer">
          Sign up through {dataSource}.
        </Link>
      </p>
      <Button isSubmitButton={true} loading={isPending}>
        Authenticate
      </Button>
    </form>
  );
});

export default ApiKeyAuthForm;
