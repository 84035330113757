import { ReportNestedSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { ComparisonElementResult, MetricData } from './interfaces';
import MetricComparison from './MetricComparison';
import { getReportGeneralColor } from 'modules/reports/helpers';

interface GenericMetricComparisonProps {
  report: ReportNestedSchema;
  results: ComparisonElementResult[];
  isPlayer: boolean;
}

const GenericMetricComparison = memo(function GenericMetricComparison({
  results,
  report,
  isPlayer
}: GenericMetricComparisonProps) {
  const data = useMemo(() => {
    const dataObj = results.reduce((acc: Record<string, MetricData>, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = {
          metric: curr.type,
          maxValue: 0,
          values: []
        };
      }
      acc[curr.type].values.push({
        teamId: curr.team_id,
        teamName: curr.team_name,
        teamColor: getReportGeneralColor(report, curr.team_id, undefined, curr.player_id),
        playerId: curr?.player_id,
        playerName: curr?.player_name,
        shirtNumber: curr?.shirt_number,
        value: curr.value
      });
      acc[curr.type].maxValue = Math.max(acc[curr.type].maxValue, curr.value * 1.2);

      return acc;
    }, {});

    for (const key of Object.keys(dataObj)) {
      dataObj[key].values.sort((a, b) => b.value - a.value);
    }

    return Object.values(dataObj);
  }, [results, report]);
  return (
    <div className="flex flex-col gap-6">
      {data.map((metricData) => (
        <MetricComparison report={report} data={metricData} isPlayer={isPlayer} key={metricData.metric} />
      ))}
    </div>
  );
});

export default GenericMetricComparison;
