import CheckCircle from 'assets/check-circle.svg?react';
import { memo } from 'react';

interface AuthenticatedBannerProps {
  signOut: () => void;
  configured: boolean;
}

const AuthenticatedBanner = memo(function AuthenticatedBanner({ signOut, configured }: AuthenticatedBannerProps) {
  return (
    <div className="flex w-full items-center justify-between rounded-md bg-gray-50 px-6 py-4">
      <div className="flex items-center gap-2">
        <CheckCircle width={20} height={20} className="fill-green-600" />
        <span className="text-sm font-semibold">Authenticated</span>
      </div>
      {!configured && (
        <button className="cursor-pointer text-xs font-medium text-brand-800" onClick={signOut}>
          Use different credentials
        </button>
      )}
    </div>
  );
});

export default AuthenticatedBanner;
