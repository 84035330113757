import { defaultPageIndex, defaultReportsTablePageSize } from 'constants/tableDefaults';
import { memo, useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

const ReportsLayout = memo(function ReportsLayout() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // init values if first render
  if (!searchParams.has('pageSize')) {
    searchParams.set('pageSize', String(defaultReportsTablePageSize));
  }
  if (!searchParams.has('pageIndex')) {
    searchParams.set('pageIndex', String(defaultPageIndex));
  }

  useEffect(
    function onUrlChange() {
      navigate(`/reports?${searchParams.toString()}`, { replace: true });
    },
    [searchParams, navigate]
  );

  return <Outlet />;
});

export default ReportsLayout;
