import {
  Combobox as ComboboxBase,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/react';
import Check from 'assets/check.svg?react';
import ChevronDown from 'assets/chevron-down.svg?react';
import { memo, useCallback, useMemo, useState } from 'react';
import { ValueOption } from 'utils/interfaces';
import Spinner from 'assets/loading.svg?react';

interface SimpleComboboxProps {
  options: ValueOption[];
  onChange: (option: ValueOption | null) => void;
  loading?: boolean;
}

const SimpleCombobox = memo(function SimpleCombobox({ options, onChange, loading }: SimpleComboboxProps) {
  const [query, setQuery] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<ValueOption | null>(null);

  const filteredOptions = useMemo(
    () =>
      query.length > 0 ? options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase())) : options,
    [options, query]
  );

  const handleInputChange = useCallback(
    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (event.target.value) {
        setQuery(event.target.value);
      } else {
        setQuery('');
      }
    },
    [setQuery]
  );

  const handleComboboxChange = useCallback(
    function handleComboboxChange(value: ValueOption | null) {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  const displayValue = useCallback(function displayValue(option: ValueOption | null) {
    return option ? option.label : '';
  }, []);

  return (
    <ComboboxBase as="div" className="relative w-fit" value={selectedOption} onChange={handleComboboxChange}>
      <ComboboxInput
        className="peer h-[38px] w-full appearance-none rounded-lg border border-gray-400 bg-white px-3 py-2 text-md focus:outline-none focus:ring-0"
        onChange={handleInputChange}
        displayValue={displayValue}
      />
      <ComboboxButton className="absolute inset-y-2 right-3 z-10" disabled={loading}>
        {loading ? (
          <Spinner className="size-5 animate-spin fill-brand-800" />
        ) : (
          <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
        )}
      </ComboboxButton>

      {filteredOptions.length > 0 && (
        <ComboboxOptions className="absolute z-50 max-h-60 w-full min-w-fit overflow-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
          {filteredOptions.map((option) => (
            <ComboboxOption
              key={option.id}
              value={option}
              className="cursor-pointer select-none px-2 py-1 ui-active:bg-gray-50"
            >
              <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                <span className="block truncate text-sm font-medium ui-selected:font-semibold ui-selected:text-brand-800">
                  {option.label}
                </span>
                <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
              </div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      )}
    </ComboboxBase>
  );
});

export default SimpleCombobox;
