import SearchEye from 'assets/search-eye.svg?react';
import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

const UnknownOrganizationPage = memo(function UnknownOrganizationPage() {
  const [searchParams] = useSearchParams();
  // const email = searchParams.get('email') ?? 'noemail@gmail.com';

  return (
    <div className="flex w-full max-w-xl flex-col items-center gap-10 rounded-xl bg-white p-16 text-center">
      <h6 className="text-h6 font-semibold text-gray-900">Data environment creation almost complete</h6>
      <p className="text-md font-semibold text-gray-900">It looks like your organization isn't on our list just yet.</p>
      <div className="flex h-32 items-center justify-center">
        <SearchEye width={64} height={64} className="fill-brand-800" />
      </div>
      <p className="text-md">
        No worries though! For security reasons, we're making sure that your email matches with your organization's
        name. Well contact you shortly at your email.
        <br />
        This process usually takes up to 24 hours.
        <br />
        Thank you for the patience!
      </p>
    </div>
  );
});

export default UnknownOrganizationPage;
