import { memo, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';

interface PillProps extends PropsWithChildren {
  type: 'empty' | 'brand';
}

const Pill = memo(function Pill({ type, children }: PillProps) {
  return (
    <span
      className={twJoin(
        'rounded-badge px-3 py-1 text-tiny font-medium',
        type === 'empty' && 'border border-gray-300',
        type === 'brand' && 'brand-gradient text-white'
      )}
    >
      {children}
    </span>
  );
});

export default Pill;
