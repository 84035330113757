import { memo, useState } from 'react';
interface DataTableDatePickerProps {
  min: string;
  max: string;
  columnName: string;
  filters: Record<string, string[]>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, string[]>>>;
}

function DataTableDatePicker({ min, max, columnName, filters, setFilters }: DataTableDatePickerProps) {
  const rangeColumnName = columnName + '__values_in_between';
  const [fromDate, setFromDate] = useState(filters[rangeColumnName] ? filters[rangeColumnName][0] : min);
  const [toDate, setToDate] = useState(filters[rangeColumnName] ? filters[rangeColumnName][1] : max);

  function handleFromDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFromDate(e.target.value);
    setFilters((prev) => {
      const next = prev;
      next[rangeColumnName] = [e.target.value, toDate];
      return next;
    });
  }
  function handleToDateChange(e: React.ChangeEvent<HTMLInputElement>) {
    setToDate(e.target.value);
    setFilters((prev) => {
      const next = prev;
      next[rangeColumnName] = [fromDate, e.target.value];
      return next;
    });
  }

  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-6">
        <div className="relative flex max-w-[148px] flex-col gap-2">
          <input
            min={min}
            max={toDate ?? max}
            value={fromDate}
            onChange={handleFromDateChange}
            id={`${columnName}-min`}
            placeholder={' '}
            type="date"
            className="peer block h-14 appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
          />
          <label
            htmlFor={`${columnName}-min`}
            className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
          >
            Min
          </label>
        </div>
        <span className="text-gray-950">-</span>
        <div className="relative flex max-w-[148px] flex-col gap-2">
          <input
            min={fromDate ?? min}
            max={max}
            value={toDate}
            onChange={handleToDateChange}
            id={`${columnName}-max`}
            placeholder={' '}
            type="date"
            className="peer block h-14 appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
          />
          <label
            htmlFor={`${columnName}-max`}
            className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
          >
            Max
          </label>
        </div>
      </div>
    </div>
  );
}

export default memo(DataTableDatePicker);
