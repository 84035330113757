import { memo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { SearchParamRoute } from 'utils/interfaces';

interface SearchParamButtonProps {
  route: SearchParamRoute;
  border?: boolean;
  size?: 'tiny' | 'xs';
}

const SearchParamButton = memo(function SearchParamButton({ route, border = false, size }: SearchParamButtonProps) {
  const [params, setParams] = useSearchParams();

  const onNavigate = useCallback(
    function onNavigate() {
      if (route.value) {
        const newParams = new URLSearchParams(params);
        newParams.set(route.key, route.value);
        setParams(newParams);
      } else {
        const newParams = new URLSearchParams(params);
        newParams.delete(route.key);
        setParams(newParams);
      }
    },
    [params, route.key, route.value, setParams]
  );

  const isActive = params.get(route.key) === route.value;

  return (
    <button
      key={route.name}
      onClick={onNavigate}
      className={twJoin(
        'flex items-center gap-2 rounded-md',
        size === 'tiny' && 'px-4 py-3',
        size === 'xs' && 'px-4 py-2.5',
        border && 'border',
        isActive
          ? 'border-brand-800 bg-brand-50 text-brand-800 *:fill-brand-800'
          : 'border-white bg-white *:fill-gray-700'
      )}
    >
      {route.Icon && <route.Icon className="size-4" />}
      <span className={twJoin('font-semibold uppercase', size === 'tiny' && 'text-tiny', size === 'xs' && 'text-xs')}>
        {route.name}
      </span>
    </button>
  );
});

export default SearchParamButton;
