import { memo } from 'react';
import { LineupTeam } from '../interfaces';

interface LineupPitchFormationProps {
  team: LineupTeam;
}

const LineupPitchFormation = memo(function LineupPitchFormation({ team }: LineupPitchFormationProps) {
  return (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold">{team.team_name}</span>
      <div className="flex flex-col gap-0.5">
        <span className="text-tiny font-medium text-gray-500">FORMATION</span>
        <span className="text-xs font-medium">{team.formation}</span>
      </div>
    </div>
  );
});

export default LineupPitchFormation;
