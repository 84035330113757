import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

Sentry.init({
  dsn: 'https://7714610b788b44c7464b85ac2d5ce0b8@o4507532316114944.ingest.de.sentry.io/4507985229709392',

  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost', // local dev
    new RegExp(import.meta.env.VITE_BASE_URL!)
  ],

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
enableMocking().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});

async function enableMocking() {
  if (import.meta.env.VITE_MOCKING === 'enabled') {
    const { worker } = await import('api/browser');
    return worker.start();
  }
  return;
}
