import useIsMobile from 'hooks/useIsMobile';
import { DatasourceSchema } from 'lib/model';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import DataSourceCard from './DataSourceCard';

interface FeaturedDataSourcesProps {
  dataSources?: DatasourceSchema[];
}

const FeaturedDataSources = memo(function FeaturedDataSources({ dataSources }: FeaturedDataSourcesProps) {
  const isMobile = useIsMobile();
  if (!dataSources || dataSources.length === 0) return null;

  return (
    <div className={twMerge('brand-gradient flex flex-col gap-6 self-center rounded-3xl p-10', isMobile && 'p-4')}>
      <span className="text-sm font-semibold text-white">Featured</span>
      <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1">
        {dataSources!.map((dataSource) => (
          <DataSourceCard key={dataSource.id} dataSource={dataSource} />
        ))}
      </div>
    </div>
  );
});

export default FeaturedDataSources;
