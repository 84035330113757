/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { kebabCaseToWords } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';

interface OptionItem {
  id?: string | number;
  name?: string;
}

interface Optionable {
  objects?: OptionItem[];
}

interface UseObjectsOptionsConfig {
  defaultOptions?: ValueOption[];
  getId?: (obj: any) => string | number | undefined;
  getName?: (obj: any) => string | undefined;
}

const defaultGetId = (obj: OptionItem) => obj.id;
const defaultGetName = (obj: OptionItem) => obj.name;

export function useObjectsOptions(optionable?: Optionable, config?: UseObjectsOptionsConfig) {
  return useMemo((): ValueOption[] => {
    const getId = config?.getId ?? defaultGetId;
    const getName = config?.getName ?? defaultGetName;
    const base: ValueOption[] = config?.defaultOptions ?? [];
    const mapped: ValueOption[] =
      optionable?.objects?.map(
        (ds) =>
          ({
            id: getId(ds),
            label: getName(ds),
            value: ds
          }) as ValueOption
      ) ?? [];
    return [...base, ...mapped];
  }, [config, optionable]);
}

export function useStringEnumOptions(enumObject: Record<string, string>) {
  return useMemo((): ValueOption[] => {
    const values = Object.values(enumObject) as string[];
    return values.map((value: string) => ({
      id: value,
      label: kebabCaseToWords(value)
    }));
  }, [enumObject]);
}
