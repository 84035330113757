import { useQueryClient } from '@tanstack/react-query';
import { Table } from '@tanstack/react-table';
import { ClientDataSchemaObjectsItem, DataTablesSchema, ProjectDataColumn, ProjectSchema } from 'lib/model';
import { usePostProjectProjectIdDataTableDataTableIdUserCorrection } from 'lib/user-correction/user-correction';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import CheckboxInput from 'modules/common/Form/CheckboxInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';
import OptionalNotes from './OptionalNotes';

interface IncorrectDataFormValues {
  [key: string]: string | boolean | undefined;

  note: string | undefined;
}

interface IncorrectDataDialogProps extends DialogProps {
  table: Table<ClientDataSchemaObjectsItem>;
  dataTableColumns: ProjectDataColumn[];
}

const IncorrectDataDialog = memo(function IncorrectDataDialog({
  table,
  open,
  setOpen,
  dataTableColumns
}: IncorrectDataDialogProps) {
  const { dataTableId } = useParams();
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['dataTables'])!;

  const dataTable = dataTables.objects!.find((dt) => dt.id! === dataTableId)!;
  const incorrect = table.getSelectedRowModel().flatRows.map((row) => row.original)[0];
  const idColumns = dataTableColumns.filter((x) => x.identity_column);
  const idKey = Object.fromEntries(
    Object.entries(incorrect).filter(([key]) => idColumns.find((x) => x.column_name! === key) !== undefined)
  );

  const { handleSubmit, register } = useForm<IncorrectDataFormValues>();
  const { mutate: createUserCorrection } = usePostProjectProjectIdDataTableDataTableIdUserCorrection();

  function reportIncorrectData(data: IncorrectDataFormValues) {
    const values = Object.entries(data).filter(([key, value]) => !key.endsWith('_checkbox') && value !== '');
    createUserCorrection({
      projectId: project.id!,
      dataTableId: dataTableId!,
      data: {
        objects: values.map(([key, value]) => ({
          comment: data.note || undefined,
          table_column_name: key,
          correction_type: incorrect[key] ? 'wrong_value' : 'missing_value',
          suggestion: {
            id: [idKey],
            original_value: incorrect[key],
            suggestion: value
          }
        }))
      }
    });
    table.resetRowSelection();
    toast.success('Report “incorrect data” sent successfully');
    setOpen(false);
  }

  function handleCancel() {
    setOpen(false);
  }

  return (
    <DialogBase title="Report Incorrect Data" onCancel={handleCancel} open={open} narrower>
      <DialogContent>
        <div className="flex flex-col gap-2">
          <span className="text-md font-semibold">Fill in correct data</span>
          <p className="text-sm">Identify and correct inaccurate data by selecting the data that needs adjustment.</p>
        </div>
        <form
          className="flex w-full flex-col gap-10"
          id="incorrect-data-form"
          onSubmit={handleSubmit(reportIncorrectData)}
        >
          {/* TODO: add data table subcategory when available */}
          <div className="flex flex-col gap-6">
            <span className="text-xs font-semibold uppercase">{dataTable.name}</span>
            <div className="flex flex-col gap-2">
              {dataTableColumns
                .filter((x) => x.visible)
                .map((col) => (
                  <CheckboxInput
                    key={col.column_name}
                    label={col.display_name!}
                    oldValue={incorrect[col.column_name!]}
                    registerReturnInput={register(col.column_name!)}
                    registerReturnCheckbox={register(col.column_name + '_checkbox')}
                  />
                ))}
            </div>
          </div>
          <OptionalNotes register={register} />
        </form>
        <p className="text-sm">
          You're about to report potential incorrect values on this data table. Our team will carefully review the data
          and resolve the issue promptly. Thank you for your valuable assistance.
        </p>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="incorrect-data-form">
          <span>Send Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default IncorrectDataDialog;
