import { useGetDatasourceDatasourceId } from 'lib/datasource/datasource';
import { DatasourceConfigurationNestedSchema } from 'lib/model';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import DataSourceSyncStatusBadge from 'modules/dataSources/components/DataSourceSyncStatusBadge';
import DataSourcesCardBase from 'modules/dataSources/components/DataSourcesCardBase';
import { memo } from 'react';
import DashboardDateTime from './DashboardDateTime';

interface DashboardActiveDataSourceCardProps {
  dataSourceConfiguration: DatasourceConfigurationNestedSchema;
}

function DashboardActiveDataSourceCard({ dataSourceConfiguration }: DashboardActiveDataSourceCardProps) {
  const { data: dataSource, isPending: isDataSourcePending } = useGetDatasourceDatasourceId(
    dataSourceConfiguration.datasource!,
    {
      query: {
        queryKey: ['dataSourcesById', dataSourceConfiguration.datasource!],
        enabled: !!dataSourceConfiguration.datasource,
        staleTime: Infinity
      }
    }
  );

  const syncDate = dataSourceConfiguration.datasource_configuration_status?.stopped_at;

  return (
    <DataSourcesCardBase disabled={!dataSourceConfiguration.is_active}>
      <div className="flex w-full items-center justify-between gap-6">
        {!isDataSourcePending ? (
          <DataSourceTitle name={dataSource!.name!} image={dataSource?.logo_image_path} size="sm" />
        ) : (
          <div className="h-6 w-1/2 animate-pulse rounded-md bg-gray-50" />
        )}
        <div className="flex items-center gap-3">
          <DataSourceSyncStatusBadge
            status={dataSourceConfiguration.datasource_configuration_status!.status!}
            inactive={!dataSourceConfiguration.is_active}
          />
        </div>
      </div>
      <Divider />
      <div className="flex w-full items-center justify-between">
        <span className="text-tiny font-medium">LAST SYNCED:</span>
        {syncDate ? (<DashboardDateTime date={syncDate ? new Date(syncDate) : new Date()} />) : 'N/A'}
      </div>
    </DataSourcesCardBase>
  );
}

export default memo(DashboardActiveDataSourceCard);
