import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { MatchGeneralData, TeamComparisonGeneralData, TeamGeneralData } from '../../reportCards/interfaces';
import StandingsController from './StandingController';
import { Standing } from './interfaces';

type StandingsData = { standings: Standing[] } | { team1: { standings: Standing[] }; team2: { standings: Standing[] } };

interface StandingsElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const StandingsElement = memo(function StandingsElement({ element, report }: StandingsElementProps) {
  const entityData = element.entity_data as StandingsData;
  let standings1: undefined | Standing[];
  let standings2: undefined | Standing[];
  if ('team1' in entityData) {
    const team1Competitions = new Set<string>();
    const team2Competitions = new Set<string>();
    for (const standing of entityData.team1.standings) {
      team1Competitions.add(standing.competition_name);
    }
    for (const standing of entityData.team2.standings) {
      team2Competitions.add(standing.competition_name);
    }

    if (team1Competitions.isSubsetOf(team2Competitions)) {
      standings1 = entityData.team2.standings;
    } else if (team1Competitions.isSupersetOf(team2Competitions)) {
      standings1 = entityData.team1.standings;
    } else {
      standings1 = entityData.team1.standings;
      standings2 = entityData.team2.standings;
    }
  } else {
    standings1 = entityData.standings;
  }

  const [highlightedTeams, team1, team2] = useMemo(() => {
    let isHighlighted: (team_id: number) => boolean;
    const _highlightedTeams: Record<string, Standing> = {};
    let _team1: undefined | string;
    let _team2: undefined | string;

    if (report.report_type === 'match') {
      const generalData = report.general_data as MatchGeneralData;
      isHighlighted = (team_id: number) => team_id === generalData.home_team_id || team_id === generalData.away_team_id;
    } else if (report.report_type === 'team') {
      const generalData = report.general_data as TeamGeneralData;
      isHighlighted = (team_id: number) => team_id === generalData.team_id;
    } else if (report.report_type === 'team_comparison') {
      const generalData = report.general_data as TeamComparisonGeneralData;
      _team1 = generalData.team1.team_name;
      _team2 = generalData.team2.team_name;
      isHighlighted = (team_id: number) =>
        team_id === generalData.team1.team_id || team_id === generalData.team2.team_id;
    }

    for (const standing of standings1!) {
      if (isHighlighted!(standing.team_id)) {
        if (!_highlightedTeams[standing.team_id]) {
          _highlightedTeams[standing.team_id] = standing;
        }
      }
    }
    if (standings2) {
      for (const standing of standings2) {
        if (isHighlighted!(standing.team_id)) {
          if (!_highlightedTeams[standing.team_id]) {
            _highlightedTeams[standing.team_id] = standing;
          }
        }
      }
    }
    return [_highlightedTeams, _team1, _team2];
  }, [report.general_data, report.report_type, standings1, standings2]);

  return (
    <div className="flex flex-col gap-6">
      {standings1 && (
        <StandingsController standings={standings1} highlightedTeams={highlightedTeams} team={team1} report={report} />
      )}
      {standings2 && (
        <StandingsController standings={standings2} highlightedTeams={highlightedTeams} team={team2} report={report} />
      )}
    </div>
  );
});

export default StandingsElement;
