import { memo, PropsWithChildren } from 'react';

interface SvgCenteredGroupProps extends PropsWithChildren {
  top: number;
  left: number;
  width: number;
  height: number;
}

const SvgCenteredGroup = memo(function SvgJersey({ top, left, width, height, children }: SvgCenteredGroupProps) {
  return (
    <g
      className="transition-transform duration-[250ms] ease-linear"
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `translate(${left - width / 2}px, ${top - height / 2}px)` }}
    >
      {children}
    </g>
  );
});

export default SvgCenteredGroup;
