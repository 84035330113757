import MultipleValueInput from 'modules/common/Form/MultipleValueInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { SendReportFormValues } from './interfaces';

interface SendReportFormProps {
  sendReport: (data: SendReportFormValues) => void;
}

function SendReportForm({ sendReport }: SendReportFormProps) {
  const { control, formState, handleSubmit } = useForm<SendReportFormValues>();

  return (
    <form className="flex flex-col gap-6" onSubmit={handleSubmit(sendReport)} id="send-report-form">
      <span className="text-md font-semibold text-gray-900">Send report to email</span>
      <MultipleValueInput
        name="emails"
        initialPlaceholder="Ex: user.name@gmail.com, user.name@yahoo.com"
        label="Enter emails"
        control={control}
        rules={{
          validate: {
            minLength: (emails: string[]) => (emails && emails.length > 0) || 'At least one email is required'
          }
        }}
        error={formState.errors.emails}
      />
    </form>
  );
}

export default memo(SendReportForm);
