import Database from 'assets/database.svg?react';
import { useGetDatasource } from 'lib/datasource/datasource';
import ReportCardSkeleton from 'modules/reports/reports/components/reportCard/ReportCardSkeleton';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardFeaturedDataSourceCard from './DashboardFeaturedDataSourceCard';

function DashboardFeaturedDataSources() {
  const { data: dataSources, isPending: isDataSourcesPending } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });

  let dataSourceCards;
  if (isDataSourcesPending) {
    dataSourceCards = (
      <>
        <ReportCardSkeleton />
        <ReportCardSkeleton />
        <ReportCardSkeleton />
        <ReportCardSkeleton />
      </>
    );
  } else {
    dataSourceCards =
      dataSources?.objects &&
      dataSources.objects.length > 0 &&
      dataSources?.objects
        ?.filter((ds) => ds.featured)
        .map((ds) => <DashboardFeaturedDataSourceCard dataSource={ds} key={ds.id} />);
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-3">
            <Database className="size-5 fill-gray-700" />
            <span className="text-sm font-semibold">Featured Data Sources</span>
          </div>
          <div className="flex gap-4">
            <NavLink className={'text-xs font-semibold text-brand-800'} to={'/data-sources/all'}>
              See All Data Sources
            </NavLink>
          </div>
        </div>
        <p className="text-xs font-medium">
          Connect your first data sources to experience the full potential of Q-ant.
        </p>
      </div>
      <div className="grid grid-cols-cards-smaller gap-6">{dataSourceCards}</div>
    </div>
  );
}

export default memo(DashboardFeaturedDataSources);
