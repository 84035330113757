import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import useAuth from 'contexts/auth/authContext';
import { UserAccountGetSchema } from 'lib/model';
import CircleImage from 'modules/common/CircleImage';
import Divider from 'modules/common/Divider';
import { Fragment, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { getUserInitials } from 'utils/helpers';

interface UserDropdownProps {
  user: UserAccountGetSchema;
  expanded: boolean;
}

const UserDropdown = memo(function UserDropdown({ user, expanded }: UserDropdownProps) {
  const { logout } = useAuth();

  return (
    <Menu>
      <MenuButton
        className={twJoin(
          'flex grow items-center gap-3 rounded-md p-2 hover:bg-gray-50',
          !expanded && 'justify-center'
        )}
      >
        <CircleImage image={user.user_image_path} text={getUserInitials(user)} size="size-8" />
        {expanded && (
          <span className="truncate text-start text-sm font-medium">{user.first_name! + ' ' + user.last_name!}</span>
        )}
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          className="z-40 origin-bottom rounded-md bg-white py-3 shadow-card focus:outline-none"
          anchor="right end"
        >
          <span className="px-6 py-1 text-xs font-medium">{user.email!}</span>
          <div className="my-2 px-6">
            <Divider />
          </div>
          <MenuItem>
            <NavLink to={'/settings/account'} className="flex w-full px-6 py-2 text-sm ui-active:bg-gray-50">
              Account
            </NavLink>
          </MenuItem>
          <MenuItem>
            <button className="flex w-full px-6 py-2 text-sm ui-active:bg-gray-50" onClick={logout}>
              Sign out
            </button>
          </MenuItem>
        </MenuItems>
      </Transition>
    </Menu>
  );
});

export default UserDropdown;
