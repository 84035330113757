import { useQueryClient } from '@tanstack/react-query';
import Unlink from 'assets/unlink.svg?react';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { usePatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive } from 'lib/datasource-configuration/datasource-configuration';
import {
  DatasourceConfigurationSchema,
  DatasourceSchema,
  ProjectConfigurationSchema,
  ProjectGetSchema
} from 'lib/model';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo } from 'react';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface DeactivateDataSourceDialogProps extends DialogProps {
  dataSource: DatasourceSchema;
  dataSourceConfiguration: DatasourceConfigurationSchema;
  setLoadingStatus: (status: boolean) => void;
}

const DeactivateDataSourceDialog = memo(function DeactivateDataSourceDialog({
  open,
  setOpen,
  dataSource,
  dataSourceConfiguration,
  setLoadingStatus
}: DeactivateDataSourceDialogProps) {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project'])!;

  const { mutate: deactivate, isPending: isActivatePending } =
    usePatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive();

  function handleDeactivate() {
    setLoadingStatus(true);
    deactivate(
      {
        projectConfigurationId: project.project_configuration_latest!,
        datasourceConfigurationId: dataSourceConfiguration.id!,
        data: {
          is_active: false
        }
      },
      {
        onSuccess: () => {
          setOpen(false);
          queryClient.invalidateQueries({ queryKey: ['dataSourceConfigurations'] }).then(() => {
            setLoadingStatus(false);
            toast.success('Data source successfully deactivated.');
          });
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
  }

  const refreshFrequency = dataSourceRefreshFrequencyOptions.find(
    (x) => x.id === dataSourceConfiguration.frequency!.substring(4).trimStart()
  )?.label;
  const reactivationPeriod = refreshFrequency?.includes('Weekly')
    ? '1 week'
    : refreshFrequency?.includes('Daily')
      ? '1 day'
      : '1 month';

  return (
    <DialogBase title="Deactivate Data Source" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex h-36 items-center justify-center">
          <Unlink width={64} height={64} className="fill-brand-800" />
        </div>
        <div className="flex items-center bg-gray-50 p-3">
          <DataSourceTitle name={dataSource.name!} image={dataSource?.logo_image_path} />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-sm">
            Deactivating this data source retains it within your tier's limit. Upon reactivation, there might be a delay
            in seeing the updated data, which could take up to {reactivationPeriod}. During deactivation, data from this
            source will not be visible on the platform.
          </span>
          <span className="text-sm">Are you sure you want to proceed with deactivation?</span>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="red" size="lg" onClick={handleDeactivate} disabled={isActivatePending}>
          Deactivate
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default DeactivateDataSourceDialog;
