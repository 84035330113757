/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutator,
  default as deleteProjectProjectIdSettingsBiConnectionMutator,
  default as getProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordMutator,
  default as getProjectProjectIdSettingsBiConnectionMutator,
  default as postProjectProjectIdSettingsBiConnectionMutator
} from '../../api/axios';
import type {
  BiConnectionCreateSchema,
  BiConnectionSchema,
  BiConnectionsSchema,
  DeleteProjectProjectIdSettingsBiConnection200,
  DeleteProjectProjectIdSettingsBiConnectionBiConnectionId200,
  DeleteProjectProjectIdSettingsBiConnectionBody,
  GetProjectProjectIdSettingsBiConnectionBiConnectionIdPassword200
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Removes multiple BI connections
 */
export const deleteProjectProjectIdSettingsBiConnection = (
  projectId: string,
  deleteProjectProjectIdSettingsBiConnectionBody: DeleteProjectProjectIdSettingsBiConnectionBody,
  options?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionMutator>
) => {
  return deleteProjectProjectIdSettingsBiConnectionMutator<DeleteProjectProjectIdSettingsBiConnection200>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/settings/bi-connection`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: deleteProjectProjectIdSettingsBiConnectionBody
    },
    options
  );
};

export const getDeleteProjectProjectIdSettingsBiConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>,
    TError,
    { projectId: string; data: DeleteProjectProjectIdSettingsBiConnectionBody },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>,
  TError,
  { projectId: string; data: DeleteProjectProjectIdSettingsBiConnectionBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>,
    { projectId: string; data: DeleteProjectProjectIdSettingsBiConnectionBody }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return deleteProjectProjectIdSettingsBiConnection(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectProjectIdSettingsBiConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>
>;
export type DeleteProjectProjectIdSettingsBiConnectionMutationBody = DeleteProjectProjectIdSettingsBiConnectionBody;
export type DeleteProjectProjectIdSettingsBiConnectionMutationError = unknown;

/**
 * @summary Removes multiple BI connections
 */
export const useDeleteProjectProjectIdSettingsBiConnection = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>,
    TError,
    { projectId: string; data: DeleteProjectProjectIdSettingsBiConnectionBody },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnection>>,
  TError,
  { projectId: string; data: DeleteProjectProjectIdSettingsBiConnectionBody },
  TContext
> => {
  const mutationOptions = getDeleteProjectProjectIdSettingsBiConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the BI connections for the project
 */
export const getProjectProjectIdSettingsBiConnection = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdSettingsBiConnectionMutator<BiConnectionsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/settings/bi-connection`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdSettingsBiConnectionQueryKey = (projectId: string) => {
  return [`/project/${projectId}/settings/bi-connection`] as const;
};

export const getGetProjectProjectIdSettingsBiConnectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdSettingsBiConnectionQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>> = ({ signal }) =>
    getProjectProjectIdSettingsBiConnection(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdSettingsBiConnectionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>
>;
export type GetProjectProjectIdSettingsBiConnectionQueryError = unknown;

/**
 * @summary Returns the BI connections for the project
 */
export const useGetProjectProjectIdSettingsBiConnection = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnection>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdSettingsBiConnectionQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new BI connection
 */
export const postProjectProjectIdSettingsBiConnection = (
  projectId: string,
  biConnectionCreateSchema: BiConnectionCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdSettingsBiConnectionMutator>
) => {
  return postProjectProjectIdSettingsBiConnectionMutator<BiConnectionSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/settings/bi-connection`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: biConnectionCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdSettingsBiConnectionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>,
    TError,
    { projectId: string; data: BiConnectionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSettingsBiConnectionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>,
  TError,
  { projectId: string; data: BiConnectionCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>,
    { projectId: string; data: BiConnectionCreateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdSettingsBiConnection(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdSettingsBiConnectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>
>;
export type PostProjectProjectIdSettingsBiConnectionMutationBody = BiConnectionCreateSchema;
export type PostProjectProjectIdSettingsBiConnectionMutationError = unknown;

/**
 * @summary Creates a new BI connection
 */
export const usePostProjectProjectIdSettingsBiConnection = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>,
    TError,
    { projectId: string; data: BiConnectionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSettingsBiConnectionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdSettingsBiConnection>>,
  TError,
  { projectId: string; data: BiConnectionCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdSettingsBiConnectionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Removes a BI connection
 */
export const deleteProjectProjectIdSettingsBiConnectionBiConnectionId = (
  projectId: string,
  biConnectionId: string,
  options?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutator>
) => {
  return deleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutator<DeleteProjectProjectIdSettingsBiConnectionBiConnectionId200>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/settings/bi-connection/${encodeURIComponent(String(biConnectionId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>,
    TError,
    { projectId: string; biConnectionId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>,
  TError,
  { projectId: string; biConnectionId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>,
    { projectId: string; biConnectionId: string }
  > = (props) => {
    const { projectId, biConnectionId } = props ?? {};

    return deleteProjectProjectIdSettingsBiConnectionBiConnectionId(projectId, biConnectionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>
>;

export type DeleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutationError = unknown;

/**
 * @summary Removes a BI connection
 */
export const useDeleteProjectProjectIdSettingsBiConnectionBiConnectionId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>,
    TError,
    { projectId: string; biConnectionId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProjectProjectIdSettingsBiConnectionBiConnectionId>>,
  TError,
  { projectId: string; biConnectionId: string },
  TContext
> => {
  const mutationOptions = getDeleteProjectProjectIdSettingsBiConnectionBiConnectionIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the password for the BI connection
 */
export const getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword = (
  projectId: string,
  biConnectionId: string,
  options?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordMutator<GetProjectProjectIdSettingsBiConnectionBiConnectionIdPassword200>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/settings/bi-connection/${encodeURIComponent(String(biConnectionId))}/password`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryKey = (
  projectId: string,
  biConnectionId: string
) => {
  return [`/project/${projectId}/settings/bi-connection/${biConnectionId}/password`] as const;
};

export const getGetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>,
  TError = unknown
>(
  projectId: string,
  biConnectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryKey(projectId, biConnectionId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>
  > = ({ signal }) =>
    getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword(projectId, biConnectionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && biConnectionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>
>;
export type GetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryError = unknown;

/**
 * @summary Returns the password for the BI connection
 */
export const useGetProjectProjectIdSettingsBiConnectionBiConnectionIdPassword = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>,
  TError = unknown
>(
  projectId: string,
  biConnectionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdSettingsBiConnectionBiConnectionIdPasswordQueryOptions(
    projectId,
    biConnectionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
