import SearchEye from 'assets/search-eye.svg?react';
import { memo } from 'react';
// import { useSearchParams } from 'react-router-dom';

const EmailMismatchPage = memo(function EmailMismatchPage() {
  // const [searchParams] = useSearchParams();
  // const email = searchParams.get('email') ?? 'noemail@gmail.com';

  return (
    <div className="flex w-full max-w-xl flex-col items-center gap-10 rounded-xl bg-white p-16 text-center">
      <h6 className="text-h6 font-semibold text-gray-900">Data Environment creation almost complete</h6>
      <p className="text-md font-semibold text-gray-900">
        It seems there's a slight mismatch between your email and organization name.
      </p>
      <div className="flex h-32 items-center justify-center">
        <SearchEye width={64} height={64} className="fill-brand-800" />
      </div>
      <p className="text-md">
        No worries though! For security reasons, we're double-checking this for you. We'll contact you shortly!
        <br />
        This process usually takes up to 24 hours.
        <br />
        Thank you for the patience!
      </p>
    </div>
  );
});

export default EmailMismatchPage;
