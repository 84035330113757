/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */

export type UserAccountDeactivateSchemaReportsAction =
  (typeof UserAccountDeactivateSchemaReportsAction)[keyof typeof UserAccountDeactivateSchemaReportsAction];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAccountDeactivateSchemaReportsAction = {
  transfer: 'transfer',
  delete: 'delete',
  keep: 'keep'
} as const;
