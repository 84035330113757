/* eslint-disable react/jsx-no-bind */
import Camera from 'assets/camera.svg?react';
import { memo } from 'react';

import ImageUploading from 'react-images-uploading';
import { ImageListType } from 'react-images-uploading/dist/typings';
import { twMerge } from 'tailwind-merge';

interface ImageUploadProps {
  images: ImageListType;
  setImages: React.Dispatch<React.SetStateAction<ImageListType>>;
  optional?: boolean;
  initials?: string;
  imageUrl?: string;
}

const ImageUpload = memo(function ImageUpload({
  images,
  setImages,
  optional = false,
  initials,
  imageUrl
}: ImageUploadProps) {
  const maxNumber = 1;

  function onChange(imageList: ImageListType) {
    setImages(imageList);
  }

  return (
    <ImageUploading value={images} onChange={onChange} maxNumber={maxNumber}>
      {({ imageList, onImageUpload, onImageUpdate }) => (
        <div className="flex justify-start gap-4">
          <div
            className={twMerge(
              'flex h-16 w-16 cursor-pointer items-center justify-center rounded-full border border-gray-200 bg-gray-50',
              !optional && 'brand-gradient',
              imageUrl && 'border-none'
            )}
            onClick={imageList.length === 0 ? onImageUpload : () => onImageUpdate(0)}
          >
            {imageList.length === 0 && !imageUrl ? (
              optional ? (
                <Camera height={26} width={26} className="fill-gray-200" />
              ) : (
                <span className="text-md uppercase text-white">{initials}</span>
              )
            ) : imageUrl && imageList.length === 0 ? (
              <img alt={initials} src={imageUrl} className="size-16 rounded-full" />
            ) : (
              <img alt="" src={imageList[0].dataURL} className="size-16 rounded-full" />
            )}
          </div>
          <div className="flex items-center justify-between gap-1">
            {imageList.length === 0 && !imageUrl ? (
              <span className="cursor-pointer text-md font-semibold text-brand-800" onClick={onImageUpload}>
                Choose image
              </span>
            ) : (
              <span className="cursor-pointer text-md font-semibold text-brand-800" onClick={() => onImageUpdate(0)}>
                Replace image
              </span>
            )}
            {optional && <span className="text-md">(Optional)</span>}
          </div>
        </div>
      )}
    </ImageUploading>
  );
});

export default ImageUpload;
