import { memo } from 'react';

interface DateTimeProps {
  date: Date;
}

const DateTime = memo(function DateTime({ date }: DateTimeProps) {
  return (
    <div className="flex items-center gap-2">
      <span className="text-xs font-medium">{date.toLocaleDateString()}</span>
      <div className="h-2.5 w-px bg-gray-300" />
      <span className="text-xs font-medium">{date.toLocaleTimeString()}</span>
    </div>
  );
});

export default DateTime;
