import { memo } from 'react';

interface SvgJerseyProps extends React.SVGProps<SVGGElement> {
  top: number;
  left: number;
  color: string;
  number: number;
  blackNumber?: boolean;
}

// IMPORTANT:
// for use in <svg> only, not a standalone component
const SvgJersey = memo(function SvgJersey({ top, left, color, number, blackNumber = false, ...props }: SvgJerseyProps) {
  const width = 52;
  const height = 48;

  return (
    <g
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `translate(${left - width / 2}px, ${top - height / 2}px)` }}
      {...props}
    >
      <g filter="url(#shadow)">
        <path
          d="M37.9973 8C38.7337 8 39.3307 8.59696 39.3307 9.33333V18.6667C39.3307 19.4031 38.7337 20 37.9973 20H35.3293L35.3307 30.6667C35.3307 31.4031 34.7337 32 33.9973 32H17.9974C17.261 32 16.6641 31.4031 16.6641 30.6667L16.6627 19.9987L13.9974 20C13.261 20 12.6641 19.4031 12.6641 18.6667V9.33333C12.6641 8.59696 13.261 8 13.9974 8H21.9974C21.9974 10.2091 23.7883 12 25.9973 12C28.2065 12 29.9973 10.2091 29.9973 8H37.9973Z"
          fill={color}
        />
      </g>
      <text
        fill={blackNumber ? 'black' : 'white'}
        x={width / 2}
        y={height / 2}
        dominantBaseline="top"
        textAnchor="middle"
        className="z-10 text-tiny font-bold"
      >
        {number}
      </text>
      {/* SHADOW */}
      <defs>
        <filter
          id="shadow"
          x="-2"
          y="-4"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2182_58324" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2182_58324" result="shape" />
        </filter>
      </defs>
    </g>
  );
});

export default SvgJersey;
