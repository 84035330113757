import { timePeriodOptions } from 'constants/formOptions';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import ButtonTabs from 'modules/common/ButtonTabs';
import { getReportGeneralColor } from 'modules/reports/helpers';
import { memo, useState } from 'react';
import { MatchInfo, Values } from 'utils/interfaces';
import { MatchGeneralData } from '../../reportCards/interfaces';
import HeatMapChart from './HeatMapChart';
import HeatMapPlayers from './HeatMapPlayers';
import { HeatMapPlayer, MatchHeatMapPeriodsData } from './interfaces';
import ElementMatchInfo from '../ElementMatchInfo';
import { capitalizeWord } from 'utils/helpers';

interface HeatMapData {
  heatmap_data_by_period: MatchHeatMapPeriodsData;
  match_info: MatchInfo;
  home_players: HeatMapPlayer[];
  away_players: HeatMapPlayer[];
}

interface MatchHeatMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const MatchHeatMapElement = memo(function MatchHeatMapElement({ element, report }: MatchHeatMapElementProps) {
  const [timePeriod, setTimePeriod] = useState<Values<typeof timePeriodOptions>>(timePeriodOptions[3]);
  const {
    heatmap_data_by_period: data,
    match_info: matchInfo,
    home_players: homePlayers,
    away_players: awayPlayers
  } = element.entity_data as HeatMapData;
  const subject1 = (report.general_data as MatchGeneralData).home_team_name;
  const subject2 = (report.general_data as MatchGeneralData).away_team_name;
  const teamId1 = (report.general_data as MatchGeneralData).home_team_id;
  const teamId2 = (report.general_data as MatchGeneralData).away_team_id;

  return (
    <div className="flex w-full max-w-screen-md flex-col gap-3 self-center">
      <ElementMatchInfo match={matchInfo} />
      {element.attribute_values?.event_types && (
        <div className="text-xs font-semibold">
          Events:{' '}
          {element.attribute_values.event_types.length === 0
            ? 'All'
            : element.attribute_values.event_types.map((type: string) => capitalizeWord(type)).join(', ')}
        </div>
      )}
      <ButtonTabs options={timePeriodOptions} selected={timePeriod} setSelected={setTimePeriod} />
      <div className="grid grid-cols-2 gap-3">
        <span className="text-xs font-semibold">{subject1}</span>
        <span className="text-xs font-semibold">{subject2}</span>

        <HeatMapChart data={data[timePeriod.id].home} isVertical={true} match={matchInfo} />
        <HeatMapChart data={data[timePeriod.id].away} isVertical={true} match={matchInfo} />
        <HeatMapPlayers players={homePlayers} color={getReportGeneralColor(report, teamId1)} />
        <HeatMapPlayers players={awayPlayers} color={getReportGeneralColor(report, teamId2)} />
      </div>
    </div>
  );
});

export default MatchHeatMapElement;
