import { ReportElementSchema, ReportElementTemplateSchema } from 'lib/model';
import {
  competitionToValueOption,
  eventToValueOption,
  matchToValueOption,
  metricToMetricOption,
  playerToValueOption,
  stringToValueOption,
  matchDetailedToValueOption
} from 'utils/mappings';
import {
  AttributeValues,
  Competition,
  Match,
  MatchDetailed,
  Metric,
  MetricOption,
  Player,
  ValueOption
} from '../../../../../../utils/interfaces';
import { ElementFormValues, EventMapEvent } from './interfaces';

export function attributeValuesPlayers(allPlayers: Player[], selectedPlayers: number[]): ValueOption[] {
  if (allPlayers.length === selectedPlayers.length) {
    return [];
  }
  return allPlayers.filter((player: Player) => selectedPlayers.includes(player.player_id)).map(playerToValueOption);
}

export function attributeValuesPlayersHeatMap(allPlayers: Player[], selectedPlayers: number[]): ValueOption[] {
  return allPlayers.filter((player: Player) => selectedPlayers.includes(player.player_id)).map(playerToValueOption);
}

export function attributeValuesCompetitions(
  allCompetitions: Competition[],
  selectedCompetitions: number[]
): ValueOption[] {
  return allCompetitions
    .filter((competition: Competition) => selectedCompetitions.includes(competition.competition_id))
    .map(competitionToValueOption);
}

export function attributeValuesCompetition(
  allCompetitions: Competition[],
  selectedCompetitions: number[]
): ValueOption {
  return allCompetitions
    .filter((competition: Competition) => selectedCompetitions.includes(competition.competition_id))
    .map(competitionToValueOption)
    .at(0)!;
}

export function attributeValuesMatchDetailed(allMatches: MatchDetailed[], selectedMatch: number[]): ValueOption {
  return allMatches
    .filter((match: MatchDetailed) => selectedMatch.includes(match.match_id))
    .map(matchDetailedToValueOption)
    .at(0)!;
}

export function attributeValuesMatch(allMatches: Match[], selectedMatch: number[]): ValueOption {
  return allMatches
    .filter((match: Match) => selectedMatch.includes(match.match_id))
    .map(matchToValueOption)
    .at(0)!;
}

export function attributeValuesMatches(allMatches: Match[], selectedMatch: number[]): ValueOption[] {
  return allMatches.filter((match: Match) => selectedMatch.includes(match.match_id)).map(matchToValueOption);
}

export function attributeValuesOption(values: string[]): ValueOption {
  return values.map(stringToValueOption).at(0)!;
}

export function attributeValuesOptions(values: string[], all?: boolean, allValues?: string[]): ValueOption[] {
  if (all && allValues && allValues.length === values.length) {
    return [];
  }

  return values.map(stringToValueOption);
}

export function attributeValuesEvents(allEvents: EventMapEvent[], selectedEvent: string[]): ValueOption | null {
  return (
    allEvents
      .filter((event) => selectedEvent.includes(JSON.stringify(event)))
      .map(eventToValueOption)
      .at(0) ?? null
  );
}

export function attributeValuesMetrics(allMetrics: Metric[], selectedMetrics: string[]): MetricOption[] {
  return allMetrics.filter((metric) => selectedMetrics.includes(metric.type)).map(metricToMetricOption);
}

export function attributeValuesMetric(allMetrics: Metric[], selectedMetrics: string[]): MetricOption {
  return attributeValuesMetrics(allMetrics, selectedMetrics).at(0)!;
}

export function loadAttributeValues(
  defaultValues: Partial<ElementFormValues>,
  element: ReportElementSchema,
  template: ReportElementTemplateSchema
) {
  const attributes = element.attribute_values as AttributeValues<ElementFormValues>;
  for (const [key, values] of Object.entries(attributes)) {
    switch (key) {
      case 'attribute_type':
      case 'aggregate_by':
      case 'aggregation_metric':
      case 'type':
      case 'metrics_within_match':
      case 'metrics_within_season_season':
      case 'time_scale':
      case 'x_axis_season':
      case 'y_axis_season':
      case 'position_type':
        defaultValues[key] = attributeValuesOption(values as string[]);
        break;
      case 'group_by':
      case 'metrics_players_season':
      case 'metrics_teams_season':
        defaultValues[key] = attributeValuesOptions(values as string[]);
        break;
      case 'seasons':
        defaultValues[key] = attributeValuesOptions(values as string[], true, template.attribute_choices![key]);
        break;
      case 'x_axis_match':
      case 'y_axis_match':
      case 'metrics_within_season_match':
        defaultValues[key] = attributeValuesMetric(template.attribute_choices![key], values as string[]);
        break;
      case 'metrics_players_match':
      case 'metrics_teams_match':
      case 'metrics':
        defaultValues[key] = attributeValuesMetrics(template.attribute_choices![key], values as string[]);
        break;
      case 'players':
        defaultValues[key] = attributeValuesPlayers(template.attribute_choices![key], values as number[]);
        break;
      case 'competitions':
        defaultValues[key] = attributeValuesCompetitions(template.attribute_choices![key], values as number[]);
        break;
      case 'match_id':
      case 'match_id_team1':
      case 'match_id_team2':
      case 'match_id_player1':
      case 'match_id_player2':
        defaultValues[key] = attributeValuesMatch(template.attribute_choices![key], values as number[]);
        break;
      case 'matches':
        defaultValues[key] = attributeValuesMatches(template.attribute_choices![key], values as number[]);
        break;
      case 'events':
        defaultValues[key] = attributeValuesEvents(template.attribute_choices![key], values as string[]);
    }
  }

  return;
}
