/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator,
  default as postProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator
} from '../../api/axios';
import type { PaymentCreateSchema, PaymentSchema, PaymentsSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of payments for the specified subscription.
 */
export const getProjectProjectIdSubscriptionSubscriptionIdPayments = (
  projectId: string,
  subscriptionId: string,
  options?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator<PaymentsSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/subscription/${encodeURIComponent(String(subscriptionId))}/payments`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryKey = (
  projectId: string,
  subscriptionId: string
) => {
  return [`/project/${projectId}/subscription/${subscriptionId}/payments`] as const;
};

export const getGetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>,
  TError = unknown
>(
  projectId: string,
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryKey(projectId, subscriptionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>> = ({
    signal
  }) => getProjectProjectIdSubscriptionSubscriptionIdPayments(projectId, subscriptionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && subscriptionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>
>;
export type GetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryError = unknown;

/**
 * @summary Returns a list of payments for the specified subscription.
 */
export const useGetProjectProjectIdSubscriptionSubscriptionIdPayments = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>,
  TError = unknown
>(
  projectId: string,
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionIdPayments>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdSubscriptionSubscriptionIdPaymentsQueryOptions(
    projectId,
    subscriptionId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new payment entry
 */
export const postProjectProjectIdSubscriptionSubscriptionIdPayments = (
  projectId: string,
  subscriptionId: string,
  paymentCreateSchema: PaymentCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>
) => {
  return postProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator<PaymentSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/subscription/${encodeURIComponent(String(subscriptionId))}/payments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: paymentCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdSubscriptionSubscriptionIdPaymentsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>,
    TError,
    { projectId: string; subscriptionId: string; data: PaymentCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>,
  TError,
  { projectId: string; subscriptionId: string; data: PaymentCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>,
    { projectId: string; subscriptionId: string; data: PaymentCreateSchema }
  > = (props) => {
    const { projectId, subscriptionId, data } = props ?? {};

    return postProjectProjectIdSubscriptionSubscriptionIdPayments(projectId, subscriptionId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdSubscriptionSubscriptionIdPaymentsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>
>;
export type PostProjectProjectIdSubscriptionSubscriptionIdPaymentsMutationBody = PaymentCreateSchema;
export type PostProjectProjectIdSubscriptionSubscriptionIdPaymentsMutationError = unknown;

/**
 * @summary Creates a new payment entry
 */
export const usePostProjectProjectIdSubscriptionSubscriptionIdPayments = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>,
    TError,
    { projectId: string; subscriptionId: string; data: PaymentCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionSubscriptionIdPaymentsMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionSubscriptionIdPayments>>,
  TError,
  { projectId: string; subscriptionId: string; data: PaymentCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdSubscriptionSubscriptionIdPaymentsMutationOptions(options);

  return useMutation(mutationOptions);
};
