import DataSourceAuth from 'constants/enums/dataSourceAuth';
import { usePostDatasourceDatasourceIdCheckCredentials } from 'lib/datasource/datasource';
import { DatasourceCredentialSchema } from 'lib/model';
import { memo, useState } from 'react';
import ApiKeyAuthForm from './ApiKeyAuthForm';
import AuthenticatedBanner from './AuthenticatedBanner';
import EmailAuthForm from './EmailAuthForm';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface DataSourceAuthProps {
  dataSourceId: string;
  projectId: string;
  dataSource: string;
  website: string;
  accountDataType: DataSourceAuth;
  configured: boolean;
  authenticated: boolean;
  setAuthenticated: (value: boolean) => void;
  note?: string | JSX.Element;
}

const DataSourceAuthentication = memo(function DataSourceAuthentication({
  dataSourceId,
  projectId,
  dataSource,
  website,
  accountDataType,
  authenticated,
  configured,
  setAuthenticated,
  note
}: DataSourceAuthProps) {
  const { mutate, isPending } = usePostDatasourceDatasourceIdCheckCredentials();
  const [authError, setAuthError] = useState('');
  function signOut() {
    setAuthenticated(false);
  }

  function signIn(formData: DatasourceCredentialSchema) {
    mutate(
      { datasourceId: dataSourceId, data: formData, params: { project_id: projectId } },
      {
        onSuccess: (response) => {
          if (response.valid_credentials) {
            setAuthError('');
            toast.success('Successfully authenticated.');
            setAuthenticated(true);
          } else {
            setAuthError('The provided credentials are incorrect, please check and try again.');
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response?.data.error;
            toast.error(error);
          }
        }
      }
    );
  }

  return (
    <>
      {!authenticated ? (
        <div className="flex flex-col">
          {note && <p className="text-sm font-medium">{note}</p>}
          {accountDataType === DataSourceAuth.EMAIL_AND_PASSWORD && (
            <EmailAuthForm
              signIn={signIn}
              dataSource={dataSource}
              website={website}
              authError={authError}
              isPending={isPending}
            />
          )}
          {accountDataType === DataSourceAuth.API_KEY && (
            <ApiKeyAuthForm
              signIn={signIn}
              dataSource={dataSource}
              website={website}
              authError={authError}
              isPending={isPending}
            />
          )}
        </div>
      ) : (
        <AuthenticatedBanner signOut={signOut} configured={configured} />
      )}
    </>
  );
});

export default DataSourceAuthentication;
