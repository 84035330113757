import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReportElementSchema, ReportElementTemplateSchema, ReportNestedSchema } from 'lib/model';
import { getGetReportLastEditedQueryKey, usePutReportReportIdElementElementId } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';
import ElementForm from './elementForms/ElementForm';
import { ElementFormValues } from './elementForms/interfaces';
import { transformAttributeValues } from './helpers';
import ElementTemplateDescription from './ElementTemplateDescription';

interface EditElementDialogProps extends DialogProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
  elementTemplates: ReportElementTemplateSchema[];
}

const EditElementDialog = memo(function EditElementDialog({
  open,
  setOpen,
  report,
  element,
  elementTemplates
}: EditElementDialogProps) {
  const queryClient = useQueryClient();
  const template = elementTemplates.find((et) => et.name === element.report_element_template_name)!;

  const { mutate: editElement, isPending: isEditingElement } = usePutReportReportIdElementElementId({
    mutation: {
      onMutate: async () => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        return { loadingToastId };
      },
      onError: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
      },
      onSuccess: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
      },
      onSettled: () => {
        setOpen(false);
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['reports', report.id!, 'elements', element.id]
        });
        queryClient.invalidateQueries({
          exact: true,
          queryKey: getGetReportLastEditedQueryKey()
        });
      }
    }
  });

  const handleEditElement = useCallback(
    function handleEditElement(data: ElementFormValues) {
      if (!template) {
        return;
      }

      const attributeValues = transformAttributeValues(data, template.attribute_choices);

      editElement({
        reportId: report.id!,
        elementId: element.id!,
        data: {
          report_element_template: template.id!,
          position: element.position,
          attribute_values: attributeValues
        }
      });
    },
    [editElement, element.id, element.position, report.id, template]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title={`Edit ${template.display_name!} Element`} onCancel={handleCancel} open={open}>
      <DialogContent>
        <div className="flex flex-col gap-6">
          <ElementTemplateDescription template={template} />
          <ElementForm onSubmitForm={handleEditElement} report={report} template={template} element={element} />
        </div>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel} disabled={isEditingElement}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-element-form" loading={isEditingElement}>
          <span>Edit Element</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default EditElementDialog;
