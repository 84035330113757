import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { ScatterChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import { AttributeValues } from '../interfaces';
import ScatterChart from './ScatterChart';
import ScatterChartLegend from './ScatterChartLegend';
import { ScatterChartPoint } from './interfaces';
import AggregationData from '../AggregationData';

interface ScatterChartElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface ScatterChartData {
  result: ScatterChartPoint[];
}

const ScatterChartElement = memo(function ScatterChartElement({ report, element }: ScatterChartElementProps) {
  const attributes = element.attribute_values as AttributeValues<ScatterChartElementFormValues>;
  const { result: points } = element.entity_data as ScatterChartData;

  let x_axis;
  let y_axis;
  if (attributes.aggregate_by[0] === 'season') {
    x_axis = attributes.x_axis_season[0];
    y_axis = attributes.y_axis_season[0];
  } else {
    x_axis = attributes.x_axis_match[0];
    y_axis = attributes.y_axis_match[0];
  }

  return (
    <div className="flex flex-col gap-6">
      {report.report_type !== 'match' && <AggregationData report={report} element={element} />}
      <ScatterChartLegend report={report} />
      <ScatterChart points={points} xField={x_axis as string} yField={y_axis as string} report={report} />
    </div>
  );
});

export default ScatterChartElement;
