import { HTMLProps, memo, RefCallback, useCallback, useState } from 'react';
import ReactSlider from 'react-slider';
import { twJoin } from 'tailwind-merge';

interface DataTableSliderProps {
  min: number;
  max: number;
  columnName: string;
  filters: Record<string, number[]>;
  setFilters: React.Dispatch<React.SetStateAction<Record<string, number[]>>>;
}

function DataTableSlider({ min, max, columnName, filters, setFilters }: DataTableSliderProps) {
  const rangeColumnName = columnName + '__values_in_between';
  const [sliderValues, setSliderValues] = useState<[number, number]>(
    filters[rangeColumnName] ? (filters[rangeColumnName] as [number, number]) : [min, max]
  );

  const handleInputChange = useCallback(
    function handleInputChange(index: number) {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const copy: [number, number] = [...sliderValues];
        copy[index] = Number(e.target.value);
        setSliderValues(copy);
        setFilters((prev) => {
          const next = prev;
          next[rangeColumnName] = copy;
          return next;
        });
      };
    },
    [sliderValues, setFilters, rangeColumnName]
  );

  const handleSliderChange = useCallback(
    function handleSliderChange(value: [number, number]) {
      setSliderValues(value);
      setFilters((prev) => {
        const next = prev;
        next[rangeColumnName] = value;
        return next;
      });
    },
    [setSliderValues, rangeColumnName, setFilters]
  );

  return (
    <div className="flex flex-col gap-3">
      <ReactSlider
        value={sliderValues}
        className="h-5"
        thumbClassName="size-5 rounded-full border-2 border-white bg-brand-800"
        min={min}
        max={max}
        pearling
        onChange={handleSliderChange}
        renderTrack={trackClassName}
      />
      <div className="flex items-center gap-6">
        <div className="relative flex w-full flex-col gap-2">
          <input
            min={min}
            max={max}
            value={sliderValues[0]}
            onChange={handleInputChange(0)}
            id={`${columnName}-min`}
            placeholder={' '}
            type="number"
            className="peer block h-14 w-full appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
          />
          <label
            htmlFor={`${columnName}-min`}
            className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
          >
            Min
          </label>
        </div>
        <span className="text-gray-950">-</span>
        <div className="relative flex w-full flex-col gap-2">
          <input
            min={min}
            max={max}
            value={sliderValues[1]}
            onChange={handleInputChange(1)}
            id={`${columnName}-max`}
            placeholder={' '}
            type="number"
            className="peer block h-14 w-full appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
          />
          <label
            htmlFor={`${columnName}-max`}
            className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
          >
            Max
          </label>
        </div>
      </div>
    </div>
  );
}

interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
  ref: RefCallback<T>;
}

function trackClassName(
  props: HTMLPropsWithRefCallback<HTMLDivElement>,
  state: {
    index: number;
    value: [number, number];
  }
) {
  const { key, ...rest } = props;
  return (
    <div
      {...rest}
      key={key}
      className={twJoin(
        'top-1/2 h-1.5 -translate-y-1/2 rounded-full',
        state.index === 1 ? 'bg-brand-800' : 'bg-gray-200'
      )}
    />
  );
}

export default memo(DataTableSlider);
