import { useQueryClient } from '@tanstack/react-query';
import Edit from 'assets/edit.svg?react';
import Trash from 'assets/trash-remove.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ProjectGetSchema } from 'lib/model';
import { useGetProjectOrganizationType } from 'lib/organization-type/organization-type';
import { usePutProjectProjectId } from 'lib/project/project';
import ImageUpload from 'modules/common/ImageUpload';
import Pill from 'modules/common/Pill';
import { memo, useEffect, useState } from 'react';
import { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import DeleteOrganizationDialog from './dialogs/DeleteOrganizationDialog';
import OrganizationEditDialog from './dialogs/OrganizationEditDialog';

function Organization() {
  const { isOwner, isAdmin } = useAuth();
  if (!isOwner && !isAdmin) {
    throw new Error('403 Forbidden');
  }
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project']);
  const { data: orgTypes } = useGetProjectOrganizationType({
    query: { queryKey: ['organizationTypes'] }
  });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [images, setImages] = useState([] as ImageListType);
  const [organizationEditOpen, setOrganizationEditOpen] = useState(false);
  const orgType = orgTypes?.objects?.find((x) => x.id === project?.organization_type);

  const { mutate: updateOrg } = usePutProjectProjectId();

  function editOrganization() {
    setOrganizationEditOpen(true);
  }

  function deleteOrganization() {
    setDeleteOpen(true);
  }

  useEffect(() => {
    if (images.length) {
      updateOrg(
        { projectId: project!.id!, data: { organization_image: images[0].dataURL } },
        {
          onSuccess: () => {
            toast.success('Organization image update successfully.');
          }
        }
      );
    }
  }, [images, updateOrg, project]);

  return (
    <section className="flex flex-col gap-10 p-10">
      <OrganizationEditDialog open={organizationEditOpen} setOpen={setOrganizationEditOpen} org={project!} />
      <span className="text-md font-semibold">Organization Information</span>

      <ImageUpload
        images={images}
        setImages={setImages}
        initials={project?.organization_name!.charAt(0)}
        imageUrl={project?.organization_image_path}
      />

      <div className="flex max-w-[612px] flex-col gap-2 rounded-md bg-gray-50 p-6">
        <div className="flex justify-between">
          <span className="text-sm font-medium text-gray-500">Organization name</span>
          <div className="flex cursor-pointer items-center gap-2" onClick={editOrganization}>
            <span className="text-sm text-brand-800">Edit</span>
            <Edit width={16} height={16} className="fill-brand-800" />
          </div>
        </div>
        <div className="flex gap-2">
          <span className="text-md font-medium">{project?.organization_name}</span>
          {orgType && <Pill type="empty">{orgType.name}</Pill>}
        </div>
      </div>
      <div className="flex cursor-pointer gap-2" onClick={deleteOrganization}>
        <Trash width={20} height={20} className="fill-red-500" />
        <span className="text-sm font-medium text-red-500">Delete Organization</span>
      </div>
      <DeleteOrganizationDialog open={deleteOpen} setOpen={setDeleteOpen} />
    </section>
  );
}

export default memo(Organization);
