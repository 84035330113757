import { memo } from 'react';

const DataSourcesHeaderSkeleton = memo(function DataSourcesHeaderSkeleton() {
  return (
    <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 px-10 pb-8 pt-10 max-md:px-0">
      <div className="grid h-16 w-full grid-cols-4 gap-6 rounded-xl bg-white p-3">
        <div className="w-full animate-pulse rounded-md bg-gray-50" />
        <div className="w-full animate-pulse rounded-md bg-gray-50" />
      </div>
    </header>
  );
});

export default DataSourcesHeaderSkeleton;
