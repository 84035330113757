/* eslint-disable react/jsx-no-bind */
import { curveMonotoneX } from '@visx/curve';
import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleTime } from '@visx/scale';
import { Circle, LinePath } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { max, min } from '@visx/vendor/d3-array';
import { ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useMemo, useRef } from 'react';
import { isLightColor } from 'utils/helpers';
import { getReportGeneralColor } from '../../../../helpers';
import ChartAxes from '../ChartAxes';
import ChartTooltip from '../ChartTooltip';
import { LineChartPoint } from './interfaces';

interface LineChartProps {
  events: LineChartPoint[];
  groupByKey: 'player_id' | 'team_id' | 'competition_id';
  xType?: 'number' | 'date' | 'category';
  xName: string;
  yName: string;
  report: ReportNestedSchema;
}

const LineChart = memo(function LineChart({
  events,
  groupByKey,
  xType = 'number',
  xName = 'Minutes',
  yName = 'Value',
  report
}: LineChartProps) {
  const lineData = useMemo(() => {
    if (!events) {
      return undefined;
    }
    return events.reduce((acc: Record<string, LineChartPoint[]>, curr) => {
      if (xType === 'date') {
        curr.x_value = new Date(curr.x_value);
      }
      if (!acc[curr[groupByKey]]) {
        acc[curr[groupByKey]] = [];
      }
      acc[curr[groupByKey]].push(curr);
      return acc;
    }, {});
  }, [events, groupByKey, xType]);

  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  const height = 350;
  const margin = {
    top: 0,
    right: 0,
    bottom: 50,
    left: 50
  };
  const chartOffset = 20;
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const getX = useCallback((point: LineChartPoint) => point.x_value, []);
  const getY = useCallback((point: LineChartPoint) => point.y_value, []);

  const yScale = scaleLinear<number>({
    domain: [
      Math.min(0, min(Object.values(events), getY as (point: LineChartPoint) => number)!),
      !!lineData
        ? max(
            Object.values(lineData).flatMap((values) => values),
            getY
          )!
        : 100
    ],
    round: true
  });
  const xScale = useMemo(() => {
    if (xType === 'number') {
      return scaleLinear<number>({
        domain: [
          0,
          !!lineData ? Math.max(max(Object.values(events), getX as (point: LineChartPoint) => number)!, 90) : 90
        ],
        round: true
      });
    } else if (xType === 'date') {
      return scaleTime<number>({
        domain: [new Date(events[0].x_value as string), new Date(events[events.length - 1].x_value as string)],
        nice: true
      });
    } else {
      return scaleBand<string>({
        domain: !!lineData ? Array.from(new Set(Object.values(events).map((value) => value.x_value) as string[])) : [],
        paddingOuter: 0,
        paddingInner: 1
      });
    }
  }, [events, getX, lineData, xType]);

  const xValue = useCallback(
    function xValue(point: LineChartPoint) {
      // TypeScript doesn't realize that right type will be used for each of scales
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return xScale(getX(point) as any) ?? 0;
    },
    [getX, xScale]
  );

  const yValue = useCallback(
    function yValue(point: LineChartPoint) {
      return yScale(getY(point)) ?? 0;
    },
    [getY, yScale]
  );

  // domain -> range === value -> "pixels" mapping
  xScale.range([chartOffset, chartWidth - chartOffset]);
  yScale.range([chartHeight - chartOffset, chartOffset]);

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } = useTooltip<LineChartPoint>({
    tooltipOpen: false
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleMouseEnter = useCallback(
    function handleMouseEnter(point: LineChartPoint) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        showTooltip({
          tooltipData: point,
          tooltipLeft: xValue(point) + 40,
          tooltipTop: yValue(point) - 20 - 16
        });
      };
    },
    [showTooltip, xValue, yValue]
  );

  return (
    <div ref={parentRef} className="relative flex flex-col gap-6">
      <svg height={height} className="w-full overflow-x-visible" ref={containerRef}>
        <rect width={chartWidth} height={chartHeight} x={margin.left} y={margin.top} fill="#f3f4f6" rx={12} ry={12} />
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={chartWidth}
            height={chartHeight}
            strokeDasharray="2,2"
            stroke="#D1D5DB"
            strokeWidth={1}
          />
          <ChartAxes
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            xScale={xScale}
            yScale={yScale}
            xUnit={xName}
            yUnit={yName}
            xType={xType}
          />
          {lineData &&
            Object.entries(lineData).map(([id, points]) => {
              const color = points[0].team_color
                ? points[0].team_color
                : getReportGeneralColor(report, points[0].team_id, undefined, points[0].player_id);

              return isLightColor(color) ? (
                <>
                  <LinePath
                    key={id + '-border'}
                    curve={curveMonotoneX}
                    data={points}
                    x={xValue}
                    y={yValue}
                    stroke="#9ca3af"
                    strokeWidth={3}
                    shapeRendering="geometricPrecision"
                  />
                  <LinePath
                    key={id}
                    curve={curveMonotoneX}
                    data={points}
                    x={xValue}
                    y={yValue}
                    stroke={color}
                    strokeWidth={2}
                    shapeRendering="geometricPrecision"
                  />
                </>
              ) : (
                <LinePath
                  key={id}
                  curve={curveMonotoneX}
                  data={points}
                  x={xValue}
                  y={yValue}
                  stroke={color}
                  strokeWidth={2}
                  shapeRendering="geometricPrecision"
                />
              );
            })}
          {lineData &&
            Object.entries(lineData).flatMap(([id, points]) =>
              points.map((point, i) => {
                const color = points[0].team_color
                  ? points[0].team_color
                  : getReportGeneralColor(report, points[0].team_id, undefined, point.player_id);

                return isLightColor(color) ? (
                  <>
                    <Circle
                      key={`${id}-${i}`}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      cx={xScale(getX(point) as any)}
                      cy={yScale(getY(point))}
                      r={5}
                      strokeWidth={2}
                      stroke="#f3f4f6"
                    />
                    <Circle
                      key={`${id}-${i}`}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      cx={xScale(getX(point) as any)}
                      cy={yScale(getY(point))}
                      r={4}
                      strokeWidth={1}
                      stroke="#9ca3af"
                      fill={color}
                      onMouseLeave={handleMouseLeave}
                      onMouseEnter={handleMouseEnter(point)}
                    />
                  </>
                ) : (
                  <Circle
                    key={`${id}-${i}`}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    cx={xScale(getX(point) as any)}
                    cy={yScale(getY(point))}
                    r={4}
                    strokeWidth={2}
                    stroke="#f3f4f6"
                    fill={color}
                    onMouseLeave={handleMouseLeave}
                    onMouseEnter={handleMouseEnter(point)}
                  />
                );
              })
            )}
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        {tooltipData && (
          <>
            {/* {tooltipData.competition_name && <span>Competition: {tooltipData.competition_name}</span>} */}
            {tooltipData.player_name ? <span> {tooltipData.player_name}</span> : <span>{tooltipData.team_name}</span>}
            <span>
              {xName}:{' '}
              {typeof tooltipData.x_value === 'number' || typeof tooltipData.x_value === 'string'
                ? tooltipData.x_value
                : tooltipData.x_value.toLocaleDateString()}
            </span>
            <span>
              {yName}: {Number(tooltipData.y_value.toFixed(2))}
            </span>
          </>
        )}
      </ChartTooltip>
    </div>
  );
});

export default LineChart;
