import Stats from 'assets/chart-data.svg?react';
import Event from 'assets/event-ball.svg?react';
import Tracking from 'assets/live-recording.svg?react';
import Odds from 'assets/odds.svg?react';
import Ranking from 'assets/trophy.svg?react';
import Biography from 'assets/user-check.svg?react';
import DataSourceDataTypeEnum from 'constants/enums/dataSourceDataType';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import Tooltip from '../../common/Tooltip';

interface DataSourceDataTypeBadgeProps {
  type: string;
  withText?: boolean;
}

const DataSourceDataTypeBadge = memo(function DataSourceDataTypeBadge({
  type,
  withText = false
}: DataSourceDataTypeBadgeProps) {
  const badgeSize = withText ? 14 : 16;
  const padding = withText ? 'px-3 py-2' : 'p-2';

  return (
    <>
      {type === DataSourceDataTypeEnum.BIOGRAPHIC &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-green', padding)}>
            <Biography width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">BIOGRAPHIC</span>
          </div>
        ) : (
          <Tooltip message="Biographic" background="bg-badge-green" padding={padding}>
            <Biography width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
      {type === DataSourceDataTypeEnum.EVENT &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-orange', padding)}>
            <Event width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">EVENT</span>
          </div>
        ) : (
          <Tooltip message="Event" background="bg-badge-orange" padding={padding}>
            <Event width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
      {type === DataSourceDataTypeEnum.ODDS &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-purple', padding)}>
            <Odds width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">ODDS</span>
          </div>
        ) : (
          <Tooltip message="Odds" background="bg-badge-purple" padding={padding}>
            <Odds width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
      {type === DataSourceDataTypeEnum.RANKING &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-red', padding)}>
            <Ranking width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">RANKING</span>
          </div>
        ) : (
          <Tooltip message="Ranking" background="bg-badge-red" padding={padding}>
            <Ranking width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
      {type === DataSourceDataTypeEnum.TRACKING &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-blue', padding)}>
            <Tracking width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">TRACKING</span>
          </div>
        ) : (
          <Tooltip message="Tracking" background="bg-badge-blue" padding={padding}>
            <Tracking width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
      {type === DataSourceDataTypeEnum.STATS &&
        (withText ? (
          <div className={twJoin('flex items-center justify-center gap-1 rounded-full bg-badge-cyan', padding)}>
            <Stats width={badgeSize} height={badgeSize} className="fill-white" />
            <span className="text-tiny font-semibold text-white">STATS</span>
          </div>
        ) : (
          <Tooltip message="Stats" background="bg-badge-cyan" padding={padding}>
            <Stats width={badgeSize} height={badgeSize} className="fill-white" />
          </Tooltip>
        ))}
    </>
  );
});

export default DataSourceDataTypeBadge;
