/* eslint-disable react-refresh/only-export-components */
import Check from 'assets/check.svg?react';
import { memo, useCallback } from 'react';
import { FieldError, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import ErrorMessage from './ErrorMessage';

interface MultipleSelectInputProps<T extends FieldValues> extends UseControllerProps<T> {
  options: ValueOption[];
  error?: FieldError;
}

function MultipleSelectList<T extends FieldValues>({ options, error, ...formProps }: MultipleSelectInputProps<T>) {
  const {
    field: { onChange: formOnChange, value: formValues }
  } = useController<T>(formProps);

  const handleChange = useCallback(
    (option: ValueOption) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let copy = [...formValues];
      if (event.target.checked) {
        copy.push(option);
      } else {
        copy = copy.filter((x) => x.value !== option.id);
      }
      formOnChange(copy);
    },
    [formOnChange, formValues]
  );

  const isChecked = useCallback(
    (option: ValueOption) => {
      return (formValues as ValueOption[]).some((x: ValueOption) => x.id === option.id);
    },
    [formValues]
  );

  return (
    <div className="flex flex-col gap-2">
      {options.map((option) => (
        <div key={option.id}>
          <input
            id={option.id as string}
            name={option.id as string}
            value={option.id}
            checked={isChecked(option)}
            type="checkbox"
            className={`peer hidden`}
            onChange={handleChange(option)}
          />
          <label
            htmlFor={option.id as string}
            className="group flex items-center justify-between rounded-md border border-gray-300 p-3 disabled:bg-gray-50 peer-checked:border-brand-800 peer-checked:bg-brand-50"
          >
            <span className="text-md font-medium peer-checked:group-[]:text-brand-800">{option.label}</span>
            <Check width={24} height={24} className="hidden fill-brand-800 peer-checked:group-[]:block" />
          </label>
        </div>
      ))}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleSelectList) as typeof MultipleSelectList;
