import { ReportElementTemplatesSchema, ReportNestedSchema } from 'lib/model';
import { useGetReportReportIdElementElementIdPublic } from 'lib/report/report';
import Divider from 'modules/common/Divider';
import ElementCardFallback from 'modules/reports/reportPage/components/elements/Card/ElementCardFallback';
import ElementCardSkeleton from 'modules/reports/reportPage/components/elements/Card/ElementCardSkeleton';
import ElementCardWarning from 'modules/reports/reportPage/components/elements/Card/ElementCardWarning';
import ComparisonElement from 'modules/reports/reportPage/components/elements/Comparison/ComparisonElement';
import EventMapElement from 'modules/reports/reportPage/components/elements/EventMap/EventMapElement';
import HeatMapElement from 'modules/reports/reportPage/components/elements/HeatMap/HeatMapElement';
import LineChartElement from 'modules/reports/reportPage/components/elements/LineChart/LineChartElement';
import LineupPitchElement from 'modules/reports/reportPage/components/elements/Lineup/LineupPitch/LineupPitchElement';
import LineupTableElement from 'modules/reports/reportPage/components/elements/Lineup/LineupTable/LineupTableElement';
import PositionMapElement from 'modules/reports/reportPage/components/elements/PositionMap/PositionMapElement';
import RadarChartElement from 'modules/reports/reportPage/components/elements/RadarChart/RadarChartElement';
import ScatterChartElement from 'modules/reports/reportPage/components/elements/ScatterChart/ScatterChartElement';
import StandingsElement from 'modules/reports/reportPage/components/elements/Standings/StandingsElement';
import TableElement from 'modules/reports/reportPage/components/elements/Table/TableElement';
import VersusElement from 'modules/reports/reportPage/components/elements/Versus/VersusElement';
import { memo, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { kebabCaseToWords } from 'utils/helpers';

interface PublicElementCardProps {
  report: ReportNestedSchema;
  elementTemplates?: ReportElementTemplatesSchema;
  elementId: string;
  token: string;
}

const PublicElementCard = memo(function PublicElementCard({ elementId, report, token }: PublicElementCardProps) {
  const { data: element, isPending: isElementPending } = useGetReportReportIdElementElementIdPublic(
    report.id!,
    elementId,
    {
      token
    },
    {
      query: {
        queryKey: ['reports', report.id, 'elements', elementId],
        staleTime: 1000 * 60
      }
    }
  );

  const elementCardContent = useMemo(() => {
    if (isElementPending || !element) {
      return <ElementCardSkeleton />;
    } else if (element.entity_data && 'error' in element.entity_data) {
      return <ElementCardWarning error={element.entity_data.error as string} />;
    }

    switch (element.report_element_template_name) {
      case 'lineups-table':
        return <LineupTableElement report={report} element={element} />;
      case 'lineups-pitch':
        return <LineupPitchElement report={report} element={element} />;
      case 'standings':
        return <StandingsElement report={report} element={element} />;
      case 'line-chart':
        return <LineChartElement report={report} element={element} />;
      case 'versus':
        return <VersusElement report={report} element={element} />;
      case 'radar-chart':
        return <RadarChartElement report={report} element={element} />;
      case 'table':
        return <TableElement report={report} element={element} />;
      case 'comparison':
        return <ComparisonElement report={report} element={element} />;
      case 'scatter-chart':
        return <ScatterChartElement report={report} element={element} />;
      case 'heatmap':
      case 'heatmap-tracking-data':
      case 'heatmap-event-data':
        return <HeatMapElement report={report} element={element} />;
      case 'avg-positions':
      case 'avg-positions-tracking-data':
      case 'avg-positions-event-data':
        return <PositionMapElement report={report} element={element} />;
      case 'event-map':
      case 'event-animation-tracking-data':
        return <EventMapElement report={report} element={element} />;
      case 'Loading...':
        return <ElementCardSkeleton />;
      default:
        return <ElementCardSkeleton />;
    }
  }, [element, isElementPending, report]);

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <header className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium">
              {element ? kebabCaseToWords(element.report_element_template_name!) : 'Loading...'}
            </span>
          </div>
        </div>
        <Divider />
      </header>
      <ErrorBoundary fallback={<ElementCardFallback />}>{elementCardContent}</ErrorBoundary>
    </div>
  );
});

export default PublicElementCard;
