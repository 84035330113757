import { memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { NavigationItem } from 'utils/interfaces';

interface NavLinksProps {
  items: NavigationItem[];
  expanded: boolean;
  mobileCloseNavigation?: (
    focusableElement?:
      | HTMLElement
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.MutableRefObject<HTMLElement | null>
      | undefined
  ) => void;
}

const NavLinks = memo(function NavLinks({ items, expanded, mobileCloseNavigation }: NavLinksProps) {
  const { pathname } = useLocation();

  function closeNavigation() {
    if (mobileCloseNavigation !== undefined) {
      mobileCloseNavigation();
    }
  }

  return (
    <>
      {items.map((item) => (
        <button
          disabled={item.disabled}
          key={item.name}
          className={twJoin(
            'rounded-md disabled:pointer-events-none',
            item.isActive(pathname) ? 'bg-brand-50' : 'bg-white hover:bg-gray-50',
            expanded ? 'w-full' : 'w-fit'
          )}
        >
          <NavLink to={item.href} className="flex items-center gap-2 p-3" onClick={closeNavigation}>
            <item.Icon
              width={20}
              height={20}
              className={twMerge(
                'min-h-5 min-w-5',
                item.isActive(pathname) ? 'fill-brand-800' : 'fill-gray-700',
                item.disabled && 'fill-gray-400'
              )}
              aria-hidden="true"
            />
            {expanded && (
              <span
                className={twMerge(
                  'overflow-hidden text-ellipsis whitespace-nowrap text-sm',
                  item.isActive(pathname) ? 'font-semibold text-brand-800' : 'font-medium',
                  item.disabled && 'text-gray-400'
                )}
              >
                {item.name}
              </span>
            )}
          </NavLink>
        </button>
      ))}
    </>
  );
});

export default NavLinks;
