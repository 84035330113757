import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Next from 'assets/arrow-right.svg?react';
import { ProjectSchema } from 'lib/model';
import { useGetReportGetPlayerMetrics, useGetReportGetPlayerPositions } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo, useCallback, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { ScoutReportFormValues } from '../interfaces';
import { metricToMetricOption, stringToValueOption } from 'utils/mappings';
import SelectInput from 'modules/common/Form/Select/SelectInput';

interface ScoutReportManualPlayersOptionsFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onNext: () => void;
  onBack: () => void;
}

function ScoutReportManualPlayersOptionsForm({
  onBack,
  onNext,
  scoutReportForm
}: ScoutReportManualPlayersOptionsFormProps) {
  const formState = useFormState(scoutReportForm);
  const position = useWatch({
    control: scoutReportForm.control,
    name: 'position'
  });
  const seasons = useWatch({
    control: scoutReportForm.control,
    name: 'seasons'
  });
  const metric = useWatch({
    control: scoutReportForm.control,
    name: 'metric'
  });

  const handleNext = useCallback(
    function handleNext() {
      onNext();
    },
    [onNext]
  );

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const playerPositions = useGetReportGetPlayerPositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerMetrics = useGetReportGetPlayerMetrics(
    { project_id: project.id!, player_position: undefined as string | undefined },
    {
      query: {
        queryKey: ['playerMetrics', project.id],
        staleTime: Infinity
        // enabled: !!position
      }
    }
  );

  const playerPositionOptions = useMemo(() => playerPositions.data?.map(stringToValueOption) ?? [], [playerPositions]);

  const playerMetricOptions = useMemo(() => {
    const baseMetrics = playerMetrics.data?.metrics ?? [];
    const filteredMetrics = baseMetrics.filter((metric) =>
      metric.primary_position_list?.includes(position?.id as string)
    );
    return filteredMetrics.map(metricToMetricOption) ?? [];
  }, [playerMetrics, seasons]);

  const metricSeasonsOptions = useMemo(() => {
    const seasons = metric?.seasons_list ?? [];
    return seasons.sort().reverse().map(stringToValueOption) ?? [];
  }, [metric]);

  const aggregationMetricOptions = useMemo(() => {
    const methods = metric?.recommended_aggregations ?? [];
    return methods.map(stringToValueOption);
  }, [metric]);

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="scout-report-custom-players-form"
          onSubmit={scoutReportForm.handleSubmit(handleNext)}
        >
          <div className="flex flex-col gap-3">
            <span className="text-md font-semibold">Player position</span>
            <p className="text-sm">Choose the player position for players you want to add to your report.</p>
          </div>
          <SelectInput
            formProps={{
              control: scoutReportForm.control,
              name: 'position',
              rules: { required: { value: true, message: 'Player position is required' } }
            }}
            loading={playerPositions.isPending}
            label="Player position"
            options={playerPositionOptions}
            error={formState.errors.position}
          />
          <div className="flex flex-col gap-3">
            <span className="text-md font-semibold">Sort players by metric criteria</span>
            <p className="text-sm">
              All players will be sorted automatically based on the selected
              <b className="font-semibold"> metric criteria </b>
              for their position.
            </p>
          </div>
          <SelectInput
            formProps={{
              control: scoutReportForm.control,
              name: 'metric',
              rules: { required: { value: true, message: 'Metric Criteria is required' } }
            }}
            label="Metric Criteria"
            options={playerMetricOptions}
            loading={playerMetrics.isFetching}
            error={formState.errors.metric}
          />
          <SelectInput
            formProps={{
              control: scoutReportForm.control,
              name: 'aggregation_metric',
              rules: { required: { value: true, message: 'Aggregate method is required' } }
            }}
            label={'Aggregate method'}
            options={aggregationMetricOptions}
            error={formState.errors.aggregation_metric}
          />
          <SelectInput
            formProps={{
              control: scoutReportForm.control,
              name: 'seasons'
            }}
            multiple
            label={'Seasons'}
            loading={playerMetrics.isFetching}
            placeholder="All Seasons"
            options={metricSeasonsOptions}
            error={formState.errors.seasons}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button size="xl" isSubmitButton form="scout-report-custom-players-form">
          <span>Next</span>
          <Next width={20} height={20} />
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportManualPlayersOptionsForm);
