/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getSubscriptionTierAddOnAddOnIdMutator,
  default as getSubscriptionTierMutator,
  default as getSubscriptionTierSubscriptionTierIdAddOnMutator,
  default as getSubscriptionTierSubscriptionTierIdMutator
} from '../../api/axios';
import type {
  AddOnSchema,
  AddOnsSchema,
  GetSubscriptionTierParams,
  GetSubscriptionTierSubscriptionTierIdAddOnParams,
  SubscriptionTierSchema,
  SubscriptionTiersSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all available subscrition tiers.
 */
export const getSubscriptionTier = (
  params?: GetSubscriptionTierParams,
  options?: SecondParameter<typeof getSubscriptionTierMutator>,
  signal?: AbortSignal
) => {
  return getSubscriptionTierMutator<SubscriptionTiersSchema>(
    { url: `/subscription-tier`, method: 'GET', params, signal },
    options
  );
};

export const getGetSubscriptionTierQueryKey = (params?: GetSubscriptionTierParams) => {
  return [`/subscription-tier`, ...(params ? [params] : [])] as const;
};

export const getGetSubscriptionTierQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionTier>>,
  TError = unknown
>(
  params?: GetSubscriptionTierParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTier>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionTierQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionTier>>> = ({ signal }) =>
    getSubscriptionTier(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionTier>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionTierQueryResult = NonNullable<Awaited<ReturnType<typeof getSubscriptionTier>>>;
export type GetSubscriptionTierQueryError = unknown;

/**
 * @summary Returns a list of all available subscrition tiers.
 */
export const useGetSubscriptionTier = <TData = Awaited<ReturnType<typeof getSubscriptionTier>>, TError = unknown>(
  params?: GetSubscriptionTierParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTier>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionTierQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the add-on object with the given id.
 */
export const getSubscriptionTierAddOnAddOnId = (
  addOnId: string,
  options?: SecondParameter<typeof getSubscriptionTierAddOnAddOnIdMutator>,
  signal?: AbortSignal
) => {
  return getSubscriptionTierAddOnAddOnIdMutator<AddOnSchema>(
    { url: `/subscription-tier/add-on/${encodeURIComponent(String(addOnId))}`, method: 'GET', signal },
    options
  );
};

export const getGetSubscriptionTierAddOnAddOnIdQueryKey = (addOnId: string) => {
  return [`/subscription-tier/add-on/${addOnId}`] as const;
};

export const getGetSubscriptionTierAddOnAddOnIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>,
  TError = unknown
>(
  addOnId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierAddOnAddOnIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionTierAddOnAddOnIdQueryKey(addOnId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>> = ({ signal }) =>
    getSubscriptionTierAddOnAddOnId(addOnId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!addOnId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionTierAddOnAddOnIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>
>;
export type GetSubscriptionTierAddOnAddOnIdQueryError = unknown;

/**
 * @summary Returns the add-on object with the given id.
 */
export const useGetSubscriptionTierAddOnAddOnId = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>,
  TError = unknown
>(
  addOnId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierAddOnAddOnId>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierAddOnAddOnIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionTierAddOnAddOnIdQueryOptions(addOnId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the subscription tier object with the given id.
 */
export const getSubscriptionTierSubscriptionTierId = (
  subscriptionTierId: string,
  options?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdMutator>,
  signal?: AbortSignal
) => {
  return getSubscriptionTierSubscriptionTierIdMutator<SubscriptionTierSchema>(
    { url: `/subscription-tier/${encodeURIComponent(String(subscriptionTierId))}`, method: 'GET', signal },
    options
  );
};

export const getGetSubscriptionTierSubscriptionTierIdQueryKey = (subscriptionTierId: string) => {
  return [`/subscription-tier/${subscriptionTierId}`] as const;
};

export const getGetSubscriptionTierSubscriptionTierIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>,
  TError = unknown
>(
  subscriptionTierId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSubscriptionTierSubscriptionTierIdQueryKey(subscriptionTierId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>> = ({ signal }) =>
    getSubscriptionTierSubscriptionTierId(subscriptionTierId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!subscriptionTierId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionTierSubscriptionTierIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>
>;
export type GetSubscriptionTierSubscriptionTierIdQueryError = unknown;

/**
 * @summary Returns the subscription tier object with the given id.
 */
export const useGetSubscriptionTierSubscriptionTierId = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>,
  TError = unknown
>(
  subscriptionTierId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierId>>, TError, TData>>;
    request?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionTierSubscriptionTierIdQueryOptions(subscriptionTierId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns a list of all available add-on options for specific subscription tier.
 */
export const getSubscriptionTierSubscriptionTierIdAddOn = (
  subscriptionTierId: string,
  params?: GetSubscriptionTierSubscriptionTierIdAddOnParams,
  options?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdAddOnMutator>,
  signal?: AbortSignal
) => {
  return getSubscriptionTierSubscriptionTierIdAddOnMutator<AddOnsSchema>(
    {
      url: `/subscription-tier/${encodeURIComponent(String(subscriptionTierId))}/add-on`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetSubscriptionTierSubscriptionTierIdAddOnQueryKey = (
  subscriptionTierId: string,
  params?: GetSubscriptionTierSubscriptionTierIdAddOnParams
) => {
  return [`/subscription-tier/${subscriptionTierId}/add-on`, ...(params ? [params] : [])] as const;
};

export const getGetSubscriptionTierSubscriptionTierIdAddOnQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>,
  TError = unknown
>(
  subscriptionTierId: string,
  params?: GetSubscriptionTierSubscriptionTierIdAddOnParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>, TError, TData>
    >;
    request?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdAddOnMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSubscriptionTierSubscriptionTierIdAddOnQueryKey(subscriptionTierId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>> = ({ signal }) =>
    getSubscriptionTierSubscriptionTierIdAddOn(subscriptionTierId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!subscriptionTierId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSubscriptionTierSubscriptionTierIdAddOnQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>
>;
export type GetSubscriptionTierSubscriptionTierIdAddOnQueryError = unknown;

/**
 * @summary Returns a list of all available add-on options for specific subscription tier.
 */
export const useGetSubscriptionTierSubscriptionTierIdAddOn = <
  TData = Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>,
  TError = unknown
>(
  subscriptionTierId: string,
  params?: GetSubscriptionTierSubscriptionTierIdAddOnParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getSubscriptionTierSubscriptionTierIdAddOn>>, TError, TData>
    >;
    request?: SecondParameter<typeof getSubscriptionTierSubscriptionTierIdAddOnMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubscriptionTierSubscriptionTierIdAddOnQueryOptions(subscriptionTierId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
