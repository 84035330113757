import { ReportNestedSchema } from 'lib/model';
import Divider from 'modules/common/Divider';
import { memo, useCallback } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import ReportTypeElement from './ReportTypeElement';

interface ReportCardProps {
  report?: ReportNestedSchema;
}

const ReportCard = memo(function ReportCard({ report }: ReportCardProps) {
  const changeable = report && !report.published_timestamp && !report.archived_timestamp;

  const handleExport = useCallback(function handleExport() {
    return;
  }, []);

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <header className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-1.5">
            <span className="text-sm font-medium">{report && snakeCaseToWords(report.report_type!)}</span>
          </div>
          <div className="flex items-center gap-4">
            {report && !changeable && (
              <button className="flex items-center gap-2" onClick={handleExport}>
                <span className="text-xs font-medium text-brand-800">Export</span>
              </button>
            )}
          </div>
        </div>
        <Divider />
      </header>
      <ReportTypeElement report={report} />
    </div>
  );
});

export default ReportCard;
