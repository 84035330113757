import useAuth from 'contexts/auth/authContext';
import { memo } from 'react';

function DashboardHeader() {
  const { user } = useAuth();

  return (
    <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 pb-8 pt-10">
      <span className="text-lg font-semibold text-gray-950">
        Welcome {user.first_name} {user.last_name}
      </span>
    </header>
  );
}

export default memo(DashboardHeader);
