import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import { ProjectUserSchema, ProjectUsersSchema, UserAccountDeactivateSchemaReportsAction } from 'lib/model';
import { usePutUserDeactivate } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import PasswordInput from 'modules/common/Form/PasswordInput';
import SettingsUserSelectInput from 'modules/common/Form/SettingsUserSelectInput';
import UserCard from 'modules/common/UserCard';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { getUserInitials } from 'utils/helpers';
import { DialogProps } from 'utils/interfaces';

interface DeleteUserDialogProps extends DialogProps {
  hasReports: boolean;
}

interface DeleteUserFormValues {
  reportsAction: UserAccountDeactivateSchemaReportsAction;
  newReportOwner?: ProjectUserSchema | null;
  newOwner?: ProjectUserSchema | null;
  password?: string;
}

function DeleteUserDialog({ hasReports, open, setOpen }: DeleteUserDialogProps) {
  const { user, logout, isOwner, isViewer } = useAuth();
  const queryClient = useQueryClient();
  const projectUsers = !isViewer ? queryClient.getQueryData<ProjectUsersSchema>(['projectUsers'])!.objects! : [];

  const { control, register, handleSubmit, watch, reset } = useForm<DeleteUserFormValues>({
    defaultValues: {
      reportsAction: UserAccountDeactivateSchemaReportsAction.transfer,
      newReportOwner: null,
      newOwner: null
    },
    shouldUnregister: true
  });
  const transferSelected = watch('reportsAction') === UserAccountDeactivateSchemaReportsAction.transfer;
  const newReportOwner = watch('newReportOwner');
  const newOwner = watch('newOwner');
  const [canContinue, setCanContinue] = useState(true);

  const [deleteInitiated, setDeleteInitiated] = useState(false);
  const { mutate: deactivateUser } = usePutUserDeactivate();

  function handleCancel() {
    setOpen(false);
    setDeleteInitiated(false);
    reset();
  }

  function initiateDelete(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    setDeleteInitiated(true);
  }

  function handleDelete(data: DeleteUserFormValues) {
    deactivateUser(
      {
        data: {
          new_owner_id: data.newOwner?.user_account_id,
          new_reports_owner_id: isOwner
            ? data.reportsAction === 'transfer'
              ? data.newOwner?.user_account_id
              : undefined
            : data.newReportOwner?.user_account_id,
          reports_action: hasReports ? data.reportsAction : undefined
        }
      },
      {
        onSuccess: () => {
          toast.success('Account deleted. You are about to be logged out.', {
            autoClose: 1000,
            onClose: () => logout()
          });
        }
      }
    );
  }

  useEffect(() => {
    setCanContinue(
      (isOwner && Boolean(newOwner)) ||
        (transferSelected && !isOwner && Boolean(newReportOwner)) ||
        (!isOwner && !transferSelected) ||
        !hasReports
    );
  }, [newOwner, newReportOwner, isOwner, transferSelected, hasReports]);

  return (
    <DialogBase title="Delete account" open={open} onCancel={handleCancel}>
      <DialogContent>
        <>
          <div className={twMerge('flex flex-col gap-10', deleteInitiated && 'hidden')}>
            <div className="flex flex-col gap-6">
              <span className="text-md font-medium">
                You are about to delete <b>{user.email}</b> account.<br />
                Are you sure you want to proceed?
              </span>
              <div className="rounded-md bg-gray-50 px-3 py-2">
                <UserCard
                  name={user.first_name + ' ' + user.last_name}
                  email={user.email!}
                  initials={getUserInitials(user)}
                  image={user.user_image_path}
                />
              </div>
            </div>
            <form
              id="transferReportsForm"
              onSubmit={handleSubmit(handleDelete)}
              className={twMerge('flex flex-col gap-10', !hasReports && 'hidden')}
            >
              {isOwner && (
                <div className="flex flex-col gap-6">
                  <span className="text-md font-semibold">Transfer Organization ownership to:</span>

                  <SettingsUserSelectInput
                    label="Choose new organization owner"
                    name="newOwner"
                    control={control}
                    options={projectUsers.filter((u) => u.user_account_id && u.user_account_id !== user!.id!)}
                  />

                  <PasswordInput label="Enter your password" registerReturn={register('password')} />
                </div>
              )}

              <div className="flex flex-col gap-6">
                <span className="text-md font-semibold">My reports</span>

                <div className="radio flex items-center">
                  <input id="transfer" type="radio" {...register('reportsAction')} value="transfer" />
                  <label htmlFor="transfer" className="radio-label">
                    {isOwner ? 'Transfer my reports to new owner' : 'Transfer all my reports'}
                  </label>
                </div>

                {transferSelected && !isOwner && (
                  <SettingsUserSelectInput
                    label="Choose new owner of your reports"
                    name="newReportOwner"
                    control={control}
                    options={projectUsers.filter((u) => u.user_account_id !== user!.id!)}
                  />
                )}

                <div className="radio flex items-center">
                  <input id="delete" type="radio" {...register('reportsAction')} value="delete" />
                  <label htmlFor="delete" className="radio-label">
                    Delete all my reports
                  </label>
                </div>
              </div>
            </form>
          </div>
          <div className={twMerge('hidden flex-col gap-6', deleteInitiated && 'flex')}>
            <span className="text-md font-medium">
              You are about to delete your account <b>{user.email}</b>.
            </span>
            {!isViewer &&
              hasReports &&
              (transferSelected ? (
                <span className="text-md font-medium">
                  All your reports will be transferred to <b>{isOwner ? newOwner?.email : newReportOwner?.email}</b>.
                </span>
              ) : (
                <span className="text-md font-medium">
                  All your reports will be <b>deleted</b>.
                </span>
              ))}
            <span className="text-md font-medium">Are you sure you want to proceed?</span>
          </div>
        </>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        {deleteInitiated ? (
          <Button variant="red" size="lg" isSubmitButton form="transferReportsForm">
            Delete Account
          </Button>
        ) : (
          <Button variant="red" size="lg" onClick={initiateDelete} disabled={!canContinue}>
            Delete Account
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(DeleteUserDialog);
