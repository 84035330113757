/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Portal, Transition } from '@headlessui/react';
import Check from 'assets/check.svg?react';
import ChevronDown from 'assets/chevron-down.svg?react';
import { ProjectUserSchema } from 'lib/model';
import { Fragment, memo, useCallback } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { getUserInitials } from 'utils/helpers';
import UserCard from '../UserCard';
import ErrorMessage from './ErrorMessage';

interface SettingsUserSelectInputProps<T extends FieldValues> extends UseControllerProps<T> {
  options: ProjectUserSchema[];
  label: string;
  error?: Merge<FieldError, FieldErrorsImpl<ProjectUserSchema>>;
}

function SettingsUserSelectInput<T extends FieldValues>({
  options,
  label,
  error,
  ...formProps
}: SettingsUserSelectInputProps<T>) {
  const {
    field: { onChange: formOnChange, value: formValue }
  } = useController<T>({ ...formProps, defaultValue: options[0] as PathValue<T, Path<T>> });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
          });
        }
      } as SizeOptions)
    ]
  });

  const handleChange = useCallback(
    function handleChange(value: ProjectUserSchema | null) {
      return formOnChange(value);
    },
    [formOnChange]
  );

  return (
    <div className="flex w-full flex-col gap-2">
      <Listbox value={formValue as ProjectUserSchema | null} onChange={handleChange} by="user_account_id">
        {({ open, value }) => (
          <>
            <div className="relative">
              <Label
                className={twMerge(
                  'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                  value && 'hidden'
                )}
              >
                {label}
              </Label>
              <ListboxButton
                ref={refs.setReference}
                className="h-14 w-full cursor-pointer rounded-md border border-gray-300 bg-white px-3 text-left text-md focus:outline-none focus:ring-0 ui-disabled:bg-gray-50"
              >
                {formValue && (
                  <UserCard
                    name={formValue.first_name + ' ' + formValue.last_name}
                    initials={getUserInitials(formValue)}
                    email={formValue.email}
                    image={formValue.user_image_path}
                  />
                )}
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronDown width={16} height={16} className="absolute right-2 fill-gray-950" />
                </span>
              </ListboxButton>
              {open && (
                <Portal>
                  <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <ListboxOptions className="max-h-60 overflow-y-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none">
                        {options.map((option) => (
                          <ListboxOption
                            key={option.user_account_id}
                            className="cursor-pointer select-none px-2 py-1 ui-active:bg-gray-50"
                            value={option}
                          >
                            <div className="flex items-center justify-between gap-3 rounded-md px-1.5 py-2 ui-selected:bg-brand-50">
                              <UserCard
                                name={option.first_name + ' ' + option.last_name}
                                email={option.email!}
                                initials={getUserInitials(option)}
                                image={option.user_image_path}
                              />
                              <Check width={20} height={20} className="hidden fill-brand-800 ui-selected:inline" />
                            </div>
                          </ListboxOption>
                        ))}
                      </ListboxOptions>
                    </Transition>
                  </div>
                </Portal>
              )}
            </div>
          </>
        )}
      </Listbox>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(SettingsUserSelectInput) as typeof SettingsUserSelectInput;
