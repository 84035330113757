import { TooltipInPortalProps } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { memo, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';

interface ChartTooltipProps extends PropsWithChildren {
  left?: number;
  top?: number;
  open: boolean;
  Tooltip: React.FC<TooltipInPortalProps>;
  tooltipKey?: string;
}

function ChartTooltip({ Tooltip, top, left, open, tooltipKey, children }: ChartTooltipProps) {
  return (
    <Tooltip
      unstyled
      key={tooltipKey}
      className="pointer-events-none absolute z-[9999] -translate-x-1/2 -translate-y-full"
      left={left}
      top={top}
    >
      <div
        className={twJoin(
          'flex w-full flex-col items-center whitespace-nowrap rounded-md bg-gray-950 px-3 py-1 text-xs font-medium text-white transition-transform',
          open ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
        )}
      >
        {children}
        <div className="absolute bottom-0 left-1/2 size-2.5 -translate-x-1/2 translate-y-1/2 rotate-45 rounded-br-sm bg-gray-950" />
      </div>
    </Tooltip>
  );
}

export default memo(ChartTooltip);
