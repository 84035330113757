import Loader from 'assets/loading.svg?react';
import Plus from 'assets/plus.svg?react';
import { ReportElementTemplatesSchema, ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useState } from 'react';
import AddElementDialog from './dialogs/AddElementDialog';

interface AddElementButtonProps {
  report: ReportNestedSchema;
  elementTemplates?: ReportElementTemplatesSchema;
  isPendingElementTemplates: boolean;
}

function AddElementButton({ report, elementTemplates, isPendingElementTemplates }: AddElementButtonProps) {
  const [showAddElementDialog, setShowAddElementDialog] = useState<boolean>(false);

  const handleShowAddElementDialog = useCallback(function handleShowAddElementDialog() {
    setShowAddElementDialog(true);
  }, []);

  return (
    <>
      <button
        className="flex w-full items-center justify-center rounded-xl border border-dashed border-gray-400 px-2.5 py-6 disabled:cursor-not-allowed"
        onClick={handleShowAddElementDialog}
        disabled={isPendingElementTemplates}
      >
        {isPendingElementTemplates ? (
          <div className="flex items-center gap-2">
            <Loader className="size-7 animate-spin fill-brand-800" />
            <span className="text-sm font-medium text-brand-800">Loading elements...</span>
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <Plus className="size-7 fill-brand-800" />
            <span className="text-sm font-medium text-brand-800">Add element</span>
          </div>
        )}
      </button>
      {elementTemplates && showAddElementDialog && (
        <AddElementDialog
          report={report}
          elementTemplates={elementTemplates}
          open={showAddElementDialog}
          setOpen={setShowAddElementDialog}
        />
      )}
    </>
  );
}

export default memo(AddElementButton);
