import CustomerSupport from 'assets/customer-support.svg?react';
import EmojiSad from 'assets/emoji-sad.svg?react';
import Logo from 'assets/logo.svg?react';
import { AxiosError } from 'axios';
import useIsMobile from 'hooks/useIsMobile';
import Button from 'modules/common/Button';
import Divider from 'modules/common/Divider';
import { memo } from 'react';
import { useRouteError } from 'react-router';
import { isRouteErrorResponse, Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const ErrorLayout = memo(function ErrorLayout() {
  let title;
  let content;
  let buttons: 'both' | 'dashboard' | 'support' | 'none';
  const error = useRouteError();
  const isMobile = useIsMobile();

  if (
    (isRouteErrorResponse(error) && error.status === 404) ||
    (error instanceof AxiosError && error.response?.status === 404)
  ) {
    title = 'Page Not Found';
    content =
      'Uh-oh! We can’t seem to find the page you’re looking for. It might have been moved or deleted. Check the URL or return to your Dashboard.';
    buttons = 'dashboard';
  } else if (
    (error instanceof Error && error.message === '403 Forbidden') ||
    (error instanceof AxiosError && error.response?.status === 403)
  ) {
    title = 'Access Restricted';
    content =
      'Sorry, but you don’t have the required permissions to view this page. If you think this is a mistake, please contact support or your admin.';
    buttons = 'both';
  } else if (error instanceof AxiosError && error.response?.status === 500) {
    title = 'Internal Server Error';
    content = 'Our apologies! It seems we encountered an unexpected error. Our team is on it. Please try again later.';
    buttons = 'both';
  } else if (error instanceof AxiosError && error.response?.status === 503) {
    title = 'Service Unavailable';
    content =
      'Our apologies! We’re currently down for maintenance or experiencing high traffic. Please check back later. Thanks for your patience!';
    buttons = 'none';
  } else if (error instanceof AxiosError && error.response?.status === 401) {
    return;
  } else {
    title = 'Unexpected error';
    content = 'An unexpected error occurred. Please contact customer support.';
    buttons = 'support';
  }

  return (
    <div className="brand-gradient flex min-h-screen w-screen flex-col items-center">
      <div
        className={twMerge('sticky top-0 flex h-[72px] w-full items-center bg-white px-8', isMobile && 'bg-gray-950')}
      >
        <Logo width={86} height={28} className={isMobile ? 'fill-white' : 'fill-gray-900'} />
      </div>
      <div className={twMerge('flex h-full w-full flex-col items-center justify-center pt-10', isMobile && 'pt-0')}>
        <div className="flex w-[612px] flex-col items-center gap-10 rounded-3xl bg-white p-16">
          <span className="text-h6 font-semibold">{title}</span>
          <EmojiSad className="my-10 size-16 fill-red-500" />
          <p className="text-center text-md font-medium">{content}</p>
          <div className="flex w-full flex-col gap-8">
            <Divider />
            <div className="flex items-center justify-between gap-6">
              {buttons !== 'none' &&
                (buttons === 'both' ? (
                  <>
                    <Link to="customer-support" className="w-full">
                      <Button variant="secondary" size="xl">
                        <CustomerSupport className="size-5 fill-gray-700" />
                        Customer support
                      </Button>
                    </Link>
                    <Link to="/" className="w-full">
                      <Button variant="primary" size="xl">
                        Go to Dashboard
                      </Button>
                    </Link>
                  </>
                ) : buttons === 'dashboard' ? (
                  <Link to="/" className="w-full">
                    <Button variant="primary" size="xl">
                      Go to Dashboard
                    </Button>
                  </Link>
                ) : (
                  <Link to="customer-support" className="w-full">
                    <Button variant="secondary" size="xl">
                      <CustomerSupport className="size-5 fill-gray-700" />
                      Customer support
                    </Button>
                  </Link>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ErrorLayout;
