import Database from 'assets/database.svg?react';
import { DatasourceConfigurationSchema } from 'lib/model';
import ReportCardSkeleton from 'modules/reports/reports/components/reportCard/ReportCardSkeleton';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import DashboardActiveDataSourceCard from './DashboardActiveDataSourceCard';

interface DashboardActiveDataSourcesProps {
  datasourceConfigurations?: DatasourceConfigurationSchema[];
  isDataSourceConfigurationsPending: boolean;
}

function DashboardActiveDataSources({
  datasourceConfigurations,
  isDataSourceConfigurationsPending
}: DashboardActiveDataSourcesProps) {
  let dataSourceCards;
  if (isDataSourceConfigurationsPending) {
    dataSourceCards = (
      <>
        <ReportCardSkeleton />
        <ReportCardSkeleton />
        <ReportCardSkeleton />
        <ReportCardSkeleton />
      </>
    );
  } else if (datasourceConfigurations && datasourceConfigurations.length > 0) {
    dataSourceCards = datasourceConfigurations.map((dsc) => (
      <DashboardActiveDataSourceCard dataSourceConfiguration={dsc} key={dsc.id} />
    ));
  } else {
    dataSourceCards = (
      <div className="flex items-center gap-6 rounded-xl bg-white p-10">
        <Database className="size-16 fill-gray-300" />
        <span className="text-md font-medium">There are currently no connected data sources</span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <Database className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Active Data Sources</span>
        </div>
        <div className="flex gap-4">
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/data-sources/connected'}>
            My Data Sources
          </NavLink>
          <NavLink className={'text-xs font-semibold text-brand-800'} to={'/data-sources/all'}>
            See All
          </NavLink>
        </div>
      </div>
      <div className="grid grid-cols-cards-smaller gap-6">{dataSourceCards}</div>
    </div>
  );
}

export default memo(DashboardActiveDataSources);
