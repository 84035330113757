import { memo } from 'react';
import DataSourcesCardBase from './DataSourcesCardBase';

const DataSourceCardSkeleton = memo(function DataSourceCardSkeleton() {
  return (
    <DataSourcesCardBase>
      <div className="grid h-8 w-full grid-cols-4 gap-4">
        <div className="animate-pulse rounded-md bg-gray-50" />
        <div className="bg-white" />
        <div className="animate-pulse rounded-md bg-gray-50" />
        <div className="animate-pulse rounded-md bg-gray-50" />
      </div>
      <div className="h-1 w-full animate-pulse bg-gray-50" />
      <div className="h-16 animate-pulse rounded-md bg-gray-50" />
      <div className="h-12 animate-pulse rounded-md bg-gray-50" />
      <div className="grid h-8 w-full grid-cols-4 gap-4">
        <div className="animate-pulse rounded-md bg-gray-50" />
        <div className="bg-white" />
        <div className="bg-white" />
        <div className="animate-pulse rounded-md bg-gray-50" />
      </div>
    </DataSourcesCardBase>
  );
});

export default DataSourceCardSkeleton;
