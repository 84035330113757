import Database from 'assets/database.svg?react';
import Link from 'assets/link.svg?react';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import { DatasourceSchema } from 'lib/model';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../common/Button';
import DataSourceCard from './DataSourceCard';

interface DataSourcesCombinationProps {
  dataSources?: DatasourceSchema[];
}

const DataSourcesCombination = memo(function DataSourcesCombination({ dataSources }: DataSourcesCombinationProps) {
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();
  const navigate = useNavigate();

  const addDataSourcesToSelected = useCallback(
    function addDataSourcesToSelected() {
      if (dataSources) {
        const availableDataSources = dataSources.filter((ds) => {
          return !selectedDataSources.some((sds) => sds.dataSource.id === ds.id);
        });

        setSelectedDataSources((prev) => {
          return prev.concat(
            availableDataSources.map((ads, index) => ({
              dataSource: ads,
              state: {
                expanded: index === 0 && prev.length === 0,
                authenticated: false,
                userData: '',
                configured: false
              }
            }))
          );
        });

        navigate('/data-sources/activation');
      }
    },
    [navigate, dataSources, selectedDataSources, setSelectedDataSources]
  );

  if (!dataSources || dataSources.length === 0) {
    return (
      <div className="mx-10 flex items-center justify-center rounded-xl bg-gray-50 py-16">
        <div className="flex flex-col items-center gap-3">
          <Database className="size-16 fill-gray-300" />
          <span className="text-sm font-medium text-gray-500">No data sources match the selected filters.</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 rounded-3xl bg-white px-10 py-6">
      <div className="flex items-center justify-between">
        <span className="text-sm font-semibold">Data Source combination</span>
        <Button variant="secondary" isFullWidth={false} size="md" onClick={addDataSourcesToSelected}>
          <Link width={18} height={18} />
          <span>Connect Data Sources</span>
        </Button>
      </div>
      <div className="grid w-full grid-cols-2 gap-6">
        {dataSources!.map((dataSource) => (
          <DataSourceCard border={true} key={dataSource.id} dataSource={dataSource} />
        ))}
      </div>
    </div>
  );
});

export default DataSourcesCombination;
