import ReportIcon from 'modules/reports/reports/components/ReportIcon';
import SharedUsersIcons from 'modules/reports/reports/components/SharedUsersIcons';
import { memo } from 'react';
import { EventCardProps } from '../EventCard';

function ReportSharedEvent({ event }: EventCardProps) {
  return (
    <div className="flex flex-col gap-1 rounded-md bg-gray-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex justify-between gap-4">
        <div className="flex min-w-0 items-center gap-2">
          <ReportIcon reportType={event.json_content!.report_type} size="sm" />
          <span className="truncate text-xs font-semibold" title={event.json_content!.report_name}>
            {event.json_content!.report_name}
          </span>
        </div>
        <SharedUsersIcons users={event.json_content!.shared_with_users.users ?? []} />
      </div>
    </div>
  );
}

export default memo(ReportSharedEvent);
