import { AxisBottom, AxisLeft, SharedAxisProps, TickFormatter, TickLabelProps, TickRendererProps } from '@visx/axis';
import { AnyD3Scale, ScaleInput } from '@visx/scale';
import { ScaleBand, ScaleLinear, ScaleTime } from '@visx/vendor/d3-scale';
import { format, timeFormat } from 'd3';
import { memo } from 'react';

interface ChartAxesProps {
  chartWidth: number;
  chartHeight: number;
  xScale: ScaleLinear<number, number> | ScaleBand<string> | ScaleTime<number, number>;
  xUnit: string;
  yScale: ScaleLinear<number, number>;
  yUnit: string;
  xType?: 'number' | 'date' | 'category';
  xTimePrecision?: 'day' | 'week' | 'month' | 'year';
  bottomTicks?: number[] | Date[] | string[];
  rotateBottomTicks?: boolean;
  yNumberOfTicks?: number;
}

const ChartAxes = memo(function ChartAxes({
  chartHeight,
  xScale,
  xUnit,
  yScale,
  yUnit,
  xType = 'number',
  xTimePrecision = 'month',
  bottomTicks,
  rotateBottomTicks = false,
  yNumberOfTicks = 6
}: ChartAxesProps) {
  let bottomFormat: TickFormatter<unknown> | undefined;
  let bottomTicksComponent: ((tickRendererProps: TickRendererProps) => React.ReactNode) | undefined;
  if (xType === 'number') {
    xScale = xScale as ScaleLinear<number, number>;
    if (xScale.domain()[1] > 9999) {
      bottomFormat = format('.2~s');
    } else {
      bottomFormat = format('.2~r');
    }
  } else if (xType === 'date') {
    xScale = xScale as ScaleTime<number, number>;
    if (xTimePrecision === 'day') {
      bottomFormat = timeFormat('%a#%x');
      bottomTicksComponent = (tickRendererProps) => {
        const { formattedValue: value, lineHeight, ...props } = tickRendererProps!;
        const valueRows = value!.split('#');
        return (
          <text {...props}>
            {valueRows.map((text, i) => (
              <tspan key={`${value}-tick-${i}`} x={props.x} dy={i === 0 ? 0 : lineHeight} className="uppercase">
                {text}
              </tspan>
            ))}
          </text>
        );
      };
    } else if (xTimePrecision === 'week') {
      bottomFormat = timeFormat('%x');
    } else if (xTimePrecision === 'month') {
      bottomFormat = timeFormat('%b %Y');
    } else if (xTimePrecision === 'year') {
      bottomFormat = timeFormat('%Y');
    }
  }

  let leftFormat: TickFormatter<unknown> | undefined;
  if (yScale.domain()[1] > 9999) {
    leftFormat = format('.2~s');
  } else {
    leftFormat = format('.2~r');
  }

  return (
    <>
      <AxisBottom
        // eslint-disable-next-line no-constant-condition
        {...(rotateBottomTicks ? verticalAxisStyleProps(xScale) : axisStyleProps)}
        tickFormat={bottomFormat}
        top={chartHeight}
        scale={xScale}
        label={xUnit}
        tickComponent={bottomTicksComponent}
        numTicks={6}
        tickValues={bottomTicks}
        labelProps={{
          ...{
            x: 0,
            y: xTimePrecision === 'day' ? 60 : 40
          },
          ...unitLabelStyle
        }}
      />
      <AxisLeft
        {...axisStyleProps}
        tickFormat={leftFormat}
        scale={yScale}
        label={yUnit}
        numTicks={yNumberOfTicks}
        labelProps={{
          ...{
            x: -chartHeight,
            y: -40
          },
          ...unitLabelStyle
        }}
      />
    </>
  );
});

const unitLabelStyle = {
  fill: '#6B7280',
  fontSize: 10,
  lineHeight: 16,
  fontWeight: 600,
  paintOrder: 'stroke',
  fontFamily: 'Inter',
  textAnchor: 'start'
} as const;

const tickLabelStyle: TickLabelProps<ScaleInput<AnyD3Scale>> = {
  fill: '#6B7280',
  fontSize: 10,
  lineHeight: 20,
  fontWeight: 500,
  paintOrder: 'stroke',
  fontFamily: 'Inter'
} as const;

const axisStyleProps: Omit<SharedAxisProps<AnyD3Scale>, 'scale'> = {
  // hideZero: true,
  hideAxisLine: true,
  hideTicks: true,
  tickLabelProps: tickLabelStyle
} as const;

function verticalAxisStyleProps(xScale: ScaleLinear<number, number> | ScaleBand<string> | ScaleTime<number, number>) {
  return {
    // hideZero: true,
    hideAxisLine: true,
    hideTicks: true,
    tickLabelProps: (value) => ({
      ...tickLabelStyle,
      transform: `translate(-12, -4) rotate(18 ${xScale(value)} 0)`
    })
  } as Omit<SharedAxisProps<AnyD3Scale>, 'scale'>;
}

export default ChartAxes;
