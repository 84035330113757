import { ProjectRoleSchemaName } from 'lib/model';
import Divider from 'modules/common/Divider';
import NavigationBar from 'modules/common/NavigationBar';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { NavigationRoute } from 'utils/interfaces';

const routes: NavigationRoute[] = [
  {
    name: 'ACCOUNT',
    href: '/settings/account',
    isActive: (path: string) => path === '/settings/account',
    roleAccess: []
  },
  {
    name: 'ORGANIZATION',
    href: '/settings/organization',
    isActive: (path: string) => path === '/settings/organization',
    roleAccess: [ProjectRoleSchemaName.owner, ProjectRoleSchemaName.admin]
  },
  {
    name: 'USER MANAGEMENT',
    href: '/settings/user-management',
    isActive: (path: string) => path.startsWith('/settings/user-management'),
    roleAccess: [ProjectRoleSchemaName.owner, ProjectRoleSchemaName.admin]
  },
  {
    name: 'BI TOOL INTEGRATION',
    href: '/settings/bi-tool-integration',
    isActive: (path: string) => path.startsWith('/settings/bi-tool-integration'),
    roleAccess: [
      ProjectRoleSchemaName.owner,
      ProjectRoleSchemaName.admin,
      ProjectRoleSchemaName.editor,
      ProjectRoleSchemaName.head_scout,
      ProjectRoleSchemaName.scout
    ]
  },
  {
    name: 'BILLING',
    href: '/settings/billing',
    isActive: (path: string) => path.startsWith('/settings/billing'),
    roleAccess: [ProjectRoleSchemaName.owner, ProjectRoleSchemaName.admin]
  },
  {
    name: 'TIER PLANS',
    href: '/settings/tier-plans',
    isActive: (path: string) => path === '/settings/tier-plans',
    roleAccess: [ProjectRoleSchemaName.owner, ProjectRoleSchemaName.admin]
  }
];

function SettingsPage() {
  return (
    <div className="flex w-full flex-col gap-10 pt-10">
      <header className="text-lg font-semibold text-gray-950">Settings</header>
      <section className="rounded-xl bg-white">
        <NavigationBar routes={routes} />
        <Divider />
        <Outlet />
      </section>
    </div>
  );
}

export default memo(SettingsPage);
