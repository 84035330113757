import { ReportElementSchema } from 'lib/model';
import { getDefaultColor } from 'modules/reports/helpers';
import { memo, useMemo } from 'react';
import { LineupTeam } from '../../interfaces';
import LineupPitchFormation from '../LineupPitchFormation';
import LineupPitchGrid from '../LineupPitchGrid';
import { createTeamGrid } from './helpers';

interface MatchLineupPitchProps {
  element: ReportElementSchema;
}

const MatchLineupPitch = memo(function MatchLineupPitch({ element }: MatchLineupPitchProps) {
  const homeTeam = element.entity_data!.home_team as LineupTeam;
  homeTeam.team_color ??= getDefaultColor(0);
  const awayTeam = element.entity_data!.away_team as LineupTeam;
  awayTeam.team_color ??= getDefaultColor(1);

  const homeTeamGrid = useMemo(() => createTeamGrid(homeTeam), [homeTeam]);
  const awayTeamGrid = useMemo(() => createTeamGrid(awayTeam), [awayTeam]);

  return (
    <div className="flex w-full justify-center rounded-xl bg-gray-50">
      <div className="grid min-w-fit grid-cols-1 grid-rows-2 px-6 py-4">
        <LineupPitchFormation team={homeTeam} />
        <LineupPitchFormation team={awayTeam} />
      </div>
      <div className="relative grid aspect-football-field-vertical w-full max-w-screen-sm grid-cols-1 grid-rows-2 bg-football-field-vertical bg-contain bg-center bg-no-repeat p-football-field">
        <div
          className="grid grid-cols-1"
          style={{ gridTemplateRows: `repeat(${homeTeamGrid?.length ?? 0}, minmax(0, 1fr))` }}
        >
          <LineupPitchGrid grid={homeTeamGrid} team={homeTeam} widthBorder={470} />
        </div>
        <div
          className="grid grid-cols-1"
          style={{ gridTemplateRows: `repeat(${awayTeamGrid?.length ?? 0}, minmax(0, 1fr))` }}
        >
          <LineupPitchGrid grid={awayTeamGrid} team={awayTeam} widthBorder={470} reversed />
        </div>
      </div>
    </div>
  );
});

export default MatchLineupPitch;
