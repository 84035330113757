import useGetScrollBarWidth from 'hooks/useGetScrollBarWidth';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface DialogFooterProps {
  children?: ReactNode | undefined;
}

const DialogContent = memo(function DialogContent({ children }: DialogFooterProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [overflowing, setOverflowing] = useState(false);
  const scrollBarWidth = useGetScrollBarWidth();
  useEffect(() => {
    if (ref.current!.clientHeight < ref.current!.scrollHeight) {
      setOverflowing(true);
    }
  }, [setOverflowing]);

  return (
    <section
      ref={ref}
      className={twMerge(
        'grow-1 flex h-full flex-col gap-10 overflow-y-auto p-10',
        overflowing && 'pr-[calc(2.5rem-' + scrollBarWidth + 'px)]'
      )}
    >
      {children}
    </section>
  );
});

export default DialogContent;
