import { memo, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';

interface CardProps extends PropsWithChildren {
  disabled?: boolean;
  border?: boolean;
  onClick?: () => void;
}

const DataSourcesCardBase = memo(function Card({ disabled = false, border = false, onClick, children }: CardProps) {
  return (
    <div
      onClick={onClick}
      className={twJoin(
        'flex flex-col gap-6 rounded-xl p-6 transition-transform duration-200 hover:shadow-card',
        disabled ? 'bg-gray-50' : 'bg-white',
        border && 'border border-gray-300',
        onClick && 'cursor-pointer'
      )}
    >
      {children}
    </div>
  );
});

export default DataSourcesCardBase;
