import { createContext, useContext } from 'react';
import { AutocompleteOption, ValueOption } from 'utils/interfaces';

// TODO: see how we will do budget, current its integer

export interface DataSourceFilters {
  useAssistant: boolean;
  featured: boolean;
  excludedDataSources: ValueOption[];
  dataTables: ValueOption[];
  dataTypes: ValueOption[];
  pricing: ValueOption[];
  competitions: AutocompleteOption[];
  seasons: ValueOption[];
  verified: boolean;
  selectedDataSource: ValueOption | null;
  source?: 'assistant' | 'filters' | 'search';
}

export const defaultDataSourceFilters: DataSourceFilters = {
  dataTypes: [],
  pricing: [],
  competitions: [],
  seasons: [],
  verified: false,
  useAssistant: false,
  dataTables: [],
  excludedDataSources: [],
  featured: false,
  selectedDataSource: null
};

export interface DataSourcesFiltersContext {
  dataSourcesFilters: DataSourceFilters;
  setDataSourcesFilters: React.Dispatch<React.SetStateAction<DataSourceFilters>>;
}

export const DataSourcesFiltersContext = createContext<DataSourcesFiltersContext>(
  {} as unknown as DataSourcesFiltersContext
);

export default function useDataSourcesFilters() {
  const context = useContext(DataSourcesFiltersContext);

  if (context === undefined) {
    throw new Error('DataSourcesFiltersContext was used outside of DataSourcesFiltersContext');
  }

  return context;
}
