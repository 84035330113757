import JerseyFilledOutline from 'assets/jersey-filled-outline.svg?react';
import JerseyFilled from 'assets/jersey-filled.svg?react';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';

interface JerseyProps {
  color: string;
  shirt_number?: number | string;
  size?: 'sm' | 'md' | 'lg';
  shadow?: boolean;
  outline?: boolean;
}

const Jersey = memo(function Jersey({
  color,
  shirt_number,
  shadow = false,
  size = 'sm',
  outline = false
}: JerseyProps) {
  let svgSize = 24;
  if (size === 'md') {
    svgSize = 32;
  } else if (size === 'lg') svgSize = 44;

  return (
    <div className={twJoin('relative flex shrink-0 items-center justify-center', shadow && 'drop-shadow-card')}>
      {outline ? (
        <JerseyFilledOutline width={svgSize} height={svgSize} className="fill-white stroke-gray-500" />
      ) : (
        <JerseyFilled width={svgSize} height={svgSize} style={{ fill: color }} />
      )}
      {size !== 'sm' && (
        <span
          className={twJoin(
            'absolute left-1/2 top-1/2 z-[2] -translate-x-1/2 -translate-y-1/2 transform text-tiny font-bold',
            isLightColor(color) ? 'text-black' : 'text-white'
          )}
        >
          {shirt_number ?? '-'}
        </span>
      )}
    </div>
  );
});

export default Jersey;
