enum SyncStatusEnum {
  FAILED = 'FAILED',
  SUCCESSFUL = 'SYNCED',
  IN_PROGRESS = 'SYNC IN PROGRESS',
  INACTIVE = 'INACTIVE',
  DEPLOYING = 'DEPLOYING',
  UNKNOWN = 'UNKNOWN'
}

export default SyncStatusEnum;
