/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getDatasourceDatasourceIdCompetitionsMutator,
  default as getDatasourceDatasourceIdMutator,
  default as getDatasourceMutator,
  default as postDatasourceDatasourceIdCheckCredentialsMutator
} from '../../api/axios';
import type {
  DatasourceCheckSchema,
  DatasourceCompetitionsSchema,
  DatasourceCredentialSchema,
  DatasourceSchema,
  DatasourcesSchema,
  GetDatasourceParams,
  PostDatasourceDatasourceIdCheckCredentialsParams
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all available datasources.
 */
export const getDatasource = (
  params?: GetDatasourceParams,
  options?: SecondParameter<typeof getDatasourceMutator>,
  signal?: AbortSignal
) => {
  return getDatasourceMutator<DatasourcesSchema>({ url: `/datasource`, method: 'GET', params, signal }, options);
};

export const getGetDatasourceQueryKey = (params?: GetDatasourceParams) => {
  return [`/datasource`, ...(params ? [params] : [])] as const;
};

export const getGetDatasourceQueryOptions = <TData = Awaited<ReturnType<typeof getDatasource>>, TError = unknown>(
  params?: GetDatasourceParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasource>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDatasourceQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDatasource>>> = ({ signal }) =>
    getDatasource(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDatasource>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDatasourceQueryResult = NonNullable<Awaited<ReturnType<typeof getDatasource>>>;
export type GetDatasourceQueryError = unknown;

/**
 * @summary Returns a list of all available datasources.
 */
export const useGetDatasource = <TData = Awaited<ReturnType<typeof getDatasource>>, TError = unknown>(
  params?: GetDatasourceParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasource>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDatasourceQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns a datasource object with the given id.
 */
export const getDatasourceDatasourceId = (
  datasourceId: string,
  options?: SecondParameter<typeof getDatasourceDatasourceIdMutator>,
  signal?: AbortSignal
) => {
  return getDatasourceDatasourceIdMutator<DatasourceSchema>(
    { url: `/datasource/${encodeURIComponent(String(datasourceId))}`, method: 'GET', signal },
    options
  );
};

export const getGetDatasourceDatasourceIdQueryKey = (datasourceId: string) => {
  return [`/datasource/${datasourceId}`] as const;
};

export const getGetDatasourceDatasourceIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getDatasourceDatasourceId>>,
  TError = unknown
>(
  datasourceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasourceDatasourceId>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceDatasourceIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDatasourceDatasourceIdQueryKey(datasourceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDatasourceDatasourceId>>> = ({ signal }) =>
    getDatasourceDatasourceId(datasourceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!datasourceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDatasourceDatasourceId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDatasourceDatasourceIdQueryResult = NonNullable<Awaited<ReturnType<typeof getDatasourceDatasourceId>>>;
export type GetDatasourceDatasourceIdQueryError = unknown;

/**
 * @summary Returns a datasource object with the given id.
 */
export const useGetDatasourceDatasourceId = <
  TData = Awaited<ReturnType<typeof getDatasourceDatasourceId>>,
  TError = unknown
>(
  datasourceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasourceDatasourceId>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceDatasourceIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDatasourceDatasourceIdQueryOptions(datasourceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Checks if the given credentials work for the specified datasource.
 */
export const postDatasourceDatasourceIdCheckCredentials = (
  datasourceId: string,
  datasourceCredentialSchema: DatasourceCredentialSchema,
  params?: PostDatasourceDatasourceIdCheckCredentialsParams,
  options?: SecondParameter<typeof postDatasourceDatasourceIdCheckCredentialsMutator>
) => {
  return postDatasourceDatasourceIdCheckCredentialsMutator<DatasourceCheckSchema>(
    {
      url: `/datasource/${encodeURIComponent(String(datasourceId))}/check-credentials`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceCredentialSchema,
      params
    },
    options
  );
};

export const getPostDatasourceDatasourceIdCheckCredentialsMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>,
    TError,
    {
      datasourceId: string;
      data: DatasourceCredentialSchema;
      params?: PostDatasourceDatasourceIdCheckCredentialsParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof postDatasourceDatasourceIdCheckCredentialsMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>,
  TError,
  { datasourceId: string; data: DatasourceCredentialSchema; params?: PostDatasourceDatasourceIdCheckCredentialsParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>,
    {
      datasourceId: string;
      data: DatasourceCredentialSchema;
      params?: PostDatasourceDatasourceIdCheckCredentialsParams;
    }
  > = (props) => {
    const { datasourceId, data, params } = props ?? {};

    return postDatasourceDatasourceIdCheckCredentials(datasourceId, data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostDatasourceDatasourceIdCheckCredentialsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>
>;
export type PostDatasourceDatasourceIdCheckCredentialsMutationBody = DatasourceCredentialSchema;
export type PostDatasourceDatasourceIdCheckCredentialsMutationError = unknown;

/**
 * @summary Checks if the given credentials work for the specified datasource.
 */
export const usePostDatasourceDatasourceIdCheckCredentials = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>,
    TError,
    {
      datasourceId: string;
      data: DatasourceCredentialSchema;
      params?: PostDatasourceDatasourceIdCheckCredentialsParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof postDatasourceDatasourceIdCheckCredentialsMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postDatasourceDatasourceIdCheckCredentials>>,
  TError,
  { datasourceId: string; data: DatasourceCredentialSchema; params?: PostDatasourceDatasourceIdCheckCredentialsParams },
  TContext
> => {
  const mutationOptions = getPostDatasourceDatasourceIdCheckCredentialsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns a list of competitions for the given datasource.
 */
export const getDatasourceDatasourceIdCompetitions = (
  datasourceId: string,
  options?: SecondParameter<typeof getDatasourceDatasourceIdCompetitionsMutator>,
  signal?: AbortSignal
) => {
  return getDatasourceDatasourceIdCompetitionsMutator<DatasourceCompetitionsSchema>(
    { url: `/datasource/${encodeURIComponent(String(datasourceId))}/competitions`, method: 'GET', signal },
    options
  );
};

export const getGetDatasourceDatasourceIdCompetitionsQueryKey = (datasourceId: string) => {
  return [`/datasource/${datasourceId}/competitions`] as const;
};

export const getGetDatasourceDatasourceIdCompetitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>,
  TError = unknown
>(
  datasourceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceDatasourceIdCompetitionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDatasourceDatasourceIdCompetitionsQueryKey(datasourceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>> = ({ signal }) =>
    getDatasourceDatasourceIdCompetitions(datasourceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!datasourceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetDatasourceDatasourceIdCompetitionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>
>;
export type GetDatasourceDatasourceIdCompetitionsQueryError = unknown;

/**
 * @summary Returns a list of competitions for the given datasource.
 */
export const useGetDatasourceDatasourceIdCompetitions = <
  TData = Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>,
  TError = unknown
>(
  datasourceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDatasourceDatasourceIdCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getDatasourceDatasourceIdCompetitionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDatasourceDatasourceIdCompetitionsQueryOptions(datasourceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
