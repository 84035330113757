/* eslint-disable react-refresh/only-export-components */
import Warning from 'assets/warning.svg?react';
import { memo, useCallback, useState } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  PathValue,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';
import ValueBadge from './ValueBadge';

interface MultipleValueInputProps<T extends FieldValues> extends UseControllerProps<T> {
  label: string;
  initialPlaceholder?: string;
  furtherPlaceholder?: string;
  smaller?: boolean;
  error?: Merge<FieldError, FieldErrorsImpl<string[]>>;
}

// TODO: refactor this to use form rules instead?
function MultipleValueInput<T extends FieldValues>({
  label,
  initialPlaceholder,
  furtherPlaceholder,
  error,
  defaultValue = [] as PathValue<T, Path<T>>,
  smaller = false,
  ...formProps
}: MultipleValueInputProps<T>) {
  const [inputValue, setInputValue] = useState<string>('');
  const [values, setValues] = useState<string[]>([]);
  const {
    field: { onChange: formOnChange }
  } = useController<T>({ ...formProps, defaultValue });
  const [inputError, setInputError] = useState('');

  const convertInputToEmail = useCallback(
    function convertInputToEmail() {
      if (!inputValue) return;
      if (!inputValue.match(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)) {
        setInputError('Please enter a valid email');
        return;
      }
      setInputError('');
      const newValues = [...values, inputValue];
      setValues(newValues);
      formOnChange(newValues);
      setInputValue('');
    },
    [formOnChange, inputValue, values]
  );

  const handleSetInputValue = useCallback(
    function handleSetInputValue(e: React.ChangeEvent<HTMLInputElement>) {
      const value = e.target.value;
      if (value[value.length - 1] === ',') {
        setInputValue(value.substring(0, value.length - 1));
        convertInputToEmail();
      } else {
        setInputValue(value);
      }
    },
    [convertInputToEmail]
  );

  const handleKeyDown = useCallback(
    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter') {
        convertInputToEmail();
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [convertInputToEmail]
  );

  const handleRemove = useCallback(
    function handleRemove(value: string) {
      const newValues = values.filter((oldValue) => oldValue !== value);
      setValues(newValues);
      formOnChange(newValues);
    },
    [formOnChange, values]
  );

  // TODO: fix html down below so input is whole area? how to make input grow? it can't have children
  return (
    <div className="flex flex-col gap-2">
      <div
        className={twJoin(
          'relative w-full rounded-md border border-gray-300 bg-white px-3 pb-1.5 pt-6.5',
          !smaller && 'min-h-24'
        )}
      >
        <label className="absolute start-3 top-1.5 z-10 block text-xs text-gray-500" htmlFor={formProps.name}>
          {label}
        </label>
        <div className="flex flex-wrap gap-2">
          {values.length > 0 &&
            values.map((value: string, index: number) => (
              <ValueBadge key={index} value={value} onRemove={handleRemove} />
            ))}
          <input
            className="grow gap-2 rounded-md border-none p-0 text-left text-md placeholder:text-gray-500 focus:outline-none focus:ring-0"
            placeholder={values.length === 0 ? initialPlaceholder : furtherPlaceholder}
            id={formProps.name}
            value={inputValue}
            onChange={handleSetInputValue}
            onBlur={convertInputToEmail}
            onKeyDownCapture={handleKeyDown}
          />
        </div>
      </div>
      {inputError && (
        <div className="flex items-center gap-2">
          <Warning className="size-5 fill-red-600" />
          <span className="text-xs text-red-600">{inputError}</span>
        </div>
      )}
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

export default memo(MultipleValueInput) as typeof MultipleValueInput;
