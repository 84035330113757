/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteProjectProjectIdUserUserIdMutator,
  default as getProjectProjectIdUserMutator,
  default as getProjectProjectIdUserUserIdMutator,
  default as postProjectProjectIdUserUserIdMutator,
  default as putProjectProjectIdUserMutator,
  default as putProjectProjectIdUserUserIdMutator
} from '../../api/axios';
import type {
  ProjectUserCreateSchema,
  ProjectUserSchema,
  ProjectUserUpdateSchema,
  ProjectUsersSchema,
  ProjectUsersUpdateSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of users and their info on a given project
 */
export const getProjectProjectIdUser = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdUserMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdUserMutator<ProjectUsersSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/user`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdUserQueryKey = (projectId: string) => {
  return [`/project/${projectId}/user`] as const;
};

export const getGetProjectProjectIdUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdUser>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdUser>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdUserMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdUserQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdUser>>> = ({ signal }) =>
    getProjectProjectIdUser(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdUser>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdUserQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectProjectIdUser>>>;
export type GetProjectProjectIdUserQueryError = unknown;

/**
 * @summary Returns a list of users and their info on a given project
 */
export const useGetProjectProjectIdUser = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdUser>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdUser>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdUserMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdUserQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Update multiple project users (not the user_account itself)
 */
export const putProjectProjectIdUser = (
  projectId: string,
  projectUsersUpdateSchema: ProjectUsersUpdateSchema,
  options?: SecondParameter<typeof putProjectProjectIdUserMutator>
) => {
  return putProjectProjectIdUserMutator<ProjectUsersSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/user`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: projectUsersUpdateSchema
    },
    options
  );
};

export const getPutProjectProjectIdUserMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdUser>>,
    TError,
    { projectId: string; data: ProjectUsersUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdUserMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectProjectIdUser>>,
  TError,
  { projectId: string; data: ProjectUsersUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectProjectIdUser>>,
    { projectId: string; data: ProjectUsersUpdateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return putProjectProjectIdUser(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectProjectIdUserMutationResult = NonNullable<Awaited<ReturnType<typeof putProjectProjectIdUser>>>;
export type PutProjectProjectIdUserMutationBody = ProjectUsersUpdateSchema;
export type PutProjectProjectIdUserMutationError = unknown;

/**
 * @summary Update multiple project users (not the user_account itself)
 */
export const usePutProjectProjectIdUser = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdUser>>,
    TError,
    { projectId: string; data: ProjectUsersUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdUserMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectProjectIdUser>>,
  TError,
  { projectId: string; data: ProjectUsersUpdateSchema },
  TContext
> => {
  const mutationOptions = getPutProjectProjectIdUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Remove a user from a project
 */
export const deleteProjectProjectIdUserUserId = (
  projectId: string,
  userId: string,
  options?: SecondParameter<typeof deleteProjectProjectIdUserUserIdMutator>
) => {
  return deleteProjectProjectIdUserUserIdMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/user/${encodeURIComponent(String(userId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteProjectProjectIdUserUserIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdUserUserIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>,
    { projectId: string; userId: string }
  > = (props) => {
    const { projectId, userId } = props ?? {};

    return deleteProjectProjectIdUserUserId(projectId, userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteProjectProjectIdUserUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>
>;

export type DeleteProjectProjectIdUserUserIdMutationError = unknown;

/**
 * @summary Remove a user from a project
 */
export const useDeleteProjectProjectIdUserUserId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteProjectProjectIdUserUserIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string },
  TContext
> => {
  const mutationOptions = getDeleteProjectProjectIdUserUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns a users' info on a given project
 */
export const getProjectProjectIdUserUserId = (
  projectId: string,
  userId: string,
  options?: SecondParameter<typeof getProjectProjectIdUserUserIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdUserUserIdMutator<ProjectUserSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/user/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdUserUserIdQueryKey = (projectId: string, userId: string) => {
  return [`/project/${projectId}/user/${userId}`] as const;
};

export const getGetProjectProjectIdUserUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>,
  TError = unknown
>(
  projectId: string,
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdUserUserIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdUserUserIdQueryKey(projectId, userId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>> = ({ signal }) =>
    getProjectProjectIdUserUserId(projectId, userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && userId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdUserUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>
>;
export type GetProjectProjectIdUserUserIdQueryError = unknown;

/**
 * @summary Returns a users' info on a given project
 */
export const useGetProjectProjectIdUserUserId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>,
  TError = unknown
>(
  projectId: string,
  userId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdUserUserId>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdUserUserIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdUserUserIdQueryOptions(projectId, userId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Add user to a project
 */
export const postProjectProjectIdUserUserId = (
  projectId: string,
  userId: string,
  projectUserCreateSchema: ProjectUserCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdUserUserIdMutator>
) => {
  return postProjectProjectIdUserUserIdMutator<ProjectUserSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/user/${encodeURIComponent(String(userId))}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: projectUserCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdUserUserIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string; data: ProjectUserCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdUserUserIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string; data: ProjectUserCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>,
    { projectId: string; userId: string; data: ProjectUserCreateSchema }
  > = (props) => {
    const { projectId, userId, data } = props ?? {};

    return postProjectProjectIdUserUserId(projectId, userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdUserUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>
>;
export type PostProjectProjectIdUserUserIdMutationBody = ProjectUserCreateSchema;
export type PostProjectProjectIdUserUserIdMutationError = unknown;

/**
 * @summary Add user to a project
 */
export const usePostProjectProjectIdUserUserId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string; data: ProjectUserCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdUserUserIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string; data: ProjectUserCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdUserUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update a project user (not the user_account itself)
 */
export const putProjectProjectIdUserUserId = (
  projectId: string,
  userId: string,
  projectUserUpdateSchema: ProjectUserUpdateSchema,
  options?: SecondParameter<typeof putProjectProjectIdUserUserIdMutator>
) => {
  return putProjectProjectIdUserUserIdMutator<ProjectUserSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/user/${encodeURIComponent(String(userId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: projectUserUpdateSchema
    },
    options
  );
};

export const getPutProjectProjectIdUserUserIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string; data: ProjectUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdUserUserIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string; data: ProjectUserUpdateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>,
    { projectId: string; userId: string; data: ProjectUserUpdateSchema }
  > = (props) => {
    const { projectId, userId, data } = props ?? {};

    return putProjectProjectIdUserUserId(projectId, userId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectProjectIdUserUserIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>
>;
export type PutProjectProjectIdUserUserIdMutationBody = ProjectUserUpdateSchema;
export type PutProjectProjectIdUserUserIdMutationError = unknown;

/**
 * @summary Update a project user (not the user_account itself)
 */
export const usePutProjectProjectIdUserUserId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>,
    TError,
    { projectId: string; userId: string; data: ProjectUserUpdateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdUserUserIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectProjectIdUserUserId>>,
  TError,
  { projectId: string; userId: string; data: ProjectUserUpdateSchema },
  TContext
> => {
  const mutationOptions = getPutProjectProjectIdUserUserIdMutationOptions(options);

  return useMutation(mutationOptions);
};
