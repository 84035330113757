import { ReportElementTemplateSchema } from 'lib/model';
import Checkbox from 'modules/common/Form/Checkbox';
import { memo, useEffect, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { MatchDetailed, ValueOption } from 'utils/interfaces';

import { SingleHeatMapElementFormValues } from '../interfaces';
import {
  competitionToValueOption,
  matchDetailedToValueOption,
  playerToValueOption,
  seasonToValueOption,
  stringToValueOption
} from 'utils/mappings';
import SelectInput from 'modules/common/Form/Select/SelectInput';

interface SingleHeatMapElementFormProps {
  template: ReportElementTemplateSchema;
  heatMapElementForm: UseFormReturn<SingleHeatMapElementFormValues>;
  subject: string;
}

const SingleHeatMapElementForm = memo(function SingleHeatMapElementForm({
  template,
  heatMapElementForm,
  subject
}: SingleHeatMapElementFormProps) {
  const formState = useFormState(heatMapElementForm);
  const hasComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'has_comparison'
  }) as boolean;

  const season = useWatch({
    control: heatMapElementForm.control,
    name: 'seasons'
  }) as ValueOption | null;
  const competition = useWatch({
    control: heatMapElementForm.control,
    name: 'competitions'
  }) as ValueOption | null;
  const match = useWatch({
    control: heatMapElementForm.control,
    name: 'matches'
  }) as ValueOption | null;

  const matchOptions = useMemo(() => {
    return (
      template
        .attribute_choices!.matches.filter(
          (option: MatchDetailed) => option.competition_id === competition?.id && option.season === season?.id
        )
        ?.map(matchDetailedToValueOption) || []
    );
  }, [competition?.id, season?.id, template.attribute_choices]);

  const seasonComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'seasons_comparison'
  }) as ValueOption | null;
  const competitionComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'competitions_comparison'
  }) as ValueOption | null;
  const matchComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'matches_comparison'
  }) as ValueOption | null;

  const matchComparisonOptions = useMemo(() => {
    return (
      template
        .attribute_choices!.matches_comparison.filter(
          (option: MatchDetailed) =>
            option.competition_id === competitionComparison?.id && option.season === seasonComparison?.id
        )
        ?.map(matchDetailedToValueOption) || []
    );
  }, [competitionComparison?.id, seasonComparison?.id, template.attribute_choices]);

  useEffect(() => {
    if (!hasComparison) {
      heatMapElementForm.setValue('seasons_comparison', null);
      heatMapElementForm.setValue('competitions_comparison', null);
      heatMapElementForm.setValue('matches_comparison', null);
      heatMapElementForm.setValue('players_comparison', []);
      heatMapElementForm.setValue('event_types_comparison', []);
    }
  }, [hasComparison, heatMapElementForm]);

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="flex gap-6">
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'seasons',
              rules: { required: 'Season is required!' }
            }}
            label="Season"
            options={template.attribute_choices!.seasons?.map(seasonToValueOption) || []}
            error={formState.errors.seasons}
          />
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'competitions',
              rules: { required: 'Competition is required!' }
            }}
            label="Competition"
            options={template.attribute_choices!.competitions?.map(competitionToValueOption) || []}
            error={formState.errors.competitions}
          />
        </div>
        <SelectInput
          formProps={{
            control: heatMapElementForm.control,
            name: 'matches',
            rules: { required: 'Match is required!' }
          }}
          label="Choose match"
          options={matchOptions}
          disabled={!season || !competition}
          error={formState.errors.matches}
        />
        {template.attribute_choices!.players && (
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'players'
            }}
            multiple
            label="Players"
            placeholder="All"
            options={template.attribute_choices!.players?.map(playerToValueOption) || []}
            error={formState.errors.players}
          />
        )}
        {template.attribute_choices?.event_types && (
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'event_types'
            }}
            multiple
            label={'Event Types'}
            placeholder="All"
            options={template.attribute_choices!.event_types?.map(stringToValueOption) || []}
            error={formState.errors.event_types}
          />
        )}
        <Checkbox
          label="Include a comparison map"
          registerReturn={{ ...heatMapElementForm.register('has_comparison') }}
        />
        {hasComparison && (
          <>
            <div className="flex gap-6">
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'seasons_comparison',
                  rules: { required: 'Comparison season is required!' }
                }}
                label="Season"
                options={template.attribute_choices!.seasons_comparison?.map(seasonToValueOption) || []}
                error={formState.errors.seasons_comparison}
              />
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'competitions_comparison',
                  rules: { required: 'Comparison competition is required!' }
                }}
                label="Competition"
                options={template.attribute_choices!.competitions_comparison?.map(competitionToValueOption) || []}
                error={formState.errors.competitions_comparison}
              />
            </div>
            <SelectInput
              formProps={{
                control: heatMapElementForm.control,
                name: 'matches_comparison',
                rules: { required: 'Comparison match is required!' }
              }}
              label="Choose match"
              options={matchComparisonOptions}
              error={formState.errors.matches_comparison}
              disabled={!seasonComparison || !competitionComparison}
            />
            {template.attribute_choices!.players_comparison && (
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'players_comparison'
                }}
                multiple
                label="Players"
                placeholder="All"
                options={template.attribute_choices!.players_comparison?.map(playerToValueOption) || []}
                error={formState.errors.players_comparison}
              />
            )}
            {template.attribute_choices?.event_types_comparison && (
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'event_types_comparison'
                }}
                multiple
                label={'Event Types'}
                placeholder="All"
                options={template.attribute_choices!.event_types_comparison?.map(stringToValueOption) || []}
                error={formState.errors.event_types_comparison}
              />
            )}
          </>
        )}
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <span className="text-xs font-semibold">{subject}</span>
          {!hasComparison && match && <span className="text-xs font-semibold">{match.label}</span>}
        </div>
        {/* <ButtonTabs
          name={'time_period'}
          control={heatMapElementForm.control}
          options={template.attribute_choices!.time_period.map(
            (option: string) =>
              ({
                label: snakeCaseToWords(option),
                id: option
              }) as ValueOption
          )}
          rules={{
            required: 'Time period is required!'
          }}
          error={formState.errors.time_period}
        /> */}
        {hasComparison && (
          <div className="grid grid-cols-2 gap-x-6 gap-y-1">
            {match && <span className="text-xs font-semibold">{match.label}</span>}
            {matchComparison && <span className="text-xs font-semibold">{matchComparison.label}</span>}
          </div>
        )}
        {/* <div className={twJoin('grid gap-x-6 gap-y-3', hasComparison ? 'grid-cols-2' : 'grid-cols-1')}>
          <div className="grid grid-cols-2 gap-x-3">
            <SimpleSingleSelectInput
              name={'phase'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.phase.map(
                (option: string) =>
                  ({
                    label: snakeCaseToWords(option),
                    id: option
                  }) as ValueOption
              )}
              rules={{
                required: 'Phase is required!'
              }}
              error={formState.errors.phase}
            />
            <SimpleSingleSelectInput
              name={'pressure'}
              control={heatMapElementForm.control}
              options={template.attribute_choices!.pressure.map(
                (option: string) =>
                  ({
                    label: snakeCaseToWords(option),
                    id: option
                  }) as ValueOption
              )}
              rules={{
                required: 'Pressure is required!'
              }}
              error={formState.errors.pressure}
            />
          </div>
          {hasComparison && (
            <div className="grid grid-cols-2 gap-x-3">
              <SimpleSingleSelectInput
                name={'phase'}
                control={heatMapElementForm.control}
                options={template.attribute_choices!.phase.map(
                  (option: string) =>
                    ({
                      label: snakeCaseToWords(option),
                      id: option
                    }) as ValueOption
                )}
                rules={{
                  required: 'Phase is required!'
                }}
                error={formState.errors.phase}
              />
              <SimpleSingleSelectInput
                name={'pressure'}
                control={heatMapElementForm.control}
                options={template.attribute_choices!.pressure.map(
                  (option: string) =>
                    ({
                      label: snakeCaseToWords(option),
                      id: option
                    }) as ValueOption
                )}
                rules={{
                  required: 'Pressure is required!'
                }}
                error={formState.errors.pressure}
              />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
});

export default SingleHeatMapElementForm;
