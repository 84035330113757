import { ValueOption } from 'utils/interfaces';

export const accessLevelPermissionList = [
  { level: ['Admin'], permission: 'Change subscription' },
  { level: ['Admin'], permission: 'Add Data Sources' },
  { level: ['Admin'], permission: 'Schedule data source ingest' },
  {
    level: ['Admin'],
    permission: 'Invite new users to Q-Ant'
  },
  { level: ['Admin'], permission: 'Approve users' },
  { level: ['Admin'], permission: 'Delete user' },
  {
    level: ['Admin', 'Editor', 'Viewer', 'Scout Viewer', 'Head Scout', 'Scout'],
    permission: 'View & Filter Data Tables'
  },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Export to CSV Data Tables' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Connect to BI tool Data Tables' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Create Reports' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Edit Reports' },
  { level: ['Admin', 'Editor', 'Viewer', 'Scout Viewer', 'Head Scout', 'Scout'], permission: 'View & Filter Reports' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Share reports' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Duplicate Reports' },
  { level: ['Admin', 'Editor', 'Viewer', 'Scout Viewer', 'Head Scout', 'Scout'], permission: 'Export report to PDF' },
  {
    level: ['Admin', 'Editor', 'Viewer', 'Scout Viewer', 'Head Scout', 'Scout'],
    permission: 'Export report element to PNG'
  },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Share Report via email' },
  { level: ['Admin', 'Editor', 'Head Scout', 'Scout'], permission: 'Schedule send report to list' },
  { level: ['Admin', 'Scout Viewer', 'Head Scout', 'Scout'], permission: 'View & Filter all scouts notes' },
  { level: ['Admin', 'Head Scout'], permission: 'View & Filter own scouts notes' },
  { level: ['Admin', 'Head Scout', 'Scout'], permission: 'Enter scouts notes' },
  { level: ['Admin', 'Head Scout'], permission: 'Edit/Delete all scouts notes' },
  { level: ['Admin', 'Head Scout', 'Scout'], permission: 'Edit/Delete own scouts notes' }
];

export const accessLevels: ValueOption[] = [
  { label: 'Admin', id: 'Admin' },
  { label: 'Editor', id: 'Editor' },
  { label: 'Viewer', id: 'Viewer' },
  { label: 'Head Scout', id: 'Head Scout' },
  { label: 'Scout', id: 'Scout' },
  { label: 'Viewer Scout', id: 'Scout Viewer' }
];
