import { getGetReportLastEditedQueryKey, useGetReportLastEdited } from 'lib/report/report';
import { memo } from 'react';
import ReportsGrid from './ReportGrid';
import { DEFAULT_LAST_EDITED_REPORTS_COUNT } from 'constants/reports';

const LastEditedReports = memo(function LastEditedReports() {
  const { data: lastEditedReports, isFetching: isLastEditedReportsPending } = useGetReportLastEdited(
    {
      num_last_edited_reports: DEFAULT_LAST_EDITED_REPORTS_COUNT
    },
    {
      query: {
        queryKey: getGetReportLastEditedQueryKey(),
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false
      }
    }
  );

  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-sm font-semibold">Last edited</span>
      <ReportsGrid data={lastEditedReports} isDataFetching={isLastEditedReportsPending} />
    </div>
  );
});

export default LastEditedReports;
