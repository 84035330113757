import Tooltip from 'modules/common/Tooltip';
import { memo } from 'react';
import { getInitials } from 'utils/helpers';

interface IntegrationDataSourceLogoProps {
  name: string;
}

const IntegrationDataSourceLogo = memo(function IntegrationDataSourceLogo({ name }: IntegrationDataSourceLogoProps) {
  return (
    <Tooltip message={name}>
      <div className="brand-gradient flex size-5 items-center justify-center rounded-md border border-gray-200">
        {<span className="text-tiny font-semibold text-white">{getInitials(name)}</span>}
      </div>
    </Tooltip>
  );
});

export default IntegrationDataSourceLogo;
