import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { Circle } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import EventBall from 'assets/event-ball.svg?react';
import { ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useRef } from 'react';
import { isLightColor } from 'utils/helpers';
import { getReportGeneralColor } from '../../../../helpers';
import ChartTooltip from '../ChartTooltip';
import SvgCenteredGroup from '../ScatterChart/SvgCenteredGroup';
import SvgJersey from '../ScatterChart/SvgJersey';
import { EventMapFrame, EventMapPlayer } from './interfaces';

interface EventMapChartProps {
  frames: EventMapFrame[];
  report: ReportNestedSchema;
  tick: number;
}

const EventMapChart = memo(function EventMapChart({ report, frames, tick }: EventMapChartProps) {
  const { width, height, parentRef } = useParentSize({ debounceTime: 150 });

  const getX = (point: { x: number; y: number }) => point.x;
  const getY = (point: { x: number; y: number }) => point.y;

  const xScale = scaleLinear<number>({
    domain: [-52.5, 52.5],
    range: [0, width],
    round: true
  });

  const yScale = scaleLinear<number>({
    domain: [-34, 34],
    range: [height, 0],
    round: true
  });

  const xValue = useCallback(
    (point: { x: number; y: number }) => {
      return xScale(getX(point)) ?? 0;
    },
    [xScale]
  );

  const yValue = useCallback(
    (point: { x: number; y: number }) => {
      return yScale(getY(point)) ?? 0;
    },
    [yScale]
  );

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } = useTooltip<EventMapPlayer>({
    tooltipOpen: false
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(
    function handleMouseLeave() {
      tooltipTimeout.current = window.setTimeout(() => {
        hideTooltip();
      }, 300);
    },
    [hideTooltip]
  );

  const handleMouseEnter = useCallback(
    function handleMouseEnter(point: EventMapPlayer) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        showTooltip({
          tooltipData: point,
          tooltipLeft: xValue(point) - 10,
          tooltipTop: yValue(point) - 40
        });
      };
    },
    [showTooltip, xValue, yValue]
  );

  return (
    <div
      ref={parentRef}
      className="relative aspect-football-field-horizontal w-full bg-football-field-horizontal bg-contain p-football-field"
    >
      <svg className="size-full overflow-visible" ref={containerRef}>
        <Group left={0} top={0}>
          {frames[tick].players_data.map((point) => (
            <SvgJersey
              key={point.player_id}
              left={xValue(point)}
              top={yValue(point)}
              color={getReportGeneralColor(report, point.team_id, undefined, point.player_id)}
              blackNumber={isLightColor(getReportGeneralColor(report, point.team_id, undefined, point.player_id))}
              number={point.shirt_number}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter(point)}
              className="transition-transform duration-[250ms] ease-linear"
            />
          ))}
          <SvgCenteredGroup
            width={20}
            height={20}
            left={xValue(frames[tick].ball_data)}
            top={yValue(frames[tick].ball_data)}
          >
            <Circle r={10} fill="white" transform={'translate(8, 8)'} />
            <EventBall className="rounded-full bg-white fill-black" width={16} height={16} />
          </SvgCenteredGroup>
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        {tooltipData && (
          <span className="shrink-0">
            {tooltipData.player_name} {tooltipData.shirt_number}
          </span>
        )}
      </ChartTooltip>
    </div>
  );
});

export default EventMapChart;
