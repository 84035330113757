import { UserAccountSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Input from 'modules/common/Form/Input';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { DialogProps } from 'utils/interfaces';

interface UsernameChangeDialogProps extends DialogProps {
  user: UserAccountSchema;
  updateUsername: (firstName: string, lastName: string) => void;
}

interface UserNameFormValues {
  firstName: string;
  lastName: string;
}

function UsernameChangeDialog({ open, setOpen, user, updateUsername }: UsernameChangeDialogProps) {
  const { handleSubmit, register, reset } = useForm<UserNameFormValues>({
    defaultValues: {
      firstName: user.first_name! ?? '',
      lastName: user.last_name! ?? ''
    }
  });

  function handleCancel() {
    reset();
    setOpen(false);
  }

  function onUserNameSubmit(data: UserNameFormValues) {
    updateUsername(data.firstName, data.lastName);
  }

  return (
    <DialogBase title="Edit name" open={open} onCancel={handleCancel}>
      <DialogContent>
        <form onSubmit={handleSubmit(onUserNameSubmit)} className="flex flex-col gap-6" id="username-change-form">
          <div className="flex justify-between gap-3">
            <Input registerReturn={register('firstName')} label="First name" fullWidth />
            <Input registerReturn={register('lastName')} label="Last name" fullWidth />
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="xl" isSubmitButton={true} form="username-change-form">
          Save
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(UsernameChangeDialog);
