import CircleImage from 'modules/common/CircleImage';
import { memo } from 'react';
import ElementRow from '../../elements/ElementRow';
import { MatchGeneralData } from '../interfaces';
import MatchHeader from './MatchHeader';

interface MatchInfoProps {
  match: MatchGeneralData;
}

const MatchInfo = memo(function MatchInfo({ match }: MatchInfoProps) {
  const date = new Date(match.date);
  const dateString = date.toLocaleString(undefined, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

  return (
    <>
      <MatchHeader match={match} />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">COMPETITION</span>
          <div className="flex items-center gap-3">
            {/* TODO: image instead of text */}
            <CircleImage image={undefined} text={match.competition_name.charAt(0)} size="size-6" />
            <span>{match.competition_name ?? '-'}</span>
          </div>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">DATE</span>
          <span>
            {dateString} - {match.time ?? ''}
          </span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">COMPET. STAGE</span>
          <span>{match.competition_stage ?? '-'}</span>
        </ElementRow>
      </div>
    </>
  );
});

export default MatchInfo;
