import Warning from 'assets/warning.svg?react';
import Button from 'modules/common/Button';
import Divider from 'modules/common/Divider';
import { memo } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

interface ElementCardFallbackProps {
  onRemove?: () => void;
}

function ElementCardFallback({ onRemove }: ElementCardFallbackProps) {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <header className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-3">
            <span className="text-sm font-medium">Error</span>
          </div>
          <div className="flex items-center gap-4" />
        </div>
        <Divider />
      </header>
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <span className="text-md font-medium">Unfortunately, an error has occurred</span>
          <div className="flex h-32 items-center justify-center rounded-xl bg-gray-50">
            <Warning className="size-16 fill-red-600" />
          </div>
        </div>
        <div className="flex items-center gap-6">
          {onRemove && (
            <Button variant="secondary" size="lg" onClick={onRemove}>
              Remove from report
            </Button>
          )}
          <Button variant="primary" size="lg" onClick={resetBoundary}>
            Try again
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(ElementCardFallback);
