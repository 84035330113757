import { ReportElementSchema } from 'lib/model';
import { memo } from 'react';
import { MatchInfo } from 'utils/interfaces';

interface ElementMatchInfoProps {
  match: MatchInfo;
  element?: ReportElementSchema;
}

function isElementAvgPositions(element?: ReportElementSchema): boolean {
  if (!element) {
    return false;
  }
  return (
    element.report_element_template_name === 'avg-positions' ||
    element.report_element_template_name === 'avg-positions-tracking-data' ||
    element.report_element_template_name === 'avg-positions-event-data'
  );
}

const ElementMatchInfo = ({ match, element }: ElementMatchInfoProps) => {
  const date = new Date(match.date);

  return (
    <>
      <div className="flex flex-wrap items-center gap-2">
        <span className="text-xs font-semibold">
          {isElementAvgPositions(element) && `Last available match: `}
          {match.home_team_name} - {match.away_team_name} ({match.home_team_score}:{match.away_team_score})
        </span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{match.competition_name}</span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{match.season}</span>
        <div className="h-3.5 w-px bg-gray-300" />
        <span className="text-xs font-semibold">{date.toLocaleDateString()}</span>
      </div>
    </>
  );
};

export default memo(ElementMatchInfo);
