import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Next from 'assets/arrow-right.svg?react';
import { ProjectSchema } from 'lib/model';
import {
  useGetReportColumnExists,
  useGetReportGetCompetitions,
  useGetReportGetCountries,
  useGetReportGetPlayerMetrics,
  useGetReportGetPlayerPositions,
  useGetReportGetPlayerSubpositions,
  useGetReportGetTeamsInfinite
} from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Fieldset from 'modules/common/Form/Fieldset';
import Input from 'modules/common/Form/Input';
import Slider from 'modules/common/Form/Slider';
import ToggleFieldset from 'modules/common/Form/ToggleFieldset';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import {
  competitionToAutocompleteOption,
  metricToMetricOption,
  stringToValueOption,
  subpositionToValueOption,
  teamToAutocompleteOption
} from 'utils/mappings';
import { ScoutReportFormValues } from '../interfaces';
import SelectInput from 'modules/common/Form/Select/SelectInput';

interface ScoutReportFilteredPlayersOptionsFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onNext: () => void;
  onBack: () => void;
}

function ScoutReportFilteredPlayersOptionsForm({
  onBack,
  onNext,
  scoutReportForm
}: ScoutReportFilteredPlayersOptionsFormProps) {
  const formState = useFormState(scoutReportForm);
  const position = useWatch({
    control: scoutReportForm.control,
    name: 'position'
  });
  const seasons = useWatch({
    control: scoutReportForm.control,
    name: 'seasons'
  });
  const metric = useWatch({
    control: scoutReportForm.control,
    name: 'metric'
  });

  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  const playerPositions = useGetReportGetPlayerPositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerSubPositions = useGetReportGetPlayerSubpositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerSubPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerMetrics = useGetReportGetPlayerMetrics(
    { project_id: project.id!, player_position: undefined as string | undefined },
    {
      query: {
        queryKey: ['playerMetrics', project.id],
        staleTime: Infinity
        // enabled: !!position
      }
    }
  );
  const competitions = useGetReportGetCompetitions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['competitions', project.id],
        staleTime: Infinity
      }
    }
  );
  const [teamQuery, setTeamQuery] = useState<string>('');

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetTeamsInfinite(
    {
      team_name: teamQuery,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['teams', project.id, teamQuery],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.teams || lastPage.teams?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const countries = useGetReportGetCountries(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['countries', project.id],
        staleTime: Infinity
      }
    }
  );
  const existHeightColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'height' },
    {
      query: {
        queryKey: ['existHeightColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingWeightColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'weight' },
    {
      query: {
        queryKey: ['existingWeightColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingMarketValueColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'market_value' },
    {
      query: {
        queryKey: ['existingMarketValueColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingBirthDateColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'birth_date' },
    {
      query: {
        queryKey: ['existingBirthDateColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingCountryColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'country' },
    {
      query: {
        queryKey: ['existingCountryColumn', project.id],
        staleTime: Infinity
      }
    }
  );
  const existingNationalityColumn = useGetReportColumnExists(
    { project_id: project.id!, column_name: 'nationality' },
    {
      query: {
        queryKey: ['existingNationalityColumn', project.id],
        staleTime: Infinity
      }
    }
  );

  const playerPositionOptions = useMemo(() => playerPositions.data?.map(stringToValueOption) ?? [], [playerPositions]);
  const playerSubPositionOptions = useMemo(
    () =>
      !position
        ? []
        : (playerSubPositions.data?.positions
            ?.filter((subpos) => subpos.primary_position! === position.id)
            .map(subpositionToValueOption) ?? []),
    [playerSubPositions, position]
  );

  const playerMetricOptions = useMemo(() => {
    const baseMetrics = playerMetrics.data?.metrics ?? [];
    const filteredMetrics = baseMetrics.filter(
      (metric) =>
        !metric.primary_position_list?.length || metric.primary_position_list?.includes(position?.id as string)
    );
    return filteredMetrics.map(metricToMetricOption) ?? [];
  }, [playerMetrics, seasons]);

  const metricSeasonsOptions = useMemo(() => {
    const seasons = metric?.seasons_list ?? [];
    return seasons.sort().reverse().map(stringToValueOption) ?? [];
  }, [metric]);

  const aggregationMetricOptions = useMemo(() => {
    const methods = metric?.recommended_aggregations ?? [];
    return methods.map(stringToValueOption);
  }, [metric]);

  const teamOptions = useMemo(() => {
    const allRows = data ? data.pages.flatMap((d) => d.teams) : [];
    return allRows.map(teamToAutocompleteOption) ?? [];
  }, [data]);

  const competitionOptions = useMemo(
    () => competitions.data?.map(competitionToAutocompleteOption) ?? [],
    [competitions]
  );

  const countryOptions = useMemo(() => countries.data?.map(stringToValueOption) ?? [], [countries.data]);

  useEffect(() => {
    scoutReportForm.setValue('subPositions', []);
  }, [position, scoutReportForm]);

  const handleNext = useCallback(
    function handleNext() {
      onNext();
    },
    [onNext]
  );

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="scout-report-player-filters"
          onSubmit={scoutReportForm.handleSubmit(handleNext)}
        >
          <div className="flex flex-col gap-3">
            <span className="text-md font-medium">Filter</span>
            <p className="text-sm">Enter preferences to find best players.</p>
          </div>
          <Input
            label="Number of filtered players (Max 20)"
            registerReturn={scoutReportForm.register('numberOfFilteredPlayers', {
              min: { value: 0, message: 'Minimum of 0 players can be filtered' },
              max: { value: 20, message: 'Maximum of 20 players can be filtered' },
              required: { value: true, message: 'Number of filtered players is required' },
              valueAsNumber: true
            })}
            type="number"
            unit="Players"
            error={formState.errors.numberOfFilteredPlayers}
          />
          <Fieldset legend="Player position">
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'position',
                rules: { required: { value: true, message: 'Player position is required' } }
              }}
              label="Player Position"
              options={playerPositionOptions}
              loading={playerPositions.isPending}
              error={formState.errors.position}
            />
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'subPositions'
              }}
              multiple
              label="Player Sub Positions"
              disabled={!position}
              options={playerSubPositionOptions}
              loading={playerSubPositions.isPending}
            />
            <div className="flex flex-col gap-3">
              <span className="text-md font-medium">Player ranking</span>
              <p className="text-sm">
                All the filtered players will be ranked automatically based on the aggregated value of the chosen
                <b className="font-semibold"> metric criteria</b> within the selected season(s).
              </p>
            </div>
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'metric',
                rules: { required: { value: true, message: 'Metric Criteria is required' } }
              }}
              label="Metric Criteria"
              options={playerMetricOptions}
              loading={playerMetrics.isFetching}
              error={formState.errors.metric}
            />
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'aggregation_metric',
                rules: { required: { value: true, message: 'Aggregate method is required!' } }
              }}
              label={'Aggregate method'}
              options={aggregationMetricOptions}
              error={formState.errors.aggregation_metric}
            />
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'seasons'
              }}
              multiple
              label={'Seasons'}
              loading={playerMetrics.isFetching}
              placeholder="All Seasons"
              options={metricSeasonsOptions}
              error={formState.errors.seasons}
            />
          </Fieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isCompetitionsAndTeamsEnabled"
            legend="Competitions and Teams"
          >
            <SelectInput
              formProps={{
                control: scoutReportForm.control,
                name: 'competitions'
              }}
              searchable
              multiple
              loading={competitions.isFetching}
              label="Competitions"
              placeholder="All Competitions"
              options={competitionOptions}
            />
            <SelectInput
              loading={status === 'pending'}
              label={'Team'}
              placeholder="All teams"
              options={teamOptions}
              onInputChange={setTeamQuery}
              searchable
              multiple
              infiniteQuery={{
                hasNextPage: hasNextPage,
                fetchNextPage: fetchNextPage,
                isFetchingNextPage: isFetchingNextPage
              }}
              formProps={{
                name: 'teams',
                rules: { required: { value: true, message: 'Team is required.' } },
                control: scoutReportForm.control
              }}
            />
          </ToggleFieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isPlaytimeExperienceEnabled"
            legend="Playtime Experience"
          >
            <Input
              label="Minimum games played (in selected seasons)"
              registerReturn={scoutReportForm.register('minPlayedGames', { min: 0, valueAsNumber: true })}
              unit="Games"
            />
            <span className="text-md font-medium">Minutes played per match (Average)</span>
            <Slider unit="min" control={scoutReportForm.control} name="avgMinutesPlayed" defaultValue={[0, 120]} />
          </ToggleFieldset>
          <ToggleFieldset control={scoutReportForm.control} name="isMarketValueEnabled" legend="Market Value">
            {existingMarketValueColumn.data === true && (
              <Slider
                unit="euro"
                control={scoutReportForm.control}
                name="avgMarketValue"
                defaultValue={[0, 200_000_000]}
              />
            )}
            {existingMarketValueColumn.data === false && (
              <span className="text-sm text-red-500">Market value is not available in the current dataset</span>
            )}
          </ToggleFieldset>
          <ToggleFieldset
            control={scoutReportForm.control}
            name="isPhysicalAttributesEnabled"
            legend="Physical Attributes"
          >
            <span className="text-md font-medium">Age</span>
            {existingBirthDateColumn.data === true && (
              <Slider unit="yrs" control={scoutReportForm.control} name="age" defaultValue={[0, 100]} />
            )}
            {existingBirthDateColumn.data === false && (
              <span className="text-sm text-red-500">Age is not available in the current dataset</span>
            )}
            <span className="text-md font-medium">Height</span>
            {existHeightColumn.data === true && (
              <Slider unit="cm" control={scoutReportForm.control} name="height" defaultValue={[0, 250]} />
            )}
            {existHeightColumn.data === false && (
              <span className="text-sm text-red-500">Height is not available in the current dataset</span>
            )}
            <span className="text-md font-medium">Weight</span>
            {existingWeightColumn.data === true && (
              <Slider unit="kg" control={scoutReportForm.control} name="weight" defaultValue={[0, 200]} />
            )}
            {existingWeightColumn.data === false && (
              <span className="text-sm text-red-500">Weight is not available in the current dataset</span>
            )}
          </ToggleFieldset>

          <ToggleFieldset control={scoutReportForm.control} name="isNationalityEnabled" legend="Nationality">
            {existingCountryColumn.data === false && (
              <span className="text-sm text-red-500">Country of birth is not available in the current dataset</span>
            )}
            {existingCountryColumn.data === true && (
              <SelectInput
                formProps={{
                  control: scoutReportForm.control,
                  name: 'countryOfBirth'
                }}
                multiple
                label="Country of birth"
                placeholder="All Countries"
                options={countryOptions}
                loading={countries.isPending}
              />
            )}
            {existingNationalityColumn.data === false && (
              <span className="text-sm text-red-500">Nationality is not available in the current dataset</span>
            )}
            {existingNationalityColumn.data === true && (
              <SelectInput
                formProps={{
                  control: scoutReportForm.control,
                  name: 'nationality'
                }}
                multiple
                label="Nationality"
                placeholder="All Nationalities"
                options={countryOptions}
                loading={countries.isPending}
              />
            )}
          </ToggleFieldset>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button size="xl" isSubmitButton form="scout-report-player-filters">
          <span>Filter</span>
          <Next width={20} height={20} />
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportFilteredPlayersOptionsForm);
