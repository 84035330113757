/* eslint-disable react/jsx-no-bind */
import { useParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import * as d3 from 'd3';
import { memo, PropsWithChildren, useMemo } from 'react';
import { twJoin } from 'tailwind-merge';
import { HeatMapPoint } from './interfaces';
import { MatchInfo } from 'utils/interfaces';

interface HeatMapChartProps extends PropsWithChildren {
  data: HeatMapPoint[];
  match: MatchInfo;
  isVertical?: boolean;
}

const HeatMapChart = memo(function HeatMapChart({ data, match, isVertical = false }: HeatMapChartProps) {
  const { width, height, parentRef } = useParentSize({ debounceTime: 150 });

  const { getX, getY } = useMemo(() => {
    const _getX = isVertical ? (dot: HeatMapPoint) => dot[1] : (dot: HeatMapPoint) => -dot[0];
    const _getY = isVertical ? (dot: HeatMapPoint) => dot[0] : (dot: HeatMapPoint) => dot[1];

    return { getX: _getX, getY: _getY };
  }, [isVertical]);

  const xScale = scaleLinear<number>({
    domain: isVertical ? [-34, 34] : [-52.5, 52.5],
    range: [0, width],
    round: true
  });

  const yScale = scaleLinear<number>({
    domain: isVertical ? [-52.5, 52.5] : [-34, 34],
    range: [height, 0],
    round: true
  });

  const { xValue, yValue } = useMemo(() => {
    const _xValue = (d: HeatMapPoint) => {
      return xScale(getX(d)) ?? 0;
    };

    const _yValue = (d: HeatMapPoint) => {
      return yScale(getY(d)) ?? 0;
    };

    return { xValue: _xValue, yValue: _yValue };
  }, [getX, getY, xScale, yScale]);

  const densityData = useMemo(
    () => d3.contourDensity().x(xValue).y(yValue).size([width, height]).bandwidth(9)(data),
    [data, height, width, xValue, yValue]
  );

  const colorDomain = useMemo(() => {
    const sortedDensityValues = densityData.map((d) => d.value).sort();
    return colors.map((_, index) => d3.quantile(sortedDensityValues, index / colors.length)!);
  }, [densityData]);

  const colorScale = scaleLinear<string>({
    domain: colorDomain,
    range: colors
  });

  const pathData = useMemo(() => {
    const toPath = d3.geoPath();
    return densityData.map((polygon) => ({
      polygon: polygon,
      path: toPath(polygon)!,
      color: colorScale(polygon.value)
    }));
  }, [colorScale, densityData]);

  return (
    <div
      ref={parentRef}
      className={twJoin(
        'relative w-full bg-contain p-football-field',
        isVertical
          ? 'aspect-football-field-vertical bg-football-field-vertical'
          : 'aspect-football-field-horizontal bg-football-field-horizontal'
      )}
    >
      <span
        className={twJoin(
          'absolute z-10 w-fit rounded-badge bg-white px-3 py-1.5 text-tiny font-bold',
          isVertical
            ? 'left-1/2 top-[8%] -translate-x-1/2 -translate-y-1/2'
            : 'left-[8%] top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90'
        )}
      >
        {match.home_team_name}
      </span>
      <span
        className={twJoin(
          'absolute z-10 w-fit rounded-badge bg-white px-3 py-1.5 text-tiny font-bold',
          isVertical
            ? 'bottom-[8%] left-1/2 -translate-x-1/2 translate-y-1/2'
            : 'right-[8%] top-1/2 -translate-y-1/2 translate-x-1/2 rotate-90'
        )}
      >
        {match.away_team_name}
      </span>
      <svg className="size-full">
        {pathData.map((point, i) => (
          <path d={point.path} fill={point.color} key={i} opacity={0.3} />
        ))}
      </svg>
    </div>
  );
});

const colors = ['#3366ff', '#33ff33', 'yellow', 'red'];

export default HeatMapChart;
