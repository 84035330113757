import Database from 'assets/database.svg?react';
import useIsMobile from 'hooks/useIsMobile';
import { DatasourceSchema } from 'lib/model';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import DataSourceCard from './DataSourceCard';

interface DataSourcesGridProps {
  dataSources: DatasourceSchema[];
}

const DataSourcesGrid = memo(function DataSourcesGrid({ dataSources }: DataSourcesGridProps) {
  const isMobile = useIsMobile();

  if (dataSources.length === 0) {
    return (
      <div className="mx-10 flex items-center justify-center rounded-xl bg-gray-50 py-16">
        <div className="flex flex-col items-center gap-3">
          <Database className="size-16 fill-gray-300" />
          <span className="text-sm font-medium text-gray-500">No data sources match the selected filters.</span>
        </div>
      </div>
    );
  }

  return (
    <div className={twMerge('grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1 2xl:grid-cols-3', isMobile && 'px-4')}>
      {dataSources.map((ds) => (
        <DataSourceCard dataSource={ds} key={ds.id} />
      ))}
    </div>
  );
});

export default DataSourcesGrid;
