import ChevronRight from 'assets/chevron-right.svg?react';
import Info from 'assets/info.svg?react';
import useIsMobile from 'hooks/useIsMobile';
import { usePostUserRegister } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import Checkbox from 'modules/common/Form/Checkbox';
import Input from 'modules/common/Form/Input';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo } from 'react';

import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { RegisterFormValues } from 'utils/interfaces';

interface RegisterFormProps {
  setUserInfo: React.Dispatch<React.SetStateAction<RegisterFormValues>>;
  handleGoogle: () => void;
  handleMicrosoft: () => void;
}

const RegisterForm = memo(function RegisterForm({ setUserInfo }: RegisterFormProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const projectId = searchParams.get('project_id');
  const email = searchParams.get('email');
  const isMobile = useIsMobile();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<RegisterFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: email ?? '',
      password: '',
      terms: false
    }
  });

  const { mutate: postRegister, isPending } = usePostUserRegister();

  function onSubmit(formData: RegisterFormValues) {
    if (token && projectId) {
      postRegister(
        {
          data: {
            email: formData.email,
            first_name: formData.firstName,
            last_name: formData.lastName,
            password: formData.password
          },
          params: { projectId: projectId, token: token }
        },
        {
          onSuccess: () => {
            navigate(`/email-verification?email=${encodeURIComponent(formData.email)}`);
          }
        }
      );
    } else {
      setUserInfo(formData);
    }
  }

  return (
    <div
      className={twMerge(
        'flex w-full max-w-xl flex-col gap-6 rounded-3xl bg-white p-16',
        isMobile && 'rounded-none p-8'
      )}
    >
      <h6 className="text-h6 font-semibold text-gray-900">Welcome to Q-ant</h6>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-6">
        <span className="text-md font-semibold text-gray-900">Basic information</span>
        <div className="flex gap-6">
          <Input
            registerReturn={register('firstName', {
              required: 'First name is required.'
            })}
            label="First name"
            error={errors.firstName}
          />
          <Input
            registerReturn={register('lastName', {
              required: 'Last name is required.'
            })}
            label="Last name"
            error={errors.lastName}
          />
        </div>
        <Input
          registerReturn={register('email', {
            required: 'Email is required.',
            pattern: {
              value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Invalid Email pattern.'
            }
          })}
          label="Email"
          error={errors.email}
        />
        <div className="flex flex-col gap-2">
          <PasswordInput
            registerReturn={register('password', { required: 'Password is required.' })}
            label="Password"
            error={errors.password}
          />
          <div className="flex items-center gap-2 pl-1">
            <Info width={16} height={16} className="fill-gray-700" />
            <span className="text-sm">Include at least one number and one special character.</span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <Checkbox
            registerReturn={register('terms', { required: 'You must accept the terms and conditions.' })}
            label="I accept the terms & conditions"
            error={errors.terms}
          />
          <a
            href="https://www.q-ant.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
            className={twJoin('cursor-pointer text-md font-medium text-brand-800 underline', isMobile && 'text-right')}
          >
            Terms & conditions
          </a>
        </div>
        <div className="mt-4 flex flex-col gap-6">
          <Button isSubmitButton={true} size="xl" loading={isPending}>
            Sign up
          </Button>
          <div>
            <span className="text-md">You already have an account? </span>
            <Link to="/login" className="inline-flex items-center">
              <span className="text-md font-semibold text-brand-800">Sign in</span>
              <ChevronRight width={24} height={24} className="fill-brand-800" />
            </Link>
          </div>
        </div>
      </form>
      {/* <TextDivider text="OR CONTINUE WITH" />
      <div className="flex w-full gap-4">
        <Button variant="secondary" size="xl" onClick={handleGoogle}>
          <Google width={20} height={20} className="fill-gray-700" />
          <span>Google</span>
        </Button>
        <Button variant="secondary" size="xl" onClick={handleMicrosoft}>
          <Microsoft width={20} height={20} className="fill-gray-700" />
          <span>Microsoft</span>
        </Button>
      </div> */}
    </div>
  );
});

export default RegisterForm;
