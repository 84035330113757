import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Metric, ValueOption } from 'utils/interfaces';
import { metricToMetricOption } from 'utils/mappings';
import AggregateOptions from './AggregateOptions';
import { loadAttributeValues } from './helpers';
import { ElementFormProps, ScatterChartElementFormValues } from './interfaces';

const ScatterChartElementForm = memo(function ScatterChartElementForm({
  report,
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<ScatterChartElementFormValues> = {
    aggregate_by: {
      label: 'Match',
      id: 'match'
    },
    // aggregation_metric
    x_axis_match: null,
    y_axis_match: null,
    x_axis_season: null,
    y_axis_season: null,
    seasons: [],
    matches: [],
    competitions: []
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  } else {
    // Creating element
  }

  const scatterForm = useForm<ScatterChartElementFormValues>({
    defaultValues
  });
  const { control, watch, formState, handleSubmit } = scatterForm;

  const xAxisMatch = watch('x_axis_match');
  const yAxisMatch = watch('y_axis_match');

  const active_metrics = useMemo(() => {
    const metrics = [];
    if (xAxisMatch) {
      metrics.push(xAxisMatch);
    }
    if (yAxisMatch) {
      metrics.push(yAxisMatch);
    }
    return metrics;
  }, [xAxisMatch, yAxisMatch]);

  // Get metrics from metric seasons and active seasons
  function getMetrics(metrics: Metric[], otherAxis: string | number | undefined): ValueOption[] {
    const options = metrics.map(metricToMetricOption);
    return options.filter((option) => option.id !== otherAxis);
  }

  const xAxisMatchOptions = useMemo(() => {
    const metrics = template.attribute_choices!.x_axis_match || [];
    return getMetrics(metrics, yAxisMatch?.id);
  }, [template.attribute_choices, yAxisMatch?.id]);

  const yAxisMatchOptions = useMemo(() => {
    const metrics = template.attribute_choices!.y_axis_match || [];
    return getMetrics(metrics, xAxisMatch?.id);
  }, [template.attribute_choices, xAxisMatch?.id]);

  const addElement = useCallback(
    function addElement(data: ScatterChartElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const renderMetrics = useCallback(() => {
    return (
      <>
        <SelectInput
          formProps={{
            control: control,
            name: 'x_axis_match',
            rules: { required: 'X Axis is required!' }
          }}
          label={'X Axis'}
          options={xAxisMatchOptions}
          error={formState.errors.x_axis_match}
        />
        <SelectInput
          formProps={{
            control: control,
            name: 'y_axis_match',
            rules: { required: 'Y Axis is required!' }
          }}
          label={'Y Axis'}
          options={yAxisMatchOptions}
          error={formState.errors.y_axis_match}
        />
      </>
    );
  }, [formState, control, xAxisMatchOptions, yAxisMatchOptions]);

  return (
    <FormProvider {...scatterForm}>
      <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
        {renderMetrics()}
        {report.report_type !== 'match' && <AggregateOptions template={template} metrics={active_metrics} />}
      </form>
    </FormProvider>
  );
});

export default ScatterChartElementForm;
