import { useCallback, useEffect, useRef, useState } from 'react';

export default function useScroll() {
  const [direction, setDirection] = useState<'up' | 'down' | 'start'>('start');
  const [y, setY] = useState(0);
  const lastTime = useRef(0);
  const delayId = useRef(0);

  // Throttle while user is scrolling
  // Once the scrolling ends run once more on delay
  // Why?
  // if user is scrolling too fast, we may not capture edge values beacause of throttle
  // this ensures we fix those mistakes
  const handleNavigation = useCallback(() => {
    const now = new Date().getTime();
    if (now - lastTime.current >= 150) {
      if (delayId.current) {
        window.clearTimeout(delayId.current);
      }
      lastTime.current = now;
      if (window.scrollY > y) {
        setDirection('down');
      } else if (window.scrollY < y) {
        setDirection('up');
      }
      setY(window.scrollY);

      window.setTimeout(() => {
        if (window.scrollY > y) {
          setDirection('down');
        } else if (window.scrollY < y) {
          setDirection('up');
        }
        setY(window.scrollY);
      }, 250);
    }
  }, [y]);

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);

    return () => window.removeEventListener('scroll', handleNavigation);
  }, [handleNavigation]);

  return { y, direction };
}
