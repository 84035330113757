import { useQueryClient } from '@tanstack/react-query';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ReadonlyInput from 'modules/common/ReadonlyInput';
import { memo, useCallback } from 'react';
import { DialogProps } from 'utils/interfaces';

interface ViewCredentialsDialogProps extends DialogProps {
  port: number;
  database: string;
  username: string;
  password: string;
}

function ViewCredentialsDialog({ port, database, username, password, open, setOpen }: ViewCredentialsDialogProps) {
  const queryClient = useQueryClient();

  const handleCancel = useCallback(
    function handleCancel() {
      queryClient.invalidateQueries({ queryKey: ['biToolConnections'] });
      setOpen(false);
    },
    [queryClient, setOpen]
  );

  return (
    <DialogBase title="Credentials" open={open} onCancel={handleCancel} narrower secure>
      <DialogContent>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Your credentials for BI tools</span>
            <ReadonlyInput label="Port" value={port.toString()} />
            <ReadonlyInput label="Host" value={database} />
            <ReadonlyInput label="Database" value="postgres" />
            <ReadonlyInput label="Username" value={username} />
            <ReadonlyInput label="Password" value={password} />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">
              These credentials are shown to you only once.<br />
              Make sure you copy them!
            </span>
            <span className="text-md font-medium">
              Once you close this dialog box you will no longer have access to it.
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="red" onClick={handleCancel}>
          Close without return
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(ViewCredentialsDialog);
