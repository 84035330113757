import { memo } from 'react';
import DataSourceCardSkeleton from './DataSourceCardSkeleton';

const DataSourceCardGridSkeleton = memo(function DataSourceCardGridSkeleton() {
  return (
    <div className="grid w-full grid-cols-2 gap-6 max-lg:grid-cols-1">
      <DataSourceCardSkeleton />
      <DataSourceCardSkeleton />
      <DataSourceCardSkeleton />
      <DataSourceCardSkeleton />
      <DataSourceCardSkeleton />
    </div>
  );
});

export default DataSourceCardGridSkeleton;
