import { memo, PropsWithChildren } from 'react';

const ElementComparisonRow = memo(function ElementComparisonRow({ children }: PropsWithChildren) {
  return (
    <div className="grid w-full grid-cols-enhance-tails items-center justify-between gap-6 rounded-md bg-gray-50 p-3 text-center text-xs font-medium [&>*:nth-child(1)]:mr-auto [&>*:nth-child(3)]:ml-auto">
      {children}
    </div>
  );
});

export default ElementComparisonRow;
