import { useGetDatasource } from 'lib/datasource/datasource';
import { DatasourceSchema } from 'lib/model';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import { memo } from 'react';
import { EventCardProps } from '../EventCard';

function DataSourcesRemovedEvent({ event }: EventCardProps) {
  const { data: dataSourcesData, isFetching } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });
  const dataSources = dataSourcesData?.objects ?? [];

  if (isFetching) {
    return (
      <div className="grid h-20 w-full grid-cols-1 gap-4">
        <div className="animate-pulse rounded-md bg-red-50" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-1 rounded-md bg-red-50 p-3">
      <span className="text-xs font-medium text-gray-500">{event.title}</span>
      <div className="flex flex-col">
        {event.json_content!.datasources?.map((ds: DatasourceSchema) => {
          const dataSource = dataSources.find((d) => d.id === ds.id) ?? ds;
          return (
            <div key={dataSource.id} className="py-1.5">
              <DataSourceTitle
                name={dataSource.name!}
                image={dataSource.logo_image_path}
                size={'xs'}
                imageSize={'tiny'}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default memo(DataSourcesRemovedEvent);
