import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import { ReportElementTemplateSchema } from 'lib/model';
import { memo } from 'react';

interface ElementTemplateDescriptionProps {
  template: ReportElementTemplateSchema;
  defaultOpen?: boolean;
}

const ElementTemplateDescription = ({ template, defaultOpen = true }: ElementTemplateDescriptionProps) => {
  return (
    <Disclosure as="div" className="rounded-xl bg-gray-50 px-4 py-3" defaultOpen={defaultOpen}>
      <DisclosureButton className="group flex w-full items-center justify-between">
        <span className="text-xs font-semibold">Description</span>
        <ChevronDown className="size-5 fill-gray-950 group-data-[open]:rotate-180" />
      </DisclosureButton>
      <DisclosurePanel className="mt-2 text-sm">{template.description}</DisclosurePanel>
    </Disclosure>
  );
};

export default memo(ElementTemplateDescription);
