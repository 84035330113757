import Plus from 'assets/plus.svg?react';
import TextArea from 'modules/common/Form/TextArea';
import { memo, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';

interface OptionalNotesProps {
  register: UseFormRegister<{ note: string | undefined }>;
}

const OptionalNotes = memo(function OptionalNotes({ register }: OptionalNotesProps) {
  const [showNote, setShowNote] = useState<boolean>(false);

  function handleShowNote() {
    setShowNote(true);
  }

  if (!showNote) {
    return (
      <button onClick={handleShowNote} className={twJoin('flex items-center gap-2', showNote && 'hidden')}>
        <Plus width={20} height={20} className="fill-brand-800" />
        <span className="text-sm font-medium text-brand-800">Add Note</span>
        <span className="text-sm font-medium text-gray-500">(Optional)</span>
      </button>
    );
  }
  return <TextArea label="Note (Optional)" registerReturn={register('note')} />;
});

export default OptionalNotes;
