/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */

export type ReportCreateSchemaReportType =
  (typeof ReportCreateSchemaReportType)[keyof typeof ReportCreateSchemaReportType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReportCreateSchemaReportType = {
  match: 'match',
  team: 'team',
  team_comparison: 'team_comparison',
  player: 'player',
  player_comparison: 'player_comparison',
  scout: 'scout'
} as const;
