/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator,
  default as getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator,
  default as getProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator,
  default as patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutator,
  default as postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator,
  default as putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
} from '../../api/axios';
import type {
  DatasourceConfigurationActivitySchema,
  DatasourceConfigurationCreateSchema,
  DatasourceConfigurationNestedSchema,
  DatasourceConfigurationSchema,
  DatasourceConfigurationsNestedSchema,
  ProjectConfigurationCreateSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns all datasource_configuration objects associated with the specified project configuration
 */
export const getProjectConfigurationProjectConfigurationIdDatasourceConfiguration = (
  projectConfigurationId: string,
  options?: SecondParameter<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>,
  signal?: AbortSignal
) => {
  return getProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator<DatasourceConfigurationsNestedSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryKey = (
  projectConfigurationId: string
) => {
  return [`/project-configuration/${projectConfigurationId}/datasource-configuration`] as const;
};

export const getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError = unknown
>(
  projectConfigurationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryKey(projectConfigurationId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>
  > = ({ signal }) =>
    getProjectConfigurationProjectConfigurationIdDatasourceConfiguration(
      projectConfigurationId,
      requestOptions,
      signal
    );

  return { queryKey, queryFn, enabled: !!projectConfigurationId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>
>;
export type GetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryError = unknown;

/**
 * @summary Returns all datasource_configuration objects associated with the specified project configuration
 */
export const useGetProjectConfigurationProjectConfigurationIdDatasourceConfiguration = <
  TData = Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError = unknown
>(
  projectConfigurationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(
    projectConfigurationId,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Adds a datasource to the specified project configuration
 */
export const postProjectConfigurationProjectConfigurationIdDatasourceConfiguration = (
  projectConfigurationId: string,
  datasourceConfigurationCreateSchema: DatasourceConfigurationCreateSchema,
  options?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>
) => {
  return postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator<DatasourceConfigurationSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceConfigurationCreateSchema
    },
    options
  );
};

export const getPostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    TError,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError,
  { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema }
  > = (props) => {
    const { projectConfigurationId, data } = props ?? {};

    return postProjectConfigurationProjectConfigurationIdDatasourceConfiguration(
      projectConfigurationId,
      data,
      requestOptions
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>
>;
export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationBody =
  DatasourceConfigurationCreateSchema;
export type PostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationError = unknown;

/**
 * @summary Adds a datasource to the specified project configuration
 */
export const usePostProjectConfigurationProjectConfigurationIdDatasourceConfiguration = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
    TError,
    { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectConfigurationProjectConfigurationIdDatasourceConfiguration>>,
  TError,
  { projectConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const mutationOptions =
    getPostProjectConfigurationProjectConfigurationIdDatasourceConfigurationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Removes the specified datasource from the configuration
 */
export const deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  options?: SecondParameter<
    typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >
) => {
  return deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator<ProjectConfigurationCreateSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
        >
      >,
      TError,
      { projectConfigurationId: string; datasourceConfigurationId: string },
      TContext
    >;
    request?: SecondParameter<
      typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
        >
      >,
      { projectConfigurationId: string; datasourceConfigurationId: string }
    > = (props) => {
      const { projectConfigurationId, datasourceConfigurationId } = props ?? {};

      return deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId(
        projectConfigurationId,
        datasourceConfigurationId,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type DeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >
  >;

export type DeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationError =
  unknown;

/**
 * @summary Removes the specified datasource from the configuration
 */
export const useDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string },
    TContext
  >;
  request?: SecondParameter<
    typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof deleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
  >,
  TError,
  { projectConfigurationId: string; datasourceConfigurationId: string },
  TContext
> => {
  const mutationOptions =
    getDeleteProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the specified datasource_configuration
 */
export const getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  options?: SecondParameter<
    typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >,
  signal?: AbortSignal
) => {
  return getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator<DatasourceConfigurationNestedSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryKey =
  (projectConfigurationId: string, datasourceConfigurationId: string) => {
    return [
      `/project-configuration/${projectConfigurationId}/datasource-configuration/${datasourceConfigurationId}`
    ] as const;
  };

export const getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >,
    TError = unknown
  >(
    projectConfigurationId: string,
    datasourceConfigurationId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<
        typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
      >;
    }
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryKey(
        projectConfigurationId,
        datasourceConfigurationId
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >
    > = ({ signal }) =>
      getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId(
        projectConfigurationId,
        datasourceConfigurationId,
        requestOptions,
        signal
      );

    return {
      queryKey,
      queryFn,
      enabled: !!(projectConfigurationId && datasourceConfigurationId),
      ...queryOptions
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type GetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >
  >;
export type GetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryError =
  unknown;

/**
 * @summary Returns the specified datasource_configuration
 */
export const useGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = <
  TData = Awaited<
    ReturnType<typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
  >,
  TError = unknown
>(
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<
      typeof getProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdQueryOptions(
      projectConfigurationId,
      datasourceConfigurationId,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates the specified datasource in the configuration
 */
export const putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  datasourceConfigurationCreateSchema: DatasourceConfigurationCreateSchema,
  options?: SecondParameter<
    typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >
) => {
  return putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator<ProjectConfigurationCreateSchema>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceConfigurationCreateSchema
    },
    options
  );
};

export const getPutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >,
      TError,
      { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
      TContext
    >;
    request?: SecondParameter<
      typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
      >,
      { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema }
    > = (props) => {
      const { projectConfigurationId, datasourceConfigurationId, data } = props ?? {};

      return putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId(
        projectConfigurationId,
        datasourceConfigurationId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >
  >;
export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationBody =
  DatasourceConfigurationCreateSchema;
export type PutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationError =
  unknown;

/**
 * @summary Updates the specified datasource in the configuration
 */
export const usePutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
    TContext
  >;
  request?: SecondParameter<
    typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutator
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof putProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationId>
  >,
  TError,
  { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationCreateSchema },
  TContext
> => {
  const mutationOptions =
    getPutProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
/**
 * @summary Activates the specified datasource_configuration
 */
export const patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive = (
  projectConfigurationId: string,
  datasourceConfigurationId: string,
  datasourceConfigurationActivitySchema: DatasourceConfigurationActivitySchema,
  options?: SecondParameter<
    typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutator
  >
) => {
  return patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutator<void>(
    {
      url: `/project-configuration/${encodeURIComponent(String(projectConfigurationId))}/datasource-configuration/${encodeURIComponent(String(datasourceConfigurationId))}/active`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: datasourceConfigurationActivitySchema
    },
    options
  );
};

export const getPatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
        >
      >,
      TError,
      {
        projectConfigurationId: string;
        datasourceConfigurationId: string;
        data: DatasourceConfigurationActivitySchema;
      },
      TContext
    >;
    request?: SecondParameter<
      typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutator
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationActivitySchema },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } = options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
        >
      >,
      { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationActivitySchema }
    > = (props) => {
      const { projectConfigurationId, datasourceConfigurationId, data } = props ?? {};

      return patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive(
        projectConfigurationId,
        datasourceConfigurationId,
        data,
        requestOptions
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
      >
    >
  >;
export type PatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutationBody =
  DatasourceConfigurationActivitySchema;
export type PatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutationError =
  unknown;

/**
 * @summary Activates the specified datasource_configuration
 */
export const usePatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
        >
      >,
      TError,
      {
        projectConfigurationId: string;
        datasourceConfigurationId: string;
        data: DatasourceConfigurationActivitySchema;
      },
      TContext
    >;
    request?: SecondParameter<
      typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutator
    >;
  }): UseMutationResult<
    Awaited<
      ReturnType<
        typeof patchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActive
      >
    >,
    TError,
    { projectConfigurationId: string; datasourceConfigurationId: string; data: DatasourceConfigurationActivitySchema },
    TContext
  > => {
    const mutationOptions =
      getPatchProjectConfigurationProjectConfigurationIdDatasourceConfigurationDatasourceConfigurationIdActiveMutationOptions(
        options
      );

    return useMutation(mutationOptions);
  };
