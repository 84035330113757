import { useQueryClient } from '@tanstack/react-query';
import MoveDown from 'assets/chevron-down.svg?react';
import MoveUp from 'assets/chevron-up.svg?react';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { usePutReportReportIdElementElementIdPosition } from 'lib/report/report';
import { memo, useCallback } from 'react';
import { toast } from 'react-toastify';

interface ElementCardPositionButtonsProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
  isElementFetching: boolean;
  first: boolean;
  last: boolean;
}

const ElementCardPositionButtons = memo(function ElementCardPositionButtons({
  element,
  report,
  isElementFetching,
  first,
  last
}: ElementCardPositionButtonsProps) {
  const queryClient = useQueryClient();

  const { mutate: moveElement, isPending: isMovingElement } = usePutReportReportIdElementElementIdPosition({
    mutation: {
      onMutate: async () => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        return { loadingToastId };
      },
      onError: (err, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.error('Your have unsaved changes');
      },
      onSuccess: (data, body, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: ['reports', report.id!]
        });
      }
    }
  });

  const handleMoveReportDown = useCallback(
    function handleMoveReportDown() {
      moveElement({
        reportId: report.id!,
        elementId: element.id!,
        params: {
          direction: 'down'
        }
      });
    },
    [report.id, element.id, moveElement]
  );

  const handleMoveReportUp = useCallback(
    function handleMoveReportUp() {
      moveElement({
        reportId: report.id!,
        elementId: element.id!,
        params: {
          direction: 'up'
        }
      });
    },
    [moveElement, report.id, element.id]
  );

  return (
    <div className="flex flex-col items-center divide-y rounded-sm border border-gray-300">
      <button
        onClick={handleMoveReportUp}
        className="group flex items-center justify-center disabled:cursor-not-allowed"
        disabled={isMovingElement || isElementFetching || first}
      >
        <MoveUp className="size-5 fill-gray-700 group-disabled:fill-gray-400" />
      </button>
      <button
        onClick={handleMoveReportDown}
        className="group flex items-center justify-center disabled:cursor-not-allowed"
        disabled={isMovingElement || isElementFetching || last}
      >
        <MoveDown className="size-5 fill-gray-700 group-disabled:fill-gray-400" />
      </button>
    </div>
  );
});

export default ElementCardPositionButtons;
