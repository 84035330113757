import { useQueryClient } from '@tanstack/react-query';
import Database from 'assets/database.svg?react';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import { ProjectSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardProgressBar from './DashboardProgressBar';

function DashboardSubscriptionDataSourcesCard() {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;
  const { data: dataSourcesCount } = useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
    query: { queryKey: ['dataSourcesCount'], enabled: !!project.id, staleTime: Infinity }
  });
  const navigate = useNavigate();

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  return (
    <div className="flex flex-col gap-6 rounded-xl bg-white p-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-2">
          <Database className="size-5 fill-gray-500" />
          <span className="text-xs font-semibold text-gray-500">DATA SOURCES</span>
        </div>
        <Button variant="secondary" size="tiny" isFullWidth={false} onClick={goToTierPlans}>
          Add Slots
        </Button>
      </div>
      <div className="flex flex-col gap-6">
        <p className="flex items-end gap-0.5">
          <span className="text-h3">{dataSourcesCount?.count ?? '-'}</span>
          <span className="pb-1 text-h6 text-gray-400">/</span>
          <span className="pb-1 text-h6 text-gray-400">{dataSourcesCount?.max ?? '-'}</span>
        </p>
        <DashboardProgressBar progress={dataSourcesCount ? dataSourcesCount.count / dataSourcesCount.max : 0} />
      </div>
    </div>
  );
}

export default memo(DashboardSubscriptionDataSourcesCard);
