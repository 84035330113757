/* eslint-disable react-refresh/only-export-components */
import { HTMLProps, memo, RefCallback, useCallback, useState } from 'react';
import { FieldError, FieldValues, Path, PathValue, useController, UseControllerProps } from 'react-hook-form';
import ReactSlider from 'react-slider';
import { twJoin, twMerge } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

interface SliderProps<T extends FieldValues> extends UseControllerProps<T> {
  unit: string;
  error?: FieldError;
}

function Slider<T extends FieldValues>({
  unit,
  error,
  defaultValue = [0, 100] as unknown as PathValue<T, Path<T>>,
  ...formProps
}: SliderProps<T>) {
  const [sliderValues, setSliderValues] = useState<[number, number]>(defaultValue);
  const {
    field: { onChange: fieldOnChange }
  } = useController<T>(formProps);

  const handleInputChange = useCallback(
    function handleInputChange(index: number) {
      return (e: React.ChangeEvent<HTMLInputElement>) => {
        const copy: [number, number] = [...sliderValues];
        if (index === 0) {
          copy[index] = Math.max(Number(e.target.value), defaultValue[0]);
        } else {
          copy[index] = Math.min(Number(e.target.value), defaultValue[1]);
        }
        setSliderValues(copy);
      };
    },
    [defaultValue, sliderValues]
  );

  const handleSliderChange = useCallback(
    function handleSliderChange(value: [number, number]) {
      setSliderValues(value);
      fieldOnChange(value);
    },
    [setSliderValues, fieldOnChange]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-3">
        <ReactSlider
          value={sliderValues}
          className="h-5"
          thumbClassName="size-5 rounded-full border-2 border-white bg-brand-800"
          min={defaultValue?.[0]}
          max={defaultValue?.[1]}
          pearling
          onChange={handleSliderChange}
          renderTrack={trackClassName}
        />
        <div className="flex items-center gap-6">
          <div className="peer relative flex w-full flex-col gap-2">
            <input
              min={defaultValue[0]}
              max={defaultValue[1]}
              value={sliderValues[0]}
              onChange={handleInputChange(0)}
              id={`${formProps.name}-min`}
              placeholder={' '}
              type="number"
              className={twMerge(
                'border-px peer block h-14 w-full appearance-none rounded-lg border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0',
                error && 'border-red-600 text-red-600'
              )}
            />
            {/* 1px difference is due to border */}
            <span className="absolute bottom-[7px] start-[13px] z-10 bg-white text-md peer-focus:hidden">
              {sliderValues[0].toLocaleString()}
            </span>
            <label
              htmlFor={`${formProps.name}-min`}
              className={twMerge(
                'absolute start-3 top-1.5 z-10 origin-[0] transform text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs',
                error && 'text-red-600'
              )}
            >
              Min
            </label>
            <span className={twMerge('absolute inset-y-4 end-3 z-10 text-md', error && 'text-red-600')}>{unit}</span>
          </div>
          <span className="text-gray-950">-</span>
          <div className="relative flex w-full flex-col gap-2">
            <input
              min={defaultValue[0]}
              max={defaultValue[1]}
              value={sliderValues[1]}
              onChange={handleInputChange(1)}
              id={`${formProps.name}-max`}
              placeholder={' '}
              type="number"
              className={twMerge(
                'border-px peer block h-14 w-full appearance-none rounded-lg border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0',
                error && 'border-red-600 text-red-600'
              )}
            />
            {/* 1px difference is due to border */}
            <span className="absolute bottom-[7px] start-[13px] z-10 bg-white text-md peer-focus:hidden">
              {sliderValues[1].toLocaleString()}
            </span>
            <label
              htmlFor={`${formProps.name}-max`}
              className={twMerge(
                'absolute start-3 top-1.5 z-10 origin-[0] transform text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs',
                error && 'text-red-600'
              )}
            >
              Max
            </label>
            <span className={twMerge('absolute inset-y-4 end-3 z-10 text-md', error && 'text-red-600')}>{unit}</span>
          </div>
        </div>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}

interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
  ref: RefCallback<T>;
}

function trackClassName(
  props: HTMLPropsWithRefCallback<HTMLDivElement>,
  state: {
    index: number;
    value: [number, number];
  }
) {
  const { key, ...rest } = props;
  return (
    <div
      {...rest}
      key={key}
      className={twJoin(
        'top-1/2 h-1.5 -translate-y-1/2 rounded-full',
        state.index === 1 ? 'bg-brand-800' : 'bg-gray-200'
      )}
    />
  );
}

export default memo(Slider) as typeof Slider;
