import { ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { getDefaultTeamColor } from '../../../../helpers';
import Player from '../Player';
import Team from '../Team';
import { MetricData } from './interfaces';
import PerformanceBar from './PerformanceBar';

interface MetricComparisonProps {
  report: ReportNestedSchema;
  data: MetricData;
  isPlayer: boolean;
}

const MetricComparison = memo(function MetricComparison({ report, isPlayer, data }: MetricComparisonProps) {
  return (
    <div className="grid grid-cols-cards overflow-x-auto">
      <table className="w-full table-fixed border-separate border-spacing-y-2">
        <thead>
          <tr>
            <th className="w-1/3 text-start">
              <span className="text-xs font-semibold">{isPlayer ? 'Players' : 'Teams'}</span>
            </th>
            <th className="w-2/3 text-start">
              <span className="text-xs font-semibold">{snakeCaseToWords(data.metric)}</span>
            </th>
          </tr>
        </thead>
        <tbody className="gap-2 bg-transparent">
          {data.values.map((row) => (
            <tr key={isPlayer ? row.playerId! : row.teamId} className="h-12">
              <td className="rounded-l-md bg-gray-50 pl-3">
                {isPlayer ? (
                  <Player
                    player_name={row.playerName!}
                    shirt_number={row.shirtNumber}
                    color={row.teamColor ?? getDefaultTeamColor(report, row.teamId)}
                    size="md"
                  />
                ) : (
                  <Team
                    team_name={row.teamName}
                    color={row.teamColor ?? getDefaultTeamColor(report, row.teamId)}
                    size="md"
                  />
                )}
              </td>
              <td
                className="whitespace-nowrap bg-gray-50 text-sm font-medium text-gray-900 first:rounded-l-md first:pl-3 last:rounded-r-md last:pr-3"
                title={String(row.value)}
              >
                <PerformanceBar
                  value={row.value}
                  maxValue={data.maxValue}
                  color={row.teamColor ?? getDefaultTeamColor(report, row.teamId)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default MetricComparison;
