import useIsMobile from 'hooks/useIsMobile';
import { memo, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export const WelcomeScreen = memo(function WelcomeScreen() {
  const isMobile = useIsMobile();
  const [opacitiesFull, setOpacities] = useState([false, false, false]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (opacitiesFull.every((full) => full)) {
        setOpacities([false, false, false]);
      } else {
        setOpacities((prevOpacities) => {
          const nextOpacities = [...prevOpacities];
          nextOpacities[nextOpacities.findIndex((full) => !full)] = true;
          return nextOpacities;
        });
      }
    }, 700);

    return () => clearInterval(interval);
  }, [opacitiesFull]);

  return (
    <div className="brand-gradient flex min-h-screen w-full flex-col items-center justify-center gap-10">
      <h1 className={isMobile ? 'text-h4 text-white' : 'text-h1 text-white'}>Welcome to Q-ant</h1>
      <div className="flex justify-between gap-4">
        {opacitiesFull.map((full, index) => (
          <span
            key={index}
            className={twMerge(
              'w-32 border-2 transition-opacity duration-700 ease-linear',
              full ? 'opacity-100' : 'opacity-10',
              isMobile && 'w-16'
            )}
          />
        ))}
      </div>
    </div>
  );
});

export default WelcomeScreen;
