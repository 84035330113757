import { usePutUser } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Input from 'modules/common/Form/Input';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface EmailChangeFormValues {
  email: string;
  password: string;
}

function EmailChangeDialog({ open, setOpen }: DialogProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid }
  } = useForm<EmailChangeFormValues>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const { mutate: updateEmail } = usePutUser();

  function handleCancel() {
    reset();
    setOpen(false);
  }

  function onEmailChangeSubmit(data: EmailChangeFormValues) {
    updateEmail(
      { data: { email: data.email, password: data.password } },
      {
        onSuccess: () => {
          toast.success('Email successfully changed.');
        }
      }
    );
  }

  return (
    <DialogBase title="Change email" open={open} onCancel={handleCancel}>
      <DialogContent>
        <form onSubmit={handleSubmit(onEmailChangeSubmit)} className="flex flex-col gap-6" id="email-change-form">
          <Input registerReturn={register('email')} label="New Email" fullWidth />
          <PasswordInput
            registerReturn={register('password', { required: 'Password is required' })}
            label="Enter your password"
            error={errors.password}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="xl" isSubmitButton={true} form="email-change-form" disabled={!isValid}>
          Change email
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(EmailChangeDialog);
