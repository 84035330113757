import { memo } from 'react';
import { isWhite } from 'utils/helpers';
import Jersey from '../Jersey';

interface PlayerProps {
  color: string;
  player_name: string;
  shirt_number?: number | string | undefined;
  size?: 'sm' | 'md' | 'lg';
}

const Player = memo(function Player({ player_name, color, ...props }: PlayerProps) {
  return (
    <div className="flex items-center gap-2">
      <Jersey outline={isWhite(color)} color={color} {...props} />
      <span className="text-xs font-medium">{player_name}</span>
    </div>
  );
});

export default Player;
