import { usePostUserChangePassword } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface PasswordChangeFormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

function PasswordChangeDialog({ open, setOpen }: DialogProps) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isValid },
    watch,
    trigger
  } = useForm<PasswordChangeFormValues>({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  });

  const { mutate: changePassword } = usePostUserChangePassword();

  function handleCancel() {
    reset();
    setOpen(false);
  }

  function onEmailChangeSubmit(data: PasswordChangeFormValues) {
    changePassword(
      { data: { current_password: data.currentPassword, new_password: data.newPassword } },
      {
        onSuccess: () => {
          reset();
          setOpen(false);
          toast.success('Password successfully changed.');
        }
      }
    );
  }

  return (
    <DialogBase title="Change passoword" open={open} onCancel={handleCancel}>
      <DialogContent>
        <form onSubmit={handleSubmit(onEmailChangeSubmit)} className="flex flex-col gap-6" id="password-change-form">
          <PasswordInput
            registerReturn={register('currentPassword', { required: 'Password is required' })}
            label="Current password"
            error={errors.currentPassword}
          />
          <PasswordInput
            registerReturn={register('newPassword', {
              required: 'Password is required',
              validate: (val: string) => {
                if (watch('currentPassword') === val) return 'New password cannot be the same as current password.';
              },
              onChange: () => trigger('newPassword')
            })}
            label="New password"
            error={errors.newPassword}
            info="Include at least one number and one special character"
          />
          <PasswordInput
            registerReturn={register('confirmPassword', {
              required: 'Password is required',
              validate: (val: string) => {
                if (watch('newPassword') !== val) return 'Passwords do not match.';
              },
              onChange: () => trigger('confirmPassword')
            })}
            label="Confirm new password"
            error={errors.confirmPassword}
          />
        </form>
      </DialogContent>
      <DialogFooter>
        <Button size="xl" variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" size="xl" isSubmitButton={true} form="password-change-form" disabled={!isValid}>
          Change password
        </Button>
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(PasswordChangeDialog);
