/* eslint-disable @typescript-eslint/no-explicit-any */
import Warning from 'assets/warning.svg?react';
import { memo } from 'react';
import { FieldError, Merge } from 'react-hook-form';

// TODO: figure out this type
interface ErrorMessageProps {
  error?: Merge<FieldError, any>;
}

function ErrorMessage({ error }: ErrorMessageProps) {
  if (!error) return null;
  return (
    <div className="flex items-center gap-2">
      <Warning className="size-5 fill-red-600" />
      <span className="text-xs text-red-600">{error.message}</span>
    </div>
  );
}

export default memo(ErrorMessage);
