import Report from 'assets/report.svg?react';
import { ReportsSchema } from 'lib/model';
import { memo } from 'react';
import ReportCard from './reportCard/ReportCard';
import ReportCardSkeleton from './reportCard/ReportCardSkeleton';

interface ReportsGridProps {
  data?: ReportsSchema;
  isDataFetching: boolean;
}

const ReportsGrid = memo(function ReportsGrid({ data, isDataFetching }: ReportsGridProps) {
  if (isDataFetching) {
    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <ReportCardSkeleton />
        <ReportCardSkeleton />
        <ReportCardSkeleton />
      </div>
    );
  }

  if (!data?.objects || data?.objects?.length === 0) {
    return (
      <div className="flex items-center justify-center rounded-xl bg-gray-50 py-16">
        <div className="flex flex-col items-center gap-3">
          <Report className="size-16 fill-gray-300" />
          <span className="text-sm font-medium text-gray-500">No reports.</span>
        </div>
      </div>
    );
  }

  return (
    data?.objects &&
    data.objects.length > 0 && (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {data?.objects?.map((report) => <ReportCard key={report.id} report={report} />)}
      </div>
    )
  );
});

export default ReportsGrid;
