import Verified from 'assets/verified-solid.svg?react';
import { memo } from 'react';

interface VerifiedBadgeProps {
  verified?: boolean;
}

const VerifiedBadge = memo(function VerifiedBadge({ verified }: VerifiedBadgeProps) {
  if (!verified) return null;

  return (
    <div className="flex items-center gap-2">
      <Verified width={16} height={16} className="fill-gray-700" />
      <span className="text-xs font-medium">VERIFIED</span>
    </div>
  );
});

export default VerifiedBadge;
