import { DataSourceFilters } from 'contexts/dataSourcesFilters/dataSourcesFiltersContext';

export const DefaultConfigurationFormValues = {
  areas: [],
  leagues: [],
  min_season: { id: '2019', label: '2019/2020' },
  refreshFrequency: { id: new Date().getDate().toString() + ' * ? *', label: 'Monthly' },
  time: { id: '0 0', label: 'Default' }
};

export const defaultDataSourceFilters: DataSourceFilters = {
  dataTypes: [],
  pricing: [],
  competitions: [],
  seasons: [],
  verified: false,
  useAssistant: false,
  dataTables: [],
  excludedDataSources: [],
  featured: false,
  selectedDataSource: null
};
