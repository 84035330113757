import Database from 'assets/database.svg?react';
import useIsMobile from 'hooks/useIsMobile';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';

const ConnectDataSourcesBanner = memo(function ConnectDataSourcesBanner() {
  const isMobile = useIsMobile();
  return (
    <div className={twMerge('flex w-full items-center gap-6 rounded-xl bg-white p-10', isMobile && 'p-6')}>
      <Database width={64} height={64} className="min-h-8 min-w-8 fill-gray-300" />
      <p className="text-md font-medium">
        You don't have any connected data sources.
        <br />
        To start, <span className="font-bold">connect a data source</span> from the available data sources below.
      </p>
    </div>
  );
});

export default ConnectDataSourcesBanner;
