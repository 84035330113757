import { memo } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

interface CheckboxProps {
  registerReturn: UseFormRegisterReturn;
  label: string;
  disabled?: boolean;
  error?: FieldError;
}

function Checkbox({ registerReturn, label, disabled, error }: CheckboxProps) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2 pl-1">
        <input
          {...registerReturn}
          placeholder=""
          disabled={disabled}
          id={registerReturn.name}
          type="checkbox"
          className={twMerge(
            'h-4.5 w-4.5 rounded-sm border-gray-500 text-brand-800 focus:ring-0 ui-disabled:bg-gray-50',
            error && 'border-red-600'
          )}
        />
        <label
          htmlFor={registerReturn.name}
          className={twMerge('text-md font-medium text-gray-500', error && 'text-red-700')}
        >
          {label}
        </label>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  );
}
export default memo(Checkbox);
