import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Report from 'assets/report.svg?react';
import StarEmpty from 'assets/star-empty.svg?react';
import StarFull from 'assets/star-full.svg?react';
import { defaultDataTablePageSize, defaultPageIndex } from 'constants/tableDefaults';
import { ReportSchema, ReportsSchema } from 'lib/model';
import { getGetReportFavoriteQueryKey, usePutReportReportIdToggleFavorite } from 'lib/report/report';
import CircleImage from 'modules/common/CircleImage';
import { memo, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReportIcon from '../ReportIcon';
import ReportsPageDropdown from '../ReportsPageDropdown';
import SharedUsersIcons from '../SharedUsersIcons';
import ReportDescription from './ReportDescription';
import ReportsTable from './ReportsTable';

interface ReportsTableControllerProps {
  data?: ReportsSchema;
  isDataFetching: boolean;
}

const columnHelper = createColumnHelper<ReportSchema>();

const ReportsTableController = memo(function ReportsTableController({
  data,
  isDataFetching
}: ReportsTableControllerProps) {
  const tableData = data?.objects;
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { mutate: toggleFavorite, isPending: isTogglingFavorite } = usePutReportReportIdToggleFavorite({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['reports'] });
        queryClient.invalidateQueries({ queryKey: getGetReportFavoriteQueryKey(), exact: true });
      }
    }
  });

  const handleToggleFavorite = useCallback(
    function handleToggleFavorite(report: ReportSchema) {
      return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        toggleFavorite({
          reportId: report.id!
        });
        return report;
      };
    },
    [toggleFavorite]
  );

  const columns = useMemo<ColumnDef<ReportSchema>[]>(
    () => [
      columnHelper.display({
        id: 'report_name',
        cell: (info) => {
          const report = info.row.original;
          return (
            <div className="flex items-center gap-3">
              <div className="flex size-10 items-center justify-center rounded-md bg-gray-50 p-2">
                <ReportIcon reportType={report.report_type!} />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-xs font-semibold">{report.name}</span>
                <ReportDescription report={report} />
              </div>
            </div>
          );
        }
        // header: () => (
        //   <span className="whitespace-nowrap text-tiny font-medium uppercase text-gray-500">REPORT NAME</span>
        // )
      }),
      columnHelper.display({
        id: 'owner',
        cell: (info) => {
          const report = info.row.original;
          return (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-medium text-gray-500">OWNER:</span>
              <div className="flex items-center gap-2">
                {/* TODO: image instead of text */}
                <CircleImage
                  image={report.owner_image_path}
                  text={report.owner_first_name!.charAt(0) + report.owner_last_name?.charAt(0)}
                  size="size-5"
                />
                <span className="text-xs font-medium">{`${report.owner_first_name} ${report.owner_last_name}`}</span>
              </div>
            </div>
          );
        }
        // header: () => <span className="whitespace-nowrap text-tiny font-medium uppercase text-gray-500">OWNER</span>
      }),
      columnHelper.display({
        id: 'shared',
        cell: (info) => {
          const report = info.row.original;
          return (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-medium text-gray-500">SHARED:</span>
              {report.users?.length === 0 ? (
                <span className="text-xs font-medium">-</span>
              ) : (
                <SharedUsersIcons users={report.users ?? []} />
              )}
            </div>
          );
        }
        // header: () => <span className="whitespace-nowrap text-tiny font-medium uppercase text-gray-500">SHARED</span>
      }),
      // columnHelper.accessor('created_timestamp', {
      //   id: 'created_timestamp',
      //   cell: (info) => (
      //     <span className="text-xs font-medium ">
      //       {info.getValue() ? new Date(info.getValue() as string).toLocaleDateString() : info.renderValue()}
      //     </span>
      //   ),
      // header: () => <span className="whitespace-nowrap text-tiny font-medium uppercase text-gray-500">CREATED</span>
      // }),
      columnHelper.accessor('last_edited_timestamp', {
        id: 'last_edited_timestamp',
        cell: (info) => (
          <div className="flex items-center gap-2">
            <span className="text-tiny font-medium text-gray-500">EDITED:</span>
            <span className="text-xs font-medium">
              {info.getValue() ? new Date(info.getValue() as string).toLocaleDateString() : info.renderValue()}
            </span>
          </div>
        )
        // header: () => (
        //   <span className="whitespace-nowrap text-tiny font-medium uppercase text-gray-500">LAST EDITED</span>
        // )
      }),
      columnHelper.display({
        id: 'favorite',
        cell: (info) => {
          const report = info.row.original;
          return report.is_favorite ? (
            <button
              className="flex items-center justify-center"
              disabled={isTogglingFavorite}
              onClick={handleToggleFavorite(info.row.original)}
            >
              <StarFull className="size-5 fill-amber-400 disabled:cursor-not-allowed" />
            </button>
          ) : (
            <button
              className="flex items-center justify-center"
              disabled={isTogglingFavorite}
              onClick={handleToggleFavorite(info.row.original)}
            >
              <StarEmpty className="size-5 fill-white disabled:cursor-not-allowed group-hover:fill-gray-300" />
            </button>
          );
        }
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const report = info.row.original;
          return <ReportsPageDropdown report={report} />;
        }
      })
    ],
    [handleToggleFavorite, isTogglingFavorite]
  );

  const pagination = {
    pageIndex: Number(searchParams.get('pageIndex') ?? defaultPageIndex),
    pageSize: Number(searchParams.get('pageSize') ?? defaultDataTablePageSize)
  };

  const table = useReactTable({
    data: tableData ?? [],
    columns,
    rowCount: data?.row_count,
    state: {
      pagination,
      columnPinning: {
        left: ['select-col'],
        right: ['actions']
      }
    },
    onPaginationChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(pagination);
      } else {
        newState = updater;
      }
      searchParams.set('pageIndex', String(newState.pageIndex));
      searchParams.set('pageSize', String(newState.pageSize));
      setSearchParams(searchParams);
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    renderFallbackValue: '-'
  });

  if (!tableData || tableData.length === 0) {
    return (
      <div className="flex items-center justify-center rounded-xl bg-gray-50 py-16">
        <div className="flex flex-col items-center gap-3">
          <Report className="size-16 fill-gray-300" />
          <span className="text-sm font-medium text-gray-500">No reports.</span>
        </div>
      </div>
    );
  }

  return <ReportsTable table={table} isDataFetching={isDataFetching} rowRedirectKey="id" />;
});

export default ReportsTableController;
