import { ReportNestedSchema, ReportNestedSchemaReportType } from 'lib/model';
import { memo } from 'react';
import MatchElement from './Match/MatchElement';
import PlayerElement from './Player/PlayerElement';
import PlayerComparisonElement from './PlayerComparison/PlayerComparisonElement';
import ReportInfoSkeleton from './ReportElementSkeleton';
import ScoutElement from './Scout/ScoutElement';
import TeamElement from './Team/TeamElement';
import TeamComparisonElement from './TeamComparison/TeamComparisonElement';
import { MatchGeneralData, PlayerGeneralData, ScoutPlayerData, TeamGeneralData } from './interfaces';

interface ReportTypeElementProps {
  report?: ReportNestedSchema;
}

const ReportTypeElement = memo(function ReportTypeElement({ report }: ReportTypeElementProps) {
  if (!report) {
    return <ReportInfoSkeleton />;
  } else if (report?.report_type === ReportNestedSchemaReportType.match) {
    return <MatchElement match={report.general_data as MatchGeneralData} />;
  } else if (report?.report_type === ReportNestedSchemaReportType.player) {
    return <PlayerElement player={report.general_data as PlayerGeneralData} />;
  } else if (report?.report_type === ReportNestedSchemaReportType.player_comparison) {
    return (
      <PlayerComparisonElement
        player1={report.general_data!.player1 as PlayerGeneralData}
        player2={report.general_data!.player2 as PlayerGeneralData}
      />
    );
  } else if (report?.report_type === ReportNestedSchemaReportType.team) {
    return <TeamElement team={report.general_data as TeamGeneralData} />;
  } else if (report?.report_type === ReportNestedSchemaReportType.team_comparison) {
    return (
      <TeamComparisonElement
        team1={report.general_data!.team1 as TeamGeneralData}
        team2={report.general_data!.team2 as TeamGeneralData}
      />
    );
  } else if (report?.report_type === ReportNestedSchemaReportType.scout) {
    return (
      <ScoutElement
        filteredPlayers={report.general_data!.players_filtered as ScoutPlayerData[]}
        manualPlayers={report.general_data!.players_manual as ScoutPlayerData[]}
      />
    );
  }
});

export default ReportTypeElement;
