import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import { stringToValueOption } from 'utils/mappings';
import { attributeValuesOption, loadAttributeValues } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';

interface PositionMapElementFormValues extends ElementFormValues {
  position_type: ValueOption | null;
}

const PositionMapElementForm = memo(function PositionMapElementForm({
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<PositionMapElementFormValues> = {
    position_type: null
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  } else {
    if (template.attribute_choices?.position_type.length > 0) {
      defaultValues.position_type = attributeValuesOption(template.attribute_choices?.position_type);
    }
  }
  const { control, formState, handleSubmit } = useForm<PositionMapElementFormValues>({ defaultValues });

  const addElement = useCallback(
    function addElement(data: PositionMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const positionTypeOptions = useMemo(() => {
    return template.attribute_choices?.position_type?.map(stringToValueOption) || [];
  }, [template.attribute_choices?.position_type]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SelectInput
        formProps={{
          name: 'position_type',
          control: control,
          rules: { required: 'Position type is required!' }
        }}
        disabled={!!defaultValues.position_type && positionTypeOptions.length === 1}
        label={'Position Type'}
        options={positionTypeOptions}
        error={formState.errors.position_type}
      />
    </form>
  );
});

export default PositionMapElementForm;
