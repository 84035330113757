import { memo, PropsWithChildren } from 'react';

const ElementRow = memo(function ElementRow({ children }: PropsWithChildren) {
  return (
    <div className="flex w-full items-center justify-between gap-6 rounded-md bg-gray-50 p-3 text-xs font-medium">
      {children}
    </div>
  );
});

export default ElementRow;
