/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdSubscriptionMutator,
  default as getProjectProjectIdSubscriptionSubscriptionIdMutator,
  default as postProjectProjectIdSubscriptionAddOnMutator,
  default as postProjectProjectIdSubscriptionMutator,
  default as putProjectProjectIdSubscriptionMutator
} from '../../api/axios';
import type {
  GetProjectProjectIdSubscription200,
  GetProjectProjectIdSubscriptionParams,
  PutProjectProjectIdSubscriptionParams,
  SubscriptionAddOnAddSchema,
  SubscriptionAddOnSchema,
  SubscriptionCreateSchema,
  SubscriptionGetSchema,
  SubscriptionsSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all project subscritions.
 */
export const getProjectProjectIdSubscription = (
  projectId: string,
  params?: GetProjectProjectIdSubscriptionParams,
  options?: SecondParameter<typeof getProjectProjectIdSubscriptionMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdSubscriptionMutator<GetProjectProjectIdSubscription200>(
    { url: `/project/${encodeURIComponent(String(projectId))}/subscription`, method: 'GET', params, signal },
    options
  );
};

export const getGetProjectProjectIdSubscriptionQueryKey = (
  projectId: string,
  params?: GetProjectProjectIdSubscriptionParams
) => {
  return [`/project/${projectId}/subscription`, ...(params ? [params] : [])] as const;
};

export const getGetProjectProjectIdSubscriptionQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscription>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdSubscriptionParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscription>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdSubscriptionQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdSubscription>>> = ({ signal }) =>
    getProjectProjectIdSubscription(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdSubscription>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdSubscriptionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdSubscription>>
>;
export type GetProjectProjectIdSubscriptionQueryError = unknown;

/**
 * @summary Returns a list of all project subscritions.
 */
export const useGetProjectProjectIdSubscription = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscription>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectProjectIdSubscriptionParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscription>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdSubscriptionQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a subscription for the given project.
 */
export const postProjectProjectIdSubscription = (
  projectId: string,
  subscriptionCreateSchema: SubscriptionCreateSchema,
  options?: SecondParameter<typeof postProjectProjectIdSubscriptionMutator>
) => {
  return postProjectProjectIdSubscriptionMutator<SubscriptionsSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscriptionCreateSchema
    },
    options
  );
};

export const getPostProjectProjectIdSubscriptionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscription>>,
    TError,
    { projectId: string; data: SubscriptionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdSubscription>>,
  TError,
  { projectId: string; data: SubscriptionCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdSubscription>>,
    { projectId: string; data: SubscriptionCreateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdSubscription(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdSubscription>>
>;
export type PostProjectProjectIdSubscriptionMutationBody = SubscriptionCreateSchema;
export type PostProjectProjectIdSubscriptionMutationError = unknown;

/**
 * @summary Creates a subscription for the given project.
 */
export const usePostProjectProjectIdSubscription = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscription>>,
    TError,
    { projectId: string; data: SubscriptionCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdSubscription>>,
  TError,
  { projectId: string; data: SubscriptionCreateSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Requests a change in the subscription tier
 */
export const putProjectProjectIdSubscription = (
  projectId: string,
  params?: PutProjectProjectIdSubscriptionParams,
  options?: SecondParameter<typeof putProjectProjectIdSubscriptionMutator>
) => {
  return putProjectProjectIdSubscriptionMutator<void>(
    { url: `/project/${encodeURIComponent(String(projectId))}/subscription`, method: 'PUT', params },
    options
  );
};

export const getPutProjectProjectIdSubscriptionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdSubscription>>,
    TError,
    { projectId: string; params?: PutProjectProjectIdSubscriptionParams },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdSubscriptionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putProjectProjectIdSubscription>>,
  TError,
  { projectId: string; params?: PutProjectProjectIdSubscriptionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putProjectProjectIdSubscription>>,
    { projectId: string; params?: PutProjectProjectIdSubscriptionParams }
  > = (props) => {
    const { projectId, params } = props ?? {};

    return putProjectProjectIdSubscription(projectId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutProjectProjectIdSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putProjectProjectIdSubscription>>
>;

export type PutProjectProjectIdSubscriptionMutationError = unknown;

/**
 * @summary Requests a change in the subscription tier
 */
export const usePutProjectProjectIdSubscription = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putProjectProjectIdSubscription>>,
    TError,
    { projectId: string; params?: PutProjectProjectIdSubscriptionParams },
    TContext
  >;
  request?: SecondParameter<typeof putProjectProjectIdSubscriptionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putProjectProjectIdSubscription>>,
  TError,
  { projectId: string; params?: PutProjectProjectIdSubscriptionParams },
  TContext
> => {
  const mutationOptions = getPutProjectProjectIdSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Adds an add-on to the current subscription of the project.
 */
export const postProjectProjectIdSubscriptionAddOn = (
  projectId: string,
  subscriptionAddOnAddSchema: SubscriptionAddOnAddSchema,
  options?: SecondParameter<typeof postProjectProjectIdSubscriptionAddOnMutator>
) => {
  return postProjectProjectIdSubscriptionAddOnMutator<SubscriptionAddOnSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/subscription/add-on`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: subscriptionAddOnAddSchema
    },
    options
  );
};

export const getPostProjectProjectIdSubscriptionAddOnMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>,
    TError,
    { projectId: string; data: SubscriptionAddOnAddSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionAddOnMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>,
  TError,
  { projectId: string; data: SubscriptionAddOnAddSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>,
    { projectId: string; data: SubscriptionAddOnAddSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return postProjectProjectIdSubscriptionAddOn(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostProjectProjectIdSubscriptionAddOnMutationResult = NonNullable<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>
>;
export type PostProjectProjectIdSubscriptionAddOnMutationBody = SubscriptionAddOnAddSchema;
export type PostProjectProjectIdSubscriptionAddOnMutationError = unknown;

/**
 * @summary Adds an add-on to the current subscription of the project.
 */
export const usePostProjectProjectIdSubscriptionAddOn = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>,
    TError,
    { projectId: string; data: SubscriptionAddOnAddSchema },
    TContext
  >;
  request?: SecondParameter<typeof postProjectProjectIdSubscriptionAddOnMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postProjectProjectIdSubscriptionAddOn>>,
  TError,
  { projectId: string; data: SubscriptionAddOnAddSchema },
  TContext
> => {
  const mutationOptions = getPostProjectProjectIdSubscriptionAddOnMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns the subscription object with the given id.
 */
export const getProjectProjectIdSubscriptionSubscriptionId = (
  projectId: string,
  subscriptionId: string,
  options?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdSubscriptionSubscriptionIdMutator<SubscriptionGetSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/subscription/${encodeURIComponent(String(subscriptionId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdSubscriptionSubscriptionIdQueryKey = (projectId: string, subscriptionId: string) => {
  return [`/project/${projectId}/subscription/${subscriptionId}`] as const;
};

export const getGetProjectProjectIdSubscriptionSubscriptionIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>,
  TError = unknown
>(
  projectId: string,
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetProjectProjectIdSubscriptionSubscriptionIdQueryKey(projectId, subscriptionId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>> = ({
    signal
  }) => getProjectProjectIdSubscriptionSubscriptionId(projectId, subscriptionId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && subscriptionId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdSubscriptionSubscriptionIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>
>;
export type GetProjectProjectIdSubscriptionSubscriptionIdQueryError = unknown;

/**
 * @summary Returns the subscription object with the given id.
 */
export const useGetProjectProjectIdSubscriptionSubscriptionId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>,
  TError = unknown
>(
  projectId: string,
  subscriptionId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdSubscriptionSubscriptionId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdSubscriptionSubscriptionIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdSubscriptionSubscriptionIdQueryOptions(projectId, subscriptionId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
