import { memo, useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import { ValueOption, Values } from 'utils/interfaces';

interface ButtonTabsProps<T extends readonly ValueOption[]> {
  options: T;
  selected: Values<T>;
  setSelected: React.Dispatch<React.SetStateAction<Values<T>>>;
  className?: string;
}

const ButtonTabs = memo(function ButtonTabs<T extends readonly ValueOption[]>({
  options,
  selected,
  setSelected,
  className
}: ButtonTabsProps<T>) {
  const handleClick = useCallback(
    function handleClick(option: ValueOption) {
      return () => {
        setSelected(option);
      };
    },
    [setSelected]
  );

  return (
    <div className="flex w-full">
      {options.map((option, index) => (
        <button
          className={twMerge(
            'grow border px-3 py-2 text-center text-xs font-medium',
            selected.id === option.id
              ? 'z-[1] border-brand-800 bg-brand-50 text-brand-800'
              : 'border-gray-400 text-gray-500',
            index === 0 && 'rounded-l-md',
            index !== 0 && '-ml-px',
            index === options.length - 1 && 'rounded-r-md',
            className && className
          )}
          key={option.id}
          onClick={handleClick(option)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
});

export default ButtonTabs;
