import { memo } from 'react';
import DashboardProgressBar from './DashboardProgressBar';

function DashboardSubscriptionUsersSkeleton() {
  return (
    <>
      <span className="text-xs font-medium">Owner</span>
      <DashboardProgressBar progress={0} />
      <div className="flex justify-end gap-px">
        <span className="text-xs font-semibold">-</span>
        <span className="text-xs font-semibold text-gray-400">/</span>
        <span className="text-xs font-semibold text-gray-400">-</span>
      </div>
      <span className="text-xs font-medium">Admin</span>
      <DashboardProgressBar progress={0} />
      <div className="flex justify-end gap-px">
        <span className="text-xs font-semibold">-</span>
        <span className="text-xs font-semibold text-gray-400">/</span>
        <span className="text-xs font-semibold text-gray-400">-</span>
      </div>
      <span className="text-xs font-medium">Editor</span>
      <DashboardProgressBar progress={0} />
      <div className="flex justify-end gap-px">
        <span className="text-xs font-semibold">-</span>
        <span className="text-xs font-semibold text-gray-400">/</span>
        <span className="text-xs font-semibold text-gray-400">-</span>
      </div>
      <span className="text-xs font-medium">Viewer</span>
      <DashboardProgressBar progress={0} />
      <div className="flex justify-end gap-px">
        <span className="text-xs font-semibold">-</span>
        <span className="text-xs font-semibold text-gray-400">/</span>
        <span className="text-xs font-semibold text-gray-400">-</span>
      </div>
    </>
  );
}

export default memo(DashboardSubscriptionUsersSkeleton);
