import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import Close from 'assets/close.svg?react';
import { memo, PropsWithChildren, useCallback, useEffect } from 'react';
import Divider from '../Divider';

interface DialogBaseProps extends PropsWithChildren {
  title: string;
  open: boolean;
  onCancel: () => void;
}

const SideDialogBase = memo(function SideDialogBase({ title, open, onCancel, children }: DialogBaseProps) {
  const handleCancel = useCallback(
    function handleCancel() {
      onCancel();
    },
    [onCancel]
  );

  // enable scrolling for side dialog
  // https://github.com/tailwindlabs/headlessui/discussions/510
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        document.documentElement.style.overflow = 'unset';
      }, 500);
    }
  }, [open]);

  return (
    <Transition show={open}>
      <Dialog as="div" className="relative z-50" onClose={handleCancel}>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto sticky inset-y-0 right-0 flex size-full max-h-screen min-w-96 max-w-screen-md flex-col bg-white shadow-card transition-all">
                  <header className="flex grow-0 flex-col gap-8 px-10 pt-10">
                    <div className="flex items-center justify-between">
                      <DialogTitle as="h3" className="text-base font-bold">
                        {title}
                      </DialogTitle>
                      <button className="flex items-center justify-center" onClick={handleCancel}>
                        <Close width={24} height={24} className="fill-gray-700" />
                      </button>
                    </div>
                    <Divider />
                  </header>
                  {children}
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default SideDialogBase;
