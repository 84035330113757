import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Transition } from '@headlessui/react';
import { Fragment, memo, PropsWithChildren, useState } from 'react';
import { Portal } from 'utils/helpers';

interface PortalTooltipProps extends PropsWithChildren {
  message: string;
  gap?: number;
}

const PortalTooltip = memo(function PortalTooltip({ message, gap = 8, children }: PortalTooltipProps) {
  const [open, setOpen] = useState<boolean>(false);
  const { refs, floatingStyles } = useFloating({
    placement: 'top',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  function hide() {
    setOpen(false);
  }

  function show() {
    setOpen(true);
  }

  return (
    <div ref={refs.setReference} onMouseEnter={show} onMouseLeave={hide}>
      {children}
      <Portal>
        <div ref={refs.setFloating} style={floatingStyles} className="z-[100]">
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-in duration-150"
            enterFrom="scale-0"
            enterTo="scale-100"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="relative flex flex-col gap-1 whitespace-break-spaces" style={{ bottom: gap }}>
              {message && (
                <span className="rounded bg-gray-950 p-2 text-xs text-white after:absolute after:left-1/2 after:top-full after:ml-[-5px] after:border-[5px] after:border-solid after:border-x-transparent after:border-b-transparent after:border-t-gray-950">
                  {message}
                </span>
              )}
            </div>
          </Transition>
        </div>
      </Portal>
    </div>
  );
});

export default PortalTooltip;
