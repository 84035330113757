import { useQueryClient } from '@tanstack/react-query';
import MailSent from 'assets/mail-sent.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ProjectGetSchema, SubscriptionTierSchema } from 'lib/model';
import { usePutProjectProjectIdSubscription } from 'lib/subscription/subscription';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import Divider from 'modules/common/Divider';
import TierPlanTitle from 'modules/common/TierPlanTitle';
import { memo } from 'react';
import { DialogProps } from 'utils/interfaces';

interface TierPlanChangeDialogProps extends DialogProps {
  tierPlan: SubscriptionTierSchema;
}

function TierPlanChangeDialog({ tierPlan, open, setOpen }: TierPlanChangeDialogProps) {
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectGetSchema>(['project']);
  const { user } = useAuth();
  const { mutate: sendRequest, isPending, isSuccess } = usePutProjectProjectIdSubscription();

  function handleClose() {
    setOpen(false);
  }

  function sendTierPlanChangeRequest() {
    sendRequest({ projectId: project!.id!, params: { subscription_tier_id: tierPlan.id } });
  }

  const priceMonthly = tierPlan.price_monthly === null || tierPlan.price_monthly === undefined || tierPlan.price_monthly === 0 ? 'Inquire' :
    parseFloat(tierPlan.price_monthly!.toString()).toFixed(2);
  const priceYearly = tierPlan.price_yearly === null || tierPlan.price_yearly === undefined || tierPlan.price_yearly === 0 ? 'Inquire' :
    parseFloat(tierPlan.price_yearly!.toString()).toFixed(2);

  return (
    <DialogBase title="Payment" open={open} onCancel={handleClose} narrower>
      <DialogContent>
        {!isSuccess ? (
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-6">
              <span className="text-md font-semibold">Chosen Tier plan:</span>
              <div className="flex flex-col gap-3 rounded-xl bg-brand-50 p-6">
                <TierPlanTitle name={tierPlan.name!} />
                <p className="text-left text-sm text-black">{tierPlan.description}</p>
                <Divider />
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-black">Monthly subscription:</span>
                  <span className="text-md font-semibold text-black">{priceMonthly}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm font-medium text-black">
                    Annual subscription:
                  </span>
                  <span className="text-md font-semibold text-black">{priceYearly}</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <span className="text-md font-semibold">Send us a request!</span>
              <span className="text-md">We will follow up this conversation through email :)</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center gap-10">
            <span className="text-h6 font-semibold text-gray-900">Your tier plan request has been sent!</span>
            <MailSent className="my-10 size-16 fill-brand-800" />
            <div className="flex flex-col items-center gap-6">
              <span className="text-md">
                We'll contact you shortly at: <b>{user.email}</b>
              </span>
              <span className="text-center text-md font-medium">
                Our response usually takes no longer than 24 hours. <br /> Thanks for your patience!
              </span>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogFooter>
        {!isSuccess ? (
          <Button size="xl" variant="primary" onClick={sendTierPlanChangeRequest} loading={isPending}>
            Send request
          </Button>
        ) : (
          <Button size="xl" variant="primary" onClick={handleClose}>
            Ok
          </Button>
        )}
      </DialogFooter>
    </DialogBase>
  );
}

export default memo(TierPlanChangeDialog);
