import Loader from 'assets/loading.svg?react';
import { memo, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

interface ButtonProps extends PropsWithChildren {
  variant?: 'primary' | 'secondary' | 'dark' | 'red' | 'white' | 'checkbox';
  size?: 'tiny' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isFullWidth?: boolean;
  isSubmitButton?: boolean;
  form?: string;
  disabled?: boolean;
  loading?: boolean;
}

const Button = memo(function Button({
  variant = 'primary',
  size = 'md',
  isFullWidth = true,
  onClick,
  children,
  isSubmitButton = false,
  disabled = false,
  form,
  loading = false
}: ButtonProps) {
  return (
    // tailwind merge recognizes text-btnMd (and others) as text color rather than font size since it uses a word
    // https://github.com/dcastil/tailwind-merge/blob/v1.12.0/docs/configuration.md
    // so if we use twMerge to remove padding for 'brand' variant, either:
    // a) the font size will be off (overwritten by text color)
    // b) the text color will be off (overwritten by text size)
    // that's why we use base tailwind names (xs, sm, base) + leading-5 for line height
    <button
      type={isSubmitButton ? 'submit' : 'button'}
      onClick={onClick}
      disabled={disabled || loading}
      form={form}
      className={twMerge(
        'flex min-w-fit items-center justify-center gap-2 rounded-md border disabled:cursor-not-allowed',
        isFullWidth ? 'w-full' : 'w-fit',
        size === 'tiny' && 'px-2 py-0.5 text-xs font-medium leading-5',
        size === 'xs' && 'px-2 py-1 text-sm font-medium leading-5',
        size === 'sm' && 'px-2.5 py-1.5 text-sm font-medium leading-5',
        size === 'md' && 'px-3 py-2 text-sm font-semibold leading-5',
        size === 'lg' && 'px-3.5 py-2.5 text-sm font-semibold leading-5',
        size === 'xl' && 'px-4 py-3.5 text-base font-semibold leading-5',
        variant === 'primary' &&
          'border-brand-800 bg-brand-800 text-white *:fill-white disabled:border-brand-500 disabled:bg-brand-500',
        variant === 'secondary' &&
          'border-gray-400 bg-transparent text-gray-600 *:fill-gray-700 disabled:text-gray-400 *:disabled:fill-gray-400',
        variant === 'dark' &&
          'border-gray-950 bg-gray-950 text-white *:fill-white disabled:border-gray-400 disabled:bg-gray-400',
        variant === 'white' && 'border-white bg-white *:fill-gray-700 disabled:text-gray-400 *:disabled:fill-gray-400',
        variant === 'red' &&
          'border-red-600 bg-red-600 text-white *:fill-white disabled:border-red-300 disabled:bg-red-300'
      )}
    >
      {loading ? <Loader className="size-5 animate-spin fill-white" /> : children}
    </button>
  );
});

export default Button;
