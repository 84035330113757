import { memo } from 'react';

function EventsSkeleton() {
  return (
    <div className="flex flex-col gap-4">
      <div className="h-4 w-1/6 animate-pulse rounded-xl bg-gray-50" />
      <div className="flex flex-col gap-1">
        <div className="flex animate-pulse flex-col gap-3 rounded-md bg-gray-50 p-3">
          <div className="h-5 w-1/2 animate-pulse rounded-xl bg-gray-100" />
          <div className="flex justify-between gap-6">
            <div className="h-4 w-1/6 animate-pulse rounded-xl bg-gray-100" />
            <div className="h-4 w-1/6 animate-pulse rounded-xl bg-gray-100" />
          </div>
        </div>
        <div className="flex animate-pulse flex-col gap-3 rounded-md bg-gray-50 p-3">
          <div className="h-5 w-1/2 animate-pulse rounded-xl bg-gray-100" />
          <div className="flex justify-between gap-6">
            <div className="h-4 w-1/6 animate-pulse rounded-xl bg-gray-100" />
            <div className="h-4 w-1/6 animate-pulse rounded-xl bg-gray-100" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(EventsSkeleton);
