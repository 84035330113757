import { memo } from 'react';
import ElementRow from '../../elements/ElementRow';
import { PlayerGeneralData } from '../interfaces';
import PlayerHeader from './PlayerHeader';

interface PlayerElementProps {
  player: PlayerGeneralData;
}

const PlayerElement = memo(function PlayerElement({ player }: PlayerElementProps) {
  return (
    <div className="flex flex-col gap-6">
      <PlayerHeader player={player} textSize="lg" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">AGE</span>
          <span>{player.age ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">BIRTH DATE</span>
          <span>{player.birth_date ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">HEIGHT</span>
          <span>{player.height ? `${player.height} cm` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">WEIGHT</span>
          <span>{player.weight ? `${player.weight} kg` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">POSITION</span>
          <span>{player.position_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">TEAM</span>
          <span>{player.team_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">NATIONALITY</span>
          <span>{player.country ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">VALUE</span>
          <span>{player.value ?? '-'}</span>
        </ElementRow>
      </div>
      {(player.current_ability || player.potential_ability || player.expected_potential_ability) && (
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-center justify-center rounded-md p-3 text-sm font-medium">
            Player Scores
          </div>
          <ElementRow>
            <span className="text-xs font-semibold uppercase">CURRENT ABILITY</span>
            <span className="text-xs font-bold">{player.current_ability ?? '-'}</span>
          </ElementRow>
          <ElementRow>
            <span className="text-xs font-semibold uppercase">POTENTIAL ABILITY</span>
            <span className="text-xs font-semibold">{player.potential_ability ?? '-'}</span>
          </ElementRow>
          <ElementRow>
            <span className="text-xs font-semibold uppercase">EXPECTED POTENTIAL ABILITY</span>
            <span className="text-xs font-semibold">{player.expected_potential_ability ?? '-'}</span>
          </ElementRow>
        </div>
      )}
    </div>
  );
});

export default PlayerElement;
