import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Info from 'assets/info.svg?react';
import { AxiosError } from 'axios';
import { ProjectSchema, ReportCreateSchemaReportType } from 'lib/model';
import { usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import { memo, useCallback, useState } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { scoutFormToScoutSettings } from '../helpers';
import { ScoutReportFormValues } from '../interfaces';
import ScoutReportAddManualPlayer from '../ScoutReportAddManualPlayer';
import ScoutReportManualPlayerList from '../ScoutReportManualPlayerList';
import ScoutReportManualFromMetricButton from './ScoutReportManualFromMetricButton';

interface ScoutReportManualFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  onBack: () => void;
}

function ScoutReportManualForm({ onBack, scoutReportForm }: ScoutReportManualFormProps) {
  const [isAddingPlayer, setIsAddingPlayer] = useState<boolean>(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const project = queryClient.getQueryData<ProjectSchema>(['project'])!;

  const formData = useWatch(scoutReportForm) as Required<ScoutReportFormValues>;
  const formState = useFormState(scoutReportForm);
  const totalPlayers = (formData.players?.length ?? 0) + (Number(formData.numberOfFilteredPlayers) ?? 0);

  const { mutate: createReport, isPending: isCreating } = usePostReport();
  const handleCreateReport = useCallback(
    function handleCreateReport(data: ScoutReportFormValues) {
      const scoutReportSettings = scoutFormToScoutSettings(data);

      createReport(
        {
          data: {
            name: data.reportName!,
            report_type: ReportCreateSchemaReportType.scout,
            project: project!.id!,
            report_entities: data.players.map((player) => player.player_id as number),
            scout_report_settings: scoutReportSettings
          }
        },
        {
          onSuccess: (res) => {
            queryClient.invalidateQueries({ queryKey: ['reports'] });
            navigate(`/reports/${res.id!}`);
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              scoutReportForm.setError('root', { message: err.response?.data.error, type: 'backend-validation' });
            }
          }
        }
      );
    },
    [createReport, navigate, project, scoutReportForm]
  );

  return (
    <>
      <DialogContent>
        <form
          className="flex flex-col gap-6"
          id="manual-scout-report-form"
          onSubmit={scoutReportForm.handleSubmit(handleCreateReport)}
        >
          <span className="text-md font-medium">Report name</span>
          <Input
            label="Enter report name"
            registerReturn={{
              ...scoutReportForm.register('reportName', {
                required: { value: true, message: 'Report name is required.' }
              })
            }}
            error={formState.errors.reportName}
          />
          {formState.errors?.root && <ErrorMessage error={formState.errors.root} />}
        </form>
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <span className="text-md font-medium">Players: {totalPlayers}</span>
            <p className="text-sm">You can add up to maximum of 20 players in your report.</p>
          </div>
          <div className="flex gap-2 rounded-md bg-brand-50 px-6 py-4">
            <Info className="size-5 fill-brand-800" />
            <p className="text-sm text-brand-800">The players are ranked by their performance and characteristics</p>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between gap-6">
            <span className="text-md font-semibold">{formData.position?.label}</span>
            <div className="flex items-center gap-2">
              <span className="text-md">Players sorted by:</span>
              <ScoutReportManualFromMetricButton scoutReportForm={scoutReportForm} />
            </div>
          </div>
          <ScoutReportManualPlayerList scoutReportForm={scoutReportForm} />
        </div>
        <ScoutReportAddManualPlayer
          scoutReportForm={scoutReportForm}
          currentPlayers={formData.players}
          position={formData.position}
          totalPlayers={totalPlayers}
          isAddingPlayer={isAddingPlayer}
          setIsAddingPlayer={setIsAddingPlayer}
        />
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={onBack} disabled={isCreating}>
          <Back width={20} height={20} />
          <span>Back</span>
        </Button>
        <Button
          size="xl"
          isSubmitButton
          form="manual-scout-report-form"
          loading={isCreating}
          disabled={totalPlayers === 0 || isAddingPlayer}
        >
          <span>Create Scout Report</span>
        </Button>
      </DialogFooter>
    </>
  );
}

export default memo(ScoutReportManualForm);
