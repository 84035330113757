import MailSent from 'assets/mail-sent.svg?react';
import { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

const EmailVerificationPage = memo(function EmailVerificationPage() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') ?? 'noemail@gmail.com';

  return (
    <div className="flex w-full max-w-xl flex-col items-center gap-10 rounded-xl bg-white p-16 text-center">
      <h6 className="text-h6 font-semibold text-gray-900">Registration almost complete</h6>
      <div className="flex h-32 items-center justify-center">
        <MailSent width={64} height={64} className="fill-brand-800" />
      </div>
      <p className="text-md">
        An email with a confirmation code has been sent to:
        <br />
        <span className="font-semibold">{email}</span>
        <br />
        Check your email to finish the registration.
      </p>
    </div>
  );
});

export default EmailVerificationPage;
