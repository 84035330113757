/* eslint-disable react/jsx-no-bind */
import Attachment from 'assets/attachment.svg?react';
import File from 'assets/file.svg?react';
import Trash from 'assets/trash-remove.svg?react';
import { useObjectsOptions } from 'hooks/useOptions';
import { SupportCategorySchema } from 'lib/model';
import { useGetUserSupportCategory, usePostUserSupportTicket } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import TextArea from 'modules/common/Form/TextArea';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { toast } from 'react-toastify';
import { ValueOption } from 'utils/interfaces';

interface CustomerSupportFormValues {
  category: ValueOption;
  subcategory: ValueOption;
  description: string;
}

function CustomerSupport() {
  const { data: categories } = useGetUserSupportCategory({ query: { queryKey: ['customerSupportCategories'] } });
  const categoryOptions = useObjectsOptions(categories);
  const { control, register, handleSubmit, watch, resetField, reset } = useForm<CustomerSupportFormValues>();
  const selectedCategory = watch('category');
  const [subcategories, setSubcategories] = useState<ValueOption[]>([]);
  const { mutate: submitTicket, isPending: isTicketSubmitPending } = usePostUserSupportTicket();
  const [images, setImages] = useState<ImageListType>([]);
  function imagesChanged(imageList: ImageListType) {
    setImages(imageList);
  }

  function sendSupportTicket(data: CustomerSupportFormValues) {
    submitTicket(
      {
        data: {
          user_support_category: data.category.id as string,
          user_support_subcategory: data.subcategory ? (data.subcategory.id as string) : undefined,
          description: data.description,
          files: images.map((x) => x.dataURL!) ?? []
        }
      },
      {
        onSuccess: () => {
          toast.success('Support ticket successfully created. We will contact you as soon as possible.');
          reset();
        }
      }
    );
  }

  useEffect(() => {
    const subscription = watch(({ category }, { name }) => {
      if (name === 'category') {
        resetField('subcategory');
        setSubcategories(
          (category?.value as SupportCategorySchema).subcategories!.map((x) => ({ id: x.id!, label: x.name! }))
        );
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField]);

  return (
    <section className="flex flex-col gap-10 pt-10">
      <span className="text-lg font-semibold text-gray-950">Customer Support</span>
      <form
        onSubmit={handleSubmit(sendSupportTicket)}
        className="flex min-w-[640px] flex-col gap-10 rounded-xl bg-white p-10"
      >
        <span className="text-h6 font-semibold text-gray-900">Contact Customer Support</span>
        <div className="flex flex-col gap-6">
          <span className="text-md font-medium">
            Please fill out the form below to contact our customer support team.
          </span>
          <SelectInput
            formProps={{
              control: control,
              name: 'category'
            }}
            options={categoryOptions}
            label="Select the issue category that best desribes your problem"
          />
          <SelectInput
            formProps={{ control: control, name: 'subcategory' }}
            options={subcategories}
            disabled={!selectedCategory}
            label="Choose the sub-category based on your selected issue category"
          />
          <TextArea
            registerReturn={register('description')}
            label="Provide a detailed description of the issue so we can better assist you."
            placeholder="Enter description"
          />
        </div>
        <div className="flex flex-col gap-6">
          <span className="text-sm font-medium">Attach any relevant images if necessary.</span>
          <ImageUploading
            value={images}
            onChange={imagesChanged}
            maxNumber={10}
            acceptType={['jpg', 'gif', 'png', 'jpeg', 'svg']}
            multiple
          >
            {({ imageList, onImageUpload, errors, onImageRemove }) => (
              <div className="flex flex-col gap-6">
                <Button variant="secondary" size="md" onClick={onImageUpload}>
                  <Attachment className="size-5 fill-gray-700" />
                  Attach files
                </Button>
                {errors && (
                  <div className="flex flex-col gap-2">
                    {errors.maxNumber && (
                      <span className="text-sm font-semibold text-red-500">
                        You can only upload a maximum of 10 images.
                      </span>
                    )}
                    {errors.acceptType && (
                      <span className="text-sm font-semibold text-red-500">You can only upload images.</span>
                    )}
                  </div>
                )}
                {imageList.length > 0 && (
                  <div className="flex flex-col gap-2">
                    {imageList.map((image, index) => (
                      <div
                        key={image.file!.name + index}
                        className="flex h-14 items-center justify-between rounded-md bg-gray-50 p-4"
                      >
                        <span className="flex items-center gap-1 text-sm font-semibold">
                          <File className="size-5 fill-gray-700" />
                          {image.file?.name}
                        </span>
                        <span
                          className="flex cursor-pointer items-center gap-1 text-sm font-medium text-brand-800"
                          onClick={() => onImageRemove(index)}
                        >
                          Remove <Trash className="size-5 fill-brand-800" />
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </ImageUploading>
        </div>
        <Button variant="primary" isSubmitButton size="xl" loading={isTicketSubmitPending}>
          Submit
        </Button>
      </form>
    </section>
  );
}

export default memo(CustomerSupport);
