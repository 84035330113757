import { memo } from 'react';

const ReportCardSkeleton = memo(function ReportCardSkeleton() {
  return (
    <div className="flex flex-col gap-4 rounded-xl bg-white p-4 transition-transform duration-200 hover:-translate-y-1 hover:shadow-card">
      <div className="h-20 animate-pulse rounded-xl bg-gray-50" />
      <div className="h-5 w-1/4 animate-pulse rounded-md bg-gray-50" />
      <div className="flex w-full items-center justify-between gap-6">
        <div className="h-8 basis-1/3 animate-pulse rounded-md bg-gray-50" />
        <div className="h-4 basis-1/12 animate-pulse rounded-md bg-gray-50" />
      </div>
    </div>
  );
});

export default ReportCardSkeleton;
