import { memo, useCallback, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

interface CheckboxInputProps {
  registerReturnInput: UseFormRegisterReturn;
  registerReturnCheckbox?: UseFormRegisterReturn;
  label: string;
  oldValue: string;
  disabled?: boolean;
  error?: FieldError;
}

function CheckboxInput({
  registerReturnCheckbox,
  registerReturnInput,
  oldValue,
  error,
  label,
  disabled
}: CheckboxInputProps) {
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = useCallback(
    function handleChange() {
      setChecked((state) => !state);
    },
    [setChecked]
  );

  return (
    <div className={twJoin('flex flex-col gap-3 rounded-md p-3', checked ? 'bg-brand-50' : 'bg-gray-50')}>
      <div className="flex items-center justify-between gap-3">
        <div className="flex items-center gap-2">
          <input
            {...registerReturnCheckbox}
            disabled={disabled}
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            id={registerReturnCheckbox?.name ?? `${registerReturnInput.name}_checkbox`}
            className="size-4 rounded-sm border-gray-500 text-brand-800 focus:ring-0 ui-disabled:bg-gray-50"
          />
          <label
            htmlFor={registerReturnCheckbox?.name ?? `${registerReturnInput.name}_checkbox`}
            className="text-xs font-medium uppercase text-gray-500"
          >
            {label}:
          </label>
        </div>
        <span className="text-sm font-medium">{oldValue}</span>
      </div>
      <div className={twJoin('relative flex flex-col gap-2', !checked && 'hidden')}>
        <input
          {...registerReturnInput}
          disabled={disabled}
          placeholder={' '}
          id={registerReturnInput.name}
          type="text"
          className="peer block h-14 w-full appearance-none rounded-lg border border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0"
        />
        <label
          htmlFor={registerReturnInput.name}
          className="absolute start-3 top-1.5 z-10 origin-[0] text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs"
        >
          Enter correct value
        </label>
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  );
}
export default memo(CheckboxInput);
