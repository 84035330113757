import { autoUpdate, size as floatingUiSize, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Listbox, ListboxButton } from '@headlessui/react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loading from 'assets/loading.svg?react';
import ValueOptionsDropdown from 'modules/common/Form/ValueOptionsDropdown';
import { memo } from 'react';
import { twMerge } from 'tailwind-merge';
import { ValueOption } from 'utils/interfaces';

interface SelectButtonProps {
  options: ValueOption[];
  initial: ValueOption;
  onChange: (option: ValueOption) => void;
  loading?: boolean;
  size?: 'sm' | 'md';
}

const SelectButton = memo(function SelectButton({
  options,
  initial,
  onChange,
  loading = false,
  size = 'sm'
}: SelectButtonProps) {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      floatingUiSize({
        apply({ rects, elements }) {
          const minSize = Math.max(rects.reference.width, 200);
          Object.assign(elements.floating.style, {
            width: `${minSize}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  return (
    <Listbox className={'relative'} as={'div'} value={initial} onChange={onChange} by="id">
      {({ open, value }) => (
        <>
          <ListboxButton ref={refs.setReference} className="flex items-center gap-1 focus:outline-none focus:ring-0">
            <span className={twMerge('text-sm font-medium text-brand-800', size === 'md' && 'text-md')}>
              {value.label}
            </span>
            {loading ? (
              <Loading aria-hidden="true" className="size-5 animate-spin fill-brand-800" />
            ) : (
              <ChevronDown aria-hidden="true" className="size-5 fill-brand-800" />
            )}
          </ListboxButton>
          {!loading && open && (
            <ValueOptionsDropdown floatingStyles={floatingStyles} ref={refs.setFloating} options={options} />
          )}
        </>
      )}
    </Listbox>
  );
});

export default SelectButton;
