import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import { metricToMetricOption } from 'utils/mappings';
import { loadAttributeValues } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';

interface VersusElementFormValues extends ElementFormValues {
  metrics: ValueOption[];
}

const VersusElementForm = memo(function VersusElementForm({ template, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<VersusElementFormValues> = {
    metrics: []
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  }

  const { handleSubmit, watch, control, formState } = useForm<VersusElementFormValues>({
    defaultValues
  });
  const attributeType = watch('attribute_type');

  const addElement = useCallback(
    function addElement(data: VersusElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const metricsPlayersMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SelectInput
        formProps={{
          control: control,
          name: 'metrics',
          rules: {
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }
        }}
        multiple
        key={'metrics'}
        label={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={metricsPlayersMatchOptions}
        error={formState.errors.metrics}
      />
    </form>
  );
});

export default VersusElementForm;
