import { DatasourceSchema } from 'lib/model';
import { createContext, useContext } from 'react';

export interface FilteredDataSourcesContext {
  filteredDataSources: DatasourceSchema[];
  isCombination: boolean;
  isFiltered: boolean;
  isPendingFilteredDataSources: boolean;
}

export const FilteredDataSourcesContext = createContext<FilteredDataSourcesContext>({
  filteredDataSources: [],
  isCombination: false,
  isFiltered: false,
  isPendingFilteredDataSources: false
});

export default function useFilteredDataSources() {
  const context = useContext(FilteredDataSourcesContext);

  if (context === undefined) {
    throw new Error('FilteredDataSourcesContext was used outside of FilteredDataSourcesContext');
  }

  return context;
}
