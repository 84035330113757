import { PropsWithChildren, useState } from 'react';
import { DataSourceFilters, DataSourcesFiltersContext, defaultDataSourceFilters } from './dataSourcesFiltersContext';

export default function DataSourcesFiltersProvider({ children }: PropsWithChildren) {
  const [dataSourcesFilters, setDataSourcesFilters] = useState<DataSourceFilters>(defaultDataSourceFilters);

  return (
    <DataSourcesFiltersContext.Provider
      value={{
        dataSourcesFilters: dataSourcesFilters,
        setDataSourcesFilters: setDataSourcesFilters
      }}
    >
      {children}
    </DataSourcesFiltersContext.Provider>
  );
}
