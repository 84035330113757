import { Grid } from '@visx/grid';
import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { max } from '@visx/vendor/d3-array';
import Warning from 'assets/warning.svg?react';
import * as d3 from 'd3';
import useIsMobile from 'hooks/useIsMobile';
import ChartAxes from 'modules/reports/reportPage/components/elements/ChartAxes';
import ChartTooltip from 'modules/reports/reportPage/components/elements/ChartTooltip';
import { memo, useCallback, useMemo, useRef } from 'react';
import { IntegrationChartPoint } from './interfaces';

const getX = (point: IntegrationChartPoint) => point.x_value;
const getY = (point: IntegrationChartPoint) => point.y_value;

const colorScale = scaleOrdinal<string, string>({
  domain: ['SUCCESS', 'IN PROGRESS', 'FAILED'],
  range: ['#22C55E', '#FB923C', '#EF4444'] // Green-500, Orange-400, Red-500
});
const height = 200;
const chartOffset = 20;
const syncFailureHeight = 36;
const barWidth = 24;

export interface IntegrationChartProps {
  points: IntegrationChartPoint[];
  scale: 'day' | 'week' | 'month' | 'year';
}

const IntegrationChart = memo(function IntegrationChart({ points, scale }: IntegrationChartProps) {
  // TODO: axis names
  const xName = 'Time';
  const yName = 'Data';

  const { parentRef, width } = useParentSize({ debounceTime: 150 });
  const margin = {
    top: 0,
    right: 10,
    bottom: scale === 'day' ? 70 : 50,
    left: 50
  };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xScale = useMemo(
    () =>
      scaleTime<number>({
        domain: [points[0].x_value, points[points.length - 1].x_value]
      }),
    [points]
  );
  const yScale = useMemo(
    () =>
      scaleLinear<number>({
        domain: [0, max(points, getY)!],
        round: true
      }),
    [points]
  );

  const xValue = useCallback(
    function xValue(point: IntegrationChartPoint) {
      return xScale(getX(point)) ?? 0;
    },
    [xScale]
  );

  const yValue = useCallback(
    function yValue(point: IntegrationChartPoint) {
      return yScale(getY(point)) ?? 0;
    },
    [yScale]
  );

  xScale.range([chartOffset, chartWidth - chartOffset]);
  yScale.range([chartHeight - chartOffset, chartOffset]);

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } =
    useTooltip<IntegrationChartPoint>({
      tooltipOpen: false
    });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout.current = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const handleMouseEnter = useCallback(
    function handleMouseEnter(point: IntegrationChartPoint) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        const successful = point.datasources.every((ds) => ds.status !== 'failed');
        showTooltip({
          tooltipData: point,
          tooltipLeft: xValue(point) + 40,
          tooltipTop: successful ? yValue(point) - 18 - 8 : height - margin.bottom - syncFailureHeight - 18 - 8
        });
      };
    },
    [margin.bottom, showTooltip, xValue, yValue]
  );

  const isMobile = useIsMobile();
  const bottomPercentileTicks = useMemo(() => {
    if (!isMobile) {
      return points.map((point) => point.x_value);
    }
    const sortedDateValues = points.map((d) => d.x_value).sort();
    return [0, 25, 50, 75, 100].map((percentile) => d3.quantile(sortedDateValues, percentile / 100)!);
  }, [isMobile, points]);

  return (
    <div ref={parentRef} className="relative flex flex-col gap-6 bg-white">
      <svg height={height} className="w-full overflow-x-visible" ref={containerRef}>
        <rect width={chartWidth} height={chartHeight} x={margin.left} y={margin.top} fill="#F9FAFB" rx={12} ry={12} />
        <Group left={margin.left} top={margin.top}>
          <Grid
            xScale={xScale}
            yScale={yScale}
            width={chartWidth}
            height={chartHeight}
            strokeDasharray="2,2"
            stroke="#D1D5DB"
            strokeWidth={1}
            numTicksColumns={0}
          />
          <ChartAxes
            chartWidth={chartWidth}
            chartHeight={chartHeight}
            xScale={xScale}
            yScale={yScale}
            xUnit={xName}
            yUnit={yName}
            xType={'date'}
            xTimePrecision={scale}
            bottomTicks={bottomPercentileTicks}
            yNumberOfTicks={2}
          />
          {points?.map((point) => {
            const totalHeight = height - margin.bottom - margin.top - yValue(point);
            const barHeight = totalHeight / point.datasources.length;
            const successful = point.datasources.every((ds) => ds.status !== 'failed');
            return successful ? (
              <Group
                key={point.x_value.getTime()}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter(point)}
              >
                {point.datasources.map((ds, j) => (
                  <rect
                    key={`${point.x_value.getTime()}-${ds.id}`}
                    x={xValue(point) - barWidth / 2}
                    y={height - margin.bottom - (point.datasources.length - j) * barHeight}
                    height={barHeight}
                    width={barWidth}
                    fill={colorScale(ds.status)}
                  />
                ))}
              </Group>
            ) : (
              <Warning
                key={point.x_value.getTime()}
                className="size-6 fill-red-500"
                x={xValue(point) - barWidth / 2}
                y={height - margin.bottom - syncFailureHeight}
                onMouseLeave={handleMouseLeave}
                onMouseEnter={handleMouseEnter(point)}
              />
            );
          })}
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        {tooltipData && (
          <div className="flex flex-col gap-2">
            <div className="flex flex-col">
              <span>
                {scale === 'day' &&
                  `${tooltipData.x_value.toLocaleString(undefined, {
                    weekday: 'long'
                  })} - `}
                {tooltipData.x_value.toLocaleDateString()}
              </span>
              <span>{tooltipData.y_value} - data synced</span>
            </div>
            <br />
            <br />
            <div className="flex flex-col">
              {tooltipData.datasources.map((ds) => (
                <span key={ds.id}>
                  {ds.name} - {ds.status}
                </span>
              ))}
            </div>
          </div>
        )}
      </ChartTooltip>
    </div>
  );
});

export default IntegrationChart;
