/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import {
  default as getProjectProjectIdDataTableDataTableIdMutator,
  default as getProjectProjectIdDataTableMutator
} from '../../api/axios';
import type { DataTableSchema, DataTablesSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all available data_tables.
 */
export const getProjectProjectIdDataTable = (
  projectId: string,
  options?: SecondParameter<typeof getProjectProjectIdDataTableMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableMutator<DataTablesSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/data-table`, method: 'GET', signal },
    options
  );
};

export const getGetProjectProjectIdDataTableQueryKey = (projectId: string) => {
  return [`/project/${projectId}/data-table`] as const;
};

export const getGetProjectProjectIdDataTableQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTable>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTable>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDataTableMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDataTableQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTable>>> = ({ signal }) =>
    getProjectProjectIdDataTable(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTable>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTable>>
>;
export type GetProjectProjectIdDataTableQueryError = unknown;

/**
 * @summary Returns a list of all available data_tables.
 */
export const useGetProjectProjectIdDataTable = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTable>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTable>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectProjectIdDataTableMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns the data_table object with the given id.
 */
export const getProjectProjectIdDataTableDataTableId = (
  projectId: string,
  dataTableId: string,
  options?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>,
  signal?: AbortSignal
) => {
  return getProjectProjectIdDataTableDataTableIdMutator<DataTableSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/data-table/${encodeURIComponent(String(dataTableId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectProjectIdDataTableDataTableIdQueryKey = (projectId: string, dataTableId: string) => {
  return [`/project/${projectId}/data-table/${dataTableId}`] as const;
};

export const getGetProjectProjectIdDataTableDataTableIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectProjectIdDataTableDataTableIdQueryKey(projectId, dataTableId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>> = ({ signal }) =>
    getProjectProjectIdDataTableDataTableId(projectId, dataTableId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && dataTableId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectProjectIdDataTableDataTableIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>
>;
export type GetProjectProjectIdDataTableDataTableIdQueryError = unknown;

/**
 * @summary Returns the data_table object with the given id.
 */
export const useGetProjectProjectIdDataTableDataTableId = <
  TData = Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>,
  TError = unknown
>(
  projectId: string,
  dataTableId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getProjectProjectIdDataTableDataTableId>>, TError, TData>
    >;
    request?: SecondParameter<typeof getProjectProjectIdDataTableDataTableIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectProjectIdDataTableDataTableIdQueryOptions(projectId, dataTableId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
