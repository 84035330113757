import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import TeamShield from '../../TeamShield';

import { isLightColor, isWhite } from 'utils/helpers';
import { getDefaultColor } from '../../../../helpers';
import { MatchGeneralData } from '../interfaces';

interface MatchHeaderProps {
  match: MatchGeneralData;
  textSize?: 'tiny' | 'sm';
  narrower?: boolean;
}

function MatchHeader({ match, textSize = 'sm', narrower = false }: MatchHeaderProps) {
  const homeTeamColor = match.home_team_color ?? getDefaultColor(0);
  const awayTeamColor = match.away_team_color ?? getDefaultColor(1);
  return (
    <div className="relative grid grid-cols-2">
      <span
        className={twJoin(
          'absolute left-1/2 top-1/4 -translate-x-1/2 -translate-y-1/2 rounded-md bg-white px-2 py-0.5 font-semibold shadow-card',
          textSize === 'sm' && 'text-sm',
          textSize === 'tiny' && 'text-tiny'
        )}
      >
        {match.home_team_score} : {match.away_team_score}
      </span>
      <div
        className={twJoin(
          'flex flex-col items-center gap-3 rounded-l-xl px-3',
          narrower ? 'py-3' : 'py-6',
          isWhite(homeTeamColor) && 'border border-gray-300'
        )}
        style={{ backgroundColor: homeTeamColor }}
      >
        <TeamShield shadow color={homeTeamColor} />
        <span
          className={twJoin(
            'text-center font-semibold',
            textSize === 'sm' && 'text-sm',
            textSize === 'tiny' && 'text-tiny',
            isLightColor(homeTeamColor) ? 'text-black' : 'text-white'
          )}
        >
          {match.home_team_name}
        </span>
      </div>
      <div
        className={twJoin(
          'flex flex-col items-center gap-3 rounded-r-xl px-3',
          narrower ? 'py-3' : 'py-6',
          isWhite(awayTeamColor) && 'border border-gray-300'
        )}
        style={{ backgroundColor: awayTeamColor }}
      >
        <TeamShield shadow color={awayTeamColor} />
        <span
          className={twJoin(
            'text-center font-semibold',
            textSize === 'sm' && 'text-sm',
            textSize === 'tiny' && 'text-tiny',
            isLightColor(awayTeamColor) ? 'text-black' : 'text-white'
          )}
        >
          {match.away_team_name}
        </span>
      </div>
    </div>
  );
}

export default memo(MatchHeader);
