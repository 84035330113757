import { Fieldset as HeadlessUIFieldset, Legend } from '@headlessui/react';
import { memo, PropsWithChildren, ReactNode } from 'react';
import { twJoin } from 'tailwind-merge';
import { SvgComponent, SvgIcon } from 'utils/interfaces';

interface Action extends SvgIcon {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface FieldsetProps extends PropsWithChildren {
  legend: string;
  LegendIcon?: SvgComponent;
  description?: ReactNode;
  actions?: Action[];
  isActive?: boolean;
}

function Fieldset({ legend, LegendIcon, actions, description, children, isActive }: FieldsetProps) {
  return (
    <HeadlessUIFieldset
      className={twJoin('flex flex-col gap-6 rounded-md p-6', isActive ? 'bg-orange-50' : 'bg-gray-50')}
    >
      <div className="flex w-full flex-col gap-3">
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            {LegendIcon && <LegendIcon className="size-6 fill-gray-700" />}
            <Legend className="text-md font-medium">{legend}</Legend>
          </div>
          {actions && (
            <div className="flex items-center gap-4">
              {actions.length > 0 &&
                actions?.map((action) => (
                  <button onClick={action.onClick} key={action.text} className="flex items-center gap-2" type="button">
                    <action.Icon className="size-5 fill-brand-800" />
                    <span className="text-sm font-medium text-brand-800">{action.text}</span>
                  </button>
                ))}
            </div>
          )}
        </div>
        {description}
      </div>
      {children}
    </HeadlessUIFieldset>
  );
}

export default memo(Fieldset);
