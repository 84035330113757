/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */

export type DatasourceSchemaPricing = (typeof DatasourceSchemaPricing)[keyof typeof DatasourceSchemaPricing];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DatasourceSchemaPricing = {
  none: 'none',
  affordable: 'affordable',
  enhanced: 'enhanced',
  premium: 'premium'
} as const;
