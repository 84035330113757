import { memo, useCallback } from 'react';
import { TileOption } from 'utils/interfaces';

interface SingleSelectTilesProps {
  options: TileOption[];
  selected: string | null;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}

const SingleSelectTiles = memo(function SingleSelectTiles({ options, selected, setSelected }: SingleSelectTilesProps) {
  const handleChange = useCallback(
    function handleChange(option: TileOption) {
      return () => {
        setSelected(option.id);
      };
    },
    [setSelected]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-wrap gap-6">
        {options.map((option) => (
          <div key={option.id} className="grow basis-1">
            <input
              id={option.id}
              type="checkbox"
              className={`peer hidden`}
              name={option.id}
              value={option.id}
              checked={option.id === selected}
              onChange={handleChange(option)}
            />
            <label
              htmlFor={option.id}
              className={`group flex h-full flex-col items-center justify-center gap-2 rounded-md border border-gray-400 bg-white px-6 py-8 text-center peer-checked:border-brand-800 peer-checked:bg-brand-50 peer-disabled:bg-gray-50`}
            >
              <option.Icon width={40} height={40} className="fill-gray-300 peer-checked:group-[]:fill-brand-800" />
              <div className="flex flex-col items-center gap-1">
                <span className="text-md font-medium peer-checked:group-[]:text-brand-800">{option.label}</span>
                <span className="text-xs peer-checked:group-[]:text-brand-800">{option.description}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
});

export default SingleSelectTiles;
