import Unschedule from 'assets/close-circle.svg?react';
import Edit from 'assets/edit.svg?react';
import Time from 'assets/time.svg?react';
import Remove from 'assets/trash-remove.svg?react';
import { dayOfMonthOptions, dayOfWeekOptions, sendReportScheduleFrequencyOptions } from 'constants/formOptions';
import { timeOptions } from 'constants/generated';
import { ReportSendScheduleSchema } from 'lib/model';
import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import ScheduleFormAddEmails from './ScheduleFormAddEmails';
import ScheduleFormUpdateSchedule from './ScheduleFormUpdateSchedule';
import { ScheduleReportAddEmailsFormValues, ScheduleReportFormValues } from './interfaces';

interface UpdateScheduleFormProps {
  schedule: ReportSendScheduleSchema;
  updateSchedule: (data: ScheduleReportFormValues) => void;
  deleteSchedule: () => void;
}

function UpdateScheduleForm({ schedule, updateSchedule, deleteSchedule }: UpdateScheduleFormProps) {
  const [editSchedule, setEditSchedule] = useState<boolean>(false);
  const { watch, reset, setValue, getValues, handleSubmit } = useForm<ScheduleReportFormValues>({
    defaultValues: {
      emails: schedule.user_emails,
      scheduleFrequency: schedule.frequency
        ? sendReportScheduleFrequencyOptions.find((option) => option.id === schedule.frequency)
        : sendReportScheduleFrequencyOptions[0],
      scheduleTimeOfDay: schedule.time_of_day
        ? timeOptions.find((option) => option.id + ':00' === schedule.time_of_day)
        : timeOptions[18],
      scheduleDayOfWeek: schedule.day_of_week
        ? dayOfWeekOptions.find((option) => option.id === schedule.day_of_week)
        : dayOfWeekOptions[0],
      scheduleDayOfMonth: schedule.day_of_month
        ? dayOfMonthOptions.find((option) => option.id === schedule.day_of_month)
        : dayOfMonthOptions[0],
      isEndDateEnabled: !!schedule.end_date,
      scheduleEndDate: schedule.end_date
    }
  });
  const scheduleFrequency = watch('scheduleFrequency');
  const emails = watch('emails');
  const [time, dayOfWeek, dayOfMonth, endDate] = getValues([
    'scheduleTimeOfDay',
    'scheduleDayOfWeek',
    'scheduleDayOfMonth',
    'scheduleEndDate'
  ]);

  const toggleEditSchedule = useCallback(function toggleEditSchedule() {
    setEditSchedule((state) => !state);
  }, []);

  const saveSchedule = useCallback(
    function saveSchedule(data: ScheduleReportFormValues) {
      reset((old) => ({ ...old, ...data }));
      setEditSchedule((state) => !state);
    },
    [reset]
  );

  const addEmails = useCallback(
    function addEmails(data: ScheduleReportAddEmailsFormValues) {
      const newEmails = [...new Set([...(emails ?? []), ...(data.emails ?? [])])];
      setValue('emails', newEmails);
    },
    [emails, setValue]
  );

  const removeEmail = useCallback(
    function removeEmail(email: string) {
      return () => {
        const newEmails = emails?.filter((oldEmail) => oldEmail !== email) ?? [];
        setValue('emails', newEmails);
      };
    },
    [emails, setValue]
  );

  const removeAllEmails = useCallback(
    function removeAllEmails() {
      setValue('emails', []);
    },
    [setValue]
  );

  return (
    <>
      {!editSchedule ? (
        <form
          className="flex flex-col gap-6 rounded-md bg-orange-50 p-6"
          id="send-report-form"
          onSubmit={handleSubmit(updateSchedule)}
        >
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-2">
              <Time className="size-6 fill-gray-700" />
              <span className="text-md font-semibold">Schedule</span>
            </div>
            <div className="flex items-center gap-4">
              <button type="button" className="flex items-center gap-2" onClick={toggleEditSchedule}>
                <span className="text-sm text-brand-800">Edit</span>
                <Edit className="size-5 fill-brand-800" />
              </button>
              <button type="button" className="flex items-center gap-2" onClick={deleteSchedule}>
                <span className="text-sm text-brand-800">Unschedule</span>
                <Unschedule className="size-5 fill-brand-800" />
              </button>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <span className="text-md font-medium">
              {scheduleFrequency.id === 'daily' && `${scheduleFrequency.label} at ${time?.label}`}
              {scheduleFrequency.id === 'weekly' &&
                `${scheduleFrequency.label} on ${dayOfWeek?.label} at ${time?.label}`}
              {scheduleFrequency.id === 'monthly' &&
                `${scheduleFrequency.label} on ${dayOfMonth?.label} at ${time?.label}`}
              {scheduleFrequency.id === 'after_iteration' && scheduleFrequency.label}
            </span>
            {endDate && (
              <span className="text-md font-medium">
                {`Schedule ends on ${new Date(endDate).toLocaleString(undefined, {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}`}
              </span>
            )}
          </div>
        </form>
      ) : (
        <ScheduleFormUpdateSchedule onCancel={toggleEditSchedule} onUpdateSchedule={saveSchedule} schedule={schedule} />
      )}
      <div className="flex flex-col gap-6">
        <div className="flex items-center justify-between gap-6">
          <span className="text-md font-semibold text-gray-900">Send report to email</span>
          <button className="flex items-center gap-2" onClick={removeAllEmails}>
            <span className="text-sm font-medium text-brand-800">Remove All</span>
            <Remove className="size-5 fill-brand-800" />
          </button>
        </div>
        <ScheduleFormAddEmails onAddEmails={addEmails} />
        <div className="flex flex-col gap-2">
          {emails?.map((email) => (
            <div className="flex items-center justify-between gap-6 rounded-md bg-gray-50 px-3 py-4" key={email}>
              <span className="text-sm font-semibold">{email}</span>
              <button className="flex items-center gap-2" onClick={removeEmail(email)}>
                <span className="text-sm font-medium text-brand-800">Remove</span>
                <Remove className="size-5 fill-brand-800" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default memo(UpdateScheduleForm);
