import ChevronRight from 'assets/chevron-right.svg?react';
import { usePostUserLogin } from 'lib/user-account/user-account';
import Button from 'modules/common/Button';
import Checkbox from 'modules/common/Form/Checkbox';
import Input from 'modules/common/Form/Input';
import PasswordInput from 'modules/common/Form/PasswordInput';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

interface LoginFormValues {
  email: string;
  password: string;
  remember: boolean;
}

const LoginForm = memo(function LoginForm() {
  const rememberedUser = localStorage.getItem('rememberedUser');
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get('redirect');
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<LoginFormValues>({
    defaultValues: {
      email: rememberedUser ?? '',
      password: '',
      remember: !!rememberedUser
    }
  });
  const [loginError, setLoginError] = useState(false);

  const navigate = useNavigate();

  function onSubmit(formData: LoginFormValues) {
    if (formData.remember) {
      localStorage.setItem('rememberedUser', formData.email);
    } else {
      localStorage.removeItem('rememberedUser');
    }

    setLoginError(false);
    logIn(
      { data: { email: formData.email, password: formData.password } },
      {
        onSuccess: (data) => {
          localStorage.setItem('token', data.token!);
          localStorage.setItem('refresh_token', data.refresh_token!);
          if (redirectUrl) {
            navigate(redirectUrl);
          } else {
            navigate('/');
          }
        }
      }
    );
  }

  const { mutate: logIn, isPending } = usePostUserLogin({
    mutation: {
      onError: () => {
        setLoginError(true);
      }
    }
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-10">
      <section className="flex flex-col gap-6">
        <span className="text-base font-semibold text-gray-900">User information</span>
        <div className="flex flex-col gap-2">
          <Input
            registerReturn={register('email', {
              required: 'Email is required.',
              pattern: {
                value: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Invalid Email pattern.'
              }
            })}
            label="Email"
            error={errors.email}
          />
        </div>
        <PasswordInput
          registerReturn={register('password', { required: 'Password is required' })}
          label="Password"
          error={errors.password}
        />
        <Checkbox registerReturn={register('remember')} label="Remember me on this device" />
      </section>
      <section className="flex flex-col gap-6">
        <div className="flex flex-col">
          <Button isSubmitButton={true} loading={isPending} size="xl">
            Sign in
          </Button>
          {loginError && (
            <span className="mt-1 text-md font-semibold text-red-600">
              Email or password are incorrect. Please try again.
            </span>
          )}
        </div>
        <div className="flex flex-col items-start gap-3">
          <Link to="/password-reset" className="flex items-center">
            <span className="text-md font-semibold text-brand-800">Forgot Password</span>
            <ChevronRight width={24} height={24} className="fill-brand-800" />
          </Link>
          <div>
            <span className="text-md">You don't have an account? </span>
            <Link to="/register" className="inline-flex items-center">
              <span className="text-md font-semibold text-brand-800">Sign up</span>
              <ChevronRight width={24} height={24} className="fill-brand-800" />
            </Link>
          </div>
        </div>
      </section>
    </form>
  );
});

export default LoginForm;
