/* eslint-disable react-refresh/only-export-components */
import { Fieldset, Legend } from '@headlessui/react';
import { memo, PropsWithChildren, useCallback } from 'react';
import { FieldValues, Path, PathValue, useController, UseControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface ToggleFieldsetProps<T extends FieldValues> extends PropsWithChildren, UseControllerProps<T> {
  legend: string;
  onCheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUncheck?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function ToggleFieldset<T extends FieldValues>({
  legend,
  children,
  onCheck,
  onUncheck,
  defaultValue = false as PathValue<T, Path<T>>,
  ...formProps
}: ToggleFieldsetProps<T>) {
  const {
    field: { onChange: onChangeEnabled, value: enabled }
  } = useController<T>({ ...formProps, defaultValue });

  const handleChange = useCallback(
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.checked) {
        if (onCheck) {
          onCheck(e);
        }
      } else {
        if (onUncheck) {
          onUncheck(e);
        }
      }
      onChangeEnabled(e);
    },
    [onChangeEnabled, onCheck, onUncheck]
  );

  return (
    <Fieldset className="flex flex-col gap-6 bg-gray-50 p-6">
      <div className="flex items-center gap-2">
        <input
          checked={enabled}
          onChange={handleChange}
          type="checkbox"
          className="h-4.5 w-4.5 rounded-sm border-gray-500 text-brand-800 focus:ring-0 ui-disabled:bg-gray-50"
        />
        <Legend className={twMerge('text-md font-medium', !enabled && 'text-gray-500')}>{legend}</Legend>
      </div>
      {enabled && children}
    </Fieldset>
  );
}

export default memo(ToggleFieldset) as typeof ToggleFieldset;
