import { memo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import ScoutReportFilteredForm from './ScoutReportFilteredPlayersForm/ScoutReportFilteredForm';
import ScoutReportManualForm from './ScoutReportManualPlayersForm/ScoutReportManualForm';
import { ScoutReportFormValues } from './interfaces';

interface ScoutReportFormProps {
  scoutReportForm: UseFormReturn<ScoutReportFormValues>;
  scoutReportType: string | null;
  onBack: () => void;
}

function ScoutReportForm({ scoutReportType, ...props }: ScoutReportFormProps) {
  if (scoutReportType === 'manual') {
    return <ScoutReportManualForm {...props} />;
  } else if (scoutReportType === 'filter') {
    return <ScoutReportFilteredForm {...props} />;
  }
  return null;
}

export default memo(ScoutReportForm);
