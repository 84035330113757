import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import MatchHeatMapElement from './MatchHeatMapElement';
import SubjectHeatMapElement from './SubjectHeatMapElement';

interface HeatMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const HeatMapElement = memo(function HeatMapElement({ element, report }: HeatMapElementProps) {
  if (report.report_type === 'match') {
    return <MatchHeatMapElement element={element} report={report} />;
  } else {
    return <SubjectHeatMapElement element={element} report={report} />;
  }
});

export default HeatMapElement;
