/* eslint-disable @typescript-eslint/no-explicit-any */
import { flexRender, Table } from '@tanstack/react-table';
import { defaultReportsTablePageSize } from 'constants/tableDefaults';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import ReportTableSkeleton from './ReportsTableSkeleton';

interface ReportsTableProps {
  table: Table<any>;
  isDataFetching: boolean;
  rowRedirectKey?: string;
}

export default function ReportsTable({ table, isDataFetching, rowRedirectKey }: ReportsTableProps) {
  const navigate = useNavigate();

  const redirectToEntity = useCallback(
    function redirectToEntity(data: any) {
      return () => {
        if (rowRedirectKey) {
          navigate(`/reports/${data[rowRedirectKey]}`);
        }
      };
    },
    [navigate, rowRedirectKey]
  );

  return (
    <>
      {isDataFetching ? (
        <ReportTableSkeleton rowNum={table.getState()?.pagination?.pageSize ?? defaultReportsTablePageSize} />
      ) : (
        <div className="grid max-w-full grid-cols-cards items-center overflow-x-auto">
          <table className="w-full border-separate border-spacing-y-3" border={0} cellPadding="0" cellSpacing="0">
            {/* <thead className="">
              <tr>
                {table.getHeaderGroups().flatMap((headerGroup) => {
                  return headerGroup.headers.map((header) => {
                    const { column } = header;
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="bg-white px-2.5 py-4 text-start first:rounded-l-xl first:pl-4 last:rounded-r-xl last:pr-4"
                        style={{
                          width: header.id === 'actions' ? 56 : undefined
                        }}
                      >
                        {header.isPlaceholder ? null : flexRender(column.columnDef.header, header.getContext())}
                      </th>
                    );
                  });
                })}
              </tr>
            </thead> */}
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr
                    key={row.id}
                    className={twJoin('group whitespace-nowrap', rowRedirectKey && 'cursor-pointer')}
                    onClick={redirectToEntity(row.original)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          className="bg-white px-2.5 py-4 first:rounded-l-xl first:pl-4 last:rounded-r-xl last:pr-4"
                          style={{
                            width: cell.column.id === 'actions' ? 56 : undefined
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
