import { ReportElementSchema } from 'lib/model';
import { getDefaultColor } from 'modules/reports/helpers';
import { memo, useMemo } from 'react';
import { LineupTeam } from '../../interfaces';
import LineupPitchFormation from '../LineupPitchFormation';
import LineupPitchGrid from '../LineupPitchGrid';
import { createTeamGrid } from './helpers';

interface TeamLineupPitchProps {
  element: ReportElementSchema;
}

const TeamLineupPitch = memo(function TeamLineupPitch({ element }: TeamLineupPitchProps) {
  const team = element.entity_data!.team as LineupTeam;
  team.team_color ??= getDefaultColor(0);
  const teamGrid = useMemo(() => createTeamGrid(team), [team]);

  return (
    <div className="flex flex-col gap-3">
      <span className="text-xs font-semibold">Most commonly used formation from the last 10 games</span>
      <div className="flex w-full justify-center rounded-xl bg-gray-50">
        <div className="min-w-fit px-6 py-4">
          <LineupPitchFormation team={team} />
        </div>
        <div
          className="relative grid aspect-football-field-half w-full max-w-screen-sm grid-cols-1 bg-football-field-upper-half bg-contain p-football-field"
          style={{ gridTemplateRows: `repeat(${teamGrid?.length ?? 0}, minmax(0, 1fr))` }}
        >
          <LineupPitchGrid grid={teamGrid} team={team} widthBorder={400} />
        </div>
      </div>
    </div>
  );
});

export default TeamLineupPitch;
