import { memo, PropsWithChildren } from 'react';
import { twJoin } from 'tailwind-merge';

interface TooltipProps extends PropsWithChildren {
  message: string;
  background?: string;
  padding?: string;
}

const Tooltip = memo(function Tooltip({ message, background, padding, children }: TooltipProps) {
  return (
    <div className={twJoin('group relative flex items-center justify-center gap-1 rounded-full', background, padding)}>
      {children}
      {message && (
        <span className="absolute bottom-10 scale-0 rounded bg-gray-950 p-2 text-xs text-white transition-all after:absolute after:left-1/2 after:top-full after:ml-[-5px] after:border-[5px] after:border-solid after:border-x-transparent after:border-b-transparent after:border-t-gray-950 group-hover:scale-100">
          {message}
        </span>
      )}
    </div>
  );
});

export default Tooltip;
